import { Button } from "react-bootstrap";
import styled from "styled-components";

export const TestOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  border-radius: 24px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  .test__passed:hover .test__overlay {
    opacity: 0;
    pointer-events: none;
  }
`;

export const TestBtn = styled(Button)`
  font-size: 11px;
  color: #808191;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  border: none;
  border-radius: 14px;
  padding: 7px 16px 6px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  opacity: 0;
  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: #6c5dd3;
    border: none;
    box-shadow: none;
    color: #fff;
  }
`;

export const Tooltip = styled.span`
  width: 160px;
  visibility: hidden;
  opacity: 0;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 8px 10px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -60px;
  transition: opacity 0.3s;
  font-size: 14px;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

export const TestBox = styled.div`
  width: 140px;
  height: 130px;
  min-height: 130px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  position: relative;
  padding: 5px;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }

  &.test__passed:hover ${TestOverlay} {
    opacity: 0;
    pointer-events: none;
  }

  &.toolText:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  &:hover ${TestOverlay} {
    opacity: 1;
    pointer-events: initial;
  }

  &:hover ${TestBtn} {
    opacity: 1;
  }
  &.fail {
    background-color: #bdbdbd;
  }
  &.malibu {
    background-color: #61dafb;
  }
  &.sushi {
    background-color: #8cc84b;
  }
  &.monza {
    background-color: #dd0031;
  }
  &.astral {
    background-color: #376e9d;
  }
  &.ocean-green {
    background-color: #41b883;
  }
  &.cerise {
    background-color: #189e70;
  }
  &.cream-can {
    background-color: #f65b69ff;
  }
  &.shandy {
    background-color: #c2b280;
  }
  &.fireBrick {
    background-color: #a6120d;
  }
  &.dotnetcolor {
    background-color: #682876;
  }
  &.gopher-blue {
    background-color: #00add8;
  }
  &.dark-green {
    background-color: #092e20;
  }
  &.orange-yellow {
    background-color: #ff9900;
  }
  &.android-green {
    background-color: #a4c639ff;
  }
  &.IOS-dark {
    background-color: #4d4d4dff;
  }
  &.flutter-blue {
    background-color: #1a68dfff;
  }
  &.reactNative-blue {
    background-color: #32a7c6;
  }
  &.drupal-blue {
    background-color: #228ea9;
  }
  &.devops-green {
    background-color: #02cc5c;
  }
  &.azure-devops-green {
    background-color: #069af3;
  }
  &.java-blue {
    background-color: #4c7491;
  }
  &.data-science-red {
    background-color: #e25344;
  }
  &.magento-orange {
    background-color: #fc6b03;
  }
  &.php-blue {
    background-color: #767db2;
  }
  &.oracle-red {
    background-color: #ff0000;
  }
  &.gcp-blue {
    background-color: #4285f4;
  }
  &.default {
    background-color: #d6ddea;
  }
  &.non-tech-color {
    background-color: #32a7c6;
  }
`;

export const StatusIconBox = styled.div`
  position: absolute;
  top: -5px;
  right: -3px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TestImgBox = styled.span`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  pointer-events: none;
  padding: 5px;
  margin-bottom: 10px;
  img {
    max-width: 100%;
    &.grayed {
      filter: grayscale(1);
    }
  }
`;

export const TestBoxDetails = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 23px;
  align-items: center;
`;

export const TestBoxText = styled.span`
  text-align: center;
  font-size: 13px;
  color: #fff;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
`;

export const SubText = styled.span`
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin-top: 1px;
  font-family: "Poppins", sans-serif;
`;
