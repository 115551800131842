import { FC } from "react";
import { imageUrl } from "utils";
import * as Styled from "./styles";

export const NoUsersList: FC = () => {
  return (
    <Styled.ChatBodyNoMsgWrapper>
      <Styled.ChatBodyNoMsgInner>
        <Styled.ChatBodyNoMsgImg>
          <img src={imageUrl.noUserListImg} alt="No chat image" />
        </Styled.ChatBodyNoMsgImg>
        <Styled.ChatBodyNoMsgHd>No chat messages</Styled.ChatBodyNoMsgHd>
        <Styled.ChatBodyNoMsgPara>No chats in the list yet.</Styled.ChatBodyNoMsgPara>
      </Styled.ChatBodyNoMsgInner>
    </Styled.ChatBodyNoMsgWrapper>
  );
};

export default NoUsersList;
