import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const MarketingModal = styled(Modal)`
  .modal-dialog {
    max-width: 640px;
  }
  .modal-content {
    background: transparent;
    border-radius: 16px;
  }
  .modal-body {
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 0 24px !important;
  }
`;

export const CrossIconBox = styled.span`
  font-size: 16px;
  color: #11142d;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  right: -15px;
  top: -12px;
  z-index: 5;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: #11142d;
    color: #fff;
  }
  @media (max-width: 767px) {
    top: -10px;
    right: -12px;
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
`;

export const ModalImage = styled.img`
  width: 100%;
`;
