import { FC, useContext, useEffect, useState } from "react";
import { initialTalentContext, initialTalentTypeContext } from "utils";
import { useGetTalentType } from "hooks";
import {
  ProfileStepContext,
  ProfileTalentContext,
  ProfileTalentTypeContext,
} from "./profileSteps.context";
import {
  IProfileTalent,
  IProfileTalentType,
  IProfileUpdate,
  ProfileContextType,
} from "./profileSteps.interface";
import { ProfileContext } from "./profile.context";
import { getEngProfileLevel, getProfileLevel } from "./profileSteps.helpers";

export const TalentTypeProvider: FC = ({ children }) => {
  const [talentType, setTalentType] = useState<IProfileTalentType>(initialTalentTypeContext);
  const { profileState } = useContext<ProfileContextType>(ProfileContext);
  const { getTalentTypes, talentTypes, loading } = useGetTalentType();

  useEffect(() => {
    if (talentTypes?.length)
      setTalentType({
        talentList: talentTypes,
        selectedTalent: profileState.data?.profile?.talentType || talentTypes[0],
      });
  }, [talentTypes]);

  useEffect(() => {
    getTalentTypes({ variables: { sortType: "TalentType", filter: { isActive: { eq: true } } } });
  }, []);

  return (
    <ProfileTalentTypeContext.Provider value={{ talentType, setTalentType, loading }}>
      {children}
    </ProfileTalentTypeContext.Provider>
  );
};

export const ProfileTalentProvider: FC = ({ children }) => {
  const [talent, setTalent] = useState(initialTalentContext);

  const updateTalent = (data: IProfileUpdate): void => {
    setTalent((prev: IProfileTalent): IProfileTalent => ({ ...prev, ...data }));
  };

  return (
    <ProfileTalentContext.Provider value={{ talent, setTalent: updateTalent }}>
      {children}
    </ProfileTalentContext.Provider>
  );
};

export const ProfileStepsProvider: FC = ({ children }) => {
  const [profileStep, setProfileStep] = useState(0);
  const [engineerStep, setEngineerStep] = useState(0);

  const { talent } = useContext(ProfileTalentContext);
  const {
    profileState: { data },
  } = useContext<ProfileContextType>(ProfileContext);

  useEffect(() => {
    setProfileStep(getEngProfileLevel(talent));
  }, [talent]);

  useEffect(() => {
    setEngineerStep(getProfileLevel(data));
  }, [data]);

  return (
    <ProfileStepContext.Provider
      value={{ engineerStep, profileStep, setProfileStep, setEngineerStep }}
    >
      {children}
    </ProfileStepContext.Provider>
  );
};
