import { NoDomainTest } from "assets/icons";
import * as Styled from "./styles";

import { bodyText, titleText } from "./constants";

export default function Page() {
  return (
    <Styled.Parent>
      <Styled.Container>
        <NoDomainTest />
        <Styled.TitlePara>{titleText}</Styled.TitlePara>

        <Styled.BodyPara>{bodyText}</Styled.BodyPara>
      </Styled.Container>
    </Styled.Parent>
  );
}
