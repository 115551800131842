import { ArrowLeftIcon, SearchIcon } from "assets/icons";
import { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import * as Styled from "../../styles";

interface Props {
  handleChange: (text: string) => void;
}

export const MyProfileHeader: FC<Props> = ({ handleChange }) => {
  const history = useHistory();
  return (
    <Fragment>
      <Styled.ChatListHeader>
        <Styled.ChatListHeaderLeft>
          <Styled.ChatListHeaderIcon onClick={history.goBack}>
            <ArrowLeftIcon />
          </Styled.ChatListHeaderIcon>
          <Styled.ChatListHeaderBackText>Back to dashboard</Styled.ChatListHeaderBackText>
        </Styled.ChatListHeaderLeft>
      </Styled.ChatListHeader>
      <Styled.ChatListSearchWrapper>
        <Styled.ChatListSearchInput>
          <input
            type="text"
            placeholder="Search a chat"
            onChange={(e): void => handleChange(e.target.value)}
          />
          <Styled.ChatListSearchInputIcon>
            <SearchIcon />
          </Styled.ChatListSearchInputIcon>
        </Styled.ChatListSearchInput>
      </Styled.ChatListSearchWrapper>
    </Fragment>
  );
};

export default MyProfileHeader;
