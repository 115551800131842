import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const UserProfileModal = styled(Modal)`
  .modal-content {
    max-width: 640px;
    background: #ffffff;
    border-radius: 16px;
  }
  .modal-body {
    padding: 0;
  }
`;

export const CrossIconBox = styled.span`
  font-size: 16px;
  color: #11142d;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  right: -15px;
  top: -12px;
  z-index: 5;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: #11142d;
    color: #fff;
  }
  @media (max-width: 767px) {
    top: -10px;
    right: -12px;
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
`;

export const UserProfileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UserProfileHeader = styled.div`
  width: 100%;
  padding: 10px 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f0f2f5;
  border-radius: 12px 12px 0px 0px;
  border: none;
`;

export const UserProfileHeaderText = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #111b21;
  margin: 0px;
`;

export const UserProfileInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserProfileBox = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImgAvatarXl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  font-size: 70px;
  margin-bottom: 20px;
  background: rgb(249 249 249);
  color: #000000;
  text-align: center;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

export const UserProfileHeaderName = styled.div`
  display: flex;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  color: #111b21;
  margin: 0px 0px 6px 0px;
  text-align: center;
`;

export const UserProfileHeaderNumber = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color: #667781;
  margin: 0px;
  text-align: center;
`;
