import { ConversationResponse } from "@remotebase/amplify-constants/API";
import { getIcon } from "hooks/utils";
import { FC, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { withProfile } from "state/profileSteps";
import { ICONS, ProfileProps } from "utils";
import * as Styled from "./styles";

interface Props {
  onCancel: () => void;
  showModal: boolean;
  currentUser: ConversationResponse | null;
}

const UserProfileModal: FC<Props & ProfileProps> = ({
  onCancel,
  showModal,
  currentUser,
  profileState: { data },
}) => {
  const initials = useMemo(() => {
    const name = currentUser?.name?.split(" ");
    if (name) {
      const nameInitials = name?.[0].charAt(0) + name?.[1].charAt(0);
      return nameInitials.toUpperCase();
    }
    return "";
  }, [currentUser]);

  const userInfo = useMemo(() => {
    const { conversationParticipants } = currentUser || {};
    if (conversationParticipants?.length)
      return conversationParticipants?.filter((item) => item?.id !== data?.id)[0];
    return null;
  }, [currentUser, data]);
  return (
    <Styled.UserProfileModal
      show={showModal}
      onHide={onCancel}
      centered
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <Styled.CrossIconBox onClick={onCancel}>{getIcon(ICONS.CLOSE)}</Styled.CrossIconBox>
        <Styled.UserProfileWrapper>
          <Styled.UserProfileHeader>
            <Styled.UserProfileHeaderText>Contact info</Styled.UserProfileHeaderText>
          </Styled.UserProfileHeader>
          <Styled.UserProfileInner>
            <Styled.UserProfileBox>
              <Styled.ImgAvatarXl>{initials}</Styled.ImgAvatarXl>
              <Styled.UserProfileHeaderName>{currentUser?.name}</Styled.UserProfileHeaderName>
              <Styled.UserProfileHeaderNumber>{userInfo?.email}</Styled.UserProfileHeaderNumber>
            </Styled.UserProfileBox>
          </Styled.UserProfileInner>
        </Styled.UserProfileWrapper>
      </Modal.Body>
    </Styled.UserProfileModal>
  );
};

export default withProfile(UserProfileModal);
