import { ILandingSkillsList } from "state/landing";
import { imageUrl } from "./imagesUrls";

export const landingNavItems = [
  { text: "About", href: "#aboutUs" },
  { text: "Skills", href: "#skills" },
  { text: "Process", href: "#process" },
];

export const LandingSocialLinks = {
  facebook: "https://www.facebook.com/RemotebaseHQ",
  youtube: "https://youtube.com/channel/UCSNQhZZpw6bpe6_-5lvAESQ",
  instagram: "https://www.instagram.com/remotebasehq/",
};

export const LandingSkillsList: ILandingSkillsList[] = [
  { dataAos: "fade-up", name: "React.js", logo: imageUrl.logoJobsReact },
  { dataAos: "fade-up", name: "Node.js", logo: imageUrl.logoJobsNode },
  { dataAos: "fade-up", name: "Python", logo: imageUrl.logoJobsPython },
  { dataAos: "fade-up", name: "Ruby on Rails", logo: imageUrl.logoJobsRails },
  { dataAos: "fade-up", name: "GraphQL", logo: imageUrl.logoGraphql },
  { dataAos: "fade-up", name: "UI/UX Designer", logo: imageUrl.logoUIUX },
  { dataAos: "fade-up", name: "Tech Recruiter", logo: imageUrl.logoRecruiter },
  { dataAos: "fade-up", name: "SDR", logo: imageUrl.logoSDR },
  { dataAos: "fade-up", name: "Performance Marketer", logo: imageUrl.logoPerformanceMarketer },
];

export const redirectToHireDeveloper = "https://www.remotebase.com/start";
export const redirectToExperience = "https://www.remotebase.com/";
