import { FC, Fragment } from "react";
import * as Styled from "./styles";

interface Props {
  time: string;
}
const DateTime: FC<Props> = ({ time }) => {
  return time.length > 0 ? (
    <Styled.ChatBodyMsgTimeBox>{time}</Styled.ChatBodyMsgTimeBox>
  ) : (
    <Fragment />
  );
};

export default DateTime;
