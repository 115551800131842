import { FC, Fragment } from "react";
import { TechDomainSkills } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "../..";
import { JobsShimmer, TalentJobList, IJobsData } from "./components";

export interface ITalentMatchProp {
  jobsData: IJobsData;
  talentSkills: (TechDomainSkills | undefined)[] | undefined;
  loading: boolean;
}

export const TalentJobs: FC<ITalentMatchProp> = ({ jobsData, talentSkills, loading }) => {
  return (
    <Fragment>
      <ShouldRender if={loading}>
        <JobsShimmer />
      </ShouldRender>

      <ShouldRender if={!loading}>
        <TalentJobList talentSkills={talentSkills} jobsData={jobsData} loading={loading} />
      </ShouldRender>
    </Fragment>
  );
};
