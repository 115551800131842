import { FC, Fragment, useEffect } from "react";
import { ShouldRender } from "@remotebase/components";
import { Link } from "react-router-dom";
import { IProfileDetails, withProfile, withTalentLevel } from "state/profileSteps";
import { useTalentProfile } from "hooks";
import { VideoInterviewProvider } from "API";
import { AuthRoutes, interviewRecorded, ProfileProps, Sidebar, TalentLevelProps } from "utils";
import TalentlyInterview from "./TalentlyInterview";
import * as Styled from "./styles";

export const VideoWidget: FC<ProfileProps & TalentLevelProps> = ({
  profileState: { data },
  updateUserProfile,
  talentLevel: { activeStages },
}) => {
  const { updateProfile } = useTalentProfile();

  useEffect(() => {
    if (data?.profile) {
      if (
        !data.profile.videoInterviewProvider ||
        data.profile.videoInterviewProvider !== VideoInterviewProvider.Talently
      )
        updateProfile({
          variables: {
            input: {
              expectedVersion: data?.profile?.version,
              videoInterviewProvider: VideoInterviewProvider.Talently,
              id: data?.profile?.id,
            },
          },
          onCompleted: (updatedData) =>
            updateUserProfile(
              updatedData?.updateTalentProfileWithUsdSalary.data as IProfileDetails,
            ),
        });
    }
  }, []);

  return (
    <Styled.BtnsBox>
      <ShouldRender if={!data?.profile?.isPassHR}>
        <TalentlyInterview />
      </ShouldRender>
      <ShouldRender if={data?.profile?.isPassHR}>
        <Fragment>
          <Styled.BtnStart>{interviewRecorded}</Styled.BtnStart>
          <ShouldRender if={activeStages.includes(Sidebar.ProblemSolving)}>
            <Styled.Btn>
              <Link to={AuthRoutes.ProblemSolving}>Go to Problem Solving</Link>
            </Styled.Btn>
          </ShouldRender>
        </Fragment>
      </ShouldRender>
    </Styled.BtnsBox>
  );
};

export default withProfile(withTalentLevel(VideoWidget));
