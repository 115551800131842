import { FC, Fragment, useRef, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Color } from "@remotebase/constants";
import AvatarEditor from "react-avatar-editor";
import { ActionBtn } from "../../buttons";
import { RotateIcon, ZoomIcon } from "../../assets";
import { ModalComponent } from "../modalComponent";
import * as Styled from "./styles";
import {
  actionBtnWidth,
  cancelBtnText,
  networkFailureErrorMsg,
  networkFailureErrorTitle,
} from "../../utils";

interface Props {
  show: boolean;
  close: () => void;
  selectedImage: File;
  uploader: (file: File) => Promise<void>;
  uploading?: boolean;
}
export const UploadPhotoModal: FC<Props> = ({
  show,
  close,
  selectedImage,
  uploader,
  uploading,
}) => {
  const [loading, setLoading] = useState(false);
  const [imgScale, setImgScale] = useState(1);
  const [imgRotation, setImgRotation] = useState(0);
  const [showPhotoUploadErrorModal, setPhotoUploadErrorModal] = useState(false);

  const editorRef = useRef<AvatarEditor>(null);

  const toggleErrorModal = (): void => setPhotoUploadErrorModal((val) => !val);

  const handleSave = (): void => {
    if (!editorRef.current) return;
    setLoading(true);
    const scaledImage = editorRef.current.getImageScaledToCanvas();
    // eslint-disable-next-line
    scaledImage.toBlob((blob: any) => {
      const photoFile = new File([blob], "dp.png", { type: "image/png" });
      uploader(photoFile);
    });

    setLoading(false);
  };
  const handleRotation = (): void => {
    let newImgRotation = imgRotation + 90;
    if (newImgRotation === 360) newImgRotation = 0;
    setImgRotation(newImgRotation);
  };

  return (
    <ModalComponent show={show} handleClose={close}>
      <Styled.SlimmerWrapper>
        <Styled.SlimmerHeading>Update your avatar</Styled.SlimmerHeading>

        <Styled.FormBody>
          <Styled.AvatarEditorContainer>
            <AvatarEditor
              ref={editorRef}
              image={selectedImage}
              width={300}
              height={300}
              border={[75, 25]}
              rotate={imgRotation}
              borderRadius={200}
              scale={imgScale}
              onLoadFailure={toggleErrorModal}
            />
          </Styled.AvatarEditorContainer>

          <Styled.ImageEditContainer>
            <Styled.ZoomIconText>
              <ZoomIcon width={17.5} height={17.5} />
              <p>
                <small>Zoom</small>
              </p>
            </Styled.ZoomIconText>
            <Form.Control
              type="range"
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
              onChange={(event): void => {
                setImgScale(parseFloat(event.target.value));
              }}
            />
            <Styled.VerticalLine></Styled.VerticalLine>
            <Styled.RotateIconText onClick={handleRotation}>
              <RotateIcon width={20} height={20} />
              <p>
                <small>Rotate</small>
              </p>
            </Styled.RotateIconText>
          </Styled.ImageEditContainer>
        </Styled.FormBody>

        <Styled.BtnGroup>
          <ActionBtn
            btnText={cancelBtnText}
            clickHandler={close}
            width={actionBtnWidth}
            color={Color.LIGHT_BLUE}
            backgroundColor={Color.TRANSPARENT}
          />
          <ActionBtn
            btnText={"Save"}
            isLoading={loading || uploading}
            clickHandler={handleSave}
            width={actionBtnWidth}
            color={Color.WHITE}
            backgroundColor={Color.LIGHT_BLUE}
            disabled={loading || uploading}
          />
        </Styled.BtnGroup>

        <ModalComponent show={showPhotoUploadErrorModal} handleClose={toggleErrorModal}>
          <Fragment>
            <Modal.Header>
              <Modal.Title>{networkFailureErrorTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{networkFailureErrorMsg}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={toggleErrorModal}>
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        </ModalComponent>
      </Styled.SlimmerWrapper>
    </ModalComponent>
  );
};

export default UploadPhotoModal;
