export const getTalentTypeSorted = /* GraphQL */ `
  query GetTalentTypeSorted(
    $sortType: String
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTalentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTalentTypeSorted(
      sortType: $sortType
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        title
        icon
        sequence
        sortType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export default getTalentTypeSorted;
