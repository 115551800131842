import { FC, useState } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IStatusBlock } from "state/dashboard";
import * as Styled from "./styles";

type Props = {
  block: IStatusBlock;
  isActive?: boolean;
  index: number;
};

const StatusSingleBlock: FC<Props> = (props) => {
  const history = useHistory();
  const { block, isActive, index } = props;
  const [elementId] = useState(`${block.text} block`);

  const handleClick = (): void => {
    if (isActive) {
      const { path } = block;
      history.push(path);
    }
  };

  return (
    <Col xs={12} sm={6} lg={3}>
      <Styled.DashboardBox
        data-testid={elementId}
        id={elementId}
        active={!!isActive}
        color={block.color}
        onClick={handleClick}
      >
        <Styled.DashboardBoxDigit>{index}</Styled.DashboardBoxDigit>
        <Styled.DashboardBoxText>{block.text}</Styled.DashboardBoxText>
      </Styled.DashboardBox>
    </Col>
  );
};

export default StatusSingleBlock;
