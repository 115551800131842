import { ConversationResponse, PersonInfo } from "@remotebase/amplify-constants/API";
import { FC, useEffect, useMemo, useState } from "react";
import { withCurrentUserChat } from "state/chat";
import { withProfile } from "state/profileSteps";
import { CurrentChatProps, getFormattedDay, ProfileProps } from "utils";
import * as Styled from "../../styles";

interface Props {
  data: ConversationResponse | null;
}
export const SingleUserBox: FC<Props & CurrentChatProps & ProfileProps> = ({
  data,
  updateRecentMessage,
  recentMessages,
  profileState: { data: currentProfile },
}) => {
  const [participants, setParticipants] = useState<PersonInfo | null>();
  const [firstRendered, setFirstRendered] = useState(false);

  useEffect(() => {
    if (data?.id && !firstRendered) {
      setFirstRendered(true);
      updateRecentMessage(data.messages?.[0] || null, data.id);
    }
  }, [data]);

  const initials = useMemo(() => {
    const temp = data?.conversationParticipants?.filter(
      (item) => item?.id !== currentProfile?.id,
    )[0];
    setParticipants(temp);
    const name = (temp?.fullName || data?.name || "").split(" ");
    if (name) {
      let nameInitials = name?.[0]?.charAt(0);
      if (name?.[1]?.charAt(0).length) nameInitials += name?.[1]?.charAt(0);
      return nameInitials.toUpperCase();
    }
    return "";
  }, [data?.name]);

  const msgToShow = useMemo(
    () => recentMessages.filter((item) => item.conversationId === data?.id)[0],
    [recentMessages],
  );

  return (
    <Styled.ChatListMsgListBox>
      <Styled.ImgAvatarMd>{initials}</Styled.ImgAvatarMd>
      <Styled.ChatListMsgListContent>
        <Styled.ChatListMsgTop>
          <Styled.ChatListMsgName>{participants?.fullName || data?.name}</Styled.ChatListMsgName>
          <Styled.ChatListMsgTime>
            {getFormattedDay(msgToShow?.message?.createdAt || data?.createdAt || "")}
          </Styled.ChatListMsgTime>
        </Styled.ChatListMsgTop>
        <Styled.ChatListMsgBtm>
          <Styled.ChatListMsgText>{msgToShow?.message?.content}</Styled.ChatListMsgText>
          {!msgToShow?.isReadConversation && <Styled.ChatListMsgUnreadBadge />}
        </Styled.ChatListMsgBtm>
      </Styled.ChatListMsgListContent>
    </Styled.ChatListMsgListBox>
  );
};

export default withCurrentUserChat(withProfile(SingleUserBox));
