import styled from "styled-components";

export const Banner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-row-gap: 8px;
  background: #3615bb;
  padding: 8px 36px 8px 32px;
  position: sticky;
  top: 0;
  z-index: 5;
  text-align: center;
  font-family: "Onest", sans-serif;

  &.banner__close {
    display: none;
  }

  @media screen and (min-width: 768px) {
    padding: 5px 50px;
  }
`;

export const BannerText = styled.div`
  font-size: 14px;
  color: #fff;
  line-height: 17.85px;
  font-weight: 300;
  margin-right: 10px;
  position: relative;

  & span {
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    padding-right: 15px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background: #fff;
      margin: auto 0;
    }
  }
`;

export const BannerDays = styled.div`
  font-size: 14px;
  color: #fff;
  line-height: 17.85px;
  font-weight: 500;
  margin-right: 12px;

  @media screen and (min-width: 768px) {
    margin-right: 30px;
  }
`;

export const RegisterBtn = styled.a`
  font-size: 14px;
  color: #312a50;
  line-height: 12px;
  font-weight: 500;
  padding: 6px 9px;
  background: #ffb900;
  border: none;
  box-shadow: none;
  text-decoration: none;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    color: #312a50;
    background: #d89e06;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
  }
`;

export const CloseBtn = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 8px;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    right: 50px;
    top: 6px;
  }
`;
