import { createContext, FC, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initialState = { hasDomainTest: true, setHasDomainTest: (hasDomainTest: boolean) => {} };

export const domainTextContext = createContext(initialState);

export const DomainTestProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const { hasDomainTest } = state;
  const setHasDomainTest = (value: boolean) => {
    setState((prev) => ({ ...prev, hasDomainTest: value }));
  };

  return (
    <domainTextContext.Provider value={{ hasDomainTest, setHasDomainTest }}>
      {children}
    </domainTextContext.Provider>
  );
};

export default DomainTestProvider;
