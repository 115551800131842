import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import mime from "mime-types";
import { Accept } from "react-dropzone";
import { IDocumentState, UseDocumentUploaderParams, UseDocumentUploaderReturnType } from "../types";
import * as Styled from "../styles";

export default function useDocumentUploader({
  handleSubmit,
  document,
  setDocument,
  pastDocumentUpload,
  allowedTypes,
  allowedTypesError,
  allowedSize,
  allowedSizeError,
}: UseDocumentUploaderParams): UseDocumentUploaderReturnType {
  const [documentErr, setDocumentErr] = useState("");
  const [pastDocumentName, setPastDocumentName] = useState<string | undefined>();

  const allowedSizeInMB = useMemo(() => Math.floor(allowedSize / (1024 * 1024)), [allowedSize]);
  const uploadIcon = useMemo(
    () => (document.isUploaded ? "check" : "upload"),
    [document.isUploaded],
  );
  const uploadText = useMemo((): JSX.Element => {
    if (document.isUploaded || document.isSubmitted || pastDocumentUpload)
      return (
        <Fragment>
          Your document is uploaded.
          <Styled.PurpleFont> Submit or upload another document ?</Styled.PurpleFont>
        </Fragment>
      );
    return (
      <Fragment>
        <Styled.PurpleFont>Upload a document</Styled.PurpleFont> or Drag and drop your document here
      </Fragment>
    );
  }, [document.isUploaded]);
  const allowedTypesExtension = useMemo(
    () => allowedTypes.map((type) => (mime.extension(type) ?? "").toString()),
    [allowedTypes],
  );
  const accept = useMemo(
    () =>
      allowedTypes.reduce((acc, type) => {
        const extension = mime.extension(type);
        if (extension) acc[type] = [`.${extension}`];
        return acc;
      }, {} as Accept),
    [allowedTypes],
  );

  const submitDocument = useCallback((): void => handleSubmit(document.file), [document.file]);
  const confirmDocument = useCallback(
    (uploadedDocument: File): void => {
      if (!uploadedDocument) return;
      const tempUpload: IDocumentState = { isUploaded: false };

      if (!allowedTypes.includes(uploadedDocument.type)) {
        setDocumentErr(allowedTypesError);
        return;
      }

      if (uploadedDocument.size >= allowedSize) {
        setDocumentErr(allowedSizeError);
        return;
      }

      setDocumentErr("");
      setDocument({ ...tempUpload, isUploaded: true, file: uploadedDocument });
    },
    [allowedTypes, allowedTypesError, allowedSize, allowedSizeError],
  );
  const getSeperator = useCallback((index: number): string => {
    if (index === allowedTypes.length - 2) return " and ";
    if (index === allowedTypes.length - 1) return "";

    return ", ";
  }, []);

  useEffect(() => {
    if (pastDocumentUpload) {
      const elems = pastDocumentUpload.split("/");
      const [fileCode, ...fileName] = elems[elems.length - 1].split("-");
      setPastDocumentName(fileName.length ? fileName.join("-") : fileCode);
    }
  }, [pastDocumentUpload]);

  return {
    documentErr,
    pastDocumentName,
    allowedSizeInMB,
    submitDocument,
    confirmDocument,
    uploadIcon,
    uploadText,
    allowedTypesExtension,
    getSeperator,
    accept,
  };
}
