/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from "react";
import {
  DocumentUploaderCard,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "@remotebase/components";
import { TalentProfile } from "@remotebase/amplify-constants/API";
import { useAnalyticsEventCreator, useFileUpload } from "hooks";
import { IErrorStateType, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import * as S from "../styles";

interface IEditResume {
  data: any;
  close: () => void;
  updateProfile: (unknown: any) => void;
  profileLoading: boolean;
  profileData?: TalentProfile | null;
  showError: (data: IErrorStateType) => void;
}

interface IResumeState {
  file?: File;
  isUploaded: boolean;
  isSubmitted?: boolean;
}

const initialResumeState: IResumeState = { isUploaded: false };

export const EditProfileResume: FC<IEditResume> = ({
  close,
  updateProfile,
  profileLoading,
  profileData,
  data,
  showError: showMessage,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resume, setResume] = useState(initialResumeState);

  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const handleClose = (): void => {
    if (close) close();
  };
  const {
    res: { isLoading, data: newResumeLink, error: uploadError },
    uploadResume,
  } = useFileUpload();

  const handleSubmit = (file?: File): void => {
    setIsSubmitted(true);
    if (file) uploadResume(file);
  };
  useEffect(() => {
    if (updateProfile && newResumeLink) {
      updateProfile({
        variables: {
          input: {
            id: data?.profile?.id,
            expectedVersion: data?.profile?.version,
            resumeLink: newResumeLink,
          },
        },
        onCompleted: () => {
          showMessage({ title: submissionSuccessTitle, message: submissionProfileSuccessMessage });
          analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE_RESUME);
        },
        onError: () => {
          showMessage({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
          analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE_RESUME_FAILED);
        },
      });
    }
  }, [updateProfile, newResumeLink, data]);

  useEffect(() => {
    if (profileData && !profileLoading && !isLoading && isSubmitted) {
      setIsSubmitted(false);
      handleClose();
    } else if (uploadError && !profileLoading && !isLoading && isSubmitted) {
      analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE_RESUME_FAILED);
    }
  }, [profileData, profileLoading, isLoading, isSubmitted, uploadError]);

  return (
    <S.EditorWrapper>
      <S.UploadResumeWrapper>
        <S.UploadBoxHeading>Upload Resume</S.UploadBoxHeading>
        <DocumentUploaderCard
          handleSubmit={handleSubmit}
          isLoading={isLoading || profileLoading}
          document={resume}
          setDocument={setResume}
        />
      </S.UploadResumeWrapper>
    </S.EditorWrapper>
  );
};

export default EditProfileResume;
