import React, { FC, Fragment, useContext } from "react";
import { ProfileStepContext } from "state/profileSteps";
import { tellUsMoreBtnText } from "utils";
import * as Styled from "./styles";

const AboutBtn: FC = () => {
  const { setEngineerStep } = useContext(ProfileStepContext);

  const nextStep = (): void => {
    setEngineerStep(1);
  };

  return (
    <Fragment>
      <h1>
        <Styled.PurpleFont>Welcome!</Styled.PurpleFont> Let’s create your profile.
      </h1>
      <p>Are you ready to work for some of the most innovative companies in the world?</p>
      <Styled.TellUsMoreBtn type="button" onClick={nextStep}>
        {tellUsMoreBtnText}
      </Styled.TellUsMoreBtn>
    </Fragment>
  );
};

export default AboutBtn;
