import { AuthType } from "utils";
import { Authentication } from "components";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import withError from "state/error/withErrorHoc";
import { ErrorProps } from "@remotebase/constants";

const LoginPage: FC<ErrorProps> = ({ showError }) => {
  const { search } = useLocation();
  const atsJobID = new URLSearchParams(search).get("atsJobID");
  const jobTitle = new URLSearchParams(search).get("jobTitle");

  useEffect(() => {
    if (atsJobID) {
      localStorage.setItem("atsJobID", atsJobID);
    }
    if (jobTitle) {
      localStorage.setItem("jobTitle", jobTitle);
      showError({
        title: decodeURI(jobTitle),
        message: " Please login or signup to start up the application process",
      });
    }
  }, [atsJobID, jobTitle]);

  return <Authentication authType={AuthType.Login} />;
};

export default withError(LoginPage);
