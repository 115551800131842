import { PencilIcon, PlusIcon, TrashIcon } from "assets/icons";
import { FC, useState } from "react";
import { EducationEntry } from "@remotebase/amplify-constants/API";
import { EducationFormEnum, extractMonthAndYear, EducationFormModal } from "@remotebase/components";
import { useForm } from "react-hook-form";
import moment from "moment";
import * as Styled from "../../styles";

interface Props {
  data: Array<EducationEntry>;
  updateEntries: (data: Array<EducationEntry>) => void;
}

const EducationSection: FC<Props> = ({ data, updateEntries }) => {
  const [showModal, setShowModal] = useState(false);
  const formHook = useForm({
    mode: "onChange",
  });

  const initEdit = (index: number, education: EducationEntry): void => {
    const startDate = moment(education.startDate);
    const endDate = moment(education.endDate);

    formHook.reset({
      ...education,
      [EducationFormEnum.startMonth]: startDate.month(),
      [EducationFormEnum.startYear]: startDate.year(),
      [EducationFormEnum.endMonth]: endDate.month(),
      [EducationFormEnum.endYear]: endDate.year(),
      [EducationFormEnum.isEdit]: true,
      [EducationFormEnum.selectedEducationIndex]: index,
    });

    setShowModal(true);
  };

  const initCreate = (): void => {
    formHook.reset({
      [EducationFormEnum.isEdit]: false,
    });

    setShowModal(true);
  };

  const initDeleteEntry = (id: number): void => {
    updateEntries([...data.slice(0, id), ...data.slice(id + 1)]);
  };

  const handleFormSubmission = (savedData: EducationEntry, index = -1): void => {
    if (!index && index !== 0) return;
    if (index === -1) updateEntries([...data, savedData]);
    else updateEntries([...data.slice(0, index), savedData, ...data.slice(index + 1)]);
  };

  return (
    <>
      <Styled.EducationComponent>
        <Styled.SectionHead>
          <Styled.SectionTitle>Education</Styled.SectionTitle>
          <Styled.SectionBtn onClick={(): void => initCreate()}>
            <PlusIcon />
            Add
          </Styled.SectionBtn>
        </Styled.SectionHead>
        <Styled.EducationGrid>
          {data.map((educationHistory, index) => (
            <Styled.EducationCard key={`education-${index}`}>
              <Styled.EducationCardContent>
                <Styled.EducationInst>{educationHistory.school}</Styled.EducationInst>
                <Styled.EducationName>{educationHistory.degree}</Styled.EducationName>
                <Styled.EducationDate>
                  {`${extractMonthAndYear(educationHistory.startDate)} -
                  ${extractMonthAndYear(educationHistory.endDate)}`}
                </Styled.EducationDate>
              </Styled.EducationCardContent>
              <Styled.EducationOptCanvas>
                <Styled.EducationOpt onClick={(): void => initEdit(index, educationHistory)}>
                  <PencilIcon />
                </Styled.EducationOpt>
                <Styled.EducationOpt onClick={(): void => initDeleteEntry(index)}>
                  <TrashIcon />
                </Styled.EducationOpt>
              </Styled.EducationOptCanvas>
            </Styled.EducationCard>
          ))}
        </Styled.EducationGrid>
      </Styled.EducationComponent>
      <EducationFormModal
        show={showModal}
        formHook={formHook}
        handleFormSubmission={handleFormSubmission}
        close={(): void => setShowModal(false)}
      />
    </>
  );
};

export default EducationSection;
