import { useSubscription } from "@apollo/client";
import { ConversationResponse, UserRoles } from "@remotebase/amplify-constants/API";
import { onCreateConversationParticipant } from "@remotebase/amplify-constants/graphql/subscriptions";
import { convertConversationToConversationResponse, getQuery } from "hooks/utils";
import { useEffect, useState } from "react";

export const useConversationSubscription = (
  userId: string,
  userRole: UserRoles,
): ConversationResponse | null => {
  const convoFilterVariables = {
    talentId: userRole === UserRoles.TALENT ? userId : "null",
    clientId: userRole === UserRoles.CLIENT ? userId : "null",
    recruiterId: userRole === UserRoles.RECRUITER ? userId : "null",
  };
  const { data, loading } = useSubscription(getQuery(onCreateConversationParticipant), {
    variables: convoFilterVariables,
  });

  const [newConversation, setNewConversation] = useState<ConversationResponse | null>(null);

  useEffect(() => {
    if (data && !loading) {
      const conversationResponse = convertConversationToConversationResponse(
        data.onCreateConversationParticipant.conversation,
      );
      setNewConversation(conversationResponse);
    }
  }, [data, loading]);

  return newConversation;
};

export default useConversationSubscription;
