import styled from "styled-components";

export const FinishProfile = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
`;

export const ProfileHead = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #666666;
  padding-bottom: 24px;
  margin-bottom: 30px;
`;

export const ProfileTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #0c2556;
  margin: 0;
`;

export const EducationComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

export const SectionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;

export const SectionTitle = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #0c2556;
  margin: 0;
`;

export const SectionBtn = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #212121;
  background: #ffffff;
  border: 1px solid #3c3c3c;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  padding: 14px 25px;
  min-width: 95px;
  min-height: 45px;
  cursor: pointer;
  transition: all 0.25s;

  & svg {
    width: 12px;
    height: 12px;

    & rect {
      fill: #212121;
      transition: all 0.25s;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: #3c3c3c;
    border: 1px solid #3c3c3c;
    box-shadow: none;
    text-decoration: none;
    color: #fff;

    & svg {
      & rect {
        fill: #fff;
      }
    }
  }
`;

export const EducationGrid = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

export const EducationCard = styled.div`
  display: flex;
  grid-gap: 20px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 14px 84px rgba(222, 219, 219, 0.25);
  border-radius: 8px;
  padding: 34px 40px 34px 48px;

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const EducationCardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EducationInst = styled.h5`
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #212121;
  margin-bottom: 9px;
`;

export const EducationOptCanvas = styled.div`
  display: flex;
  grid-gap: 12px;
  margin-left: auto;

  @media only screen and (max-width: 575px) {
    grid-gap: 5px;
  }
`;

export const EducationOpt = styled.button`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;

  & svg {
    width: 16px;
    height: 16px;
  }

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    box-shadow: none;
    border: none;
    text-decoration: none;
  }

  @media only screen and (max-width: 575px) {
    width: 24px;
    height: 24px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const EducationName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #212121;
  margin-bottom: 9px;
`;

export const EducationDate = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #212121;
`;

export const ExpComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

export const ExpGrid = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

export const ExpCard = styled.div`
  display: flex;
  grid-gap: 20px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 14px 84px rgba(222, 219, 219, 0.25);
  border-radius: 8px;
  padding: 34px 40px 34px 48px;

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const ExpContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 595px;
`;

export const ExpTitleCanvas = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 4px;
  grid-row-gap: 9px;
`;

export const ExpRole = styled.h5`
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #212121;
  margin: 0;
`;

export const ExpRoleType = styled.h6`
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #212121;
  margin: 0;
`;

export const ExpDate = styled.p`
  display: flex;
  flex-direction: column;
`;

export const ExpPara = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #7c7c7c;
  margin-bottom: 24px;
`;

export const ExpList = styled.ul`
  padding-left: 20px;
  margin: 0;
`;

export const ExpItem = styled.li`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #7c7c7c;
  margin-bottom: 10px;
`;

export const ContactComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactTitle = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #0c2556;
  margin-bottom: 52px;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;

export const FormCanvas = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const FormLabel = styled.label`
  font-weight: 500;
  font-size: 15px;
  line-height: 80%;
  color: #7c7c7c;
  margin: 0;
  text-transform: uppercase;
`;

export const FormGroup = styled.div`
  position: relative;
`;

export const FormInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #0c2556;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px 25px;
  height: 58px;
  margin: 0 !important;
  transition: all 0.25s;
`;

export const FormError = styled.small`
  color: red !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 0.8rem !important;
`;

export const NextBtnCover = styled.div`
  margin-top: 2.5rem;
`;

export const NextBtn = styled.button`
  opacity: 1;
  min-width: 126px;
  position: relative;
  font-size: 14px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  transition: all 0.2s;
  font-weight: 700;
  border: none;
  min-height: 48px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  &:disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  color: #ffffff;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;
