import { useMutation } from "@apollo/client";
import { ApplyJobWorkableMutation, ApplyJobWorkableMutationVariables } from "API";
import { applyJobWorkable } from "graphql/mutations";
import { getQuery } from "hooks/utils/helpers";

type UseApplyJobWorkableReturnType = {
  applyThroughWorkableApi: (unknown) => void;
  data: ApplyJobWorkableMutation | null | undefined;
  isLoading: boolean;
};

export const useApplyJobWorkable = (): UseApplyJobWorkableReturnType => {
  const [applyThroughWorkableApi, { data, loading }] = useMutation<
    ApplyJobWorkableMutation,
    ApplyJobWorkableMutationVariables
  >(getQuery(applyJobWorkable));

  return {
    applyThroughWorkableApi,
    data,
    isLoading: loading,
  };
};

export default useApplyJobWorkable;
