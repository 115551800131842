/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AnalyticsMetricsFrontEnd } from "@remotebase/constants";

const isJSON = (str: string) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const populateMetaData = (talentTypeName: string, properties: AnalyticsMetricsFrontEnd) => {
  if (!talentTypeName) return properties;

  if (!properties) {
    return {
      metaData: JSON.stringify({ talentTypeName }),
    };
  }

  if (!properties.metaData) {
    properties.metaData = JSON.stringify({ talentTypeName });
  } else {
    if (isJSON(properties.metaData)) {
      const parsedMetaData = JSON.parse(properties.metaData);
      if (parsedMetaData instanceof Array) {
        properties.metaData = JSON.stringify({
          value: parsedMetaData,
          talentTypeName,
        });
      } else {
        properties.metaData = JSON.stringify({
          ...parsedMetaData,
          talentTypeName,
        });
      }
    } else {
      properties.metaData = JSON.stringify({
        value: properties.metaData,
        talentTypeName,
      });
    }
  }

  return properties;
};
