import styled from "styled-components";

export const MeetingLink = styled.p`
  color: #1565d8;
  margin-bottom: 0;
  text-decoration: underline;
`;

export const LinkCopyWrap = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;

export const BriefMessage = styled.p`
  position: absolute;
  top: -1rem;
  right: -12rem;
  width: 100%;
`;
