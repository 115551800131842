import React from "react";
import AOS from "aos";
import { fetchUtmParams, imageUrl, UnAuthRoutes } from "utils";
import { ChevronRightIcon } from "assets/icons";
import * as S from "./styles";

export const LandingGetStarted: React.FC = () => {
  // const mailto = "hello@remotebase.com";
  AOS.init();
  return (
    <S.GetStartedWrapper>
      <S.Container>
        <S.GetMain>
          <S.GetImgCanvas className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            <S.GetImg src={imageUrl.getStartedImg} alt="Get Started Image" />
            <S.GetOvalFirst>
              <S.GetStackImg src={imageUrl.javaImg} alt="Java Image" />
            </S.GetOvalFirst>
            <S.GetOvalSecond src={imageUrl.getOvalSecond} alt="Get Started Oval" />
            <S.OverlayImg>
              <S.OverlayHeading>Selvi</S.OverlayHeading>
              <S.OverlayText>
                Java developer at <b>Fireflies</b>
              </S.OverlayText>
            </S.OverlayImg>
          </S.GetImgCanvas>
          <S.GetContent>
            <S.GetHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
              Ready to get started?
            </S.GetHeading>
            <S.RegisterBtnCanvas>
              <S.RegisterBtn
                to={`${UnAuthRoutes.Register}?${fetchUtmParams()}`}
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                Apply Now
                <S.RegisterBtnIcon>
                  <ChevronRightIcon />
                </S.RegisterBtnIcon>
              </S.RegisterBtn>
              <S.ContactUsBtn
                href="mailto:hello@remotebase.com"
                // to={"#"}
                // onClick={(e) => {
                //   window.location.href = mailto;
                //   e.preventDefault();
                // }}
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                Contact Us
              </S.ContactUsBtn>
            </S.RegisterBtnCanvas>
          </S.GetContent>
        </S.GetMain>
      </S.Container>
    </S.GetStartedWrapper>
  );
};

export default LandingGetStarted;
