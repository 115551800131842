import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainWrapper = styled.div`
  padding-top: 72px;
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-bottom: 30px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1328px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContent = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
`;

export const MainHeading = styled.h1`
  font-size: 32px;
  color: #000;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 32px;
  font-family: "Poppins", sans-serif;

  @media only screen and (min-width: 768px) {
    font-size: 72px;
    max-width: 694px;
    margin: 0 auto 50px;
  }
`;

export const textBlueSpan = styled.span`
  color: #3c65fe;
`;

export const MainPara = styled.p`
  font-size: 16px;
  color: #1c1f28;
  font-weight: 400;
  line-height: 180%;
  text-align: center;
  margin-bottom: 24px;

  @media only screen and (min-width: 768px) {
    max-width: 438px;
    margin: 0 auto 40px auto;
  }
`;

export const RegisterBtnCanvas = styled.div`
  text-align: center;
`;

export const RegisterBtn = styled(Link)`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  height: 60px;
  border-radius: 40px;
  background-color: #3615bd;
  border: none;
  font-family: "Poppins", sans-serif;
  transition: all 0.25s;

  &:hover {
    background-color: #1c0381;
    border: none;
    box-shadow: none;
    text-decoration: none;
    color: #fff;
  }
`;

export const RegisterBtnIcon = styled.span`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-left: 10px;

  & svg {
    width: 10px;
    height: 10px;

    & path {
      fill: #3615bd;
    }
  }
`;

export const MainImgs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: -120px;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: -320px;
  }
`;

export const MainImgBox = styled.div`
  width: 280px;
  height: 340px;
  margin-bottom: 50px;

  @media only screen and (min-width: 768px) {
    width: 400px;
    height: 488px;
    margin-bottom: 0;
  }
`;

export const HeroImgFemale = styled.img`
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
`;

export const HeroImgMale = styled.img`
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
`;

export const HeroFemaleOverlay = styled.div`
  background: linear-gradient(144.49deg, #f4a38b -15.77%, rgba(247, 193, 178, 0) 122.67%);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 48px;
  right: 0;
  max-width: 135px;

  @media screen and (min-width: 768px) {
    bottom: 86px;
    right: 15px;
    max-width: 178px;
    padding: 20px 28px;
  }
`;

export const HeroMaleOverlay = styled.div`
  background: linear-gradient(
    139.06deg,
    #ab8aef -4.04%,
    rgba(255, 255, 255, 0) 107.87%,
    #c4c4c4 107.87%
  );
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 22px;
  left: -15px;
  max-width: 135px;

  @media screen and (min-width: 768px) {
    bottom: 36px;
    left: -3px;
    max-width: 178px;
    padding: 20px 28px;
  }
`;

export const OverlayHeading = styled.h3`
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 4px;

  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
`;

export const OverlayText = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 0;
`;

export const HeroFemaleCircle = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  background-color: #ff7c57;
  border-radius: 50%;
  position: absolute;
  bottom: 35px;
  right: 38px;

  @media only screen and (min-width: 768px) {
    width: 80px;
    height: 80px;
    bottom: 68px;
    right: 53px;
  }
`;

export const HeroMaleCircle = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  background-color: #844aff;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  left: 20px;

  @media only screen and (min-width: 768px) {
    width: 80px;
    height: 80px;
    bottom: 20px;
    left: 43px;
  }
`;
