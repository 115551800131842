import styled from "styled-components";

export const ChatBodyNoMsgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 540px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  padding: 20px;
  margin: auto;
`;

export const ChatBodyNoMsgImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 32px;
  overflow: hidden;
  & img {
    max-width: 100%;
  }
`;

export const ChatBodyNoMsgtext = styled.div`
  display: flex;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color: #667781;
  margin-bottom: 0px;
  text-align: center;
  width: 60%;
`;
