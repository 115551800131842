import { FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetAllDomainTests, useGetAllDomainTestsStatus } from "hooks";
import { ITestBlock } from "state/codeTests";
import {
  domainTestErrorHeading,
  ProfileProps,
  TestStatus,
  testBlockList,
  defaultTestBlock,
  pendingTestResultErrorHeading,
  pendingTestResultErrorMessage,
  combineHOCs,
  PendingDomainTestType,
  getTestStatusOldVersion,
  TalentLevelProps,
  Sidebar,
  AuthRoutes as Route,
} from "utils";
import withError from "state/error/withErrorHoc";
import { TechDomainSkillsProgress, UserTestMappingInput } from "API";
import { ShouldRender } from "@remotebase/components";
import { withProfile, withTalentLevel } from "state/profileSteps";
import { DomainTestShimmer } from "shimmerPages";
import { useSentry } from "hooks/sentry";
import { ErrorProps } from "@remotebase/constants";
import { domainTextContext } from "state/domainTestcontext/provider";
import { TestBlockSingleList } from "./TestBlockSingleList";
import NoDomainTest from "../noDomainTest";

interface Props extends ProfileProps, ErrorProps, TalentLevelProps {}
const TestBlockList: FC<Props> = (props) => {
  const {
    profileState: { data: profile },
    showError,
    validateRoute,
  } = props;
  const history = useHistory();
  const [testArray, setTestArray] = useState<Array<TechDomainSkillsProgress | null> | null>([]);
  const [mappedTests, setMappedTests] = useState<Array<ITestBlock | null>>([]);
  const [pendingDomainTest, setPendingDomainTest] = useState<Array<PendingDomainTestType>>([]);
  const [pendingTests, setPendingTests] = useState(false);
  const { data: domainTest, loading, error } = useGetAllDomainTests();
  const { setHasDomainTest } = useContext(domainTextContext);
  const {
    getDomainTestStatus,
    data: domainTestStatus,
    loading: statusLoading,
  } = useGetAllDomainTestsStatus();
  const { sendError } = useSentry();

  const getTestStatus = (type: ITestBlock, pendingTest?: PendingDomainTestType): TestStatus => {
    const test = testArray?.find((e) => (e?.skill as string) === type.tags?.[0]);
    const testStatus = domainTestStatus?.find((e) => e?.testId === pendingTest?.testId);
    if (test && testStatus) {
      if (test.isPass) return TestStatus.PASSED;
      if (!test.testStart || !testStatus?.attempt_starttime) return TestStatus.READY;
      if (!test.testEnd || testStatus?.attempt_starttime === testStatus.attempt_endtime)
        return TestStatus.PENDING;
      return TestStatus.FAILED;
    }
    // for backward compatility
    return getTestStatusOldVersion(test, statusLoading, loading);
  };

  const getTestBlock = (testBlock: ITestBlock, index: number): JSX.Element => {
    const pendingTest = pendingDomainTest?.find((e) => (e?.tag as string) === testBlock.skillName);
    const status = getTestStatus(testBlock, pendingTest);
    if (status === TestStatus.PENDING) {
      setPendingTests(true);
    }
    return (
      <TestBlockSingleList
        status={status}
        key={index}
        testBlock={testBlock}
        pendingDomainTestData={pendingTest}
      />
    );
  };

  useEffect(() => {
    if (!loading && pendingTests) {
      showError({ title: pendingTestResultErrorHeading, message: pendingTestResultErrorMessage });
    }
  }, [loading, pendingTests]);

  useEffect(() => {
    const tests = profile?.profile?.takenDomainTests;
    if (tests?.length) setTestArray(tests);
  }, [profile]);

  useEffect(() => {
    const domainTestData = testArray?.filter((e) => (e?.domainTestData as string) !== null);
    if (domainTestData?.length) {
      const domainTestIds =
        domainTestData.map((e) => {
          if (e?.domainTestData) {
            return JSON.parse(e?.domainTestData);
          }
          return null;
        }) || [];
      if (domainTestIds?.length) {
        setPendingDomainTest(domainTestIds);
        const input = domainTestIds.map((e): UserTestMappingInput => {
          return { testId: e?.testId, candidateId: e?.candidateId };
        });
        if (input) getDomainTestStatus({ variables: { candidateTests: { data: input } } });
      }
    }
  }, [testArray]);

  const shouldShowShimmer = useMemo(
    () => loading || (pendingDomainTest && statusLoading),
    [loading, pendingDomainTest, statusLoading],
  );

  useEffect(() => {
    if (validateRoute(Sidebar.DomainTest)) history.push(Route.Dashbord);
  }, []);

  useEffect(() => {
    if (domainTest?.length) {
      const allMappedTests =
        domainTest.map((test) => {
          if (test) {
            const result = testBlockList.find((element) => element.skillName === test?.tags?.[0]);
            if (result) return { ...test, ...result };
            // return test;
            return {
              ...defaultTestBlock,
              title: test?.tags?.[0] as string,
              ...test,
            };
          }
          return null;
        }) || [];
      if (allMappedTests) setMappedTests(allMappedTests);
    }
    if (error) {
      sendError(error, { userId: profile?.profile?.id });
      showError({ title: domainTestErrorHeading, message: error?.message });
    }
  }, [domainTest, profile, error]);

  const listTestBlock = useMemo(() => {
    if (mappedTests?.length) {
      setHasDomainTest(true);
      return mappedTests.map((test, index) => {
        if (test?.tags?.length) return getTestBlock(test, index);

        return <Fragment key={index} />;
      });
    }
    setHasDomainTest(false);
    return <NoDomainTest />;
  }, [mappedTests, domainTestStatus]);

  return (
    <Fragment>
      <ShouldRender if={shouldShowShimmer}>
        <DomainTestShimmer />
      </ShouldRender>

      <ShouldRender if={!shouldShowShimmer}>
        <Fragment>{listTestBlock}</Fragment>
      </ShouldRender>
    </Fragment>
  );
};

export default combineHOCs(withProfile, withError, withTalentLevel)(TestBlockList) as FC;
