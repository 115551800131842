import { FC, useEffect, useState, useCallback, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import { IProfile, IProfileDetails, withProfile } from "state/profileSteps";
import withError from "state/error/withErrorHoc";
import { Talent } from "@remotebase/amplify-constants/API";
import { useTalentProfile, useTalentsProfile, getTalentProfile } from "hooks";
import { TalentUpdateProps } from "hooks/types";
import {
  DefaultDetailsToShow,
  ModalComponent,
  ProfileDetail,
  acceptedImageTypes,
  acceptedImgSize,
  invalidPhotoErrorTitle,
  invalidPhotoErrorMsg,
  ViewProfile,
  ProfileResumeDownload,
  SkillsSection,
  TalentProfileDescription,
  TalentInterests,
  TalentEmploymentHistory,
  TalentProfileDetail,
  EducationHistory,
} from "@remotebase/components";
import { ErrorProps } from "@remotebase/constants";
import { ProfileProps } from "utils";
import {
  EditProfileResume,
  UpdateProfileInfo,
  UpdateProfilePhoto,
  UpdateProfileDetails,
} from "./components";
import * as S from "./styles";

export const UserProfileScreen: FC<ProfileProps & ErrorProps> = ({
  profileState: { data },
  updateUserProfile,
  updateUserInfo,
  showError,
}) => {
  const {
    updateInfo,
    data: updatedData,
    error: engineerError,
    isLoading: engineerLoading,
  } = useTalentsProfile();
  const {
    updateProfile,
    data: profileData,
    error: profileError,
    isLoading: profileLoading,
  } = useTalentProfile();

  const [talentData, setTalentData] = useState(data as Talent);
  const [openProfileResumeModal, setOpenProfileResumeModal] = useState(false);
  const [openProfileInfoModal, setOpenProfileInfoOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [openProfileDetails, setOpenProfileDetails] = useState(false);

  const handleResumeEditClick = (): void => setOpenProfileResumeModal(true);
  const closeResumeEditModal = (): void => setOpenProfileResumeModal(false);
  const closeEditProfileDetailsClick = (): void => setOpenProfileDetails(false);
  const handleProfileDetailsClick = (): void => setOpenProfileDetails(true);
  const { getProfile } = getTalentProfile();

  const handleProfileInfoEditClick = (): void => setOpenProfileInfoOpenModal(true);
  const closeProfileInfoModal = (): void => setOpenProfileInfoOpenModal(false);

  // Edit Profile Photo
  const [openPhotoErrorModal, setPhotoErrorModal] = useState(false);
  const [openProfilePhotoModal, setProfilePhotoOpenModal] = useState(false);
  const [selectedUserImg, setSelectedUserImg] = useState<File>(new File([], ""));

  const closeProfilePhotoModal = (): void => setProfilePhotoOpenModal(false);
  const closePhotoErrorModal = (): void => setPhotoErrorModal(false);

  const handleNewPhotoUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    const newImg = event.target.files[0];
    if (acceptedImageTypes.includes(newImg.type) && newImg.size <= acceptedImgSize) {
      setSelectedUserImg(newImg);
      setProfilePhotoOpenModal(true);
    } else {
      setPhotoErrorModal(true);
    }
    // It is important to clear `event.target.value` because otherwise, it prevents the user
    // from selecing the same picture twice.
    // https://stackoverflow.com/questions/19643265/second-use-of-input-file-doesnt-trigger-onchange-anymore/56258902#56258902
    // eslint-disable-next-line no-param-reassign
    event.target.value = "";
  };

  const refetchProfile = useCallback((): void => {
    const userId = data?.id;
    if (userId) {
      getProfile({ variables: { id: userId } });
    }
  }, [data]);

  const talentUpdateProps: TalentUpdateProps = {
    updateInfo,
    updateProfile,
    data: updatedData,
    profileData,
    error: engineerError || profileError,
    isLoading: engineerLoading || profileLoading,
    refetchProfile,
    showError,
  };
  useEffect(() => {
    const profileVersion = talentData?.profile?.version;
    if (profileData && profileVersion && profileData.version >= profileVersion) {
      setTalentData((prev) => ({ ...prev, profile: profileData } as Talent));
      updateUserProfile(profileData as IProfileDetails);
    }
  }, [profileData, profileLoading]);

  useEffect(() => {
    const talentVersion = talentData?.version;
    if (updatedData && updatedData.version > talentVersion) {
      setTalentData((prev) => ({ ...prev, ...updatedData }));
      updateUserInfo(updatedData as IProfile);
    }
  }, [updatedData]);

  return (
    <S.ProfileWrapper>
      <div>
        <div id="confirmation-modal" />
        <ViewProfile
          data={talentData}
          selfEdit
          handleNewPhotoUpload={handleNewPhotoUpload}
          openProfileInfoModal={handleProfileInfoEditClick}
        />
        <S.ProfileSection>
          <S.SkillSection>
            <SkillsSection data={talentData} removeBorderTop isTalent />
            <EducationHistory data={talentData} talentUpdateProps={talentUpdateProps} />
          </S.SkillSection>
          <S.UserDetails>
            <S.DescriptionWrapper>
              <TalentProfileDescription
                data={talentData}
                isEditable
                talentUpdateProps={talentUpdateProps}
              />
            </S.DescriptionWrapper>
            <S.HR />
            <TalentProfileDetail
              open={handleProfileDetailsClick}
              data={talentData}
              isEditable
              detailsToShow={[...DefaultDetailsToShow, ProfileDetail.Email, ProfileDetail.Phone]}
            />
            <S.HR />
            <S.ExpertiseWrapper>
              <TalentInterests data={talentData} selfEdit talentUpdateProps={talentUpdateProps} />
            </S.ExpertiseWrapper>
          </S.UserDetails>
        </S.ProfileSection>
      </div>
      <TalentEmploymentHistory data={talentData} isEditable talentUpdateProps={talentUpdateProps} />
      {data && (
        <ProfileResumeDownload
          data={talentData}
          handleEditClick={handleResumeEditClick}
          isEditable
        />
      )}
      <ModalComponent show={openProfileResumeModal} handleClose={closeResumeEditModal}>
        <EditProfileResume
          close={closeResumeEditModal}
          updateProfile={updateProfile}
          profileLoading={profileLoading}
          profileData={profileData}
          data={talentData}
          showError={showError}
        />
      </ModalComponent>

      <ModalComponent show={openProfileDetails} handleClose={closeEditProfileDetailsClick}>
        <UpdateProfileDetails
          data={talentData}
          close={closeEditProfileDetailsClick}
          talentUpdateProps={talentUpdateProps}
        />
      </ModalComponent>

      <ModalComponent
        show={openProfileInfoModal}
        handleClose={closeProfileInfoModal}
        hidden={openConfirmModal}
      >
        <UpdateProfileInfo
          close={closeProfileInfoModal}
          data={talentData}
          talentUpdateProps={talentUpdateProps}
          openConfirmModal={openConfirmModal}
          setOpenConfirmModal={setOpenConfirmModal}
        />
      </ModalComponent>

      <ModalComponent show={openProfilePhotoModal} handleClose={closeProfilePhotoModal}>
        <UpdateProfilePhoto
          selectedImage={selectedUserImg}
          close={closeProfilePhotoModal}
          talentProfile={talentData?.profile}
          updateProfile={updateProfile}
          refetchProfile={talentUpdateProps?.refetchProfile}
          showError={showError}
        />
      </ModalComponent>

      <ModalComponent show={openPhotoErrorModal} handleClose={closePhotoErrorModal}>
        <Fragment>
          <Modal.Header>
            <Modal.Title>{invalidPhotoErrorTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{invalidPhotoErrorMsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closePhotoErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Fragment>
      </ModalComponent>
    </S.ProfileWrapper>
  );
};
export default withProfile(withError(UserProfileScreen));
