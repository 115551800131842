import { FC, Fragment, useContext, useEffect, useState } from "react";
import ParsedResumeShimmer from "shimmerPages/parsedResumeShimmer";
import {
  combineHOCs,
  ProfileProps,
  SidebarProps,
  SidebarType,
  SocialForm,
  SocialFormSchema,
} from "utils";
import { BtnLoader, cleanArray, ShouldRender } from "@remotebase/components";
import { CheckMarkIcon } from "assets/icons";
import { codeToCountry, ErrorProps, mapFinishSignupAnalyticsEvent } from "@remotebase/constants";
import { useAnalyticsEventCreator, useTalentProfile } from "hooks";
import useLocalStorage from "hooks/utils/localStorage";
import {
  EducationEntry,
  EmploymentEntry,
  SocialLinkTypes,
  SocialProfileInput,
} from "@remotebase/amplify-constants/API";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IProfileDetails, withProfile } from "state/profileSteps";
import { withSidebar } from "state/sidebar";
import {
  IParsedResumeContextType,
  IParsedResumeProps,
  ParsedResumeContext,
  withParsedResume,
} from "state/parsedResume";
import { EducationSection, EmploymentHistorySection, SocialSection } from "./sections";
import * as Styled from "./styles";
import withError from "../../state/error/withErrorHoc";

interface Props extends ProfileProps, SidebarProps, ErrorProps, IParsedResumeProps {}
const FinishSignUp: FC<Props> = ({
  profileState: { data: profile },
  updateUserProfile,
  setSidebarType,
  showError,
  loading: parsingLoading,
}) => {
  const { parsedResumeData, initialized: parsingInitialized } =
    useContext<IParsedResumeContextType>(ParsedResumeContext);
  const [educationEntries, setEducationEntries] = useState<Array<EducationEntry>>([]);
  const [employmentEntries, setEmploymentEntries] = useState<Array<EmploymentEntry>>([]);
  const { data: talentProfile, updateProfile, isLoading } = useTalentProfile();
  const { deleteItem } = useLocalStorage();
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const socialForm = useForm({
    resolver: yupResolver(SocialFormSchema),
    mode: "onChange",
  });

  const handleSubmit = (): void => {
    if (Object.keys(socialForm.formState.errors).length) {
      showError({
        title: "Socials Error",
        message: "Social URL is invalid",
      });

      return;
    }

    if (profile?.id) {
      const event = mapFinishSignupAnalyticsEvent(profile.profile?.talentType?.name as string);
      analyticsRegisterEvent(event, {
        metaData: JSON.stringify({
          country: codeToCountry[profile.country],
        }),
      });

      const profileData = profile?.profile;

      if (profileData?.id) {
        const socials = socialForm.getValues();
        const socialProfiles: Array<SocialProfileInput> = [];

        if (socials[SocialForm.githubUrl]) {
          socialProfiles.push({
            urlType: SocialLinkTypes.GITHUB,
            url: socials[SocialForm.githubUrl],
          });
        }

        if (socials[SocialForm.linkedinUrl]) {
          socialProfiles.push({
            urlType: SocialLinkTypes.LINKEDIN,
            url: socials[SocialForm.linkedinUrl],
          });
        }

        const employmentHistory = employmentEntries.reduce((prev, current) => {
          const { __typename: typename, ...data } = current;
          prev.push(data);
          return prev;
        }, [] as Array<Omit<EmploymentEntry, "__typename">>);

        const educationHistory = educationEntries.reduce((prev, current) => {
          const { __typename: typename, ...data } = current;
          prev.push(data);
          return prev;
        }, [] as Array<Omit<EducationEntry, "__typename">>);

        updateProfile({
          variables: {
            input: {
              id: profileData.id,
              expectedVersion: profileData?.version,
              isProfileCompleted: true,
              employmentHistory,
              educationHistory,
              socialProfiles,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoading && talentProfile) {
      deleteItem(profile?.profile?.resumeLink || "");
      updateUserProfile(talentProfile as IProfileDetails);
      setSidebarType(SidebarType.Dashboard);
    }
  }, [talentProfile, isLoading]);

  useEffect(() => {
    socialForm.reset({
      [SocialForm.githubUrl]: parsedResumeData?.basic_info?.github_main_page_url,
      [SocialForm.linkedinUrl]: parsedResumeData?.basic_info?.linkedin_url,
      [SocialForm.portfolioUrl]: parsedResumeData?.basic_info?.portfolio_website_url,
    });

    setEducationEntries(cleanArray(parsedResumeData?.education_history));
    setEmploymentEntries(cleanArray(parsedResumeData?.employment_history));
  }, [parsedResumeData]);

  useEffect(() => {
    if (parsingInitialized && !parsingLoading && !parsedResumeData) {
      const profileData = profile?.profile;
      if (!profileData) return;

      const event = mapFinishSignupAnalyticsEvent(profileData.talentType?.name as string);
      analyticsRegisterEvent(event, {
        metaData: JSON.stringify({
          country: codeToCountry[profile.country],
        }),
      });

      updateProfile({
        variables: {
          input: {
            id: profileData.id,
            expectedVersion: profileData.version,
            isProfileCompleted: true,
          },
        },
      });
    }
  }, [parsingInitialized, parsingLoading, parsedResumeData]);

  return (
    <Styled.FinishProfile>
      <Styled.ProfileHead>
        <Styled.ProfileTitle>Profile</Styled.ProfileTitle>
      </Styled.ProfileHead>
      {parsedResumeData ? (
        <>
          <EducationSection data={educationEntries} updateEntries={setEducationEntries} />
          <EmploymentHistorySection data={employmentEntries} updateEntries={setEmploymentEntries} />
          <SocialSection formHook={socialForm} />
          <Styled.NextBtnCover>
            <Styled.NextBtn
              onClick={handleSubmit}
              type="button"
              id="multiSelect_nextButton"
              disabled={isLoading}
            >
              <ShouldRender if={!isLoading}>
                <Fragment>
                  Submit
                  <CheckMarkIcon />
                </Fragment>
              </ShouldRender>
              <ShouldRender if={isLoading}>
                <BtnLoader />
              </ShouldRender>
            </Styled.NextBtn>
          </Styled.NextBtnCover>
        </>
      ) : (
        <ParsedResumeShimmer />
      )}
    </Styled.FinishProfile>
  );
};

export default combineHOCs(
  withProfile,
  withSidebar,
  withError,
  withParsedResume,
)(FinishSignUp) as FC;
