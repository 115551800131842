import { times } from "lodash";
import React, { useState, useEffect } from "react";
import { englishProficiency, Country } from "@remotebase/amplify-constants/API";
import {
  englishProficiencyLabel,
  phoneNumberLabel,
  possibleYearsOfExperienceLabel,
  ProfileDetailsForm,
  profileExperienceBoxes as maxYearsOfExperience,
  saveBtnText,
} from "utils";
import {
  TalentEditData,
  ProfilePhoneInput,
  SelectInput,
  ActionBtn,
  cancelBtnText,
  actionBtnWidth,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "@remotebase/components";
import { Color } from "@remotebase/constants";
import * as S from "../styles";

interface IFormData {
  phone: string;
  englishProficiency: string;
  experience: string;
}

export const UpdateProfileDetails: React.FC<TalentEditData> = ({
  data,
  setTalentData,
  close,
  talentUpdateProps,
}) => {
  const {
    isLoading,
    updateInfo,
    profileData,
    data: updateTalentData,
    updateProfile,
    refetchProfile,
    showError: showMessage,
  } = talentUpdateProps || {};
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState<Country | undefined>();
  const [formData, setFormData] = useState<IFormData>({
    [ProfileDetailsForm.phoneNumber]: data?.phone || "",
    [ProfileDetailsForm.englishProficiency]: data?.profile?.englishProficiency || "",
    [ProfileDetailsForm.possibleYearsOfExperience]: data?.profile?.experience || "",
  });
  const possibleProficiency = Object.entries(englishProficiency).map(([value, label]) => ({
    value,
    label,
  }));
  const possibleYearsOfExperience = times(maxYearsOfExperience, (index: number) => {
    let yearsValue = (index + 1).toString();
    if (index === maxYearsOfExperience - 1) yearsValue += "+";
    return { label: yearsValue, value: yearsValue };
  });

  const handleClose = (): void => {
    if (close) close();
  };

  const handleSave = (): void => {
    setIsSubmitted(true);
    if (updateInfo && updateProfile) {
      updateProfile({
        variables: {
          input: {
            id: data?.profile?.id,
            expectedVersion: data?.profile?.version,
            englishProficiency: formData.englishProficiency,
            experience: formData.experience,
          },
        },
        onCompleted: () => {
          showMessage?.({
            title: submissionSuccessTitle,
            message: submissionProfileSuccessMessage,
          });
        },
        onError: () => {
          showMessage?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
        },
      });
      updateInfo({
        variables: {
          input: {
            id: data?.id,
            phone: formData.phone,
            expectedVersion: data?.version,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (updateTalentData && profileData && isSubmitted) {
      if (setTalentData && refetchProfile) {
        setIsSubmitted(false);
        setTalentData({ ...updateTalentData, profile: profileData });
        refetchProfile();
      }
      if (close) close();
    }
  }, [updateTalentData, profileData, isSubmitted]);

  const updateFormData = (key: string, value: string): void => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    if (data?.phone) setPhoneNumber(data.phone);
  }, [data]);

  useEffect(() => {
    if (phoneNumber && country) {
      updateFormData(ProfileDetailsForm.phoneNumber, phoneNumber);
    }
  }, [phoneNumber]);

  return (
    <S.EditorWrapper>
      <S.SectionHeading>Edit your Profile Details</S.SectionHeading>
      <S.ProfileFormBody>
        <S.FormGroup>
          <SelectInput
            selected={possibleProficiency.find(
              (it) => it.value === formData[ProfileDetailsForm.englishProficiency],
            )}
            label={englishProficiencyLabel}
            options={possibleProficiency}
            handleSingleSelect={(option): void =>
              updateFormData(ProfileDetailsForm.englishProficiency, option.value)
            }
          />
        </S.FormGroup>
        <S.FormGroup>
          <SelectInput
            selected={possibleYearsOfExperience.find(
              (it) => it.value === formData[ProfileDetailsForm.possibleYearsOfExperience],
            )}
            label={possibleYearsOfExperienceLabel}
            options={possibleYearsOfExperience}
            handleSingleSelect={(option): void =>
              updateFormData(ProfileDetailsForm.possibleYearsOfExperience, option.value)
            }
          />
        </S.FormGroup>
        <S.FormGroup>
          <ProfilePhoneInput
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            setCountry={setCountry}
            label={phoneNumberLabel}
          />
        </S.FormGroup>
      </S.ProfileFormBody>

      <S.BtnGroup>
        <ActionBtn
          btnText={cancelBtnText}
          clickHandler={handleClose}
          width={actionBtnWidth}
          color={Color.LIGHT_BLUE}
          backgroundColor={Color.TRANSPARENT}
        />
        <ActionBtn
          btnText={saveBtnText}
          isLoading={isLoading}
          clickHandler={handleSave}
          width={actionBtnWidth}
          color={Color.WHITE}
          backgroundColor={Color.LIGHT_BLUE}
          disabled={isLoading}
        />
      </S.BtnGroup>
    </S.EditorWrapper>
  );
};

export default UpdateProfileDetails;
