import { FC } from "react";
import * as Styled from "./styles";

interface Props {
  content: string;
}
const OtherMessage: FC<Props> = ({ content }) => {
  return <Styled.ChatBodyReplyBox>{content}</Styled.ChatBodyReplyBox>;
};

export default OtherMessage;
