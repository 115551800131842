import { FC, Fragment, useEffect, useState, useMemo } from "react";
import { Talent } from "@remotebase/amplify-constants/API";
import { EditToolTip } from "../../modal";
import { CheckIcon, CrossIcon, DomainPendingIcon } from "../../assets";
import { ShouldRender } from "../../shouldRender";
import { TalentUpdateProps } from "../../../web/src/hooks/types";
import {
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "../../utils";
import * as Styled from "./styles";

interface Props {
  data: Talent;
  isEditable?: boolean;
  talentUpdateProps?: TalentUpdateProps;
  setTalentData?: React.Dispatch<any>;
}

export const TalentProfileDescription: FC<Props> = ({
  data,
  isEditable,
  talentUpdateProps,
  setTalentData,
}) => {
  const { profileData, updateProfile, isLoading, refetchProfile, showError } =
    talentUpdateProps || {};
  const [editMode, setEditMode] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const { bio, title } = data?.profile || {};

  const [formData, setFormData] = useState({
    title: title || "",
    bio: bio || "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleEditClick = (): void => setEditMode(true);
  const handleCloseEdit = (): void => setEditMode(false);

  const updateFormData = (key: string, value: string): void => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSave = (): void => {
    setIsSubmitted(true);
    updateProfile?.({
      variables: {
        input: {
          expectedVersion: data?.profile?.version,
          id: data?.profile?.id,
          title: formData.title,
          bio: formData.bio,
        },
      },
      onCompleted: () => {
        showError?.({ title: submissionSuccessTitle, message: submissionProfileSuccessMessage });
      },
      onError: () => {
        showError?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
      },
    });
  };

  useEffect(() => {
    if (profileData && data && isSubmitted) {
      if (setTalentData && refetchProfile) {
        setIsSubmitted(false);
        setTalentData({ ...data, profile: profileData });
        refetchProfile();
      }
      handleCloseEdit();
    }
  }, [data, profileData, isSubmitted]);

  const currentBio = useMemo(() => {
    if (bio && !readMore) return bio?.slice(0, 500);
    return bio;
  }, [bio, readMore]);

  return (
    <Fragment>
      <Styled.TalentProfileDescription $editMode={editMode}>
        <ShouldRender if={editMode}>
          <Styled.BtnGroup>
            <Styled.SaveButton onClick={handleCloseEdit}>
              <CrossIcon />
            </Styled.SaveButton>
            <ShouldRender if={isLoading}>
              <Styled.SaveButton>
                <DomainPendingIcon />
              </Styled.SaveButton>
            </ShouldRender>
            <ShouldRender if={!isLoading}>
              <Styled.SaveButton onClick={handleSave}>
                <CheckIcon />
              </Styled.SaveButton>
            </ShouldRender>
          </Styled.BtnGroup>
        </ShouldRender>
        <Styled.SectionHeadingContainer>
          <Fragment>
            <ShouldRender if={!editMode}>
              <Fragment>
                <ShouldRender if={title?.length}>
                  <Styled.SectionHeading>{title}</Styled.SectionHeading>
                </ShouldRender>
                <ShouldRender if={!title?.length}>
                  <Styled.SectionHeading>Add Title</Styled.SectionHeading>
                </ShouldRender>
              </Fragment>
            </ShouldRender>
            <ShouldRender if={editMode}>
              <Styled.TextInput
                type="text"
                maxLength={100}
                placeholder="Add title"
                value={formData.title}
                onChange={(e): void => updateFormData("title", e.target.value)}
              />
            </ShouldRender>
          </Fragment>

          <ShouldRender if={isEditable && !editMode}>
            <EditToolTip handleEditClick={handleEditClick} />
          </ShouldRender>
        </Styled.SectionHeadingContainer>
        <Styled.DescriptionTextContainer>
          <ShouldRender if={!editMode && bio?.length}>
            <Fragment>
              <Styled.DescriptionText>{currentBio}</Styled.DescriptionText>
              <ShouldRender if={bio && bio?.length > 500}>
                <Styled.DescriptionReadMore onClick={(): void => setReadMore(!readMore)}>
                  {readMore ? "Read Less" : "Read More"}
                </Styled.DescriptionReadMore>
              </ShouldRender>
            </Fragment>
          </ShouldRender>
          <ShouldRender if={!editMode && !bio?.length}>
            <Styled.TextAreaDummy>
              <Styled.DummyParagraph>Add description about you</Styled.DummyParagraph>
            </Styled.TextAreaDummy>
          </ShouldRender>
          <ShouldRender if={editMode}>
            <Styled.TextAreaWrapper>
              <Styled.TextAreaInput
                maxLength={1000}
                placeholder="Add your bio"
                value={formData.bio}
                onChange={(e): void => updateFormData("bio", e.target.value)}
              />
              <Styled.CharactersCount>{1000 - formData.bio.length}</Styled.CharactersCount>
              <Styled.CharactersLabel> characters remaining</Styled.CharactersLabel>
            </Styled.TextAreaWrapper>
          </ShouldRender>
        </Styled.DescriptionTextContainer>
      </Styled.TalentProfileDescription>
    </Fragment>
  );
};

export default TalentProfileDescription;
