import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { StyledProps } from "utils";

export const PurpleFont = styled.span`
  color: #4c40f7;
`;

export const NextBtn = styled.button`
  opacity: 1;
  position: relative;
  background: #4c40f7;
  color: #ffffff;
  font-size: 14px;
  background: #ff2c2c;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  transition: all 0.2s;
  color: white !important;
  font-weight: 700;
  border: none;
  min-height: 48px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  &:disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  color: #ffffff;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;

export const MembersSelect = styled.div`
  width: 100%;
  border-radius: 6px;
  background: white;
  box-shadow: 0 12px 20px 2px rgba(0, 0, 0, 0.17);
  padding: 25px 25px 20px;
  margin-bottom: 30px;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + label {
    background: #001b4e;
    color: white;
  }
  input[type="radio"] + label {
    width: 100%;
    height: 52px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  input[type="radio"] + label {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-right: 1px solid #c6cbd3;
  }

  @media (max-width: 767px) {
    padding: 15px;
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked + label {
      background: #001b4e;
      color: white;
    }
    input[type="radio"] + label {
      height: 50px;
      padding: 0 10px;
      font-size: 20px;
    }
    input[type="radio"] + label:last-child {
      width: 100%;
      border-top: 1px solid #c6cbd3;
      border-right: 0;
      flex-basis: auto;
    }
    input[type="radio"] + label:nth-last-of-type(2) {
      border-right: none;
    }
  }
  @media (max-width: 390px) {
    .includes {
      display: none;
    }
  }
`;

export const MembersSelectRadios = styled.div`
  border-radius: 6px;
  border: 1px solid #c6cbd3;
  overflow: hidden;
  .row .radio:not(:last-child) input[type="radio"] + label {
    border-right: 1px solid #c6cbd3;
  }

  @media (max-width: 767px) {
    border-radius: 6px;
    border: 1px solid #c6cbd3;
    overflow: hidden;
    .row .radio:not(:last-child) input[type="radio"] + label {
      border-right: 1px solid #c6cbd3;
    }
    .row {
      margin: 0;
    }
    .col-auto {
      padding: 0;
    }
  }
`;

// Category cards css

export const CategoryWrapper = styled.div`
  width: 100%;
`;

export const CategoryRow = styled(Row)`
  margin: 0px -6.5px;
  padding-top: 27px;
  &.small__row {
    margin: 0px -5px;
    padding-top: 18px;
  }
`;

export const CategoryCol = styled(Col)`
  display: flex;
  padding: 0px 6.5px;
  @media (max-width: 420px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &.small__col {
    padding: 0px 5px;
  }
`;

export const CategoryCard = styled.div<{ $active: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 5.35849px 26.7925px rgba(101, 101, 101, 0.1);
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 44px 12px 24px 12px;
  margin: 0px 0px 24px 0px;
  transition: all 0.3s linear;
  cursor: pointer;
  &.small__card {
    padding: 33px 12px 18px 12px;
    margin: 0px 0px 20px 0px;
  }

  &:hover {
    border: 2px solid #4a40ee;
    box-shadow: 0px 5.35849px 26.7925px rgba(101, 101, 101, 0.1);
  }

  ${(props): StyledProps =>
    props.$active
      ? css`
          border: 2px solid #4a40ee;
          box-shadow: 0px 5.35849px 26.7925px rgba(101, 101, 101, 0.1);
        `
      : css``}
`;

export const CategoryImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40px;
  margin-bottom: 30px;
  &.sm__img {
    max-width: 28px;
    margin-bottom: 22px;
  }
  & img {
    max-width: 100%;
  }
`;

export const CategoryTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  color: #0c2556;
  letter-spacing: -0.01em;
  margin: auto auto 0px auto;
  &.sm__title {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const SpecifyTalentCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #4a40ee;
  box-shadow: 0px 5.35849px 26.7925px rgba(101, 101, 101, 0.1);
  border: none;
  border-radius: 14px;
  padding: 35px 16px 24px 24px;
  margin: 0px 0px 24px 0px;
  @media (min-width: 576px) {
    padding: 35px 20px 24px 52px;
  }
`;

export const SpecifyTalentTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  letter-spacing: -0.01em;
  margin: 0px 0px 19px 0px;
`;

export const SpecifyTalentBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  letter-spacing: -0.01em;
  padding: 8px 12px;
  min-width: 187px;
  min-height: 42px;
  width: auto;
  margin: 0px;
  cursor: pointer;
  &:hover,
  &:focus,
  &.active {
    background: #f7f5f5;
    border: none;
    outline: none;
    box-shadow: none;
    color: #000000;
  }
`;

export const TalentBadge = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 805px;
  width: 100%;
  padding: 12px 20px;
  background: #fff0ec;
  border-radius: 8px;
`;

export const TalentBadgeContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const TalentBadgeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100 %;
  max-width: 21px;
  max-height: 25px;
  margin-right: 10px;
`;

export const TalentBadgePara = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin: 0px !important;

  letter-spacing: -0.04em;

  color: #7c7c7c !important;
`;
