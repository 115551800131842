import { upperFirst } from "lodash";
import { FC, useMemo } from "react";
import Select from "react-select";
import { ShouldRender, customStyles, SelectOption } from "..";
import * as Styled from "./styles";

interface ISelectInput {
  handleMultiSelect?: (selectedOptions: Array<SelectOption>) => void;
  handleSingleSelect?: (selectedOption: SelectOption) => void;
  isClearable?: boolean;
  label?: string;
  options: Array<SelectOption>;
  selected?: SelectOption | Array<SelectOption> | null;
  placeholder?: string;
  isDisabled?: boolean;
  removeMargin?: boolean;
  removeBorderRight?: boolean;
  minHeight?: string;
  errorMsg?: string;
}

export const SelectInput: FC<ISelectInput> = ({
  handleMultiSelect,
  handleSingleSelect,
  isClearable,
  label,
  options,
  selected,
  placeholder,
  isDisabled,
  removeMargin,
  removeBorderRight,
  minHeight,
  errorMsg,
}) => {
  const updatedSelect = useMemo(() => selected || null, [selected]);
  return (
    <Styled.SelectGroup>
      <ShouldRender if={!!label}>
        <Styled.SelectLabel>{label}</Styled.SelectLabel>
      </ShouldRender>
      <Styled.SelectGroupCombine>
        <Styled.SelectGroupMain
          removeMargin={removeMargin}
          removeBorderRight={removeBorderRight}
          minHeight={minHeight}
        >
          <Select
            isClearable={isClearable}
            styles={customStyles}
            options={options}
            onChange={handleMultiSelect || handleSingleSelect}
            isMulti={!!handleMultiSelect}
            isSearchable
            value={updatedSelect}
            placeholder={placeholder}
            isDisabled={isDisabled}
          />
        </Styled.SelectGroupMain>
        <Styled.EditSelectError removeMargin={removeMargin}>
          {upperFirst(errorMsg)}
        </Styled.EditSelectError>
      </Styled.SelectGroupCombine>
    </Styled.SelectGroup>
  );
};

export default SelectInput;
