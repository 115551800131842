import { useMutation } from "@apollo/client";
import { GetParsedResumeDataMutation, GetParsedResumeDataMutationVariables } from "API";
import { getParsedResumeData } from "graphql/mutations";
import { ReturnPropsResumeParsed } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

export const useGetParsedResume = (): ReturnPropsResumeParsed => {
  const [getParsedResume, { data, loading, error }] = useMutation<
    GetParsedResumeDataMutation,
    GetParsedResumeDataMutationVariables
  >(getQuery(getParsedResumeData));

  const parsedResumeData = data?.getParsedResumeData?.data || null;
  const successCode = data?.getParsedResumeData?.code || null;
  return {
    getParsedResume,
    parsedResumeData,
    successCode,
    loading,
    error,
  };
};

export default useGetParsedResume;
