import React, { FC, useMemo } from "react";
import { SlotsPerDay } from "@remotebase/amplify-constants/API";
import moment from "moment";
import { uuid } from "short-uuid";
import { useFormContext } from "react-hook-form";
import { FormData } from "./types";
import * as Styled from "./styled";

interface Props {
  PerDaySlot: SlotsPerDay;
}

export const ScheduleCol: FC<Props> = ({ PerDaySlot }) => {
  const { register } = useFormContext<FormData>();
  const getFormatedDate = (startTime, endTime): string =>
    ` ${moment(startTime).format("hh:mm:ss A")} - ${moment(endTime).format("hh:mm:ss A")}`;
  const slotsWithUniqueId = useMemo(
    () => PerDaySlot.availableSlots?.map((s) => ({ ...s, id: uuid() })),
    [PerDaySlot],
  );
  return (
    <Styled.SchedularCol>
      <Styled.SchedularCardTop>{moment(PerDaySlot.date).format("ll")}</Styled.SchedularCardTop>
      <Styled.SchedularCardBody>
        {slotsWithUniqueId?.map((item) => (
          <Styled.DateInput key={item.id}>
            <input
              type="radio"
              value={JSON.stringify(item)}
              hidden
              {...register("slot")}
              id={item.id}
            />{" "}
            <Styled.SchedularDateBtn htmlFor={item.id}>
              {getFormatedDate(item?.startTime, item?.endTime)}
            </Styled.SchedularDateBtn>
          </Styled.DateInput>
        ))}
      </Styled.SchedularCardBody>
    </Styled.SchedularCol>
  );
};

export default ScheduleCol;
