import { upperFirst } from "lodash";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { SelectInput } from "@remotebase/components";
import { currencyOptions } from "../../utils";
import * as Styled from "./styles";

interface Props {
  type: string;
  title: string;
  handler: UseFormReturn;
  salaryCurrency: string;
  setSalaryCurrency: (value: string) => void;
}
export const CurrencyInput: FC<Props> = ({
  type,
  title,
  handler: {
    register,
    formState: { errors },
  },
  salaryCurrency,
  setSalaryCurrency,
}) => {
  return (
    <div className="col-md-5">
      <Styled.CustomLabel>{title}</Styled.CustomLabel>
      <Styled.CurrencyContainer>
        <Styled.CurrencyCode>
          <SelectInput
            selected={currencyOptions.find((it) => it.value === salaryCurrency)}
            options={currencyOptions}
            handleSingleSelect={(option): void => setSalaryCurrency(option.value)}
            minHeight="52px"
            removeMargin
            removeBorderRight
          />
        </Styled.CurrencyCode>
        <Styled.CurrencyInput type={type} placeholder={title} {...register(title)} />
      </Styled.CurrencyContainer>
      <Styled.CustomError>{upperFirst(errors[title]?.message as string)}</Styled.CustomError>
    </div>
  );
};

export default CurrencyInput;
