import { FC, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withProfile, withTalentLevel } from "state/profileSteps";
import {
  AuthRoutes as Route,
  FinalTechEvalCriteria,
  FinalTechInstructions,
  TestsType,
  ProfileProps,
  TalentLevelProps,
  Sidebar,
  FinalNonTechInstructions,
  FinalNonTechEvalCriteria,
} from "utils";
import { rejectionTemplate, getClearedTests } from "utils/misc";
import * as Styled from "./styles";

export const FinalTechInterview: FC<ProfileProps & TalentLevelProps> = ({
  profileState: { data, techProfile },
  validateRoute,
}) => {
  const history = useHistory();
  const [isRejected, setIsRejected] = useState(false);

  const getListItem = (inputArray: string[], keyId: string): JSX.Element => {
    return (
      <Fragment>
        {inputArray.map((list, index) => {
          return <Styled.FinalTechLi key={`${index} ${keyId}`}>{list}</Styled.FinalTechLi>;
        })}
      </Fragment>
    );
  };

  useEffect(() => {
    if (validateRoute(Sidebar.FinalInterview)) history.push(Route.Dashbord);
    else if (getClearedTests(data)[TestsType.FinalInterview] === false) setIsRejected(true);
  }, [data]);
  const getRejectionTemplate = (): JSX.Element => {
    return rejectionTemplate(data?.fullName || "");
  };
  const getDisplay = (): JSX.Element => {
    if (!isRejected)
      return (
        <Styled.FinalTechWrapper>
          <Styled.H3>Instructions:</Styled.H3>
          <Styled.FinalTechUl>
            {getListItem(
              techProfile ? FinalTechInstructions : FinalNonTechInstructions,
              "instruction",
            )}
          </Styled.FinalTechUl>

          <Styled.H3>Evaluation Criteria:</Styled.H3>
          <Styled.FinalTechUl>
            {getListItem(
              techProfile ? FinalTechEvalCriteria : FinalNonTechEvalCriteria,
              "criteria",
            )}
          </Styled.FinalTechUl>
        </Styled.FinalTechWrapper>
      );

    return (
      <Styled.FinalTechWrapper>
        <Styled.H3>Did not pass</Styled.H3>
        {getRejectionTemplate()}
      </Styled.FinalTechWrapper>
    );
  };
  return getDisplay();
};

export default withProfile(withTalentLevel(FinalTechInterview));
