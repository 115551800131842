import { StyledProps } from "@remotebase/constants";
import styled from "styled-components";

export const ChatBodySearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: #f0f2f5;
  border: none;
  padding: 10px 16px;
  flex-shrink: 0;
  position: fixed;
  top: auto;
  bottom: 0px;
  left: 0px;
  right: 0px;
  @media only screen and (min-width: 768px) {
    position: relative;
    top: 0px;
    bottom: auto;
  }
`;

export const ChatBodySearchInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0px 20px 0px 0px;
  & input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 18px;
    height: 38px;
    padding: 4px 16px;
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    border: none;
    outline: none;
    box-shadow: none;
    &:focus {
      background-color: #ffffff;
      border: 1px solid #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      outline: none;
    }
  }
`;

export const ChatBodyFooterIcon = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 50%;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  position: relative;
  & svg {
    fill: ${({ disabled }): StyledProps => (disabled ? "#54656f" : "#4c40f7")};
    width: 20px;
    height: 20px;
  }
  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: rgba(11, 20, 26, 0.1);
      border: none;
      border-radius: 50%;
      box-shadow: none;
    }
    &:disabled {
      cursor: normal;
    }
  }
`;

export const ChatBodyFooterLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  right: 10px;
  top: 0px;
  bottom: 0px;
`;
