import { FC } from "react";
import * as Styled from "./styles";

export const FinishSignUpShimmer: FC = () => {
  return (
    <Styled.FinishWrapper>
      <Styled.FinishStepOne>
        <Styled.FinishHeadingShimmer />
        <Styled.FinishParaShimmer />
        <Styled.FinishGridShimmer>
          <Styled.FinishBoxShimmer>
            <Styled.FinishIconShimmer />
            <Styled.FinishTextShimmer />
          </Styled.FinishBoxShimmer>
          <Styled.FinishBoxShimmer>
            <Styled.FinishIconShimmer />
            <Styled.FinishTextShimmer />
          </Styled.FinishBoxShimmer>
          <Styled.FinishBoxShimmer>
            <Styled.FinishIconShimmer />
            <Styled.FinishTextShimmer />
          </Styled.FinishBoxShimmer>
          <Styled.FinishBoxShimmer>
            <Styled.FinishIconShimmer />
            <Styled.FinishTextShimmer />
          </Styled.FinishBoxShimmer>
          <Styled.FinishBoxShimmer>
            <Styled.FinishIconShimmer />
            <Styled.FinishTextShimmer />
          </Styled.FinishBoxShimmer>
          <Styled.FinishBoxShimmer>
            <Styled.FinishIconShimmer />
            <Styled.FinishTextShimmer />
          </Styled.FinishBoxShimmer>
        </Styled.FinishGridShimmer>
        <Styled.FinishBtnShimmer />
      </Styled.FinishStepOne>
      <Styled.FinishStepTwo>
        <Styled.FinishHeadingShimmer />
        <Styled.FinishExpCanvasShimmer>
          <Styled.FinishExpShimmer>
            <Styled.FinishExpLineShimmer>
              <Styled.FinishExpBoxShimmer />
              <Styled.FinishExpBoxShimmer />
              <Styled.FinishExpBoxShimmer />
              <Styled.FinishExpBoxShimmer />
              <Styled.FinishExpBoxShimmer />
              <Styled.FinishExpBoxShimmer />
              <Styled.FinishExpBoxShimmer />
            </Styled.FinishExpLineShimmer>
          </Styled.FinishExpShimmer>
        </Styled.FinishExpCanvasShimmer>
        <Styled.FinishBtnShimmer />
      </Styled.FinishStepTwo>
    </Styled.FinishWrapper>
  );
};

export default FinishSignUpShimmer;
