import styled from "styled-components";

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 0px 0px 20px 20px;

  @media only screen and (max-width: 849px) {
    flex-direction: column-reverse;
  }
`;

export const SkillSection = styled.div`
  flex-grow: 0;
  border-right: 1px solid rgb(224, 224, 224);
`;

export const UserDetails = styled.div`
  flex-grow: 2;
  width: 100%;
  word-wrap: break-word;
`;

export const DescriptionWrapper = styled.div`
  padding: 48px 40px;
`;

export const ExpertiseWrapper = styled.div`
  margin: 48px 40px;
`;

export const HR = styled.hr`
  width: 100%;
`;
