import { CameraIcon, UserIcon } from "assets/icons";
import { FC, useEffect, useRef, useState } from "react";
import { ProfileEditIcon } from "@remotebase/components/assets";
import { acceptedImageTypes, acceptedImgSize } from "@remotebase/components";
import { UploadPhotoModal } from "components";
import { withProfile } from "state/profileSteps";
import { ProfileProps } from "utils";
import { useFileUpload } from "hooks";
import * as Styled from "./styles";

const ProfilePhotoSection: FC<ProfileProps> = ({ pendingImage, saveProfileImage }) => {
  const [selectedUserImg, setSelectedUserImg] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const inputElem = useRef<HTMLInputElement>(null);

  const { uploadImage, res } = useFileUpload();
  const togglePhotoModal = (): void => setShowModal((val) => !val);

  const handleNewPhotoUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files || !event.target.files[0]) return;

    const newImg = event.target.files[0];
    if (acceptedImageTypes.includes(newImg.type) && newImg.size <= acceptedImgSize) {
      setSelectedUserImg(newImg);
      togglePhotoModal();
    }
  };

  const handleFileInputClick = (): void => inputElem.current?.click();

  useEffect(() => {
    if (res.isLoading) return;

    if (res.data) {
      saveProfileImage(res.data);
      togglePhotoModal();
      setSelectedUserImg(null);
    }
  }, [res]);

  return (
    <Styled.UploadPhotoWrapper>
      <Styled.UploadPhotoAvatar>
        {pendingImage ? <Styled.UploadedProfileImage src={pendingImage} /> : <UserIcon />}
        <Styled.UploadAvatarIcon onClick={handleFileInputClick}>
          <ProfileEditIcon width={12} />
        </Styled.UploadAvatarIcon>
      </Styled.UploadPhotoAvatar>
      <Styled.UploadImgLink onClick={handleFileInputClick}>
        <Styled.UploadImgInput
          ref={inputElem}
          type="file"
          onChange={handleNewPhotoUpload}
          accept={acceptedImageTypes.join(", ")}
        />
        <Styled.UploadImgText>Upload Photo</Styled.UploadImgText>
        <Styled.UploadCameraIcon>
          <CameraIcon />
        </Styled.UploadCameraIcon>
      </Styled.UploadImgLink>

      {selectedUserImg && (
        <UploadPhotoModal
          show={showModal}
          close={togglePhotoModal}
          selectedImage={selectedUserImg}
          uploader={uploadImage}
          uploading={res.isLoading}
        />
      )}
    </Styled.UploadPhotoWrapper>
  );
};

export default withProfile(ProfilePhotoSection);
