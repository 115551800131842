import styled from "styled-components";

export const PerkList = styled.ul`
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const PerkListText = styled.li`
  font-size: 16px;
  color: #000;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
  display: inline-flex;

  @media only screen and (min-width: 992px) {
    margin-bottom: 50px;
  }

  &:last-child {
    @media only screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }
`;

export const BlueCircleDiv = styled.div`
  font-size: 11px;
  color: #ffffff;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #59c2f9;
  border-radius: 50%;
  margin-right: 15px;

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff;
    }
  }
`;
