import { yupResolver } from "@hookform/resolvers/yup";
import { AcceptInvitationMutation, CreateTalentInput, UserRoles } from "API";
import {
  AuthProps,
  ProfileProps,
  combineHOCs,
  ContactForm,
  ContactFormSchema,
  updateTalentErrorHeading,
  lastNamePlaceholder,
  firstNamePlaceholder,
  emailPlaceholder,
  refInvalidHeading,
} from "utils";
import { BtnLoader, PhoneNumberInput, ProfileInput } from "components";
import { useTalentsProfile, useAnalyticsEventCreator, postIdentify } from "hooks";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { withAuth } from "state/auth";
import withError from "state/error/withErrorHoc";
import { IContactData, IProfile, IProfileName, withProfile } from "state/profileSteps";
import { useAddReferral } from "hooks/query/useAddReferral";
import classNames from "classnames";
import { useSentry } from "hooks/sentry";
import { Country } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { ErrorProps, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import * as Styled from "./styles";
import ProfilePhotoSection from "../profilePhotoSection";

interface Props extends AuthProps, ProfileProps, ErrorProps {}
const ContactSection: FC<Props> = (props: Props) => {
  const {
    authState: { email, userId },
    updateUserInfo,
    profileState: { data: profile },
    showError,
    pendingImage,
  } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    trigger,
  } = useForm({ resolver: yupResolver(ContactFormSchema), mode: "onChange" });

  const { updateInfo, createInfo, data: profileData, error, isLoading } = useTalentsProfile();
  const [isProfileCreated, setIsProfileCreated] = useState(false);
  const [isNewSignup, setIsNewSignUp] = useState(false);
  const [createTalentInputFields, setCreateTalentInputFields] = useState({});
  const { res: refResponse, performAction: linkReferral, shouldPerformAction } = useAddReferral();
  const { sendError } = useSentry();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [country, setCountry] = useState<Country>();
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();
  const { performAction: analyticsIdentifyUser } = postIdentify();
  const onSubmit = (data: IContactData): void => {
    const input: CreateTalentInput = {
      fullName: `${data[ContactForm.firstName]} ${data[ContactForm.lastName]}`,
      phone: `${data[ContactForm.phone]}`,
      email: data[ContactForm.email],
      role: UserRoles.TALENT,
      country: country as Country,
      id: userId,
    };
    if (profile?.id) {
      const updatedInfo = { id: profile.id, expectedVersion: profile.version };
      updateInfo({ variables: { input: { ...input, ...updatedInfo } } });
    } else {
      createInfo({ variables: { input } });
      if (!shouldPerformAction) {
        analyticsIdentifyUser(userId as string, input as IProfile);
        analyticsRegisterEvent(RemoteBaseAnalyticsEvents.SUCCESSFUL_SIGNUP, {
          metaData: JSON.stringify({ ...input }),
        });
      } else {
        setCreateTalentInputFields({ ...input });
        setIsNewSignUp(true);
      }

      setIsProfileCreated(true);
    }
  };
  const splitName = (val: string): IProfileName => {
    const parts = val.split(" ");
    const firstName = parts.shift() || "";
    const lastName = parts.join(" ");
    return { firstName, lastName };
  };

  const shouldShowLoader = useMemo(
    () => isLoading || (shouldPerformAction && refResponse.isLoading),
    [shouldPerformAction, isLoading, refResponse.isLoading],
  );

  useEffect(() => {
    setValue(ContactForm.imageLink, pendingImage);
    trigger([ContactForm.imageLink]).then();
  }, [pendingImage]);

  useEffect(() => {
    setValue(ContactForm.email, email);
  }, [email]);

  useEffect(() => {
    if (profile) {
      const { firstName, lastName } = splitName(profile?.fullName);
      const profilePhone = profile?.phone;
      setValue(ContactForm.firstName, firstName);
      setValue(ContactForm.lastName, lastName);
      setValue(ContactForm.phone, profilePhone);
      setPhoneNumber(profilePhone);
    }
  }, [profile]);

  useEffect(() => {
    if (profileData && email) {
      if (isProfileCreated && shouldPerformAction) linkReferral();
      else updateUserInfo(profileData as IProfile);
    } else if (error) {
      if (profile) {
        const { firstName, lastName } = splitName(profile?.fullName);
        const createTalentInput: CreateTalentInput = {
          fullName: `${firstName} ${lastName}`,
          phone: `${profile?.phone}`,
          email: email || "",
          role: UserRoles.TALENT,
          country: profile.country,
          id: userId,
        };
        sendError(error, { createTalentInput });
      }
      showError({ title: updateTalentErrorHeading, message: error?.message });
    }
  }, [profileData, error]);

  useEffect(() => {
    if (refResponse.data && isNewSignup && createTalentInputFields) {
      const resData = refResponse.data as AcceptInvitationMutation;
      const reffereByEmail = resData.acceptInvitation?.data?.refferedBy;
      const input = createTalentInputFields as IProfile;
      analyticsRegisterEvent(RemoteBaseAnalyticsEvents.SUCCESSFUL_SIGNUP, {
        metaData: JSON.stringify({ ...createTalentInputFields, referredBy: reffereByEmail }),
      });
      input.referredBy = reffereByEmail;
      analyticsIdentifyUser(userId as string, input as IProfile);
    }
    if (refResponse.data && profileData) {
      updateUserInfo(profileData as IProfile);
      setIsProfileCreated(false);
    }
    if (refResponse.error) showError({ title: refInvalidHeading, message: refResponse.error });
  }, [refResponse, profileData]);

  useEffect(() => {
    if (phoneNumber) setValue(ContactForm.phone, phoneNumber);
  }, [phoneNumber]);

  return (
    <Fragment>
      <h2>
        What’s your<Styled.PurpleFont> name</Styled.PurpleFont> and contact details?
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.ProfilePhotoCover>
          <ProfilePhotoSection />
          {errors[ContactForm.imageLink] && (
            <Styled.CustomError>{errors[ContactForm.imageLink].message}</Styled.CustomError>
          )}
        </Styled.ProfilePhotoCover>

        <div className="row">
          <ProfileInput
            label="First Name"
            type="text"
            inputProps={register(ContactForm.firstName)}
            errorMsg={errors[ContactForm.firstName]?.message as string}
            placeholder={firstNamePlaceholder}
          />
          <ProfileInput
            label="Last Name"
            type="text"
            inputProps={register(ContactForm.lastName)}
            errorMsg={errors[ContactForm.lastName]?.message as string}
            placeholder={lastNamePlaceholder}
          />
        </div>

        <div className="row">
          <ProfileInput
            label="Email"
            type="email"
            value={email || ""}
            disabled={!!email}
            placeholder={emailPlaceholder}
            inputProps={register(ContactForm.email)}
            errorMsg={errors[ContactForm.email]?.message as string}
          />
          <PhoneNumberInput
            setCountry={setCountry}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
          />
        </div>

        <div className="row row-20 align-items-center">
          <div className="col-auto mb-2">
            <Styled.NextBtn
              type="submit"
              name="commit"
              value="Next"
              id="submitForm"
              data-disable-with="Submit"
              className={classNames({ disabled: !isDirty })}
              disabled={!isDirty && shouldShowLoader}
            >
              <ShouldRender if={!shouldShowLoader}>
                <Fragment>Next</Fragment>
              </ShouldRender>
              <ShouldRender if={shouldShowLoader}>
                <BtnLoader />
              </ShouldRender>
            </Styled.NextBtn>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default combineHOCs(withAuth, withProfile, withError)(ContactSection) as FC;
