import { Sidebar, SidebarProps, combineHOCs, SidebarType, TalentLevelProps } from "utils";
import { FC, Fragment } from "react";
import { withTalentLevel } from "state/profileSteps";
import {
  sidebarMenu,
  ISidebarElements,
  withSidebar,
  getStatus,
  SidebarCreateProfileOptions,
} from "state/sidebar";
import SidebarItem from "./SidebarItem";
import * as Styled from "./styles";

interface Props extends SidebarProps, TalentLevelProps {}
const SidebarMenu: FC<Props> = ({ sidebarType, talentLevel: { activeStages, level } }) => {
  const getElemIndex = (index: number, key?: string): string => `${key || ""} ${index}`;
  const getSidebarItem = (element: Sidebar, index: string): JSX.Element => {
    const profileIndex = activeStages.findIndex((val) => val === element);
    const createProfileIndex = SidebarCreateProfileOptions.findIndex((val) => val === element);
    return (
      <SidebarItem
        key={index}
        text={element}
        status={getStatus(Math.max(profileIndex, createProfileIndex), level)}
      />
    );
  };

  const getMenuObjects = (objValue: ISidebarElements, objKey?: string): Array<JSX.Element> => {
    const elems = [] as JSX.Element[];
    objValue.map((element, index) => {
      if (typeof element === "string") {
        elems.push(getSidebarItem(element, getElemIndex(index, objKey)));
      } else if (sidebarType === SidebarType.Dashboard)
        elems.push(...getMenuObjects(activeStages, `subElem${index}`));
      else elems.push(...getMenuObjects(element, `subElem${index}`));
      return <Fragment />;
    });
    if (objKey)
      return [<Styled.SidebarSubMenu key={`subMenu_${objKey}`}>{elems}</Styled.SidebarSubMenu>];
    return elems;
  };
  const getSidebarMenu = (): Array<JSX.Element> => {
    const output = sidebarMenu[sidebarType] as ISidebarElements;
    if (output?.length) return getMenuObjects(output);
    return [];
  };

  return <Fragment>{getSidebarMenu()}</Fragment>;
};

export default combineHOCs(withSidebar, withTalentLevel)(SidebarMenu) as FC;
