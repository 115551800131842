import styled from "styled-components";

export const CarouselBtnCanvas = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;

  @media screen and (min-width: 768px) {
    position: absolute;
    top: -80px;
    right: 36px;
  }
`;

export const CarouselBtnPrev = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c65fe;
  border: 1px solid #3c65fe;
  border-radius: 50%;
  position: static;

  @media screen and (min-width: 576px) {
    width: 40px;
    height: 40px;
  }

  & svg {
    width: 14px;
    height: 14px;

    @media screen and (min-width: 576px) {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: #fff;
    }
  }

  &:disabled {
    background-color: transparent;

    & svg {
      & path {
        fill: #3c65fe;
      }
    }
  }
`;

export const CarouselBtnNext = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c65fe;
  border: 1px solid #3c65fe;
  border-radius: 50%;
  position: static;

  @media screen and (min-width: 576px) {
    width: 40px;
    height: 40px;
  }

  & svg {
    width: 14px;
    height: 14px;

    @media screen and (min-width: 576px) {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: #fff;
    }
  }

  &:disabled {
    background-color: transparent;

    & svg {
      & path {
        fill: #3c65fe;
      }
    }
  }
`;
