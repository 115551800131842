import React, { useEffect } from "react";
import { LandingInner } from "components";
import { storeUtmParams } from "utils";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import * as Styled from "./styles";

export const LandingPage: React.FC = () => {
  const { search } = useLocation();
  const atsJobID = new URLSearchParams(search).get("atsJobID");
  storeUtmParams();

  useEffect(() => {
    if (atsJobID) {
      localStorage.setItem("atsJobID", atsJobID);
    }
  }, [atsJobID]);

  return (
    <Styled.LandingPageWrapper>
      <Helmet>
        <link rel="canonical" href="https://talent.remotebase.com" />
      </Helmet>
      <LandingInner />
    </Styled.LandingPageWrapper>
  );
};

export default LandingPage;
