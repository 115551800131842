import { FC, Fragment, useContext, useEffect, useState } from "react";
import { CheckMarkIcon } from "assets/icons";
import { ProfileTalentContext, ProfileOptions, withProfile } from "state/profileSteps";
import { HeardFromBtnId, HeardFromInputId, HeardFroms as HeardFromData, ProfileProps } from "utils";
import { CheckBoxInput } from "components";
import * as Styled from "./styles";

export const HeardFromSection: FC<ProfileProps> = ({ profileState: { data } }) => {
  const [heardFrom, setHeardFrom] = useState<string>(data?.profile?.heardFrom || "");
  const { talent, setTalent } = useContext(ProfileTalentContext);
  const checkBoxType = "heardFrom_section_checkbox";

  const updateHeardFrom = (val: HeardFromData): void => setHeardFrom(val);

  const getInput = (val: HeardFromData): JSX.Element =>
    Object.values(HeardFromData).includes(val) ? (
      <CheckBoxInput
        testingId={HeardFromInputId}
        key={val}
        type={checkBoxType}
        value={val}
        onSelect={updateHeardFrom}
        isChecked={heardFrom === val}
        isBox
        text={val}
      />
    ) : (
      <Fragment key={val} />
    );

  const heardFromOptions = ProfileOptions(HeardFromData, getInput, heardFrom);

  const handleSubmit = (): void => setTalent({ heardFrom });

  useEffect(() => {
    if (data?.profile?.heardFrom) setHeardFrom(data?.profile?.heardFrom || "");
  }, []);

  useEffect(() => {
    if (talent.heardFrom) setHeardFrom(talent.heardFrom);
  }, [talent]);

  return (
    <Fragment>
      <h2 className="pb-2">
        How did you <Styled.PurpleFont>hear</Styled.PurpleFont> about us?
      </h2>
      <p />
      <Styled.Checkboxes>{heardFromOptions}</Styled.Checkboxes>
      <Styled.NextBtn
        data-testid={HeardFromBtnId}
        onClick={handleSubmit}
        type="button"
        id="multiSelect_nextButton_projectType"
        disabled={!heardFrom}
      >
        Next
        <CheckMarkIcon />
      </Styled.NextBtn>
    </Fragment>
  );
};

export default withProfile(HeardFromSection);
