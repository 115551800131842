import { FC, Fragment, useEffect, useState, useCallback } from "react";
import { useAnalyticsEventCreator, useTalentProfile, useGetProblemSolvingTest } from "hooks";
import {
  TestsType,
  AuthRoutes as Route,
  ProfileProps,
  parseInviteResponse,
  hackerRankUrlErrorHeading,
  hackerRankUrlErrorMessage,
  codeTestText,
  isTestCleared,
  startTest,
  waitingForResult,
  goToFinalTech,
  defaultPSScore,
} from "utils";
import { useHistory } from "react-router-dom";
import { IProfileDetails, withProfile } from "state/profileSteps";
import withError from "state/error/withErrorHoc";
import { DomainTestModal } from "components";
import { TechSkillTestProvider, UpdateTalentProfileWithUsdSalaryInput } from "API";
import { ErrorProps, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import { ShouldRender } from "@remotebase/components";
import { useSentry } from "hooks/sentry";
import * as Styled from "./styles";

export const ProblemSolving: FC<ProfileProps & ErrorProps> = (props) => {
  const {
    profileState: { data: profile },
    updateUserProfile,
    showError,
  } = props;
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { getProblemSolving, data: problemSolvingData, error } = useGetProblemSolvingTest();
  const { sendError } = useSentry();
  const { updateProfile, data: updatedData } = useTalentProfile();
  const [testStart, setTestStarted] = useState(false);
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const displayButtonText = useCallback(
    () => (testStart || profile?.profile?.isPassPS === false ? waitingForResult : startTest),
    [testStart, profile?.profile],
  );

  const startButtonClickCallback = useCallback((): void => {
    if (isTestCleared(profile, TestsType.ProblemSolving)) {
      history.push(Route.finalTechInterview);
    } else {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    if (
      profile?.profile?.problemSolvingScore &&
      profile?.profile?.problemSolvingScore < defaultPSScore &&
      profile?.profile?.isPassPS === null
    ) {
      setTestStarted(true);
    }
  }, [profile]);

  useEffect(() => {
    if (problemSolvingData?.getProblemSolvingTest) {
      const inviteData = parseInviteResponse(problemSolvingData?.getProblemSolvingTest);
      if (inviteData) {
        const { url, candidateId } = inviteData;
        analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TAKE_PS_TEST);
        window.open(url);
        if (profile?.profile) {
          const input: UpdateTalentProfileWithUsdSalaryInput = {
            expectedVersion: profile?.profile.version,
            id: profile?.profile.id,
            problemSolvingScore: -1,
            problemSolvingTest: {
              score: 0,
              isPass: false,
              provider: TechSkillTestProvider.HackerRank,
              dateAttempted: new Date().toISOString(),
              candidateId,
              testLink: url,
            },
          };
          updateProfile({ variables: { input } });
          setTestStarted(true);
        }
      } else {
        sendError(error, { userId: profile?.profile?.id });
        showError({
          title: hackerRankUrlErrorHeading,
          message: hackerRankUrlErrorMessage,
        });
      }
    }
  }, [problemSolvingData, error]);

  useEffect(() => {
    if (updatedData) updateUserProfile(updatedData as IProfileDetails);
  }, [updatedData]);
  return (
    <Fragment>
      <ShouldRender if={showModal}>
        <DomainTestModal
          onCancel={(): void => setShowModal(false)}
          onOkay={(): void => {
            getProblemSolving();
            setShowModal(false);
          }}
          heading={codeTestText.heading}
          body={codeTestText.body}
          btnText={codeTestText.btnText}
        />
      </ShouldRender>
      <ShouldRender if={profile?.profile?.isPassPS === false}>
        <Styled.FailedPSText>
          Unfortunately you couldn't pass this stage.
          <br /> You should be able to re-attempt the test in 3 months
        </Styled.FailedPSText>
      </ShouldRender>
      <ShouldRender if={profile?.profile?.isPassPS === null || testStart}>
        <>
          <Styled.BtnStart
            onClick={(): void => startButtonClickCallback()}
            disabled={testStart || profile?.profile?.isPassPS === false || true}
          >
            {displayButtonText()}
          </Styled.BtnStart>

          <ShouldRender if={testStart}>
            <Styled.LinkBtn
              target="_blank"
              href={profile?.profile?.problemSolvingTest?.testLink ?? ""}
            >
              Take me back to the test
            </Styled.LinkBtn>
          </ShouldRender>
        </>
      </ShouldRender>
      <ShouldRender if={profile?.profile?.isPassPS === true}>
        <Styled.BtnsBox>
          <Styled.BtnStart onClick={(): void => startButtonClickCallback()}>
            {goToFinalTech}
          </Styled.BtnStart>
        </Styled.BtnsBox>
      </ShouldRender>
    </Fragment>
  );
};
export default withProfile(withError(ProblemSolving));
