import { FC, Fragment, useContext, useEffect } from "react";
import { CheckMarkIcon } from "assets/icons";
import { TalentType } from "@remotebase/amplify-constants/API";
import { useGetTalentTypeDetails } from "hooks";
import { ProfileTalentContext, ProfileTalentTypeContext, withProfile } from "state/profileSteps";
import { iconBucketName, ProfileProps, TalentFieldBtnId } from "utils";
import { Username } from "@remotebase/components";
import * as Styled from "./styles";

const TalentSection: FC<ProfileProps> = ({ profileState: { data } }) => {
  const { setTalent } = useContext(ProfileTalentContext);
  const {
    talentType: { talentList, selectedTalent },
    setTalentType,
  } = useContext(ProfileTalentTypeContext);
  const { getTalentTypeDetail, talentType, loading } = useGetTalentTypeDetails();

  const handleSubmit = (): void => {
    setTalent({ talentTypeID: selectedTalent?.id });
    getTalentTypeDetail({ variables: { id: selectedTalent?.id } });
  };

  const updateSelectedTalent = (talentId: string): void => {
    setTalentType({
      talentList,
      selectedTalent: talentList.filter((item) => item?.id === talentId)[0],
    });
  };

  useEffect(() => {
    if (talentType && !loading)
      setTalentType({
        talentList,
        selectedTalent: talentType,
      });
  }, [talentType, loading]);

  return (
    <Fragment>
      <Styled.CategoryWrapper>
        <h2>
          <Styled.PurpleFont>
            Hi <Username showFirstName fullName={data?.fullName} />! {""}
          </Styled.PurpleFont>
          we’d love to get to know you better
        </h2>
        <p>
          {" "}
          Select from any of the categories that best suits you and describes what you do well.
        </p>

        <Styled.TalentBadge>
          <Styled.TalentBadgeContent>
            <Styled.TalentBadgeIcon>🔔</Styled.TalentBadgeIcon>
            <Styled.TalentBadgePara>
              Please note that when you select a category, it cannot be changed after sign up is
              complete
            </Styled.TalentBadgePara>
          </Styled.TalentBadgeContent>
        </Styled.TalentBadge>

        <Styled.CategoryRow className="small__row">
          {talentList?.map((item: TalentType, index) => (
            <Styled.CategoryCol className="small__col" xl={2} md={3} sm={4} xs={6} key={index}>
              <Styled.CategoryCard
                className="small__card"
                onClick={(): void => updateSelectedTalent(item.id)}
                $active={item.id === selectedTalent?.id}
              >
                <Styled.CategoryImg className="sm__img">
                  <img src={`${iconBucketName}${item.icon}`} alt={"sm__img"} />
                </Styled.CategoryImg>
                <Styled.CategoryTitle className="sm__title">{item.title}</Styled.CategoryTitle>
              </Styled.CategoryCard>
            </Styled.CategoryCol>
          ))}
        </Styled.CategoryRow>
      </Styled.CategoryWrapper>

      <Styled.NextBtn
        data-testid={TalentFieldBtnId}
        onClick={handleSubmit}
        type="button"
        id="multiSelect_nextButton_projectType"
        disabled={!selectedTalent}
      >
        Next
        <CheckMarkIcon />
      </Styled.NextBtn>
    </Fragment>
  );
};

export default withProfile(TalentSection);
