import { FC, Fragment } from "react";
import { ShouldRender } from "../../shouldRender";
import { BtnLoader } from "../../loader";
import * as S from "./styles";

interface IActionBtn {
  btnText: string;
  clickHandler?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  width?: string;
  color?: string;
  hoverColor?: string;
  backgroundColor?: string;
  hoverBackground?: string;
  borderRadius?: string;
  border?: string;
}

export const ActionBtn: FC<IActionBtn> = ({
  btnText,
  clickHandler,
  width,
  isLoading,
  disabled,
  color,
  hoverColor,
  hoverBackground,
  backgroundColor,
  borderRadius,
  border,
}) => {
  const handleClick = (): void => {
    if (clickHandler) clickHandler();
  };

  const cursorNature = (): string => (clickHandler ? "pointer" : "normal");
  return (
    <S.GenericBtn
      onClick={handleClick}
      disabled={disabled}
      $width={width}
      $color={color}
      $hoverColor={hoverColor}
      $backgroundColor={backgroundColor}
      $hoverBackground={hoverBackground}
      $borderRadius={borderRadius}
      $border={border}
      $cursor={cursorNature()}
    >
      <ShouldRender if={!isLoading}>
        <Fragment>{btnText}</Fragment>
      </ShouldRender>
      <ShouldRender if={isLoading}>
        <BtnLoader />
      </ShouldRender>
    </S.GenericBtn>
  );
};

export default ActionBtn;
