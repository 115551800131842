import { FC } from "react";
import { withProfile } from "state/profileSteps";
import {
  AuthRoutes as Route,
  imageUrl,
  ProfileProps,
  sidebarBurgerTestId,
  sidebarCloseBtnTestId,
  sideBarLogoBlackAltText,
  sideBarLogoWhiteAltText,
  sidebarTopLogoTestId,
} from "utils";
import * as Styled from "./styles";

interface Props {
  handleClick: () => void;
  toggle: boolean;
}

const SidebarTop: FC<Props & ProfileProps> = (props) => {
  const {
    handleClick,
    toggle,
    profileState: { data },
  } = props;
  const logoOnClick = (): Route => {
    if (!data) return Route.CreateProfile;
    if (data && !data.profile) return Route.FinishSignUp;
    return Route.Dashbord;
  };
  return (
    <Styled.SidebarTop>
      <Styled.SidebarCloseBtn data-testid={sidebarCloseBtnTestId} onClick={handleClick}>
        <i className="icon-cross" />
      </Styled.SidebarCloseBtn>

      <Styled.SidebarTopLogo
        data-testid={sidebarTopLogoTestId}
        to={logoOnClick}
        className="app__logo"
      >
        <Styled.SidebarPicBlack src={imageUrl.logo} alt={sideBarLogoBlackAltText} />
        <Styled.SidebarPicWhite src={imageUrl.logo} alt={sideBarLogoWhiteAltText} />
      </Styled.SidebarTopLogo>

      <Styled.SidebarBurger
        className={toggle ? "active" : ""}
        onClick={handleClick}
        data-testid={sidebarBurgerTestId}
      ></Styled.SidebarBurger>
    </Styled.SidebarTop>
  );
};

export default withProfile(SidebarTop);
