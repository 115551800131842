import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const TalentModal = styled(Modal)`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .modal-content {
    max-width: 614px;
    background: #ffffff;
    border-radius: 13px;
  }
  .modal-body {
    position: relative;
    padding: 0;
  }
`;

export const ModalCrossIcon = styled.div`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  box-shadow: none;
  position: absolute;
  left: auto;
  right: 44px;
  top: 30px;
  bottom: auto;
  z-index: 5;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s ease;
  & svg {
    width: 20px;
    height: 16px;
    & rect {
      fill: #000000;
    }
  }
  &:hover,
  &:focus {
    background-color: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border: none;
    border-radius: 50%;
    & svg {
      & rect {
        fill: #767676;
      }
    }
  }
  @media (min-width: 576px) {
    right: 48px;
    top: 38px;
  }
`;

export const TalentModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 403px;
  margin: 0px auto;
  padding: 43px 20px 99px 20px;
`;

export const TalentModalCube = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 148px;
  max-height: 148px;
  margin: 0px auto 36px auto;
  & img {
    max-width: 100%;
  }
`;

export const TalentTopSphere = styled.div`
  position: absolute;
  top: 2px;
  bottom: auto;
  left: 2px;
  right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 62px;
  max-height: 62px;
  margin: 0px;
  & img {
    max-width: 100%;
  }
  @media (min-width: 576px) {
    top: -33px;
    left: -39px;
    max-width: 92px;
    max-height: 92px;
  }
`;

export const TalentBottomSphere = styled.div`
  position: absolute;
  top: auto;
  bottom: 10px;
  left: auto;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 62px;
  max-height: 62px;
  margin: 0px;
  @media (min-width: 576px) {
    bottom: 18px;
    right: -23px;
    max-width: 92px;
    max-height: 92px;
  }
  & img {
    max-width: 100%;
  }
`;

export const TalentModalHd = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #0c2556;
  margin: 0px 0px 8px 0px;
  text-align: center;
`;

export const TalentModalPara = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #7c7c7c;
  margin: 0px 0px 24px 0px;
  text-align: center;
`;

export const TalentModalInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 27.5px !important;
  outline: none;
  box-shadow: none;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #404040 !important;
  padding: 8px 35px !important;
  height: 57px !important;
  width: 100%;
  margin: 0px 0px 25px 0px;
  cursor: pointer;
  &::placeholder {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #7c7c7c;
  }
  &:focus {
    background: #ffffff;
    border: 1px solid #5138ed !important;
    outline: none;
    box-shadow: none;
    color: #404040 !important;
  }
`;

export const TalentModalBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5138ed;
  border: none;
  border-radius: 27.5px;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  letter-spacing: -0.01em;
  padding: 8px 12px;
  min-height: 57px;
  width: 100%;
  margin: 0px;
  cursor: pointer;
  &:hover,
  &:focus,
  &.active {
    background: #432fc0;
    border: none;
    outline: none;
    box-shadow: none;
    color: #ffffff;
  }

  &:disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
`;
