import { AnalyticsMetricsFrontEnd, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import { RemotebaseWindow } from "hooks/types";
import { populateMetaData } from "hooks/utils";
import { useCallback, useContext } from "react";
import { ProfileContext, ProfileContextType } from "state/profileSteps";
import { useAnalytics } from "utils";

type UseAnalyticsEventCreatorRerturnType = {
  performAction: (
    eventName: RemoteBaseAnalyticsEvents,
    properties?: AnalyticsMetricsFrontEnd,
  ) => void;
};

export const useAnalyticsEventCreator = (): UseAnalyticsEventCreatorRerturnType => {
  const {
    profileState: { data },
  } = useContext<ProfileContextType>(ProfileContext);
  const win: RemotebaseWindow = window;
  const cb = async (
    eventName: RemoteBaseAnalyticsEvents,
    properties: AnalyticsMetricsFrontEnd,
  ): Promise<void> => {
    if (useAnalytics) {
      win.analytics?.track(
        eventName,
        populateMetaData(data?.profile?.talentType?.name || "", properties),
      );
    } else {
      console.info("would have sent following event to segment ", {
        eventName,
        properties: populateMetaData(data?.profile?.talentType?.name || "", properties),
      });
    }
  };
  const callback = useCallback(cb, [win.analytics, useAnalytics]);
  return { performAction: callback };
};

export default useAnalyticsEventCreator;
