import { upperFirst } from "lodash";
import { FC, Fragment } from "react";

interface Props {
  type: string;
  value: string;
  onSelect: (value: string) => void;
  isBox?: boolean;
  isChecked?: boolean;
  testingId?: string;
  text?: string;
}

export const CheckBoxInput: FC<Props> = ({
  text,
  type,
  value,
  onSelect,
  isBox,
  isChecked,
  testingId,
}) => {
  const id = `${type}_${value}`;

  const getValue = (): string => upperFirst(text || value);
  const printValue = (): string | JSX.Element => {
    if (isBox) return <span>{getValue()}</span>;
    return getValue();
  };

  return (
    <Fragment>
      <input
        data-testid={testingId}
        type="checkbox"
        value={value}
        name={type}
        id={id}
        checked={isChecked}
        onChange={(e): void => onSelect(e.target.value)}
      />
      <label htmlFor={id}>{printValue()}</label>
    </Fragment>
  );
};

export default CheckBoxInput;
