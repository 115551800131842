import styled from "styled-components";

const animate = `
animation: shimmer 4s infinite linear;
background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
background-size: 1000px 100%;

@keyframes shimmer {
  0% { background-position: -1000px 0;}
  100% {background-position: 1000px 0;}
}
`;

const animateDark = `
animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #e8ecef 4%, #e2e2e2 25%, #e8ecef 36%);
  background-size: 1000px 100%;

@keyframes shimmer {
  0% { background-position: -1000px 0;}
  100% {background-position: 1000px 0;}
}
`;

const shimmerStyle = `
background-color: #ededed;
border-radius: 4px;
display: inline-flex;`;

export const FinishWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const FinishStepOne = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinishHeadingShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  height: 40px;
  max-width: 812px;
  margin-bottom: 24px;
`;

export const FinishParaShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  height: 25px;
  max-width: 600px;
  margin-bottom: 22px;
`;

export const FinishGridShimmer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
  padding-top: 18px;

  @media only screen and (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FinishBoxShimmer = styled.div`
  ${shimmerStyle}
  ${animateDark}
  width: 100%;
  height: 119px;
  padding: 33px 12px 18px 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FinishIconShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 28px;
  height: 26px;
  margin-bottom: 22px;
`;

export const FinishTextShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 80px;
  height: 16px;
`;

export const FinishBtnShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 110px;
  height: 48px;
  border-radius: 5px;
`;

export const FinishStepTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 124px;
`;

export const FinishExpCanvasShimmer = styled.div`
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  @media only screen and (min-width: 992px) {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  @media only screen and (min-width: 1200px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`;

export const FinishExpShimmer = styled.div`
  ${shimmerStyle}
  ${animateDark}
  width: 100%;
  height: 99px;
  border-radius: 6px;
  padding: 25px 25px 20px;
  margin-bottom: 30px;
`;

export const FinishExpLineShimmer = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;

export const FinishExpBoxShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  height: 52px;

  /* @media only screen and (min-width: 1200px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  } */
`;
