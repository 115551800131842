import { FC } from "react";
import * as Styled from "./styles";

interface Props {
  href?: string;
  text: string;
}
const SidebarProfileOption: FC<Props> = ({ href, text }) => {
  return (
    <Styled.SidebarLink to={href || "#"}>
      <Styled.SidebarText center>{text}</Styled.SidebarText>
    </Styled.SidebarLink>
  );
};

export default SidebarProfileOption;
