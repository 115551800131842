import React from "react";
// import { getIndividualPerk, mapOutput, PerksListInterface } from "state/dashboard";
import { mapOutput, PerksListInterface } from "state/dashboard";
import { CheckIcon } from "assets/icons";
import * as Styled from "./styles";

type Props = {
  perk: PerksListInterface;
};

const PerksSingleList: React.FC<Props> = ({ perk }) => {
  const getPerkList = (value: string, index): JSX.Element => {
    return (
      <Styled.PerkListText
        key={index}
        className="aos-item"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Styled.BlueCircleDiv>
          <CheckIcon />
        </Styled.BlueCircleDiv>
        {value}
      </Styled.PerkListText>
    );
  };

  const listPerk = mapOutput<string>(Object.values(perk), getPerkList);

  return <Styled.PerkList>{listPerk}</Styled.PerkList>;
};

export default PerksSingleList;
