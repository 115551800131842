import {
  EditToolTip,
  formInputText,
  ProfileEditInput,
  SelectInput,
  ShouldRender,
} from "@remotebase/components";
import { SidebarLockIcon } from "assets/icons";
import { Fragment, FC } from "react";
import {
  availbilityOptions,
  currencyOptions,
  currentSalaryLabel,
  expectedSalaryLabel,
  firstNameLabel,
  firstNamePlaceholder,
  IProfileInfoFormData,
  isAvailableLabel,
  lastNameLabel,
  lastNamePlaceholder,
  markAvailable,
  perMonthLabel,
  ProfileInfoForm,
} from "utils";
import * as S from "../styles";

export interface IProfileInfoFormBody {
  formData: IProfileInfoFormData;
  updateFormData: (key: string, value: string) => void;
  currentSalaryCountry: string;
  expectedSalaryCountry: string;
  setCurrentSalaryCountry: React.Dispatch<React.SetStateAction<string>>;
  setExpectedSalaryCountry: React.Dispatch<React.SetStateAction<string>>;
  expectedSalaryCheck: {
    isEditable: boolean;
    message: string;
  };
}

export const ProfileInfoFormBody: FC<IProfileInfoFormBody> = ({
  formData,
  updateFormData,
  currentSalaryCountry,
  expectedSalaryCountry,
  setCurrentSalaryCountry,
  setExpectedSalaryCountry,
  expectedSalaryCheck,
}) => {
  return (
    <Fragment>
      <S.FormBody>
        <S.FormGroup>
          <S.InputGroup>
            <ProfileEditInput
              type={formInputText}
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              value={formData[ProfileInfoForm.firstName]}
              onTextChange={(val): void => updateFormData(ProfileInfoForm.firstName, val)}
            />
            <ProfileEditInput
              type={formInputText}
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              value={formData[ProfileInfoForm.lastName]}
              onTextChange={(val): void => updateFormData(ProfileInfoForm.lastName, val)}
            />
          </S.InputGroup>
        </S.FormGroup>
        <S.FormGroup>
          <S.FormLabel>
            {currentSalaryLabel} <small>{perMonthLabel}</small>
          </S.FormLabel>
          <S.SalaryFieldContainer>
            <S.CurrencyWrapper>
              <SelectInput
                selected={currencyOptions.find((it) => it.value === currentSalaryCountry)}
                options={currencyOptions}
                handleSingleSelect={(option): void => setCurrentSalaryCountry(option.value)}
                removeMargin
                removeBorderRight
              />
            </S.CurrencyWrapper>
            <S.SalaryInput
              onChange={(e): void => updateFormData(ProfileInfoForm.currentSalary, e.target.value)}
              defaultValue={formData[ProfileInfoForm.currentSalary] ?? ""}
              type={formInputText}
            />
          </S.SalaryFieldContainer>
        </S.FormGroup>
        <S.FormGroup>
          <S.FormLabel>
            {expectedSalaryLabel} <small>{perMonthLabel}</small>
            <ShouldRender if={!expectedSalaryCheck.isEditable}>
              <EditToolTip
                icon={<SidebarLockIcon width={14} />}
                popoverText={expectedSalaryCheck.message}
              />
            </ShouldRender>
          </S.FormLabel>
          <S.SalaryFieldContainer disableExpected={!expectedSalaryCheck.isEditable}>
            <S.CurrencyWrapper>
              <SelectInput
                selected={currencyOptions.find((it) => it.value === expectedSalaryCountry)}
                options={currencyOptions}
                handleSingleSelect={(option): void => setExpectedSalaryCountry(option.value)}
                isDisabled={!expectedSalaryCheck.isEditable}
                removeMargin
                removeBorderRight
              />
            </S.CurrencyWrapper>
            <S.SalaryInput
              onChange={(e): void => updateFormData(ProfileInfoForm.expectedSalary, e.target.value)}
              defaultValue={formData[ProfileInfoForm.expectedSalary] ?? ""}
              type={formInputText}
              disabled={!expectedSalaryCheck.isEditable}
            />
          </S.SalaryFieldContainer>
        </S.FormGroup>
        <SelectInput
          selected={availbilityOptions.find(
            (it) => (it.value === markAvailable) === formData[ProfileInfoForm.isAvailable],
          )}
          label={isAvailableLabel}
          options={availbilityOptions}
          handleSingleSelect={(option): void =>
            updateFormData(ProfileInfoForm.isAvailable, option.value)
          }
        />
      </S.FormBody>
    </Fragment>
  );
};

export default ProfileInfoFormBody;
