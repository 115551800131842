import React from "react";
import AOS from "aos";
import { imageUrl } from "utils";
import * as S from "./styles";

export const dataList = [
  {
    imgURL: imageUrl.growthMindset,
    dataAosDelay: "0",
    traitsTitle: "Growth mindset",
    alt: "Growth Mindset Image",
  },
  {
    imgURL: imageUrl.solveProblems,
    dataAosDelay: "300",
    traitsTitle: "Natural problem solver",
    alt: "Solve Problems Image",
  },
  {
    imgURL: imageUrl.briefcase,
    dataAosDelay: "600",
    traitsTitle: "Relevant Experience",
    alt: "Briefcase Image",
  },
  {
    imgURL: imageUrl.commSkills,
    dataAosDelay: "900",
    traitsTitle: "Exceptional communication skills",
    alt: "Comm Skills Image",
  },
];

export const LandingTraits: React.FC = () => {
  AOS.init();

  return (
    <S.TraitsWrapper id="criteria">
      <S.Container>
        <S.TraitsHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
          Traits that we looking for
        </S.TraitsHeading>
        <S.TraitPara
          className="aos-item"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          If you have these traits, you are the person we are looking for!
        </S.TraitPara>
        <S.TraitsCanvas>
          <S.TraitsContent>
            {dataList?.map((item, index) => (
              <S.TraitItem
                key={index}
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration={item.dataAosDelay}
                data-testid={"default_traits"}
              >
                <S.TraitsImgBox>
                  <S.TraitsImg src={item.imgURL} alt={item.alt} />
                </S.TraitsImgBox>
                <S.TraitTitle>{item.traitsTitle}</S.TraitTitle>
              </S.TraitItem>
            ))}
          </S.TraitsContent>
        </S.TraitsCanvas>
      </S.Container>
    </S.TraitsWrapper>
  );
};

export default LandingTraits;
