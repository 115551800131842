import styled from "styled-components";

export const ChatBodyWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  flex-grow: 1;
  flex: 1 1 100%;
  max-width: 100%;
  background: #ffffff;
  border: none;
  overflow: hidden;
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    flex: 1 1 58%;
    max-width: 58%;
    display: flex;
  }
  @media only screen and (min-width: 992px) {
    flex: 1 1 62%;
    max-width: 62%;
  }
  @media only screen and (min-width: 1200px) {
    flex: 1 1 70%;
    max-width: 70%;
  }
`;

export const ChatBodyMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  background-color: rgba(243, 243, 241, 0.3);
  margin-top: 60px;
  margin-bottom: 54px;
  flex-grow: 1;
  height: calc(100vh - 114px);
  & > div {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dfdbdb;
    border-radius: 0px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 0px;
    margin-bottom: 0px;
    height: auto;
  }
`;
