// import { useLazyQuery } from "@apollo/client";
// import {
//   VerifyAndGetAvailableSlotsQuery,
//   VerifyAndGetAvailableSlotsQueryVariables,
// } from "@remotebase/amplify-constants/API";
// import { verifyAndGetAvailableSlots } from "@remotebase/amplify-constants/graphql/queries";
// import { getQuery } from "hooks/utils";
import { IReturnPropsScheduleMagicLink } from "state/types/magicLinkTypes";

/**
 *
 * @deprecated due to the removal of searchable directive from CalendarBooking
 */
export const useScheduleMagicLink = (): IReturnPropsScheduleMagicLink => {
  // const [getAvailableSlots, { data, loading }] = useLazyQuery<
  //   VerifyAndGetAvailableSlotsQuery,
  //   VerifyAndGetAvailableSlotsQueryVariables
  // >(getQuery(verifyAndGetAvailableSlots));
  // const availableSlots = data?.verifyAndGetAvailableSlots?.data || [];
  // const code = data?.verifyAndGetAvailableSlots?.code || null;

  return {
    getAvailableSlots: () => {},
    loading: false,
    availableSlots: [],
    code: null,
  };
};

export default useScheduleMagicLink;
