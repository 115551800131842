import { upperFirst } from "lodash";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import * as Styled from "./styles";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  type: string;
  label: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  inputProps?: any;
  onTextChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  errorMsg?: string;
  placeholder?: string;
}

export const ProfileInput: FC<Props> = (props) => {
  const { type, label, onTextChange, errorMsg, inputProps, value, disabled, ...rest } = props;

  return (
    <div className="col-md-5">
      <Styled.CustomLabel>{label}</Styled.CustomLabel>
      <input
        type={type}
        onChange={onTextChange && ((e): void => onTextChange(e.target.value))}
        defaultValue={value || ""}
        disabled={disabled}
        {...rest}
        {...inputProps}
      />
      <Styled.CustomError>{upperFirst(errorMsg)}</Styled.CustomError>
    </div>
  );
};

export default ProfileInput;
