import { FC, useState, useEffect, Fragment, useContext } from "react";
import className from "classnames";
import { ShouldRender } from "@remotebase/components";
import {
  ProfileProps,
  AuthRoutes as Route,
  combineHOCs,
  AuthProps,
  updateTalentErrorHeading,
  autoScrollOnCreateProfile,
  SidebarProps,
  retriveUtmParams,
  AuthRoutes,
} from "utils";
import { useHistory } from "react-router-dom";
import {
  ProfileStepContext,
  withProfile,
  ProfileTalentContext,
  IProfile,
  ProfileTalentTypeContext,
  IProfileDetails,
} from "state/profileSteps";
import {
  useTalentProfile,
  useTalentsProfile,
  useAnalyticsEventCreator,
  useCompleteTalentProfile,
} from "hooks";
import { FinishSignUpShimmer } from "shimmerPages";
import {
  codeToCountry,
  ErrorProps,
  mapFinishSignupAnalyticsEvent,
  RemoteBaseAnalyticsEvents,
} from "@remotebase/constants";
import { withAuth } from "state/auth";
import { VideoInterviewProvider } from "@remotebase/amplify-constants/API";
import {
  IParsedResumeContextType,
  ParsedResumeContext,
  IParsedResumeProps,
  withParsedResume,
} from "state/parsedResume";
import { withSidebar } from "state/sidebar";
import withError from "state/error/withErrorHoc";
import { useSentry } from "hooks/sentry";
import { useLocalStorage } from "hooks/utils";

import {
  ExperienceSection,
  ResumeSection,
  EnglishSection,
  InterestedFields,
  Skills,
  HeardFromSection,
  SalarySection,
  TalentSection,
} from "./sections";

interface Props extends AuthProps, ProfileProps, SidebarProps, ErrorProps, IParsedResumeProps {}
const CompleteProfile: FC<Props> = (props: Props) => {
  const {
    profileState: { data: profile },
    authState: { userId },
    updateUserInfo,
    updateUserProfile,
    showError,
    parseResume,
    loading: resumeParsingLoading,
    pendingImage,
  } = props;

  const history = useHistory();
  const [shouldLinkProfile, handleLinkProfile] = useState(false);

  const { deleteItem } = useLocalStorage();
  const { sendError } = useSentry();
  const { data: talentProfile, updateProfile, createProfile } = useTalentProfile();
  const {
    updateInfo,
    data: updatedData,
    error,
    isLoading: createInfoLoading,
  } = useTalentsProfile();
  const { completeProfile } = useCompleteTalentProfile();
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const {
    loading,
    talentType: { selectedTalent },
  } = useContext(ProfileTalentTypeContext);
  const { profileStep: currentStep } = useContext(ProfileStepContext);
  const { talent } = useContext(ProfileTalentContext);
  const { parsedResumeData } = useContext<IParsedResumeContextType>(ParsedResumeContext);

  const isActive = (level: number): string => className({ active: currentStep === level });

  const createTalentProfile = (): void => {
    if (!profile) return;
    const { utmSource, utmMedium, utmCampaign, referral } = retriveUtmParams();
    const analyticsData = {
      source: utmSource,
      medium: utmMedium,
      campaign: utmCampaign,
      referral,
    };

    if (!parsedResumeData) {
      const event = mapFinishSignupAnalyticsEvent(selectedTalent?.name as string);
      analyticsRegisterEvent(event, {
        metaData: JSON.stringify({
          country: codeToCountry[profile?.country],
        }),
      });
    }
    createProfile({
      variables: {
        input: {
          ...talent,
          imgLink: pendingImage,
          talentProfileTalentId: userId,
          analyticsData,
          videoInterviewProvider: VideoInterviewProvider.Talently,
          isProfileCompleted: !parsedResumeData,
        },
      },
    });

    deleteItem(`${userId}_profile`);
    handleLinkProfile(true);
  };

  const linkTalentProfile = (): void => {
    if (!talentProfile) {
      return;
    }
    updateInfo({
      variables: {
        input: {
          id: profile?.id,
          talentProfileId: talentProfile?.id,
          expectedVersion: profile?.version,
        },
      },
    });
    handleLinkProfile(false);
  };

  const updateTalentProfile = (): void => {
    const profileData = profile?.profile;
    const { talentTypeID, ...talentData } = talent;
    updateProfile({
      variables: {
        input: {
          ...talentData,
          imgLink: pendingImage ?? talentData.imgLink,
          expectedVersion: profileData?.version,
          id: profileData?.id,
        },
      },
    });

    deleteItem(`${userId}_profile`);
  };

  const handleSubmit = (): void => {
    if (profile?.id) {
      analyticsRegisterEvent(RemoteBaseAnalyticsEvents.REDIRECTED_FROM, {
        metaData: talent.heardFrom || "other",
      });

      const profileData = profile?.profile;
      if (profileData?.id) updateTalentProfile();
      else createTalentProfile();
    }
  };

  useEffect(() => {
    if (updatedData && !createInfoLoading && talentProfile) {
      completeProfile().then(() => updateUserProfile(talentProfile as IProfileDetails));
    }
  }, [updatedData, createInfoLoading, talentProfile]);

  useEffect(() => {
    if (currentStep > -1) {
      autoScrollOnCreateProfile(currentStep);
    } else history.push(Route.Dashbord);
  }, [currentStep]);

  useEffect(() => {
    if (talentProfile && profile?.id) {
      if (shouldLinkProfile) linkTalentProfile();
      else if (parsedResumeData) history.replace(AuthRoutes.FinishSignUp);
      else history.replace(AuthRoutes.Dashbord);
    }

    if (error) {
      sendError(error, { userId });
      showError({
        title: updateTalentErrorHeading,
        message: error?.message,
      });
    }
  }, [talentProfile, error]);

  useEffect(() => {
    if (updatedData) updateUserInfo(updatedData as IProfile);
  }, [updatedData]);

  return (
    <Fragment>
      <ShouldRender if={loading}>
        <FinishSignUpShimmer />
      </ShouldRender>
      <ShouldRender if={!loading}>
        <>
          <section data-step="0" className={isActive(0)}>
            <TalentSection />
          </section>
          <section data-step="1" className={isActive(1)}>
            <ExperienceSection />
          </section>
          <section data-step="2" className={isActive(2)}>
            <ResumeSection parseResume={parseResume} />
          </section>
          <section data-step="3" className={isActive(3)}>
            <EnglishSection />
          </section>
          <section data-step="4" className={isActive(4)}>
            <InterestedFields />
          </section>
          <section data-step="5" className={isActive(5)}>
            <SalarySection />
          </section>
          <section data-step="6" className={isActive(6)}>
            <HeardFromSection />
          </section>
          <section data-step="7" className={isActive(7)}>
            <Skills onSubmit={handleSubmit} resumeLoading={resumeParsingLoading} />
          </section>
        </>
      </ShouldRender>
    </Fragment>
  );
};

export default combineHOCs(
  withAuth,
  withProfile,
  withSidebar,
  withError,
  withParsedResume,
)(CompleteProfile) as FC;
