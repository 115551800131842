/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Parent = styled.div`
  width: 100%vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TitlePara = styled.p`
  font-weight: bold;
`;

export const BodyPara = styled.p`
  font-size: 1em;
`;
