import { FC } from "react";
import { ConversationResponse } from "@remotebase/amplify-constants/API";
import SingleUserBox from "./singleUserBox";
import * as Styled from "../../styles";

interface Props {
  allUsers: Array<ConversationResponse | null>;
  setCurrentUser: (data: ConversationResponse | null) => void;
}
export const UsersList: FC<Props> = ({ allUsers, setCurrentUser }) => {
  return (
    <Styled.ChatListMsgList>
      {allUsers.map((item, index) => (
        <div onClick={(): void => setCurrentUser(item)} key={`${item?.id} ${index}`}>
          <SingleUserBox data={item} />
        </div>
      ))}
    </Styled.ChatListMsgList>
  );
};

export default UsersList;
