import { useTrackReferral } from "hooks";
import { FC, Fragment, useEffect, useState } from "react";
import { ShouldRender } from "@remotebase/components";
import { RefBoxShimmer } from "shimmerPages";
import withError from "state/error/withErrorHoc";
import { withProfile } from "state/profileSteps";
import { withReferral } from "state/referral";
import { getShareUrl, ProfileProps, refInvalidHeading, RefProps } from "utils";
import { ErrorProps } from "@remotebase/constants";
import {
  RefTrackerBoxes,
  RefTrackerTable,
  RefEmail,
  RefCopyLink,
  RefSocialShareBtns,
} from "./components";

const ReferralHandler: FC<ProfileProps & RefProps & ErrorProps> = ({
  profileState: { data: profileData },
  setRefDetails,
  setFilteredTalentData,
  showError,
}) => {
  const { data: referralList, isLoading, error } = useTrackReferral();
  const [referralUrl, setReferralUrl] = useState("");

  useEffect(() => {
    if (profileData?.id) {
      setReferralUrl(getShareUrl(profileData?.id));
    }
  }, [profileData]);

  useEffect(() => {
    if (error) showError({ title: refInvalidHeading, message: error });
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error && referralList) {
      setRefDetails(referralList);
      setFilteredTalentData(referralList);
    }
  }, [referralList, isLoading]);

  return (
    <Fragment>
      <RefEmail />
      <RefCopyLink shareUrl={referralUrl} />
      <RefSocialShareBtns shareUrl={referralUrl} />
      <ShouldRender if={!isLoading}>
        <Fragment>
          <RefTrackerBoxes />
          <RefTrackerTable />
        </Fragment>
      </ShouldRender>
      <ShouldRender if={isLoading}>
        <RefBoxShimmer />
      </ShouldRender>
    </Fragment>
  );
};

export default withProfile(withReferral(withError(ReferralHandler)));
