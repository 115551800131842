import styled from "styled-components";

export const PerksListDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 66px;
  }
`;

export const Empty = styled.div``;
