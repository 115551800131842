import {
  ConversationResponse,
  MessageResponse,
  UserRoles,
} from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { useGetTalentChatList } from "hooks";
import useConversationSubscription from "hooks/query/useConversationSubscription";
import React, { Fragment, useEffect, useState } from "react";
import { ChatMainShimmer } from "shimmerPages";
import { withProfile } from "state/profileSteps";
import { ProfileProps } from "utils";
import {
  MyProfileHeader,
  CurrentUserHeader,
  UsersList,
  CurrentUserChat,
  NoUserSelected,
  NewMessage,
  NoUsersList,
} from "./components";
import * as Styled from "./styles";

export const Chat: React.FC<ProfileProps> = ({ profileState: { data } }) => {
  const { getTalentConversation, loading, data: usersList } = useGetTalentChatList();
  const [currentUser, setCurrentUser] = useState<ConversationResponse | null>(null);
  const [allUsers, setAllUsers] = useState<Array<ConversationResponse | null>>([]);
  const [conversationId, setConversationId] = useState<string>();
  const [message, setMessage] = useState<MessageResponse | null>(null);
  const [firstFetchCall, setIsFirstFetchCall] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<Array<ConversationResponse | null>>([]);

  const newConversation = useConversationSubscription(data?.id || "", UserRoles.TALENT);

  const handleChange = (searchText: string): void => {
    if (searchText.length) {
      const filteredData = allUsers.filter((e) => {
        const temp = e?.conversationParticipants?.filter((item) => item?.id !== data?.id)[0];
        const tempFullName = temp?.fullName;
        if (tempFullName?.toLowerCase()?.includes(searchText.toLowerCase())) return true;
        return false;
      });
      setFilteredUsers([...filteredData]);
    } else setFilteredUsers(allUsers);
  };

  useEffect(() => {
    if (usersList && firstFetchCall) {
      setIsFirstFetchCall(false);
      setAllUsers(usersList);
      setFilteredUsers(usersList);
    }
  }, [usersList]);

  useEffect(() => {
    if (newConversation) {
      setAllUsers([newConversation, ...allUsers]);
      setFilteredUsers([newConversation, ...filteredUsers]);
    }
  }, [newConversation]);

  useEffect(() => {
    getTalentConversation();
    setIsFirstFetchCall(true);
  }, []);

  useEffect(() => {
    const convoId = currentUser?.id;
    if (convoId) {
      setMessage(null);
      setConversationId(convoId);
    }
  }, [currentUser]);

  return (
    <Fragment>
      <ShouldRender if={loading}>
        <ChatMainShimmer />
      </ShouldRender>
      <ShouldRender if={!loading}>
        <Styled.ChatPageContainer>
          <Styled.ChatListWrapper>
            <MyProfileHeader handleChange={handleChange} />
            <ShouldRender if={filteredUsers.length}>
              <UsersList allUsers={filteredUsers} setCurrentUser={setCurrentUser} />
            </ShouldRender>
            <ShouldRender if={!filteredUsers.length}>
              <NoUsersList />
            </ShouldRender>
          </Styled.ChatListWrapper>
          <Styled.ChatBodyWrapper>
            {currentUser && (
              <Fragment>
                <CurrentUserHeader currentUser={currentUser} />
                <CurrentUserChat conversationId={conversationId || ""} localMessage={message} />
                <NewMessage
                  loading={loading}
                  conversationId={conversationId}
                  setNewMessages={(newMessage): void => setMessage(newMessage)}
                />
              </Fragment>
            )}
            {!currentUser && <NoUserSelected />}
          </Styled.ChatBodyWrapper>
        </Styled.ChatPageContainer>
      </ShouldRender>
    </Fragment>
  );
};

export default withProfile(Chat);
