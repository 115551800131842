import React, { Fragment } from "react";
import { mapOutput, TeamListInterface } from "state/dashboard";
import { teamList } from "utils";
import MeetTeamSingleList from "./MeetTeamSingleList";

const MeetTeamList: React.FC = () => {
  const getTeamMember = (teamMember: TeamListInterface, index): JSX.Element => {
    return <MeetTeamSingleList key={index} teamMember={teamMember} />;
  };

  const listTeamMember = mapOutput<TeamListInterface>(teamList, getTeamMember);

  return <Fragment>{listTeamMember}</Fragment>;
};

export default MeetTeamList;
