import { HttpStatus, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import { useAnalyticsEventCreator, useGetParsedResume } from "hooks";
import withApolloProvider from "hooks/apollo/withApollo";
import useLocalStorage from "hooks/utils/localStorage";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext, ProfileContextType } from "state/profileSteps";
import { shouldParseResume } from "utils";
import { ParsedResumeContext } from "./parsedResume.context";
import { IParsedResumeContextType, IParsedResumeProps } from "./parsedResume.interface";

export function withParsedResume<T>(Component: React.FC<T & IParsedResumeProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const [apiCalled, setApiCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setParsedResumeData, setInitialized } =
      useContext<IParsedResumeContextType>(ParsedResumeContext);
    const {
      getParsedResume,
      parsedResumeData,
      successCode,
      loading: parseLoading,
      error,
    } = useGetParsedResume();
    const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();
    const {
      profileState: { data },
    } = useContext<ProfileContextType>(ProfileContext);

    const { setItem } = useLocalStorage();

    const parseResume = (resumeLink: string): void => {
      setLoading(true);
      if (apiCalled) return;
      setApiCalled(true);
      getParsedResume({ variables: { resumeLink } });
    };

    useEffect(() => {
      if (parseLoading) return;

      if ((successCode === HttpStatus.INTERNAL_SERVER_ERROR || error) && !parsedResumeData) {
        analyticsRegisterEvent(RemoteBaseAnalyticsEvents.RESUME_PARSE_FAIL);
        setParsedResumeData(null);
      } else if (parsedResumeData) {
        setParsedResumeData(parsedResumeData);
        analyticsRegisterEvent(RemoteBaseAnalyticsEvents.RESUME_PARSE_SUCCESS);
      }
      setApiCalled(false);
      setLoading(parseLoading);
    }, [parsedResumeData, successCode, parseLoading, error]);

    useEffect(() => {
      if (data?.profile?.resumeLink && parsedResumeData)
        setItem(data.profile.resumeLink, JSON.stringify(parsedResumeData));
    }, [parsedResumeData]);

    useEffect(() => {
      if (!data?.profile?.resumeLink) return;
      const { resumeLink } = data.profile;
      const dataFromStorage = localStorage.getItem(resumeLink);

      if (dataFromStorage) setParsedResumeData(JSON.parse(dataFromStorage));
      else if (!apiCalled && shouldParseResume) parseResume(resumeLink);
      setInitialized(true);
    }, []);

    const parsedProps: IParsedResumeProps = {
      loading,
      parseResume,
    };

    return <Component {...props} {...parsedProps} />;
  });
}
export default withParsedResume;
