import { FC, Fragment, useState, useEffect } from "react";
import { EmploymentEntry, Talent } from "@remotebase/amplify-constants/API";
import {
  cancelBtnText,
  confirmBtnText,
  deleteEmploymentMessage,
  extractMonthAndYear,
  ShouldRender,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "../../..";
import { TalentUpdateProps } from "../../../../web/src/hooks/types";
import { ModalComponent, DeleteConfirmationModal } from "../../../modal";
import { EditEmploymentHistory } from "../editEmploymentHistory";
import { PencilIcon, TrashIcon, DomainPendingIcon, WorkExperienceIcon } from "../../../assets";
import * as Styled from "../styles";

interface ITalentEmploymentHistory {
  data: Talent | null;
  isEditable?: boolean;
  talentUpdateProps?: TalentUpdateProps;
  setTalentData?: React.Dispatch<any>;
}

export const TalentEmploymentHistory: FC<ITalentEmploymentHistory> = ({
  data,
  isEditable,
  talentUpdateProps,
  setTalentData,
}) => {
  const {
    isLoading,
    profileData,
    updateProfile,
    refetchProfile,
    showError: showMessage,
  } = talentUpdateProps || {};

  const employmentHistory = data?.profile?.employmentHistory || [];
  const [openEmploymentModal, setOpenEmploymentModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editIndex, setEditIndex] = useState<undefined | number>(undefined);
  const [deleteIndex, setDeleteIndex] = useState<undefined | number>(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAddNewClick = (index?: number): void => {
    setEditIndex(index);
    setOpenEmploymentModal(true);
  };
  const handleDeleteIconClick = (index?: number): void => {
    setDeleteIndex(index);
    setOpenDeleteModal(true);
  };
  const closeEmploymentModalModal = (): void => setOpenEmploymentModal(false);
  const closeDeleteModal = (): void => {
    setOpenDeleteModal(false);
    setDeleteIndex(undefined);
  };

  const handleDeleteEmployment = (index?: number): void => {
    if (index !== undefined) {
      setIsSubmitted(true);
      const extractedHistory = employmentHistory.map((currHistory) => ({
        company: currHistory?.company,
        summary: currHistory?.summary,
        title: currHistory?.title,
        startDate: currHistory?.startDate,
        endDate: currHistory?.endDate,
        currentJob: currHistory?.currentJob,
      }));
      extractedHistory.splice(index, 1);
      if (updateProfile) {
        updateProfile({
          variables: {
            input: {
              id: data?.profile?.id,
              expectedVersion: data?.profile?.version,
              employmentHistory: extractedHistory,
            },
          },
          onCompleted: () => {
            showMessage?.({
              title: submissionSuccessTitle,
              message: submissionProfileSuccessMessage,
            });
          },
          onError: () => {
            showMessage?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
          },
        });
      }
    }
  };

  useEffect(() => {
    if (profileData && isSubmitted) {
      if (setTalentData && refetchProfile) {
        closeDeleteModal();
        setDeleteIndex(undefined);
        setIsSubmitted(false);
        if (data) setTalentData({ ...data, profile: profileData });
        refetchProfile();
      }
    }
  }, [profileData, isSubmitted]);

  return (
    <Fragment>
      <Styled.ProfileGridWrapper>
        <Styled.ProfileEmploymentHistory>
          <Styled.EmploymentHistoryTopSection>
            <Styled.EmploymentHistoryTitle>
              <Styled.SectionHeading>Employment History</Styled.SectionHeading>
              <ShouldRender if={isEditable}>
                <Styled.WrapAddNew onClick={(): void => handleAddNewClick()}>
                  <Styled.AddNew>Add New</Styled.AddNew>
                </Styled.WrapAddNew>
              </ShouldRender>
            </Styled.EmploymentHistoryTitle>
          </Styled.EmploymentHistoryTopSection>

          {employmentHistory?.map((history: EmploymentEntry | null, index: number) => (
            <Styled.ProfileEmploymentHistoryContainer key={index}>
              <Styled.ProfileEmploymentHistoryLeft>
                <Styled.CompanyImageCover>
                  <WorkExperienceIcon />
                </Styled.CompanyImageCover>
              </Styled.ProfileEmploymentHistoryLeft>
              <Styled.ProfileFeedbackDetailsRight>
                <Styled.TitleWithIcon>
                  <Styled.JobRoleTitle>
                    <Styled.TitleLabel>{history?.title}</Styled.TitleLabel>
                    <Styled.Bullet></Styled.Bullet>
                    <Styled.RoleLabel>{history?.company}</Styled.RoleLabel>
                  </Styled.JobRoleTitle>
                  <ShouldRender if={isEditable}>
                    <Styled.IconGroup>
                      <PencilIcon onClick={(): void => handleAddNewClick(index)} />
                      <ShouldRender if={deleteIndex !== index}>
                        <TrashIcon onClick={(): void => handleDeleteIconClick(index)} />
                      </ShouldRender>
                      <ShouldRender if={isLoading && deleteIndex === index}>
                        <DomainPendingIcon />
                      </ShouldRender>
                    </Styled.IconGroup>
                  </ShouldRender>
                </Styled.TitleWithIcon>
                <Styled.JobDuration>
                  <Styled.DurationText>
                    <Styled.Span>{extractMonthAndYear(history?.startDate)}</Styled.Span>
                    <Styled.Span> - </Styled.Span>
                    <Styled.Span>{extractMonthAndYear(history?.endDate)}</Styled.Span>
                  </Styled.DurationText>
                </Styled.JobDuration>
                <Styled.EmploymentDetails>{history?.summary}</Styled.EmploymentDetails>
              </Styled.ProfileFeedbackDetailsRight>
            </Styled.ProfileEmploymentHistoryContainer>
          ))}
        </Styled.ProfileEmploymentHistory>
      </Styled.ProfileGridWrapper>

      <ModalComponent show={openEmploymentModal} handleClose={closeEmploymentModalModal}>
        <EditEmploymentHistory
          editIndex={editIndex}
          close={closeEmploymentModalModal}
          data={data}
          setTalentData={setTalentData}
          talentUpdateProps={talentUpdateProps}
        />
      </ModalComponent>

      <ModalComponent show={openDeleteModal} handleClose={closeDeleteModal}>
        <DeleteConfirmationModal
          message={deleteEmploymentMessage}
          cancelBtnText={cancelBtnText}
          confirmBtnText={confirmBtnText}
          isLoading={isLoading}
          closeBtnHandler={closeDeleteModal}
          confirmBtnHandler={(): void => handleDeleteEmployment(deleteIndex)}
        />
      </ModalComponent>
    </Fragment>
  );
};

export default TalentEmploymentHistory;
