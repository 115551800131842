import { FC } from "react";
import { Helmet } from "react-helmet-async";

export const MetaTags: FC = () => {
  return (
    <Helmet>
      <title>Remotebase | Bringing Silicon Valley companies to the comfort of your home.</title>
      <meta
        name="title"
        content="Remotebase | Bringing Silicon Valley companies to the comfort of your home."
      />
      <meta
        name="description"
        content="Hiring remote talent across the globe | Work directly with Silicon Valley companies."
      />
      <meta
        property="og:title"
        content="Remotebase | Bringing Silicon Valley companies to the comfort of your home."
      />
      <meta
        property="og:description"
        content="Hiring remote talent across the globe | Work directly with Silicon Valley companies."
      />
      <meta
        property="twitter:title"
        content="Remotebase | Bringing Silicon Valley companies to the comfort of your home."
      />
      <meta
        property="twitter:description"
        content="Hiring remote talent across the globe | Work directly with Silicon Valley companies."
      />
    </Helmet>
  );
};

export default MetaTags;
