import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { SocialForm } from "utils";
import * as Styled from "../../styles";

interface Props {
  formHook: UseFormReturn;
}

const SocialSection: FC<Props> = ({
  formHook: {
    register,
    formState: { errors },
  },
}) => {
  return (
    <Styled.ContactComponent>
      <Styled.ContactTitle>Social Links</Styled.ContactTitle>
      <Styled.FormGrid>
        <Styled.FormCanvas>
          <Styled.FormLabel>GITHUB</Styled.FormLabel>
          <Styled.FormGroup>
            <Styled.FormInput
              placeholder="Github.com/username"
              {...register(SocialForm.githubUrl)}
            />
            {errors[SocialForm.githubUrl] && (
              <Styled.FormError>{errors[SocialForm.githubUrl].message}</Styled.FormError>
            )}
          </Styled.FormGroup>
        </Styled.FormCanvas>
        <Styled.FormCanvas>
          <Styled.FormLabel>PORTFOLIO LINK</Styled.FormLabel>
          <Styled.FormGroup>
            <Styled.FormInput placeholder="www.domain.com" {...register(SocialForm.portfolioUrl)} />
            {errors[SocialForm.portfolioUrl] && (
              <Styled.FormError>{errors[SocialForm.portfolioUrl].message}</Styled.FormError>
            )}
          </Styled.FormGroup>
        </Styled.FormCanvas>
        <Styled.FormCanvas>
          <Styled.FormLabel>LINKEDIN</Styled.FormLabel>
          <Styled.FormGroup>
            <Styled.FormInput
              placeholder="linkedin.com/in/username/"
              {...register(SocialForm.linkedinUrl)}
            />
            {errors[SocialForm.linkedinUrl] && (
              <Styled.FormError>{errors[SocialForm.linkedinUrl].message}</Styled.FormError>
            )}
          </Styled.FormGroup>
        </Styled.FormCanvas>
      </Styled.FormGrid>
    </Styled.ContactComponent>
  );
};

export default SocialSection;
