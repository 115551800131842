import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const FaqWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 80px 0px 206px 0px;
  background-color: rgba(89, 194, 249, 0.1);

  @media screen and (min-width: 768px) {
    padding: 120px 0px 206px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const FaqTitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const FaqTitle = styled.h2`
  font-size: 32px;
  line-height: 120%;
  font-weight: 500;
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 0px;

  @media screen and (min-width: 768px) {
    font-size: 48px;
  }
`;

export const FaqViewLink = styled.a`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #3c65fe;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const HomepageFaq = styled.div`
  width: 100%;
`;

export const HomepageAccordion = styled(Accordion)`
  width: 100%;
  & .card {
    background-color: transparent;
    border: 1px solid #9f9f9f;
    border-left: none;
    border-right: none;
    padding: 24px 0px;
    border-radius: 0px;

    @media screen and (min-width: 768px) {
      padding: 40px 0;
    }
  }
  & .card-header {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    padding: 0 10px;
    cursor: pointer;
  }
  & .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 16px 10px 0px 48px;

    & p {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      color: #14142b;
      max-width: 620px;
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      padding: 20px 20px 0px 100px;
    }
  }
`;

export const AcdHeadTextRow = styled.div`
  display: flex;
`;

export const AcdHeadCount = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000000;
  margin-right: 16px;

  @media screen and (min-width: 768px) {
    margin-right: 64px;
    font-size: 24px;
    line-height: 33px;
  }
`;

export const AcdHeadText = styled.h3`
  font-size: 18px;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;

  @media screen and (min-width: 768px) {
    font-size: 28px;
    line-height: 120%;
  }
`;

export const AcdHeadIcon = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  text-decoration: none;
  margin-left: 8px;
  margin-bottom: auto;
  margin-top: 6px;

  @media screen and (min-width: 768px) {
    margin: 0;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const PlusIcon = styled.span`
  display: inline-flex;

  & svg {
    width: 14px;
    height: 14px;

    @media screen and (min-width: 768px) {
      width: 24px;
      height: 24px;
    }

    & path {
      fill: #14142b;
    }
  }
`;

export const CrossIcon = styled.span`
  display: inline-flex;

  & svg {
    width: 14px;
    height: 14px;

    @media screen and (min-width: 768px) {
      width: 24px;
      height: 24px;
    }

    & path {
      fill: #14142b;
    }
  }
`;
