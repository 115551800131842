import { createContext } from "react";
import { IParsedResumeContextType } from "./parsedResume.interface";

export const ParsedResumeContext = createContext<IParsedResumeContextType>({
  parsedResumeData: null,
  setParsedResumeData: () => {},
  initialized: false,
  setInitialized: () => {},
});

export default ParsedResumeContext;
