import { IActiveType } from "state/dashboard";
import { StyledProps } from "utils";
import styled, { css } from "styled-components";

// NEED TO BE DECLARED BEFORE DashboardBox
export const DashboardBoxText = styled.p`
  font-size: 12px;
  color: #919191;
  font-weight: normal;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  margin: 0px 0px 12px;
  max-width: 88px;
  user-select: none;
`;
export const DashboardBoxDigit = styled.span`
  font-size: 15px;
  color: #e5e5e5;
  font-weight: 600;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c7c7c7;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 38px;
  user-select: none;
`;
// NEED TO BE DECLARED BEFORE DashboardBox

export const DashboardBox = styled.div<IActiveType>`
  background-color: #e5e5e5;
  border-radius: 24px;
  padding: 18px 14px 18px 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 146px;
  min-height: 164px;
  position: relative;
  transition: all 0.25s;
  z-index: 1;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    transform: scale(1.03);
  }

  ${(props): StyledProps =>
    props.active
      ? css`
          background: ${props.color};
          cursor: pointer;
          ${DashboardBoxDigit} {
            background-color: #fff;
            color: ${props.color};
          }
          ${DashboardBoxText} {
            color: #fff;
          }
        `
      : css``}

  @media (max-width: 1199px) {
    min-width: 10px;
  }
`;
