/* eslint-disable max-len */
import React, { Fragment } from "react";
import AOS from "aos";
import { imageUrl } from "utils";
import * as S from "./styles";

export const processDataList = [
  {
    className: "processImg__1",
    img: imageUrl.processImgFirst,
    processCycle: 1,
    subHeading: "Sign up",
    processText:
      "Sign up on our portal, add your resume, and submit the HR screening video interview.",
  },
  {
    className: "processImg__2",
    img: imageUrl.processImgSecond,
    processCycle: 2,
    subHeading: "Assessment",
    processText: "Complete the Remotebase automated tests to move on to the next round.",
  },
  {
    className: "processImg__3",
    img: imageUrl.processImgThird,
    processCycle: 3,
    subHeading: "Live Interview",
    processText: "Appear for online interviews with our senior industry experts and HR team.",
  },
  {
    className: "processImg__4",
    img: imageUrl.processImgForth,
    processCycle: 4,
    subHeading: "Offer Placement",
    processText:
      "Receive a call from our HR department to get an offer from us after clearing the hiring process.",
  },
];

export const LandingProcess: React.FC = () => {
  AOS.init();
  return (
    <Fragment>
      <S.ProcessWrapper id="process">
        <S.Container>
          <S.ProcessHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            How to get started?
          </S.ProcessHeading>
          <S.ProcessPara
            className="aos-item"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            Just following 4 easy step below to get started
          </S.ProcessPara>
          <S.ProcessGrid>
            {processDataList?.map((processData, index) => (
              <S.ProcessSteps
                key={index}
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-testid={"default_processes"}
              >
                <S.ProcessImg className={processData.className}>
                  <S.ProcessStepsImg src={processData.img} alt="Process Image" />
                  <S.ProcessCircle>{processData.processCycle}</S.ProcessCircle>
                </S.ProcessImg>
                <S.ProcessDetail>
                  <S.ProcessSubHeading>{processData.subHeading}</S.ProcessSubHeading>
                  <S.ProcessText>{processData.processText}</S.ProcessText>
                </S.ProcessDetail>
              </S.ProcessSteps>
            ))}
          </S.ProcessGrid>
        </S.Container>
      </S.ProcessWrapper>
    </Fragment>
  );
};

export default LandingProcess;
