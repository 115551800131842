import { upperFirst } from "lodash";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { UseFormReturn } from "react-hook-form";
import * as Styled from "./styles";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  label: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  inputProps?: any;
  onTextChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  errorMsg?: string;
  placeholder?: string;
  formHook?: UseFormReturn;
  title?: string;
}

export const ProfileTextArea: FC<Props> = (props) => {
  const { label, onTextChange, errorMsg, inputProps, value, disabled, formHook, title, ...rest } =
    props;

  return (
    <Styled.Container>
      <Styled.EditFormLabel>{label}</Styled.EditFormLabel>
      {formHook && title ? (
        <>
          <Styled.EditFormTextArea disabled={disabled} {...formHook.register(title)} />
          <Styled.EditFormError>{formHook.formState.errors[title]?.message}</Styled.EditFormError>
        </>
      ) : (
        <>
          <Styled.EditFormTextArea
            onChange={onTextChange && ((e): void => onTextChange(e.target.value))}
            defaultValue={value || ""}
            disabled={disabled}
            {...rest}
            {...inputProps}
          />
          <Styled.EditFormError>{upperFirst(errorMsg)}</Styled.EditFormError>
        </>
      )}
    </Styled.Container>
  );
};

export default ProfileTextArea;
