import { PerksList } from "components";
import React, { useEffect } from "react";
import AOS from "aos";
import { autoScrollOnTop, fetchUtmParams, UnAuthRoutes } from "utils";
import { ChevronRightIcon } from "assets/icons";
import * as S from "./styles";

const LandingInfo: React.FC = () => {
  useEffect(() => {
    autoScrollOnTop();
    AOS.init();
  }, []);

  return (
    <S.InfoWrapper>
      <S.ValueComponent>
        <S.Container>
          <S.ValueGrid>
            <S.ValuePreview>
              <S.ValuePreviewImg className="aos-item" data-aos="fade-up" data-aos-duration="1400">
                <S.HeroImgFemale src="/images/landing/value-img.png" alt="Value Image" />
              </S.ValuePreviewImg>
              <S.ValuePreviewOverlay
                className="aos-item"
                data-aos="fade-up"
                data-aos-easing="ease-in-back"
                data-aos-delay="700"
                data-aos-offset="0"
              >
                <S.ValuePreviewName>Rutzell</S.ValuePreviewName>
                <S.ValuePreviewJob>Performance Marketer</S.ValuePreviewJob>
                <S.ValuePreviewWork>
                  at <b>Qume</b>
                </S.ValuePreviewWork>
              </S.ValuePreviewOverlay>
            </S.ValuePreview>
            <S.ValueContent>
              <S.ValueCardLabel className="aos-item" data-aos="fade-up" data-aos-duration="1000">
                🔥 Join the top 1% of the world's leading talent pool.
              </S.ValueCardLabel>
              <S.ValueCardHeading
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                Realise your future with us!
              </S.ValueCardHeading>
              <S.ValueCardDescrip
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                Becoming part of Remotebase extends further than just a job. We, at the company, aim
                to make your time with us oriented towards your professional success just as much as
                your personal progress. We provide opportunities for you to create innovative
                solutions for real-life startups that help them accelerate their growth while
                enabling yours
              </S.ValueCardDescrip>
              <S.AppNowBtnCanvas
                className="aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <S.AppNowBtn to={`${UnAuthRoutes.Register}?${fetchUtmParams()}`}>
                  Apply Now
                  <S.AppNowBtnIcon>
                    <ChevronRightIcon />
                  </S.AppNowBtnIcon>
                </S.AppNowBtn>
              </S.AppNowBtnCanvas>
            </S.ValueContent>
          </S.ValueGrid>
        </S.Container>
      </S.ValueComponent>

      <S.Container>
        <S.PerksContent>
          <S.PerksHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            Why join Remotebase?
          </S.PerksHeading>
          <S.PerksSubHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            Remotebase offers the best perks to the best talent.
          </S.PerksSubHeading>
          <PerksList />
        </S.PerksContent>

        {/* <WorkFigureList /> */}
      </S.Container>
    </S.InfoWrapper>
  );
};

export default LandingInfo;
