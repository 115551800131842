import { FC } from "react";
import { imageUrl } from "utils";
import * as Styled from "./styles";

export const NoUserSelected: FC = () => {
  return (
    <Styled.ChatBodyNoMsgWrapper>
      <Styled.ChatBodyNoMsgImg>
        <img src={imageUrl.noCurrentUserImg} />
      </Styled.ChatBodyNoMsgImg>
      <Styled.ChatBodyNoMsgtext>
        Pick a person from left menu and start your conversation
      </Styled.ChatBodyNoMsgtext>
    </Styled.ChatBodyNoMsgWrapper>
  );
};

export default NoUserSelected;
