import classNames from "classnames";
import React, { Fragment } from "react";
import { CheckMarkIcon } from "@remotebase/components/icons";
import { BtnLoader, ShouldRender } from "@remotebase/components";
import { resumeBtnId } from "@remotebase/constants";
import * as Styled from "./styles";
import { DocumentSubmitProps } from "./types";

export default function DocumentSubmit({
  handleSubmit,
  isLoading,
  documentUploaded,
  uploadButtonId = "multiSelect_nextButton",
  documentBtnTestId = resumeBtnId,
}: DocumentSubmitProps): ReturnType<React.FC> {
  return (
    <Styled.NextBtn
      data-testid={documentBtnTestId}
      onClick={handleSubmit}
      type="button"
      id={uploadButtonId}
      className={classNames({ disabled: !documentUploaded })}
      disabled={!documentUploaded || isLoading}
    >
      <ShouldRender if={!isLoading}>
        <Fragment>
          Submit
          <CheckMarkIcon />
        </Fragment>
      </ShouldRender>
      <ShouldRender if={isLoading}>
        <BtnLoader color="#4c40f7" />
      </ShouldRender>
    </Styled.NextBtn>
  );
}
