import { FC, Fragment, useMemo } from "react";
import moment from "moment";
import { ShouldRender } from "@remotebase/components";
import { CalendarBooking } from "@remotebase/amplify-constants/API";
import { TabOptions } from "utils";
import * as S from "../styled";

interface IMeetingTime {
  meetingDetails: CalendarBooking;
  tab: string;
}

export const MeetingTime: FC<IMeetingTime> = ({ meetingDetails, tab }) => {
  const extractMeetingTime = useMemo(() => {
    const extractedDate = moment(meetingDetails.startTime).format("MMM Do");
    const extractFullYear = moment(meetingDetails.startTime).format("MMM Do, YYYY");
    const meetingStart = moment(meetingDetails.startTime).format("hh:mmA");
    const meetingEnd = moment(meetingDetails.endTime).format("hh:mmA");
    return { extractedDate, meetingStart, meetingEnd, extractFullYear };
  }, [meetingDetails]);

  return (
    <S.ContentLine>
      <ShouldRender if={TabOptions.Recent === tab}>
        <Fragment>
          <S.TimeDate>{extractMeetingTime.extractedDate}.</S.TimeDate>
          <S.TextTime> {extractMeetingTime.meetingStart} - </S.TextTime>
          <S.TextTime> {extractMeetingTime.meetingEnd}</S.TextTime>
        </Fragment>
      </ShouldRender>
      <ShouldRender if={TabOptions.Completed === tab}>
        <Fragment>
          <S.TextTime>Completed on </S.TextTime>
          <S.TimeDate>{extractMeetingTime.extractFullYear}</S.TimeDate>
        </Fragment>
      </ShouldRender>
    </S.ContentLine>
  );
};

export default MeetingTime;
