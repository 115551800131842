import { FC, Fragment, useContext, useEffect, useState } from "react";
import { CheckMarkIcon } from "assets/icons";
import { CheckBoxInput, BtnLoader } from "components";
import { ShouldRender } from "@remotebase/components";
import {
  ISkillsType,
  ProfileTalentContext,
  ProfileOptions,
  ProfileTalentTypeContext,
  withProfile,
} from "state/profileSteps";
import { ProfileProps, SkillsBtnId, SkillsInputId } from "utils";
import * as Styled from "./styles";

interface Props {
  onSubmit: () => void;
  resumeLoading: boolean;
}
const Skills: FC<Props & ProfileProps> = ({ onSubmit, resumeLoading, profileState: { data } }) => {
  const [skills, setSkills] = useState<Array<string>>([]);
  const [submit, updateSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const checkBoxType = "skills_section_checkbox";
  const {
    talentType: { selectedTalent },
  } = useContext(ProfileTalentTypeContext);
  const { talent, setTalent } = useContext(ProfileTalentContext);
  const updateSkills = (val: string): void => {
    if (skills.includes(val)) {
      const updatedSkills = skills.filter((item) => item !== val);
      setSkills(updatedSkills);
    } else {
      setSkills((prevState) => [...prevState, val]);
      updateSubmit(false);
    }
  };
  const handleSubmit = (): void => {
    setIsLoading(true);
    setTalent({ skills });
    updateSubmit(true);
  };
  const getInput = (val: ISkillsType): JSX.Element => (
    <CheckBoxInput
      testingId={SkillsInputId}
      key={val.name}
      type={checkBoxType}
      value={val.name}
      onSelect={updateSkills}
      isChecked={skills.includes(val.name)}
      isBox
      text={val.title}
    />
  );

  const skillOptions = ProfileOptions(selectedTalent?.skills || [], getInput, skills);

  useEffect(() => {
    if (data?.profile) setSkills(data.profile.skills || []);
  }, []);

  useEffect(() => {
    if (talent.skills) setSkills(talent.skills);
  }, [talent]);

  useEffect(() => {
    if (submit && !resumeLoading && !submitted) {
      setSubmitted(true);
      onSubmit();
    }
  }, [submit, resumeLoading]);

  return (
    <Fragment>
      <h2>
        What <Styled.PurpleFont>skills</Styled.PurpleFont> are you proficient at?
      </h2>
      <p>Here are the top stacks we are currently hiring for:</p>
      <Styled.Checkboxes>{skillOptions}</Styled.Checkboxes>
      <Styled.LoadingMessageCover>
        <Styled.NextBtn
          data-testid={SkillsBtnId}
          onClick={handleSubmit}
          type="button"
          id="multiSelect_nextButton"
          disabled={!skills.length || isLoading || (submit && resumeLoading)}
        >
          <ShouldRender if={!isLoading && !(submit && !resumeLoading)}>
            <Fragment>
              Submit
              <CheckMarkIcon />
            </Fragment>
          </ShouldRender>
          <ShouldRender if={isLoading || (submit && resumeLoading)}>
            <BtnLoader />
          </ShouldRender>
        </Styled.NextBtn>
        <ShouldRender if={submit && resumeLoading}>
          <Styled.LoadingMessageText>Building your profile...</Styled.LoadingMessageText>
        </ShouldRender>
      </Styled.LoadingMessageCover>
    </Fragment>
  );
};
export default withProfile(Skills);
