import { Accordion, Card } from "react-bootstrap";
import { PlusIcon, CrossIcon } from "assets/icons";
import { FC, useState } from "react";
import { ShouldRender } from "@remotebase/components";
import * as S from "./styles";

type Props = {
  eventKey: string;
  title: string;
  description: string;
  number: string;
};

const FAQCard: FC<Props> = ({ eventKey, title, description, number }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Card data-testid={"faq_list"}>
      <Accordion.Toggle
        onClick={(): void => setIsOpen(!isOpen)}
        as={Card.Header}
        eventKey={eventKey}
      >
        <S.AcdHeadTextRow>
          <S.AcdHeadCount>{number}</S.AcdHeadCount>
          <S.AcdHeadText>{title}</S.AcdHeadText>
        </S.AcdHeadTextRow>
        <S.AcdHeadIcon>
          <ShouldRender if={!isOpen}>
            <S.PlusIcon>
              <PlusIcon />
            </S.PlusIcon>
          </ShouldRender>
          <ShouldRender if={isOpen}>
            <S.CrossIcon>
              <CrossIcon />
            </S.CrossIcon>
          </ShouldRender>
        </S.AcdHeadIcon>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <p>{description}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default FAQCard;
