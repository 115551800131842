/* eslint-disable max-len */
import React from "react";
import AOS from "aos";
import { fetchUtmParams, imageUrl, UnAuthRoutes } from "utils";
import * as S from "./styles";

export const DataList = [
  {
    jobType: "FULL-TIME REMOTE JOB",
    jobTitle: "Full Stack Engineer",
    description: "Work as a full-time remote Full Stack engineer for global startups.",
    requirements: [
      "At least 2 years of experience",
      "Skills in JavaScript, CSS and HTML",
      "Proficiency in multiple programming languages",
      "Excellent database skills",
    ],
  },
  {
    jobType: "FULL-TIME REMOTE JOB",
    jobTitle: "Sales Assistant",
    description: "Work as a full-time remote Sales Assistant for global startups.",
    requirements: [
      "Preferred 1 year of experience in tech sales development and/or marketplace sales development",
      "Experience in data entry and task-oriented roles",
      "Excellent organizational skills",
      "Good level of technical aptitude",
    ],
  },
];

export const LandingJobs: React.FC = () => {
  AOS.init();
  return (
    <S.JobsWrapper>
      <S.Container>
        <S.JobsHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
          Explore Remote Talent Jobs
        </S.JobsHeading>

        <S.JobsPara className="aos-item" data-aos="fade-up" data-aos-duration="1000">
          Explore and get your dream remote job right now
        </S.JobsPara>

        <S.JobsGrid>
          {DataList?.map((item, index) => (
            <S.JobsBox
              className="job__box-1 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              key={index}
              data-testid={"default_jobs"}
            >
              <S.JobsHead>
                <S.JobsIcon>
                  <S.JobsIconImg src={imageUrl.briefcase} alt="Briefcase Image" />
                </S.JobsIcon>
                <S.JobsHeadDiv>
                  <S.JobsType>{item.jobType}</S.JobsType>
                  <S.JobsName>{item.jobTitle}</S.JobsName>
                </S.JobsHeadDiv>
              </S.JobsHead>
              <S.JobsDescrip>{item.description}</S.JobsDescrip>
              <S.JobsInfo>
                <S.RequirementSectionContainer>
                  <S.divWrapper>Requirements</S.divWrapper>
                  <S.divWrapper>
                    <S.RequirementSectionUL>
                      {item.requirements.map((req, indexNumber) => (
                        <S.RequirementSectionLI key={indexNumber}>{req}</S.RequirementSectionLI>
                      ))}
                    </S.RequirementSectionUL>
                  </S.divWrapper>
                </S.RequirementSectionContainer>
              </S.JobsInfo>
              <S.JobsBadgeCanvas className="badgeCanvas__1">
                <S.JobsBadge to={`${UnAuthRoutes.Register}?${fetchUtmParams()}`}>
                  Apply Now
                </S.JobsBadge>
              </S.JobsBadgeCanvas>
            </S.JobsBox>
          ))}

          <S.JobsBrowse className="aos-item" data-aos="fade-left" data-aos-duration="1000">
            <S.JobsBrowseHeading>Looking for the best remote jobs</S.JobsBrowseHeading>
            <S.JobsBrowseBtn to={`${UnAuthRoutes.Register}?${fetchUtmParams()}`}>
              Apply
            </S.JobsBrowseBtn>
          </S.JobsBrowse>
        </S.JobsGrid>
      </S.Container>
    </S.JobsWrapper>
  );
};
export default LandingJobs;
