import styled from "styled-components";

export const BookingsWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0px;
`;

export const BookingsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
`;
export const BookingsHeading = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: #11142d;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5rem;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const BookingsParagraph = styled.p`
  font-size: 1.25rem;
  color: #808191;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 0px;
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

export const BookingsInner = styled.div`
  width: 100%;
  border-radius: 0px;
  padding: 1.5rem 0 0 0;
`;
