import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { FC, Fragment, useState, useMemo, useEffect } from "react";
import { TechDomainSkills, Job } from "@remotebase/amplify-constants/API";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import {
  AuthRoutes as Route,
  QueryParams,
  trimSize,
  pageSize,
  SkillsNameMap,
} from "../../../../utils";
import { checkJobSkill, isEligible } from "../../../../helpers";
import { CompanyNotMatchedIcon } from "../../../../assets/icons";
import { ShouldRender } from "../../../../shouldRender";
import { PaginationComponent } from "../../../../pagination";
import { VerifiedIcon } from "../../../../assets";
import * as S from "./styles";

type ITalentJobList = {
  jobsData: IJobsData;
  talentSkills?: (TechDomainSkills | undefined)[];
  loading: boolean;
};

export interface JobMatchProp extends Job {
  matched?: boolean;
  isUnavailable?: boolean;
  isApplied?: boolean;
}

export type IJobsData = Array<JobMatchProp | null> | null;

export const TalentJobList: FC<ITalentJobList> = ({ jobsData, talentSkills, loading }) => {
  // Get page query and set initial current page
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get(QueryParams.PAGE);
  const initialPage = !Number(page) ? 0 : Number(page) - 1;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [startingPage, setStartingPage] = useState(0);

  const totalPages = useMemo(() => Math.ceil((jobsData?.length || 0) / pageSize), [jobsData]);

  // Check for invalid initial page and redirect to base url
  useEffect(() => {
    if (initialPage > totalPages && !loading && jobsData) {
      setCurrentPage(0);
      query.delete(QueryParams.PAGE);
      history.push(Route.Jobs);
    }
  }, [initialPage, totalPages, loading, jobsData]);

  // Extract data to display for the current page
  const displayJobs = useMemo(
    () => jobsData?.slice(currentPage * pageSize, (currentPage + 1) * pageSize),
    [jobsData, currentPage],
  );

  const viewMoreOnClick = (id: string): void => {
    history.push({
      pathname: Route.Job,
      search: `?${QueryParams.ID}=${id}`,
      state: { [QueryParams.PAGE]: `${currentPage + 1}` },
    });
  };

  const extractCompanyBio = (bio: string | null | undefined): string => {
    if (!bio) return "";
    if (bio.length < trimSize) return bio;
    return `${bio.slice(0, trimSize)}...`;
  };

  const changePage = (changedVal: number): void => {
    const currentVal = currentPage + changedVal;
    setCurrentPage(currentVal);
    query.set(QueryParams.PAGE, `${currentVal + 1}`);
    window.history.pushState(null, "", `${Route.Jobs}?${query.toString()}`);
    if (currentVal < startingPage || currentVal > startingPage + pageSize - 1) {
      setStartingPage((value) => value + changedVal);
    }
  };

  const jumpToPageClick = (num: number): void => {
    setCurrentPage(num);
    query.set(QueryParams.PAGE, `${num + 1}`);
    window.history.pushState(null, "", `${Route.Jobs}?${query.toString()}`);
  };

  const pageLimit = useMemo(() => Math.min(pageSize, totalPages), [totalPages]);

  const hidePagination = useMemo(() => _.size(jobsData) < pageLimit, [jobsData]);

  const paginationProps = {
    totalPages,
    currentPage,
    startingPage,
    pageLimit,
    changePage,
    jumpToPageClick,
    hidePagination,
  };

  const getEligiblePopover = (): JSX.Element => {
    return (
      <Popover id="popover-basic" style={{ maxWidth: 400 }}>
        <Popover.Content>
          <strong>You are eligible for this job!</strong>
          <br></br>
          If you are interested, please reach out to HR now!
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <Fragment>
      <S.H1>Jobs</S.H1>

      <ShouldRender if={jobsData?.length === 0}>
        <Fragment>
          <S.Paragraph>
            You have passed the live coding!\n We are working on matching you with the best
            companies as per your profile.
          </S.Paragraph>
          <S.ImageSection>
            <CompanyNotMatchedIcon />
          </S.ImageSection>
        </Fragment>
      </ShouldRender>

      <ShouldRender if={!_.isEmpty(jobsData)}>
        <Fragment>
          <S.Paragraph>Discover jobs available for top talents like you!</S.Paragraph>
          <S.BreakTag></S.BreakTag>

          <Fragment>
            {displayJobs?.map((companyMatch: JobMatchProp | null, itemIndex) => (
              <S.CardWrapper
                key={itemIndex}
                data-testid={companyMatch?.id}
                $matched={companyMatch?.matched && !companyMatch?.isUnavailable}
                $isEligible={
                  talentSkills && isEligible(talentSkills, companyMatch?.requiredTechDomainSkills)
                }
              >
                <S.InnerWrapper>
                  <S.LogoWrapper>
                    <S.CompanyLogo src={companyMatch?.company?.logo} alt="" />
                  </S.LogoWrapper>

                  <S.CompanyHeader>
                    <S.TitleBadge>
                      <S.H5>{companyMatch?.title}</S.H5>
                      <ShouldRender if={companyMatch?.isUnavailable}>
                        <S.UnAvailable>UNAVAILABLE</S.UnAvailable>
                      </ShouldRender>
                      <ShouldRender if={!companyMatch?.isUnavailable}>
                        <Fragment>
                          <ShouldRender if={companyMatch?.matched}>
                            <Fragment>
                              <ShouldRender if={companyMatch?.isApplied}>
                                <S.EligibleBtn>APPLIED</S.EligibleBtn>
                              </ShouldRender>
                              <ShouldRender if={!companyMatch?.isApplied}>
                                <S.MatchedBtn>MATCH</S.MatchedBtn>
                              </ShouldRender>
                            </Fragment>
                          </ShouldRender>

                          <ShouldRender
                            if={
                              !companyMatch?.matched &&
                              talentSkills &&
                              isEligible(talentSkills, companyMatch?.requiredTechDomainSkills)
                            }
                          >
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="top"
                              overlay={getEligiblePopover()}
                            >
                              <S.EligibleBtn>ELIGIBLE</S.EligibleBtn>
                            </OverlayTrigger>
                          </ShouldRender>
                        </Fragment>
                      </ShouldRender>
                    </S.TitleBadge>

                    <S.NameLocation>
                      <S.Span>{companyMatch?.company?.name}</S.Span>
                      <S.Dot></S.Dot>
                      <S.Span>{companyMatch?.company?.address.city}</S.Span>
                    </S.NameLocation>
                    <S.CompanyDetail>
                      {extractCompanyBio(companyMatch?.description)}
                    </S.CompanyDetail>
                    <ShouldRender if={companyMatch?.requiredTechDomainSkills}>
                      <S.BtnGroup>
                        {companyMatch?.requiredTechDomainSkills.map((skill, index) => (
                          <S.StackBtn key={index}>
                            {skill && SkillsNameMap[skill]}
                            <S.Span>
                              <ShouldRender if={talentSkills && checkJobSkill(talentSkills, skill)}>
                                <VerifiedIcon />
                              </ShouldRender>
                            </S.Span>
                          </S.StackBtn>
                        ))}
                      </S.BtnGroup>
                    </ShouldRender>
                  </S.CompanyHeader>
                </S.InnerWrapper>
                <S.TopRight>
                  <S.ViewJobBtn
                    id={companyMatch?.id}
                    onClick={(): void => viewMoreOnClick(companyMatch ? companyMatch.id : "")}
                  >
                    View Job
                  </S.ViewJobBtn>
                </S.TopRight>
              </S.CardWrapper>
            ))}
          </Fragment>
          <PaginationComponent {...paginationProps} />
        </Fragment>
      </ShouldRender>
    </Fragment>
  );
};

export default TalentJobList;
