import { FC, useEffect, useState } from "react";
import { BtnLoader } from "../../loader";
import ShouldRender from "../../shouldRender";
import * as S from "./styles";

interface IDeleteConfirmationModal {
  message: string;
  cancelBtnText: string;
  confirmBtnText: string;
  isLoading?: boolean;
  closeBtnHandler: () => void;
  confirmBtnHandler: () => void;
}

export const DeleteConfirmationModal: FC<IDeleteConfirmationModal> = ({
  message,
  closeBtnHandler,
  confirmBtnHandler,
  cancelBtnText,
  confirmBtnText,
  isLoading,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleContinueBtn = (): void => {
    setIsSubmitting(true);
    confirmBtnHandler();
  };

  useEffect(() => {
    if (!isLoading && isSubmitting) {
      closeBtnHandler();
      setIsSubmitting(false);
    }
  });

  return (
    <S.ConfirmationWrapper>
      <S.MessageText>{message}</S.MessageText>
      <S.BtnGroup>
        <S.CancelBtn onClick={closeBtnHandler}>{cancelBtnText}</S.CancelBtn>
        <ShouldRender if={isLoading}>
          <S.SubmitBtn>
            <BtnLoader />
          </S.SubmitBtn>
        </ShouldRender>
        <ShouldRender if={!isLoading}>
          <S.SubmitBtn onClick={handleContinueBtn}>{confirmBtnText}</S.SubmitBtn>
        </ShouldRender>
      </S.BtnGroup>
    </S.ConfirmationWrapper>
  );
};

export default DeleteConfirmationModal;
