export const FinalTechInstructions: string[] = [
  "Please wait patiently for our team to reach out to you regarding the interview details.",
  "Acknowledge your participation in the calendar invite, for the interview.",
  `Make sure you join in from your laptop (NOT your phone) and have the correct
  development environment setup that you will need to code i.e compiler, IDE, etc.`,
  `This interview will be conducted over a video call,
  so make sure you have a functioning microphone and webcam.`,
  "Make sure you have a stable internet connection for the course of your interview.",
  "Make sure you have the correct permissions configured for screen sharing mode on call.",
  "The interview might be around 50 to 90 minutes.",
];

export const FinalNonTechInstructions: string[] = [
  "Make sure you join in from your computer and have a stable internet connection",
  "Acknowledge your participation in the calendar invite, for the interview.",
  `This interview will be conducted over a video call;
  hence please ensure you have a functioning microphone and webcam.`,
  "Make sure you have the correct permissions configured for screen-sharing mode on call.",
  "The duration of the interview might be around 50 - 90 mins.",
];

export const FinalTechEvalCriteria: string[] = [
  "Live Coding",
  "System Design",
  "Domain (React, Node, relevant to candidates' area of expertise)",
];

export const FinalNonTechEvalCriteria: string[] = [
  "Skill based questions",
  "Professional achievements",
  "Future goals",
];
