import { ConversationResponse, PersonInfo } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { UserProfileModal } from "components";
import { FC, Fragment, useMemo, useState } from "react";
import { withProfile } from "state/profileSteps";
import { ProfileProps } from "utils";
import * as Styled from "./styles";

interface Props {
  currentUser: ConversationResponse | null;
}
export const CurrentUserHeader: FC<Props & ProfileProps> = ({
  currentUser,
  profileState: { data },
}) => {
  const [showModal, setShowModal] = useState(false);
  const [participants, setParticipants] = useState<PersonInfo | null>();
  const handleProfileModal = (): void => setShowModal(!showModal);

  const initials = useMemo(() => {
    const temp = currentUser?.conversationParticipants?.filter((item) => item?.id !== data?.id)[0];
    setParticipants(temp);
    const name = temp?.fullName?.split(" ");
    if (name) {
      let nameInitials = name?.[0]?.charAt(0);
      if (name?.[1]?.charAt(0).length) nameInitials += name?.[1]?.charAt(0);
      return nameInitials.toUpperCase();
    }
    return "";
  }, [currentUser]);

  return (
    <Fragment>
      <Styled.ChatBodyHeader>
        <Styled.ChatBodyProfile>
          <Styled.ImgAvatar>{initials}</Styled.ImgAvatar>
          <Styled.ChatBodyProfileDetail>
            <Styled.ChatBodyProfileTitle>{participants?.fullName}</Styled.ChatBodyProfileTitle>
          </Styled.ChatBodyProfileDetail>
        </Styled.ChatBodyProfile>
      </Styled.ChatBodyHeader>
      <ShouldRender if={showModal}>
        <UserProfileModal
          showModal={showModal}
          onCancel={handleProfileModal}
          currentUser={currentUser}
        />
      </ShouldRender>
    </Fragment>
  );
};

export default withProfile(CurrentUserHeader);
