import styled from "styled-components";
import { Row } from "react-bootstrap";

const DashboardBoxLine = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  border-bottom: 3px dashed #e2e2e2;
  height: 3px;
  pointer-events: none;

  @media (max-width: 575px) {
    top: 40px;
    bottom: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 5px;
    height: auto;
    border-bottom: none;
    border-left: 3px dashed #e2e2e2;
  }
`;

export const HeadingMain = styled.h1`
  font-size: 40px;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
`;

export const HeadingSmall = styled.span`
  margin-bottom: 15px;
  font-size: 18px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;

export const DASHBOARD_BOX_ROW = styled(Row)`
  justify-content: space-between;
  margin-top: 56px;
  margin-bottom: 48px;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;

  .col-12 {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media only screen and (min-width: 1024px) {
    .col-12 {
      flex: initial;
      width: auto;
    }
  }
`;

export const DashboardBoxLine1 = styled(DashboardBoxLine)`
  top: 80px;
`;

export const DashboardBoxLine2 = styled(DashboardBoxLine)`
  bottom: 100px;

  @media only screen and (min-width: 992px) {
    display: none;
  }

  @media (max-width: 575px) {
    display: none;
  }
`;
