import React, { useState } from "react";
import { initialTalentLevelContext } from "utils";
import { IProfileState, TalentLevelContext, ITalentLevelContext } from ".";
import { ProfileContext } from "./profile.context";

export const ProfileProvider: React.FC = (props) => {
  const [profileState, setProfileState] = useState<IProfileState>({ isLoading: false });

  const value = {
    profileState,
    setProfileState,
  };

  return <ProfileContext.Provider value={value}>{props.children} </ProfileContext.Provider>;
};

export const TalentLevelProvider: React.FC = (props) => {
  const [talentLevel, setTalentLevel] = useState<ITalentLevelContext>(initialTalentLevelContext);

  const value = {
    talentLevel,
    setTalentLevel,
  };
  return <TalentLevelContext.Provider value={value}>{props.children} </TalentLevelContext.Provider>;
};
