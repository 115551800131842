import { FC, Fragment, useContext, useState, useEffect } from "react";
import { CheckMarkIcon } from "assets/icons";
import { times } from "lodash";
import { ProfileTalentContext, withProfile } from "state/profileSteps";
import {
  defaultProfileExperience,
  experienceBtnId,
  experienceInputId,
  profileExperienceBoxes as totalInputs,
  ProfileProps,
} from "utils";
import { RadioInput } from "components";
import * as Styled from "./styles";

const ExperienceSection: FC<ProfileProps> = ({ profileState: { data } }) => {
  const radioType = "experience_section_radio";
  const [experience, setExperience] = useState(defaultProfileExperience);
  const { talent, setTalent } = useContext(ProfileTalentContext);

  const getInputs = (): Array<JSX.Element> =>
    times(totalInputs, (n: number) => {
      let value = (n + 1).toString();
      if (n === totalInputs - 1) value += "+";
      return (
        <RadioInput
          testingId={experienceInputId}
          key={n}
          value={value}
          type={radioType}
          onSelect={setExperience}
          isChecked={value === experience}
        />
      );
    });

  const handleSubmit = (): void => {
    setTalent({ experience });
  };

  useEffect(() => {
    if (data?.profile) setExperience(data.profile.experience || defaultProfileExperience);
  }, []);

  useEffect(() => {
    if (talent.experience) setExperience(talent.experience || defaultProfileExperience);
  }, [talent]);
  return (
    <Fragment>
      <h2>
        How many years of <Styled.PurpleFont> Experience</Styled.PurpleFont> do you have in your
        field?
      </h2>
      <div className="row">
        <div className="col-12 col-md-11 col-lg-10 col-xl-8">
          <Styled.MembersSelect>
            <Styled.MembersSelectRadios>
              <div className="row row-0">{getInputs()}</div>
              <div className="col-auto px-0" />
            </Styled.MembersSelectRadios>
          </Styled.MembersSelect>
        </div>
      </div>
      <Styled.NextBtn
        data-testid={experienceBtnId}
        type="button"
        onClick={handleSubmit}
        disabled={experience === defaultProfileExperience}
      >
        Next
        <CheckMarkIcon />
      </Styled.NextBtn>
    </Fragment>
  );
};
export default withProfile(ExperienceSection);
