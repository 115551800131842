import { AuthType, storeUtmParams } from "utils";
import { Authentication } from "components";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";

const SignUpPage: FC = () => {
  useEffect(() => {
    storeUtmParams();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Remotebase | Apply now and get a chance to work with Silicon Valley companies.
        </title>
        <meta
          name="title"
          content="Remotebase | Apply now and get a chance to work with Silicon Valley companies."
        />
        <meta
          name="description"
          content="Hiring remote talent on par with the world’s
          leading talent pool | Work directly with Silicon Valley companies."
        />
        <meta
          property="og:title"
          content="Remotebase | Apply now and get a chance to work with Silicon Valley companies."
        />
        <meta
          property="og:description"
          content="Hiring remote talent on par with the world’s
          leading talent pool | Work directly with Silicon Valley companies."
        />
        <meta
          property="twitter:title"
          content="Remotebase | Apply now and get a chance to work with Silicon Valley companies."
        />
        <meta
          property="twitter:description"
          content="Hiring remote talent on par with the world’s
          leading talent pool | Work directly with Silicon Valley companies."
        />
        <link rel="canonical" href="https://talent.remotebase.com/register" />
      </Helmet>
      <Authentication authType={AuthType.Register} />
    </>
  );
};

export default SignUpPage;
