/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { TechDomainSkills } from "@remotebase/amplify-constants/API";
import { RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import { DomainTestStatus, SkillsSuffix, TalentTypeNameSuffix } from "./constants";

export const mapDomainTestAnalyticsEvent = (
  skillName: TechDomainSkills,
  eventStatus: DomainTestStatus,
): RemoteBaseAnalyticsEvents => {
  const computedKey =
    `${eventStatus}_DOMAIN_TEST_${SkillsSuffix[skillName]}` as keyof typeof RemoteBaseAnalyticsEvents;
  let event = RemoteBaseAnalyticsEvents[computedKey];
  if (!event) {
    event = RemoteBaseAnalyticsEvents[`${eventStatus}_DOMAIN_TEST_OTHER`];
  }

  return event;
};

export const mapFinishSignupAnalyticsEvent = (
  talentTypeName: string,
): RemoteBaseAnalyticsEvents => {
  return RemoteBaseAnalyticsEvents[
    `FINISH_SIGNUP_${TalentTypeNameSuffix[talentTypeName]}` as keyof typeof RemoteBaseAnalyticsEvents
  ];
};
