import { createContext } from "react";
import { initialTalentLevelContext, initialTalentTypeContext } from "utils";
import {
  IProfileTalent,
  IProfileTalentType,
  IProfileUpdate,
  ITalentLevelContext,
} from "./profileSteps.interface";

export const ProfileStepContext = createContext({
  profileStep: 0,
  engineerStep: 0,
  setEngineerStep: (step: number) => console.log("engineer step: ", step),
  setProfileStep: (step: number) => console.log("profile step: ", step),
});

export const ProfilePageContext = createContext({
  profilePage: 1,
  setProfilePage: (page: number) => {
    console.log("PROFILE PAGE NO: ", page);
  },
});

export const ProfileTalentContext = createContext({
  talent: { isAvailable: true } as IProfileTalent,
  setTalent: (talent: IProfileUpdate) => {
    console.log("PROFILE Talent: ", talent);
  },
});

export const ProfileTalentTypeContext = createContext({
  loading: false,
  talentType: initialTalentTypeContext,
  setTalentType: (talent: IProfileTalentType) => {
    console.log("PROFILE ENGINEERING: ", talent);
  },
});

export const TalentLevelContext = createContext({
  talentLevel: initialTalentLevelContext,
  setTalentLevel: (item: ITalentLevelContext) => {
    console.log("PROFILE Level: ", item);
  },
});
