import { FC, Fragment } from "react";
import * as Styled from "./styles";

const Repeat: FC<{ n: number; Component: FC }> = ({ n, Component }) => {
  return (
    <Fragment>
      {[...Array(n)].map((e, index) => {
        return <Component key={index} />;
      })}
    </Fragment>
  );
};

const Section: FC<{ Heading: FC }> = ({ Heading }) => {
  return (
    <Fragment>
      <Heading />
      <Repeat n={3} Component={Styled.Paragraph} />
      <Styled.HalfParagraph />
      <Styled.Space />
    </Fragment>
  );
};

export const JobDetailShimmer: FC = () => {
  return (
    <Fragment>
      <Styled.H3 />
      <Styled.Label />
      <Styled.HR />

      <Styled.Row>
        <Styled.Column1>
          <Styled.ColumnWrapper>
            <Section Heading={Styled.H6} />
            <Section Heading={Styled.Label} />
            <Section Heading={Styled.Label} />

            <Styled.Label />
            <Repeat n={3} Component={Styled.Skill} />
          </Styled.ColumnWrapper>
        </Styled.Column1>

        <Styled.Column2>
          <Styled.CardWrapper>
            <Styled.JobPostedBy />
            <Styled.CompanyLogo />
            <Repeat n={3} Component={Styled.SmallLine} />
            <Styled.HalfSmallLine />

            <Styled.Space />
            <Styled.Detail />
            <Styled.Space />
            <Repeat n={3} Component={Styled.DetailItem} />
          </Styled.CardWrapper>
        </Styled.Column2>
      </Styled.Row>
    </Fragment>
  );
};

export default JobDetailShimmer;
