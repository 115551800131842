import { SlotsPerDay } from "@remotebase/amplify-constants/API";
import { BtnLoader, FullPageLoader, ShouldRender } from "@remotebase/components";
import { useScheduleMagicLink, useSubmitCalendarBooking } from "hooks";
import { FC, Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { withProfile } from "state/profileSteps";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { OverlayTrigger } from "react-bootstrap";
import { AuthRoutes, ProfileProps } from "utils";
import { Color, ErrorProps, HttpStatus } from "@remotebase/constants";
import withError from "state/error/withErrorHoc";
import validationSchema from "./validationSchema";
import ScheduleCol from "./ScheduleCol";
import * as Styled from "./styled";

import { FormData } from "./types";
import { NoSlotParagraph } from "./styled";

/**
 *
 * @deprecated: this page is deprecated, due to removal of searchable directive from CalendarBooking schema
 */
export const SelfSchedule: FC<ProfileProps & ErrorProps> = ({
  profileState: { data },
  showError,
}) => {
  const { getAvailableSlots, loading, availableSlots, code } = useScheduleMagicLink();
  const { loading: bookingSlot, result, submitBooking, error } = useSubmitCalendarBooking();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: { slot: "" },
  });

  // route back to homepage, this PAGE IS DEPRECATED
  useEffect(() => {
    history.replace(AuthRoutes.Dashbord);
  }, []);

  useEffect(() => {
    if (result) {
      showError({ title: "Success", message: "Saved your slot successfully" });
      history.replace(AuthRoutes.Dashbord);
    }
  }, [result]);

  useEffect(() => {
    if (code === HttpStatus.INTERNAL_SERVER_ERROR) {
      showError({ title: "Error", message: "Invitation link is invalid or expired." });
      history.replace(AuthRoutes.Dashbord);
    }
  }, [code]);

  useEffect(() => {
    if (error) showError({ title: "Error", message: error || "" });
  }, [error]);

  const { handleSubmit, getValues } = methods;

  useEffect(() => {
    if (data?.id)
      getAvailableSlots({
        variables: {
          token,
          talentID: data.id,
          utcTime: new Date().toISOString(),
        },
      });
  }, [token]);

  const onSubmitHandler = ({ slot }: FormData): void => {
    const { startTime, endTime } = JSON.parse(slot);
    submitBooking({
      variables: {
        token,
        startTime,
        endTime,
        talentID: data?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  };
  const renderTooltip = (props): JSX.Element => {
    const singleValue = getValues("slot");

    return (
      <Styled.ButtonTooltip
        id="button-tooltip"
        {...props}
        className={classNames({
          tooltip__null: singleValue,
        })}
      >
        {!singleValue ? "Please select a slot first" : null}
      </Styled.ButtonTooltip>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormProvider {...methods}>
        <ShouldRender if={loading}>
          <FullPageLoader />
        </ShouldRender>
        <ShouldRender if={!loading}>
          <>
            <ShouldRender if={(availableSlots || []).length < 1}>
              <NoSlotParagraph>
                It seems like all the slots are booked. Please check back in few days. <br /> <br />
                If it is not resolved, please reach out our{" "}
                <a href="mailto:contact@remotebase.com">support</a>
              </NoSlotParagraph>
            </ShouldRender>
            <ShouldRender if={availableSlots?.length}>
              <Fragment>
                <Styled.SchedularRow>
                  {availableSlots?.map((item: SlotsPerDay, index) => (
                    <ScheduleCol PerDaySlot={item} key={index} />
                  ))}
                </Styled.SchedularRow>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <Styled.SearchButton type="submit" disabled={bookingSlot}>
                    <ShouldRender if={bookingSlot}>
                      <BtnLoader color={Color.WHITE} />
                    </ShouldRender>
                    <ShouldRender if={!bookingSlot}>
                      <Fragment>Submit</Fragment>
                    </ShouldRender>
                  </Styled.SearchButton>
                </OverlayTrigger>
              </Fragment>
            </ShouldRender>
          </>
        </ShouldRender>
      </FormProvider>
    </form>
  );
};

export default withProfile(withError(SelfSchedule));
