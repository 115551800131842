import React from "react";
import { mapOutput, WorkFigureInterface } from "state/dashboard";
import { figureList } from "utils";
import WorkFigureSingleList from "./WorkFigureSingleList";
import * as Styled from "./styles";

const WorkFigureList: React.FC = () => {
  const getWorkFigure = (figure: WorkFigureInterface): JSX.Element => {
    return <WorkFigureSingleList key={figure.src} figure={figure} />;
  };
  const listFigure = mapOutput<WorkFigureInterface>(figureList, getWorkFigure);
  return <Styled.WorkFiguresDiv>{listFigure}</Styled.WorkFiguresDiv>;
};

export default WorkFigureList;
