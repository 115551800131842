import { ModelJobFilterInput, ModelJobMatchFilterInput, TechDomainSkillsProgress } from "API";
import { refLocalKey } from "hooks/utils";
import moment, { Moment } from "moment";
import short from "short-uuid";
import { IProfile } from "state/profileSteps";
import {
  AnalyticsParams,
  CanNullOrUndefined,
  HackerRankInviteResponse,
  refShareUrl,
  requiredDomainTests,
  TestsClearedType,
  TestStatus,
  TestsType,
} from "utils";
import {
  contactSupport,
  expectedSalaryLockDuration,
  generalConfirmationMessage,
  lockConfirmationMessage,
} from "./constants";

export const parseInviteResponse = (response: string): HackerRankInviteResponse | null => {
  if (response) {
    const tokanized = response.replace("{", "").replace("}", "").split(",");
    if (tokanized?.[1]?.includes("test_link") && tokanized?.[3]?.includes("id")) {
      const parts = tokanized[1].split("=");
      parts.shift();
      const candidateId = tokanized[3].split("=");
      candidateId.shift();
      return { candidateId: candidateId.join("="), url: parts.join("=") };
    }
  }
  return null;
};

export const isDomainTestCleared = (data: CanNullOrUndefined<IProfile>): boolean | null => {
  const { profile } = data || {};
  const passedTests = profile?.takenDomainTests?.filter((test) => test?.isPass) || [];
  return passedTests.length >= requiredDomainTests || null;
};
export const isProblemSolvingCleared = (
  data: CanNullOrUndefined<IProfile>,
): CanNullOrUndefined<boolean> => {
  const { profile } = data || {};
  return profile?.isPassPS;
};

export const isHRScreeningCleared = (
  data: CanNullOrUndefined<IProfile>,
): CanNullOrUndefined<boolean> => {
  const { profile } = data || {};
  return profile?.isPassHR;
};

export const isFinalTechCleared = (
  data: CanNullOrUndefined<IProfile>,
): CanNullOrUndefined<boolean> => {
  const { profile } = data || {};
  return profile?.isPassFinalTech;
};

export const isMatchable = (data: CanNullOrUndefined<IProfile>): CanNullOrUndefined<boolean> => {
  const { profile } = data || {};
  return profile?.isHired || profile?.isNetwork;
};

export const retriveUtmParams = (): AnalyticsParams => {
  const utmSource = localStorage.getItem("utm_source") || "undefined";
  const utmMedium = localStorage.getItem("utm_medium") || "undefined";
  const utmCampaign = localStorage.getItem("utm_campaign") || "undefined";
  const referral = localStorage.getItem(refLocalKey);
  return { utmSource, utmMedium, utmCampaign, referral };
};
export const fetchUtmParams = (): string => {
  const { utmSource, utmMedium, utmCampaign } = retriveUtmParams();
  return `utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
};

export const getUtmParams = (): AnalyticsParams => {
  const params = new URLSearchParams(window.location.search);
  return {
    utmSource: params.get("utm_source") || "undefined",
    utmMedium: params.get("utm_medium") || "undefined",
    utmCampaign: params.get("utm_campaign") || "undefined",
    referral: params.get("referral"),
  };
};

export const storeUtmParams = (): void => {
  const { utmSource, utmMedium, utmCampaign, referral } = getUtmParams();
  localStorage.setItem("utm_source", utmSource);
  localStorage.setItem("utm_medium", utmMedium);
  localStorage.setItem("utm_campaign", utmCampaign);
  if (referral) localStorage.setItem(refLocalKey, referral);
};

export const getClearedTests = (data: CanNullOrUndefined<IProfile>): TestsClearedType => {
  const tests: TestsClearedType = {
    [TestsType.DomainTest]: isDomainTestCleared(data),
    [TestsType.HRInterview]: isHRScreeningCleared(data),
    [TestsType.ProblemSolving]: isProblemSolvingCleared(data),
    [TestsType.FinalInterview]: isFinalTechCleared(data),
    [TestsType.Network]: isMatchable(data),
  };
  return tests;
};

export const isTestCleared = (
  data: CanNullOrUndefined<IProfile>,
  test: TestsType,
): CanNullOrUndefined<boolean> => {
  const tests = getClearedTests(data);
  return tests[test];
};

export const getFailedTestsScore = (data: CanNullOrUndefined<IProfile>): number => {
  const testsCleared = getClearedTests(data);
  let testIndex = 0;
  Object.values(testsCleared).forEach((isPass, i) => {
    if (isPass === false && i >= testIndex) testIndex = i + 1;
  });
  return -testIndex;
};

export const getClearedTestsCount = (data: CanNullOrUndefined<IProfile>): number => {
  const testsCleared = getClearedTests(data);
  return Object.values(testsCleared).filter(Boolean).length;
};

export const isProfileCreated = (data: CanNullOrUndefined<IProfile>): boolean => {
  return !!data;
};

export const autoScrollOnCreateProfile = (step: number): void => {
  const activeDoc = document.querySelector("section.active") as HTMLElement;
  if (activeDoc && step > 0)
    window.scrollTo({ top: Math.abs(activeDoc.offsetTop - 50), behavior: "smooth" });
  else window.scrollTo({ top: 0, behavior: "smooth" });
};

export const autoScrollOnTop = (): void => {
  window.scrollTo(0, 0);
};

export const scrollToId = (id: string): void => {
  const element = document.getElementById(id);
  if (element === null) return;
  window.scrollTo({
    top: element.offsetTop,
    behavior: "smooth",
  });
};

export const getShareUrl = (uuid: string): string => {
  const id = short().fromUUID(uuid);
  const referralUrl = `${refShareUrl}${id}`;
  return referralUrl;
};

export const getTestStatusOldVersion = (
  test: TechDomainSkillsProgress | null | undefined,
  statusLoading: boolean,
  loading: boolean,
): TestStatus => {
  if (test && !statusLoading && !loading) {
    if (!test.testStart) return TestStatus.READY;
    if (!test.testEnd) return TestStatus.PENDING;
    if (test.isPass) return TestStatus.PASSED;
    return TestStatus.FAILED;
  }
  return TestStatus.READY;
};

export const rejectionTemplate = (name: string): JSX.Element => {
  return (
    <div>
      <p>Hello {name},</p>
      <p>
        Thank you for applying to Remotebase and taking out time to become a part of our recruitment
        process. Unfortunately, we will not be proceeding with your application with us at this
        point in time. Please don’t take it personally. We have carefully reviewed hundreds of
        applicants and since there’s a limit on the number of people we can hire, we had to turn
        away a lot of promising candidates.
      </p>
      <p>
        It’s practically certain that candidates turned down will go on to create successful careers
        at other companies. If you do, we’d sincerely appreciate it if you’d send us an email
        telling us about it; we very much want to learn from our mistakes.
      </p>
      <p>
        A few reasons for not proceeding with your application forward might be due to a mismatch of
        skillset between the job and your experience, a certain experience required for the role,
        lacking passion and motivation for the work that you do, and a drive to learn and grow more,
        not well-versed in professional communication or being a cultural misfit at Remotebase.
      </p>
      <p>
        In the meantime, feel free to engage with us through one of our social channels, or, perhaps
        joining us on our live sessions with tech entrepreneurs, we look forward to staying
        connected.
      </p>
      <p>Kindly, accept our best wishes for your continued success and career growth.</p>
      Please note: If you're still eager to join us, you can re-apply after 90 days of submission of
      your previous application.
      <p></p>
      <p>Best of luck</p>
    </div>
  );
};

export const getFormattedDay = (createdAt: string): string => {
  const messageTime = moment(createdAt);
  const timeDifference = moment().diff(messageTime, "days");
  const difference = moment.duration(timeDifference, "days").asDays();
  if (difference === 0) return "TODAY";
  if (difference === 1) return "YESTERDAY";
  return messageTime.format("DD MMM YYYY");
};

export const splitFullName = (name: string | undefined): { [key: string]: string } | null => {
  if (!name) return null;
  const lastSpaceChar = name.lastIndexOf(" ");
  const talentFirstName = name.slice(0, lastSpaceChar);
  const talentLastName = name.slice(lastSpaceChar + 1);
  return { talentFirstName, talentLastName };
};

export const extractSalaryAmount = ({
  salary,
  unit,
}: {
  salary?: string | null;
  unit?: boolean;
}): string | null => {
  if (!salary) return null;
  const splitSalary = salary.split(" ");
  if (splitSalary.length !== 2) {
    return null;
  }
  if (unit) return splitSalary[1];
  return splitSalary[0];
};

export const extractDateOfExpectedSalaryUpdate = (meta: string): string | null => {
  const { dateOfExpectedSalaryUpdate } = JSON.parse(meta);
  return dateOfExpectedSalaryUpdate;
};

export const nextEditableDate = (input: string | null): Moment => {
  return moment(input).add(expectedSalaryLockDuration, "days");
};

export const expectedSalaryStatus = (
  statusMeta?: string | null,
): { isEditable: boolean; message: string } => {
  if (statusMeta) {
    const storedExpectedSalaryUpdate = extractDateOfExpectedSalaryUpdate(statusMeta);
    const editableDate = nextEditableDate(storedExpectedSalaryUpdate);
    if (storedExpectedSalaryUpdate) {
      const daysToEditing = editableDate.diff(moment(), "days");
      if (daysToEditing > 0) {
        return {
          isEditable: false,
          message: `${generalConfirmationMessage} ${daysToEditing} days. ${contactSupport}`,
        };
      }
    }
  }
  return {
    isEditable: true,
    message: `${lockConfirmationMessage.message} ${contactSupport}`,
  };
};

type MatchJobsProps = {
  talentMatchList?: (unknown: unknown) => void;
  jobsList?: (unknown: unknown) => void;
  filter: ModelJobMatchFilterInput | ModelJobFilterInput;
  limit: number;
  nextToken: string;
};
export const matchTalentJobs = ({
  talentMatchList,
  jobsList,
  filter,
  limit,
  nextToken,
}: MatchJobsProps): void => {
  if (talentMatchList) {
    talentMatchList({
      variables: {
        filter,
        limit,
        nextToken,
      },
    });
  }
  if (jobsList) {
    jobsList({
      variables: {
        filter,
        limit,
        nextToken,
      },
    });
  }
};
