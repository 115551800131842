import { FC } from "react";
import { rangeRight, times } from "lodash";
import { Color } from "@remotebase/constants";
import {
  addBtnText,
  addNewEducationTitle,
  cancelBtnText,
  certificateLabel,
  certificatePlaceholder,
  educationBtnWidth,
  EducationFormEnum,
  endDateLabel,
  FormData,
  FormErrors,
  formInputText,
  getinitialFormErrorsState,
  institutionLabel,
  institutionPlaceholder,
  mandatoryNotification,
  monthPlaceholder,
  startDateLabel,
  updateBtnText,
  updateEducationTitle,
  yearPlaceholder,
} from "../../../utils";
import { ActionBtn } from "../../../buttons";
import { ProfileEditInput, SelectInput } from "../../../formInput";
import { months } from "../../../helpers";
import * as S from "../styles";

interface IEducationForm {
  isLoading: boolean | undefined;
  formErrors: FormErrors;
  formData: FormData;
  handleFormSubmission: () => void;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
  close: () => void;
}

export const EducationForm: FC<IEducationForm> = ({
  formData,
  formErrors,
  isLoading,
  handleFormSubmission,
  setFormData,
  setFormErrors,
  close,
}) => {
  const monthList = times(12, (index: number) => ({
    value: String(index),
    label: months[index],
  }));
  const startYearList = rangeRight(new Date().getFullYear() - 75, new Date().getFullYear() + 1).map(
    (item) => ({
      value: String(item),
      label: String(item),
    }),
  );
  const endYearList = rangeRight(new Date().getFullYear() - 75, new Date().getFullYear() + 11).map(
    (item) => ({
      value: String(item),
      label: String(item),
    }),
  );

  const handleFormChange = (key: string, value: string): void => {
    setFormData({ ...formData, [key]: value });
    setFormErrors(getinitialFormErrorsState());
  };

  const getUniversityField = (): JSX.Element => {
    return (
      <S.FormGroup>
        <ProfileEditInput
          type={formInputText}
          label={institutionLabel}
          placeholder={institutionPlaceholder}
          value={formData[EducationFormEnum.institution]}
          errorMsg={formErrors[EducationFormEnum.institution]}
          onTextChange={(val): void => handleFormChange(EducationFormEnum.institution, val)}
        />
      </S.FormGroup>
    );
  };

  const getDegreeField = (): JSX.Element => {
    return (
      <S.FormGroup>
        <ProfileEditInput
          type={formInputText}
          label={certificateLabel}
          placeholder={certificatePlaceholder}
          value={formData[EducationFormEnum.certificate]}
          errorMsg={formErrors[EducationFormEnum.certificate]}
          onTextChange={(val): void => handleFormChange(EducationFormEnum.certificate, val)}
        />
      </S.FormGroup>
    );
  };

  const getStartMonth = (): JSX.Element => {
    return (
      <SelectInput
        placeholder={monthPlaceholder}
        selected={monthList.find(
          (it) => it.value === String(formData[EducationFormEnum.startMonth]),
        )}
        options={monthList}
        handleSingleSelect={(option): void =>
          handleFormChange(EducationFormEnum.startMonth, option.value)
        }
        errorMsg={formErrors[EducationFormEnum.startMonth]}
        removeMargin
      />
    );
  };

  const getStartYearField = (): JSX.Element => {
    return (
      <SelectInput
        placeholder={yearPlaceholder}
        selected={startYearList.find(
          (it) => it.value === String(formData[EducationFormEnum.startYear]),
        )}
        options={startYearList}
        handleSingleSelect={(option): void =>
          handleFormChange(EducationFormEnum.startYear, option.value)
        }
        errorMsg={formErrors[EducationFormEnum.startYear]}
        removeMargin
      />
    );
  };

  const getEndMonthField = (): JSX.Element => {
    return (
      <SelectInput
        placeholder={monthPlaceholder}
        selected={monthList.find((it) => it.value === String(formData[EducationFormEnum.endMonth]))}
        options={monthList}
        handleSingleSelect={(option): void =>
          handleFormChange(EducationFormEnum.endMonth, option.value)
        }
        errorMsg={formErrors[EducationFormEnum.endMonth]}
        removeMargin
      />
    );
  };

  const getEndYearField = (): JSX.Element => {
    return (
      <SelectInput
        placeholder={yearPlaceholder}
        selected={endYearList.find(
          (it) => it.value === String(formData[EducationFormEnum.endYear]),
        )}
        options={endYearList}
        handleSingleSelect={(option): void =>
          handleFormChange(EducationFormEnum.endYear, option.value)
        }
        errorMsg={formErrors[EducationFormEnum.endYear]}
        removeMargin
      />
    );
  };

  const getButton = (): JSX.Element => {
    return (
      <S.BtnGroup>
        <ActionBtn
          btnText={cancelBtnText}
          clickHandler={close}
          width={educationBtnWidth}
          color={Color.LIGHT_BLUE}
          backgroundColor={Color.TRANSPARENT}
        />
        <ActionBtn
          btnText={formData[EducationFormEnum.isEdit] ? updateBtnText : addBtnText}
          isLoading={isLoading}
          clickHandler={handleFormSubmission}
          width={educationBtnWidth}
          color={Color.WHITE}
          backgroundColor={Color.LIGHT_BLUE}
          disabled={formErrors[EducationFormEnum.doErrorsExist]}
        />
      </S.BtnGroup>
    );
  };

  return (
    <S.EditorWrapper>
      <S.EditSectionHeading>
        {formData[EducationFormEnum.isEdit] ? updateEducationTitle : addNewEducationTitle}
      </S.EditSectionHeading>
      <S.FormBody>
        {getUniversityField()}
        {getDegreeField()}
        <S.FormGroup>
          <S.SelectLabel>{startDateLabel}</S.SelectLabel>
          <S.MonthYearWrapper>
            {getStartMonth()}
            {getStartYearField()}
          </S.MonthYearWrapper>
        </S.FormGroup>

        <S.FormGroup>
          <S.SelectLabel>{endDateLabel}</S.SelectLabel>
          <S.MonthYearWrapper>
            {getEndMonthField()}
            {getEndYearField()}
          </S.MonthYearWrapper>
        </S.FormGroup>
        <S.FootNote>{mandatoryNotification}</S.FootNote>
      </S.FormBody>
      {getButton()}
    </S.EditorWrapper>
  );
};

export default EducationForm;
