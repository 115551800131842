import classNames from "classnames";
import { FC } from "react";
import { CrossIcon } from "../../assets";
import { ModalComponentSize } from "../../utils";
import * as S from "./styles";

interface IModalComponent {
  children: JSX.Element;
  show: boolean;
  handleClose: () => void;
  size?: ModalComponentSize;
  hidden?: boolean;
  w94?: boolean;
  persist?: boolean;
}

export const ModalComponent: FC<IModalComponent> = ({
  children,
  show,
  handleClose,
  size = undefined,
  hidden,
  w94,
}) => {
  return (
    <S.CustomModal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      size={size}
      centered
      className={classNames({ hidden })}
      dialogClassName={classNames({ w94 })}
    >
      <S.CloseBtn onClick={handleClose}>
        <CrossIcon width={16} />
      </S.CloseBtn>
      {children}
    </S.CustomModal>
  );
};

export default ModalComponent;
