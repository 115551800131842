import styled from "styled-components";

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -20px;
`;

export const Logo = styled.div`
  margin-bottom: 78px;
`;

export const ClientFormMainQuestions = styled.div`
  section {
    opacity: 0.2;
    pointer-events: none;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }
  section.active {
    opacity: 1;
    pointer-events: initial;
  }
  section h1 {
    font-size: 40px;
    font-weight: 500;
    @media (max-width: 991px) {
      font-size: 30px;
    }
  }
  section h2 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 16px;
    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 44px;
    }
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  section p {
    font-size: 16px;
    line-height: 27px;
    margin: 20px 0 30px;
    color: #6b6b6b;
  }
  section:not(:first-child) {
    margin-top: 100px;
  }
  section:last-child {
    height: 80vh;
    margin-bottom: 80px;
  }
  @media (max-width: 767px) {
    section:last-child {
      margin-bottom: 150px;
    }
  }
`;
