import { Button } from "react-bootstrap";
import styled from "styled-components";

export const BtnsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: calc(1rem * 0.5);

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const BtnTry = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 124px;
  min-height: 34px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  transition: all 0.25s;
  margin-right: 0.5rem;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #295bdb;
    box-shadow: none;
    border: none;
    color: #fff;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 0.5rem;
  }
`;

export const BtnStart = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 124px;
  min-height: 34px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #11142d;
  transition: all 0.25s;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #3c405c;
    box-shadow: none;
    border: none;
    color: #fff;
  }
  &:disabled {
    background: black;
    opacity: 0.3;
  }
`;
export const FailedPSText = styled.p`
  list-style-type: decimal;
  margin-bottom: 5px;
  font-size: 14px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ca544f;
  margin-left: 25px;
`;

export const LinkBtn = styled.a`
  display: block;
  text-align: left;
  font-size: 14px;
  color: #270beb;
  padding-top: 12px;
  min-width: 200px;
  font-family: "Poppins", sans-serif;
  &:hover {
    cursor: pointer;
  }
`;
