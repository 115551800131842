import { FC } from "react";
import { Color } from "@remotebase/constants";
import { UseFormReturn } from "react-hook-form";
import { EmploymentEntry } from "@remotebase/amplify-constants/API";
import {
  addBtnText,
  addEmploymentHistoryTitle,
  cancelBtnText,
  checkboxInputText,
  checkBoxLabel,
  companyLabel,
  companyPlaceholder,
  editEmploymentHistoryTitle,
  educationBtnWidth,
  EmploymentFormData,
  EmploymentFormEnum,
  endDateLabel,
  formInputText,
  mandatoryNotification,
  startDateLabel,
  summaryLabel,
  summaryPlaceholder,
  titleLabel,
  titlePlaceholder,
  updateBtnText,
} from "../../utils";
import { ActionBtn } from "../../buttons";
import { ModalComponent } from "../modalComponent";
import { ProfileEditInput, SelectMonthYear, ProfileTextArea } from "../../formInput";
import * as Styled from "./style";

interface Props {
  formHook: UseFormReturn;
  close: () => void;
  show: boolean;
  handleFormSubmission: (data: EmploymentEntry, index?: number | null) => void;
}
export const EmploymentFormModal: FC<Props> = ({ formHook, close, handleFormSubmission, show }) => {
  const [endDate, startDate, currentJob, isEdit] = formHook.watch([
    EmploymentFormEnum.endDate,
    EmploymentFormEnum.startDate,
    EmploymentFormEnum.currentJob,
    EmploymentFormEnum.isEdit,
  ]);

  const clickedSubmit = (data: EmploymentFormData): void => {
    const { selectedIndex, isEdit: editing, ...employmentData } = data;
    handleFormSubmission(
      { ...employmentData, endDate: currentJob ? null : employmentData.endDate } as EmploymentEntry,
      editing ? selectedIndex : -1,
    );
    close();
  };

  return (
    <ModalComponent show={show} handleClose={close}>
      <Styled.EditorWrapper>
        <Styled.EditSectionHeading>
          {isEdit ? editEmploymentHistoryTitle : addEmploymentHistoryTitle}
        </Styled.EditSectionHeading>
        <Styled.FormBody>
          <Styled.FormGroup>
            <ProfileEditInput
              type={formInputText}
              label={companyLabel}
              placeholder={companyPlaceholder}
              formHook={formHook}
              title={EmploymentFormEnum.company}
            />
          </Styled.FormGroup>
          <Styled.FormGroup>
            <SelectMonthYear
              label={startDateLabel}
              valueLabel={EmploymentFormEnum.startDate}
              handleChange={formHook.setValue}
              date={startDate}
              errorMsg={formHook.formState.errors[EmploymentFormEnum.startDate]}
            />
            <Styled.CheckBoxWrapper>
              <Styled.CheckBox
                type={checkboxInputText}
                {...formHook.register(EmploymentFormEnum.currentJob)}
              />
              <Styled.SelectLabel>{checkBoxLabel}</Styled.SelectLabel>
            </Styled.CheckBoxWrapper>
          </Styled.FormGroup>
          <Styled.FormGroup>
            <SelectMonthYear
              label={endDateLabel}
              valueLabel={EmploymentFormEnum.endDate}
              handleChange={formHook.setValue}
              date={endDate}
              isDisabled={currentJob}
              errorMsg={formHook.formState.errors[EmploymentFormEnum.endDate]}
            />
          </Styled.FormGroup>
          <Styled.FormGroup>
            <ProfileEditInput
              type={formInputText}
              label={titleLabel}
              placeholder={titlePlaceholder}
              formHook={formHook}
              title={EmploymentFormEnum.title}
            />
          </Styled.FormGroup>
          <Styled.FormGroup>
            <ProfileTextArea
              label={summaryLabel}
              placeholder={summaryPlaceholder}
              formHook={formHook}
              title={EmploymentFormEnum.summary}
            />
          </Styled.FormGroup>
          <Styled.FootNote>{mandatoryNotification}</Styled.FootNote>
        </Styled.FormBody>

        <Styled.BtnGroup>
          <ActionBtn
            btnText={cancelBtnText}
            clickHandler={close}
            width={educationBtnWidth}
            color={Color.LIGHT_BLUE}
            backgroundColor={Color.TRANSPARENT}
          />
          <ActionBtn
            btnText={isEdit ? updateBtnText : addBtnText}
            clickHandler={formHook.handleSubmit(clickedSubmit)}
            width={educationBtnWidth}
            color={Color.WHITE}
            backgroundColor={Color.LIGHT_BLUE}
          />
        </Styled.BtnGroup>
      </Styled.EditorWrapper>
    </ModalComponent>
  );
};

export default EmploymentFormModal;
