import { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { withProfile, withTalentLevel } from "state/profileSteps";
import {
  AuthRoutes as Route,
  networkNonTechHeadline,
  networkTechHeadline,
  ProfileProps,
  Sidebar,
  TalentLevelProps,
} from "utils";
import * as Styled from "./styles";

const TalentNetworkPage: FC<TalentLevelProps & ProfileProps> = ({
  validateRoute,
  profileState: { techProfile },
}) => {
  const history = useHistory();
  const modesOfHiring = ["Direct hiring for the core team", "Hiring for the network"];
  const getModesOfHiring = useMemo(
    (): JSX.Element[] =>
      modesOfHiring.map((modeOfHiring, index) => (
        <Styled.NetworkLi key={index}>{modeOfHiring}</Styled.NetworkLi>
      )),
    [modesOfHiring],
  );
  useEffect(() => {
    if (validateRoute(Sidebar.Network)) history.push(Route.Dashbord);
  }, []);
  return (
    <Styled.NetworkWrapper>
      <Styled.H3>Congratulations!</Styled.H3>
      <Styled.paragraph>
        {techProfile ? networkTechHeadline : networkNonTechHeadline}
      </Styled.paragraph>
      <Styled.H4>What is the next step?</Styled.H4>
      <Styled.paragraph>Remotebase has two modes of hiring:</Styled.paragraph>
      <Styled.NetworkUl>{getModesOfHiring}</Styled.NetworkUl>
      <Styled.H4>Direct hiring:</Styled.H4>
      <Styled.paragraph>
        In this mode, we hire you immediately after you have cleared the tests and interviews to
        join and work with our core team.
      </Styled.paragraph>
      <Styled.H4>Network hiring:</Styled.H4>
      <Styled.paragraph>
        In this mode, we add you to our networking list and match your skills with the requirements
        of our clients. Once we have found the best fit for you, we will get you on board and place
        you to work with that client.
      </Styled.paragraph>
      <Styled.paragraph>
        We will shortly contact you by email for confirmation about your hiring mode.
      </Styled.paragraph>
      <Styled.paragraph>
        We are looking forward to working with you and have an amazing journey ahead.
      </Styled.paragraph>
    </Styled.NetworkWrapper>
  );
};
export default withTalentLevel(withProfile(TalentNetworkPage));
