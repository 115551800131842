import { FC } from "react";
import { EmptyBookingIcon } from "assets/icons";
import * as S from "../styled";

export const EmptyBooking: FC = () => {
  return (
    <S.EmptyContainer>
      <EmptyBookingIcon />
      <S.EmptyHeading>Nothing to see here!</S.EmptyHeading>
      <S.EmptyParagraph>No bookings has been created at the moment </S.EmptyParagraph>
    </S.EmptyContainer>
  );
};

export default EmptyBooking;
