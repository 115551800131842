import { Button } from "react-bootstrap";
import styled from "styled-components";

export const HrScreeningWrapper = styled.div`
  border: 1px solid #e4e4e4;
  padding: 20px;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  max-width: 840px;
  margin: 0 auto;
  background-color: #fcfcfc;
  margin-top: calc(1rem * 1.5);
`;

export const H3 = styled.h3`
  font-size: 24px;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
`;

export const HrScreenUl = styled.ul`
  padding-left: 28px;
  margin-bottom: 18px;
`;

export const HrScreenLi = styled.li`
  list-style-type: disc;
  margin-bottom: 5px;
  font-size: 14px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;

export const BtnTry = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 124px;
  min-height: 34px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  transition: all 0.25s;
  margin-right: 0.5rem;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #295bdb;
    box-shadow: none;
    border: none;
    color: #fff;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 0.5rem;
  }
`;

export const HrPara = styled.div`
  font-size: 14px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 18px;
`;

export const HrHead = styled.div`
  font-size: 16px;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
`;
