import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const ChatListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1 1 100%;
  max-width: 100%;
  background: #ffffff;
  border: none;
  overflow: hidden;
  @media only screen and (min-width: 768px) {
    flex: 1 1 42%;
    max-width: 42%;
    border-right: 1px solid #e4e4e4;
  }
  @media only screen and (min-width: 992px) {
    flex: 1 1 38%;
    max-width: 38%;
  }
  @media only screen and (min-width: 1200px) {
    flex: 1 1 30%;
    max-width: 30%;
  }
`;

export const ChatListHeader = styled.div`
  width: 100%;
  padding: 10px 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f0f2f5;
  flex-shrink: 0;
  position: fixed;
  top: 0px;
  bottom: auto;
  left: 0px;
  right: 0px;
  @media only screen and (min-width: 768px) {
    position: relative;
  }
`;

export const ChatListHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ImgAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background: rgb(249 249 249);
  color: #000000;
  text-align: center;
  overflow: hidden;
  border: none;
  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

export const ChatListHeaderBackText = styled.div`
  display: flex;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  color: #111b21;
  margin: 0px 0px 0px 12px;
`;

export const ChatListHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ChatListHeaderIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  & svg {
    fill: #54656f;
    width: 20px;
    height: 20px;
  }
  &:hover,
  &:focus {
    background-color: rgba(11, 20, 26, 0.1);
    border: none;
    border-radius: 50%;
    box-shadow: none;
  }
`;

export const ChatListHeaderDropDown = styled(Dropdown)`
  & .btn,
  & .btn-success {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 18px;
    color: #3b4a54;
    font-weight: 500;
    min-width: auto;
    min-height: auto;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 0.3s ease;
    svg {
      fill: #54656f;
      width: 20px;
      height: 20px;
    }
  }

  &.show,
  &.show > {
    & .btn-success.dropdown-toggle,
    & .btn-success.dropdown-toggle:focus {
      background-color: rgba(11, 20, 26, 0.1);
      border: none;
      border-radius: 50%;
      box-shadow: none;
      color: #3b4a54;
    }
  }
  & .dropdown-toggle:not(:disabled):not(.disabled):active:focus,
  & .dropdown-toggle:not(:disabled):not(.disabled):active,
  & .dropdown-toggle:hover,
  & .dropdown-toggle:focus {
    background-color: rgba(11, 20, 26, 0.1);
    border: none;
    border-radius: 50%;
    box-shadow: none;
    color: #3b4a54;
  }

  & .dropdown-toggle:after {
    content: none;
  }

  & .dropdown-toggle svg {
    fill: #54656f;
    width: 20px;
    height: 20px;
  }

  & .dropdown-menu {
    border-radius: 12px;
  }
  & .dropdown-item {
    font-size: 14px;
    color: #383838;
    padding: 8px 20px;
  }

  & .dropdown-item:hover,
  & .dropdown-item:focus,
  & .dropdown-item:active {
    color: #383838;
    background-color: #e9ecef;
  }
  @media screen and (max-width: 575px) {
    & .dropdown-toggle {
      width: 100%;
    }
    & .dropdown-menu {
      width: 100%;
    }
  }
`;

export const ChatListSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: #ffffff;
  border: none;
  padding: 10px 16px;
  flex-shrink: 0;
  position: fixed;
  top: 60px;
  bottom: auto;
  left: 0px;
  right: 0px;
  @media only screen and (min-width: 768px) {
    position: relative;
    top: 0px;
  }
`;

export const ChatListSearchInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  & input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 18px;
    height: 35px;
    padding: 4px 20px 4px 52px;
    width: 100%;
    border-radius: 8px;
    background-color: #f0f2f5;
    border: none;
    outline: none;
    box-shadow: none;
    &:focus {
      background-color: #ffffff;
      border: 1px solid #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      outline: none;
    }
  }
`;

export const ChatListSearchInputIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 16px;
  right: auto;
  top: 0px;
  bottom: 0px;
  & svg {
    fill: #54656f;
    width: 16px;
    height: 16px;
  }
`;

export const ChatListMsgList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 110px;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dfdbdb;
    border-radius: 0px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const ChatListMsgListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  padding: 10px 15px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: rgba(54, 21, 189, 0.07);
  }
`;

export const ChatListMsgListContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 50px);
`;

export const ImgAvatarMd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  background: #dee2e6;
  color: #000000;
  text-align: center;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

export const ChatListMsgTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 16px;
`;

export const ChatListMsgName = styled.div`
  display: flex;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  color: #111b21;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 16px;
`;

export const ChatListMsgTime = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #667781;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ChatListMsgBtm = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 6px;
  margin-left: 16px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #111b21;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ChatListMsgText = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #111b21;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 16px;
`;

export const ChatListMsgUnreadBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0px;
  font-size: 6px;
  line-height: 8px;
  font-weight: 400;
  color: #ffffff;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(54, 21, 189, 0.8);
  margin-top: 4px;
`;
