import { Card } from "react-bootstrap";
import styled from "styled-components";

export const CARD_FAQ = styled(Card)`
  padding: 8px 7px 0;
  border-radius: 24px !important;
  border: none;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin-bottom: 14px;

  &:not(:last-of-type) {
    padding: 8px 7px 0;
    border-radius: 24px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    margin-bottom: 14px;
  }

  .card-header {
    padding: 0;
    border-bottom: 0;
    background: none;
  }
  .card-header .btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 80px 16px 24px;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: #27272e;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all 0.25s;
    width: 100%;

    @media (max-width: 575px) {
      font-size: 16px;
    }
  }

  .card-header .btn::before,
  .card-header .btn::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2px;
    background: #27272e;
    transition: all 0.25s;
  }

  .card-header .btn::before {
    right: 44px;
    width: 16px;
    height: 2px;
  }

  .card-header .btn::after {
    right: 51px;
    width: 2px;
    height: 16px;
  }
  .card-header .btn:hover,
  .card-header .btn:focus {
    color: #5956e9;
    text-decoration: none;
    box-shadow: none;
  }

  .card-body {
    padding: 34px 22px 22px;
  }
  .card-body p {
    font-size: 16px;
    color: #999fae;
    margin-bottom: 0;
    user-select: none;
  }

  &.active {
    -webkit-box-shadow: 0 5px 40px rgb(39 39 46 / 10%);
    box-shadow: 0 5px 40px rgb(39 39 46 / 10%);
  }
  &.active .card-header .btn {
    padding: 24px 80px 24px 24px;
    user-select: none;
    background: #5956e9;
    color: #fff;
  }

  &.active .card-header .btn::before,
  &.active .card-header .btn::after {
    background: #fff;
  }
  &.active .card-header .btn::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
  }
`;

export const EmptyDiv = styled.div``;
