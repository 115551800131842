import React from "react";
import AOS from "aos";
import { fetchUtmParams, imageUrl, UnAuthRoutes } from "utils";
import { ChevronRightIcon } from "assets/icons";
import * as Styled from "./styles";

export const LandingMain: React.FC = () => {
  AOS.init();
  return (
    <Styled.MainWrapper className="aos-item" data-aos="fade-up" data-aos-offset="200" id="aboutUs">
      <Styled.Container>
        <Styled.MainBlock>
          <Styled.MainContent>
            <Styled.MainHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
              Work remotely with <Styled.textBlueSpan>Silicon Valley</Styled.textBlueSpan> startups
              and more
            </Styled.MainHeading>
            <Styled.MainPara className="aos-item" data-aos="fade-up" data-aos-duration="1000">
              Remotebase hires the top 1% of talent and places them to work remotely with some of
              the most innovative companies in the world.
            </Styled.MainPara>
            <Styled.RegisterBtnCanvas
              className="aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <Styled.RegisterBtn to={`${UnAuthRoutes.Register}?${fetchUtmParams()}`}>
                Apply Now
                <Styled.RegisterBtnIcon>
                  <ChevronRightIcon />
                </Styled.RegisterBtnIcon>
              </Styled.RegisterBtn>
            </Styled.RegisterBtnCanvas>
          </Styled.MainContent>
          <Styled.MainImgs>
            <Styled.MainImgBox className="aos-item" data-aos="fade-right" data-aos-duration="1000">
              <Styled.HeroImgFemale src={imageUrl.heroImageFirst} alt="Hero Female Image" />
              <Styled.HeroFemaleCircle />
              <Styled.HeroFemaleOverlay>
                <Styled.OverlayHeading>Nola</Styled.OverlayHeading>
                <Styled.OverlayText>
                  UI/UX Designer at <b>Qume</b>
                </Styled.OverlayText>
              </Styled.HeroFemaleOverlay>
            </Styled.MainImgBox>
            <Styled.MainImgBox className="aos-item" data-aos="fade-left" data-aos-duration="1000">
              <Styled.HeroImgMale src={imageUrl.heroImageSecond} alt="Hero Male Image" />
              <Styled.HeroMaleCircle />
              <Styled.HeroMaleOverlay>
                <Styled.OverlayHeading>Adam</Styled.OverlayHeading>
                <Styled.OverlayText>
                  Python developer at <b>Capbase</b>
                </Styled.OverlayText>
              </Styled.HeroMaleOverlay>
            </Styled.MainImgBox>
          </Styled.MainImgs>
        </Styled.MainBlock>
      </Styled.Container>
    </Styled.MainWrapper>
  );
};
export default LandingMain;
