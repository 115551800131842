import { useLazyQuery } from "@apollo/client";
import {
  GetUserCanSignUpStatusQuery,
  GetUserCanSignUpStatusQueryVariables,
} from "@remotebase/amplify-constants/API";
import { getUserCanSignUpStatus } from "@remotebase/amplify-constants/graphql/queries";

import { getQuery } from "hooks/utils";
import awsConfig from "@remotebase/amplify-constants/aws-exports";
import { ReturnPropsUserCanSignUp } from "hooks/types/UserCanSignUp";

export const useUserCanSignUp = (): ReturnPropsUserCanSignUp => {
  const [checkIfUserCanSignUp, { data, loading, called }] = useLazyQuery<
    GetUserCanSignUpStatusQuery,
    GetUserCanSignUpStatusQueryVariables
  >(getQuery(getUserCanSignUpStatus), {
    context: { headers: { "x-api-key": awsConfig.aws_appsync_apiKey } },
  });
  const { httpStatusCode, message: responseMsg } = data?.getUserCanSignUpStatus ?? {};
  const userCanSignUp = httpStatusCode === 200;
  const message = responseMsg || "Unable to complete request. Please try again";

  return { checkIfUserCanSignUp, userCanSignUp, loading, message, called };
};

export default useUserCanSignUp;
