import { FC, useContext, useEffect } from "react";
import className from "classnames";
import { autoScrollOnCreateProfile } from "utils";
import { ProfileStepContext } from "state/profileSteps";
import { AboutBtn, ContactSection } from "./sections";

const BasicTalentInfo: FC = () => {
  const { engineerStep: currentStep } = useContext(ProfileStepContext);

  const isActive = (level: number): string => className({ active: currentStep === level });

  useEffect(() => {
    autoScrollOnCreateProfile(currentStep);
  }, [currentStep]);

  return (
    <>
      <section data-step="0" className={isActive(0)}>
        <AboutBtn />
      </section>
      <section data-step="1" className={isActive(1)}>
        <ContactSection />
      </section>
    </>
  );
};

export default BasicTalentInfo;
