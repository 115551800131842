import { MessageResponse } from "@remotebase/amplify-constants/API";
import { BtnLoader } from "@remotebase/components";
import { useChatSubscription } from "hooks";
import { FC, Fragment, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { withCurrentUserChat } from "state/chat";
import { withProfile } from "state/profileSteps";
import { CurrentChatProps, getFormattedDay, ProfileProps } from "utils";
import DateTime from "./DateTime";
import MyMessage from "./MyMessage";
import OtherMessage from "./OtherMessage";
import * as Styled from "./styles";

interface Props {
  localMessage: MessageResponse | null;
  conversationId: string;
}

const CurrentUserChat: FC<Props & ProfileProps & CurrentChatProps> = ({
  profileState: { data },
  chatMessages,
  hasMoreData,
  chatBoxRef,
  localMessage,
  conversationId,
  updateLocalMessage,
  updateSubscribedMessage,
  fetchMore,
}) => {
  const subscribedMsg = useChatSubscription(conversationId || "");
  let prevMsgTime = "";

  const renderMessage = (item: MessageResponse): JSX.Element => {
    if (data?.id !== item?.senderInfo?.id) return <MyMessage content={item.content || ""} />;
    return <OtherMessage content={item.content || ""} />;
  };

  const renderDateTime = (item: MessageResponse): string => {
    const time = getFormattedDay(item.createdAt || "");
    if (prevMsgTime !== time) {
      prevMsgTime = time;
      return time;
    }
    return "";
  };

  useEffect(() => {
    if (localMessage) updateLocalMessage(localMessage);
  }, [localMessage]);

  useEffect(() => {
    if (subscribedMsg) updateSubscribedMessage(subscribedMsg);
  }, [subscribedMsg]);

  return (
    <Styled.ChatBodyMessageWrapper ref={chatBoxRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchMore}
        useWindow={false}
        isReverse
        hasMore={hasMoreData}
        loader={
          <Styled.ChatBodyMsgsLoader>
            <BtnLoader color={"#4c40f7"} />
          </Styled.ChatBodyMsgsLoader>
        }
      >
        <Styled.ChatBodyMsgsInner key="chat panel container">
          {chatMessages.map((item: MessageResponse, index: number) => (
            <Fragment key={index}>
              <DateTime time={renderDateTime(item)} />
              {renderMessage(item)}
            </Fragment>
          ))}
        </Styled.ChatBodyMsgsInner>
      </InfiniteScroll>
    </Styled.ChatBodyMessageWrapper>
  );
};

export default withProfile(withCurrentUserChat(CurrentUserChat));
