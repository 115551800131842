import styled from "styled-components";

export const ProcessWrapper = styled.div`
  padding: 120px 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const ProcessHeading = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
`;

export const ProcessPara = styled.p`
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  line-height: 180%;
  max-width: 620px;
  margin: 0 auto 68px;
  text-align: center;
`;

export const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 48px;
  position: relative;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
  }

  &:after {
    @media only screen and (min-width: 768px) {
      content: "";
      width: 100%;
      max-width: 654px;
      height: 258px;
      background-color: rgba(132, 74, 255, 0.1);
      border-radius: 30px;
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`;

export const ProcessSteps = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;
  z-index: 1;
`;

export const ProcessImg = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-radius: 20px 20px 0px 0px;

  &.processImg__1 {
    background-color: #def3fe;

    @media only screen and (min-width: 1200px) {
      transform: skewY(4deg);
      height: 104px;
      margin-top: -10px;
    }

    &:before {
      @media only screen and (min-width: 1200px) {
        content: "";
        background-color: #fff;
        height: 20px;
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        transform: skewY(-4deg);
        z-index: 1;
      }
    }

    &:after {
      @media only screen and (min-width: 1200px) {
        content: "";
        background-color: #def3fe;
        height: 28px;
        position: absolute;
        bottom: -18px;
        left: 0;
        right: 0;
        transform: skewY(-4deg);
      }
    }

    & img {
      @media only screen and (min-width: 1200px) {
        transform: skewY(-4deg);
        margin-bottom: -4px;
      }
    }

    & > span {
      background-color: #3c65fe;

      @media only screen and (min-width: 1200px) {
        transform: skewY(-4deg);
      }
    }
  }
  &.processImg__2 {
    background-color: #ffe5dd;

    & > span {
      background-color: #ff7c57;
    }
  }
  &.processImg__3 {
    background-color: #e6dbff;

    & > span {
      background-color: #844aff;
    }
  }
  &.processImg__4 {
    background-color: #fcf1da;

    @media only screen and (min-width: 1200px) {
      transform: skewY(-4deg);
      height: 104px;
      margin-top: -10px;
    }

    &:before {
      @media only screen and (min-width: 1200px) {
        content: "";
        background-color: #fff;
        height: 20px;
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        transform: skewY(4deg);
        z-index: 1;
      }
    }

    &:after {
      @media only screen and (min-width: 1200px) {
        content: "";
        background-color: #fcf1da;
        height: 28px;
        position: absolute;
        bottom: -18px;
        left: 0;
        right: 0;
        transform: skewY(4deg);
      }
    }

    & img {
      @media only screen and (min-width: 1200px) {
        transform: skewY(4deg);
        margin-bottom: -10px;
      }
    }

    & > span {
      background-color: #f2bb47;

      @media only screen and (min-width: 1200px) {
        transform: skewY(4deg);
      }
    }
  }
`;

export const ProcessStepsImg = styled.img`
  max-width: initial;
  max-height: initial;
  margin-top: -12px;
  pointer-events: none;
  position: relative;
  z-index: 1;
`;

export const ProcessCircle = styled.span`
  font-size: 28px;
  color: #fff;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.2px;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #ffffff;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  position: absolute;
  left: 28px;
  top: -30px;
`;

export const ProcessDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 42px;
`;

export const ProcessSubHeading = styled.h3`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
`;

export const ProcessText = styled.p`
  font-size: 12px;
  color: #323232;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 0;
`;

// Used in ProcessBlock
export const ProcessStepsImgBox = styled.div`
  position: absolute;
  pointer-events: none;
`;

// Used in ProcessBlock
export const ProcessImageContainer = styled.div`
  position: relative;
  border-radius: 24px;
  font-size: 0;

  @media only screen and (max-width: 1279px) {
    border-radius: 16px;
  }

  @media only screen and (max-width: 1279px) {
    left: 60px;
  }
`;
