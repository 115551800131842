import { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ShouldRender } from "@remotebase/components";
import { CalendarBooking } from "@remotebase/amplify-constants/API";
import { CommentIcon } from "assets/icons";
import { AuthRoutes, TabOptions } from "utils";
import * as S from "../styled";

interface IButtonBasedOnTab {
  tab: string;
  meetingDetails: CalendarBooking;
  showFeedback: boolean;
  feedbackHandler: () => void;
}

const ButtonBasedOnTab: FC<IButtonBasedOnTab> = ({
  tab,
  meetingDetails,
  showFeedback,
  feedbackHandler,
}) => {
  const { push } = useHistory();

  const bookSlotHandler = (): void => {
    try {
      if (meetingDetails.inviteToken) {
        push(AuthRoutes.SelfSchedule.replace(":token", meetingDetails.inviteToken));
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <Fragment>
      <ShouldRender if={tab === TabOptions.Recent}>
        <Fragment>
          <ShouldRender if={meetingDetails.meetingLink}>
            <S.JoinMeeting target="_blank" to={{ pathname: String(meetingDetails.meetingLink) }}>
              <S.BtnText>Join Meeting</S.BtnText>
            </S.JoinMeeting>
          </ShouldRender>
          <ShouldRender if={!meetingDetails.meetingLink}>
            <S.BookSlot onClick={bookSlotHandler}>
              <S.BtnText>Book a slot</S.BtnText>
            </S.BookSlot>
          </ShouldRender>
        </Fragment>
      </ShouldRender>
      <ShouldRender if={tab === TabOptions.Completed}>
        <S.BookSlot isCompleted onClick={feedbackHandler} disabled={!meetingDetails.feedback}>
          <S.BtnText>{showFeedback ? "Close" : "See Feedbacks"}</S.BtnText>
          <CommentIcon />
        </S.BookSlot>
      </ShouldRender>
    </Fragment>
  );
};

export default ButtonBasedOnTab;
