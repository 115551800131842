import { FC, useMemo } from "react";
import { CalendarBooking } from "@remotebase/amplify-constants/API";
import * as S from "../styled";

interface IFeedback {
  meetingDetails: CalendarBooking;
}

const Feedback: FC<IFeedback> = ({ meetingDetails }) => {
  const feedbackData = useMemo(() => {
    const { feedback, client } = meetingDetails;
    const clientName = client?.fullName;
    const companyName = client?.company?.name;
    return { feedback, clientName, companyName };
  }, [meetingDetails]);

  return (
    <S.FeedbackContainer>
      <S.HorizontalDivider />
      <S.FeedbackContent>
        <S.ContentLine>
          <S.CompanyName>{feedbackData.clientName} </S.CompanyName>
          <S.CompanyInvite>From {feedbackData.companyName}</S.CompanyInvite>
        </S.ContentLine>
        <S.FeedbackComment>{feedbackData.feedback}</S.FeedbackComment>
      </S.FeedbackContent>
    </S.FeedbackContainer>
  );
};

export default Feedback;
