/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($conversationId: ID!) {
    onCreateMessage(conversationId: $conversationId) {
      id
      content
      senderInfo {
        id
        fullName
        email
        phone
        role
      }
      conversationId
      createdAt
      updatedAt
      isRead
      readBy {
        id
        fullName
        email
        phone
        role
      }
      version
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateConversationParticipant = /* GraphQL */ `
  subscription OnCreateConversationParticipant(
    $talentId: ID
    $clientId: ID
    $recruiterId: ID
  ) {
    onCreateConversationParticipant(
      talentId: $talentId
      clientId: $clientId
      recruiterId: $recruiterId
    ) {
      id
      conversationId
      talentId
      clientId
      recruiterId
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onCreateAnalytics = /* GraphQL */ `
  subscription OnCreateAnalytics {
    onCreateAnalytics {
      id
      developersCompletedProfiles
      developersJoined
      recruitersJoined
      hiredToday
      takenDomainTests
      passedDomainTests
      takenProblemSolvingTests
      passedProblemSolving
      developersReferredByRecruiters
      createdAt
      updatedAt
      version
    }
  }
`;
export const onUpdateAnalytics = /* GraphQL */ `
  subscription OnUpdateAnalytics {
    onUpdateAnalytics {
      id
      developersCompletedProfiles
      developersJoined
      recruitersJoined
      hiredToday
      takenDomainTests
      passedDomainTests
      takenProblemSolvingTests
      passedProblemSolving
      developersReferredByRecruiters
      createdAt
      updatedAt
      version
    }
  }
`;
export const onDeleteAnalytics = /* GraphQL */ `
  subscription OnDeleteAnalytics {
    onDeleteAnalytics {
      id
      developersCompletedProfiles
      developersJoined
      recruitersJoined
      hiredToday
      takenDomainTests
      passedDomainTests
      takenProblemSolvingTests
      passedProblemSolving
      developersReferredByRecruiters
      createdAt
      updatedAt
      version
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      id
      clientId
      invoiceBatchId
      invoiceNumber
      amount
      status
      reason
      stripeInvoiceId
      stripeInvoicePdfUrl
      description
      createdAt
      updatedAt
      invoiceBatch {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      id
      clientId
      invoiceBatchId
      invoiceNumber
      amount
      status
      reason
      stripeInvoiceId
      stripeInvoicePdfUrl
      description
      createdAt
      updatedAt
      invoiceBatch {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      id
      clientId
      invoiceBatchId
      invoiceNumber
      amount
      status
      reason
      stripeInvoiceId
      stripeInvoicePdfUrl
      description
      createdAt
      updatedAt
      invoiceBatch {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateInvoiceBatch = /* GraphQL */ `
  subscription OnCreateInvoiceBatch {
    onCreateInvoiceBatch {
      id
      csvFileUrl
      createdBy
      approvedBy
      status
      invoiceBatchNumber
      createdAt
      sortHashKey
      errorMsg
      invoicesToProcess
      invoicesProcessed
      updatedAt
      version
    }
  }
`;
export const onUpdateInvoiceBatch = /* GraphQL */ `
  subscription OnUpdateInvoiceBatch {
    onUpdateInvoiceBatch {
      id
      csvFileUrl
      createdBy
      approvedBy
      status
      invoiceBatchNumber
      createdAt
      sortHashKey
      errorMsg
      invoicesToProcess
      invoicesProcessed
      updatedAt
      version
    }
  }
`;
export const onDeleteInvoiceBatch = /* GraphQL */ `
  subscription OnDeleteInvoiceBatch {
    onDeleteInvoiceBatch {
      id
      csvFileUrl
      createdBy
      approvedBy
      status
      invoiceBatchNumber
      createdAt
      sortHashKey
      errorMsg
      invoicesToProcess
      invoicesProcessed
      updatedAt
      version
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment {
    onCreatePayment {
      id
      invoiceId
      amount
      transactionId
      paymentStatus
      paymentMethod
      reason
      createdAt
      updatedAt
      invoice {
        id
        clientId
        invoiceBatchId
        invoiceNumber
        amount
        status
        reason
        stripeInvoiceId
        stripeInvoicePdfUrl
        description
        createdAt
        updatedAt
        invoiceBatch {
          id
          csvFileUrl
          createdBy
          approvedBy
          status
          invoiceBatchNumber
          createdAt
          sortHashKey
          errorMsg
          invoicesToProcess
          invoicesProcessed
          updatedAt
          version
        }
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
            availableSlots {
              startTime
              endTime
            }
          }
          googleCalendarId
          remainingCredits
          totalCredits
          stripeCustomerId
          currentStripeSubscriptionId
          hasMandate
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment {
    onUpdatePayment {
      id
      invoiceId
      amount
      transactionId
      paymentStatus
      paymentMethod
      reason
      createdAt
      updatedAt
      invoice {
        id
        clientId
        invoiceBatchId
        invoiceNumber
        amount
        status
        reason
        stripeInvoiceId
        stripeInvoicePdfUrl
        description
        createdAt
        updatedAt
        invoiceBatch {
          id
          csvFileUrl
          createdBy
          approvedBy
          status
          invoiceBatchNumber
          createdAt
          sortHashKey
          errorMsg
          invoicesToProcess
          invoicesProcessed
          updatedAt
          version
        }
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
            availableSlots {
              startTime
              endTime
            }
          }
          googleCalendarId
          remainingCredits
          totalCredits
          stripeCustomerId
          currentStripeSubscriptionId
          hasMandate
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment {
    onDeletePayment {
      id
      invoiceId
      amount
      transactionId
      paymentStatus
      paymentMethod
      reason
      createdAt
      updatedAt
      invoice {
        id
        clientId
        invoiceBatchId
        invoiceNumber
        amount
        status
        reason
        stripeInvoiceId
        stripeInvoicePdfUrl
        description
        createdAt
        updatedAt
        invoiceBatch {
          id
          csvFileUrl
          createdBy
          approvedBy
          status
          invoiceBatchNumber
          createdAt
          sortHashKey
          errorMsg
          invoicesToProcess
          invoicesProcessed
          updatedAt
          version
        }
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
            availableSlots {
              startTime
              endTime
            }
          }
          googleCalendarId
          remainingCredits
          totalCredits
          stripeCustomerId
          currentStripeSubscriptionId
          hasMandate
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onCreateStripeSetupIntent = /* GraphQL */ `
  subscription OnCreateStripeSetupIntent {
    onCreateStripeSetupIntent {
      id
      status
      paymentMethodId
      setupIntentId
      mandateId
      mandateStatus
      createdAt
      clientId
      clientSecret
      updatedAt
    }
  }
`;
export const onUpdateStripeSetupIntent = /* GraphQL */ `
  subscription OnUpdateStripeSetupIntent {
    onUpdateStripeSetupIntent {
      id
      status
      paymentMethodId
      setupIntentId
      mandateId
      mandateStatus
      createdAt
      clientId
      clientSecret
      updatedAt
    }
  }
`;
export const onDeleteStripeSetupIntent = /* GraphQL */ `
  subscription OnDeleteStripeSetupIntent {
    onDeleteStripeSetupIntent {
      id
      status
      paymentMethodId
      setupIntentId
      mandateId
      mandateStatus
      createdAt
      clientId
      clientSecret
      updatedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      logo
      stage
      tagline
      description
      address {
        id
        streetAddress
        apartmentAddress
        postalCode
        district
        city
        country
      }
      industry
      associatedWith
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      logo
      stage
      tagline
      description
      address {
        id
        streetAddress
        apartmentAddress
        postalCode
        district
        city
        country
      }
      industry
      associatedWith
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      logo
      stage
      tagline
      description
      address {
        id
        streetAddress
        apartmentAddress
        postalCode
        district
        city
        country
      }
      industry
      associatedWith
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJob = /* GraphQL */ `
  subscription OnCreateJob {
    onCreateJob {
      id
      companyID
      status
      title
      description
      responsibilites
      tags
      requiredTechDomainSkills
      seniorityLevel
      isPublic
      isPermanent
      internalNotes
      department
      jobPerks
      createdAt
      updatedAt
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateJob = /* GraphQL */ `
  subscription OnUpdateJob {
    onUpdateJob {
      id
      companyID
      status
      title
      description
      responsibilites
      tags
      requiredTechDomainSkills
      seniorityLevel
      isPublic
      isPermanent
      internalNotes
      department
      jobPerks
      createdAt
      updatedAt
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteJob = /* GraphQL */ `
  subscription OnDeleteJob {
    onDeleteJob {
      id
      companyID
      status
      title
      description
      responsibilites
      tags
      requiredTechDomainSkills
      seniorityLevel
      isPublic
      isPermanent
      internalNotes
      department
      jobPerks
      createdAt
      updatedAt
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      name
      createdAt
      updatedAt
      isActive
      lastMessageAt
      version
      messages {
        items {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          readBy {
            id
            fullName
            email
            phone
            role
          }
          version
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
        }
        nextToken
      }
      conversationParticipants {
        items {
          id
          conversationId
          talentId
          clientId
          recruiterId
          createdAt
          updatedAt
          version
          client {
            id
            fullName
            email
            phone
            country
            role
            companyID
            timezone
            availability {
              day
              date
              availableSlots {
                startTime
                endTime
              }
            }
            googleCalendarId
            remainingCredits
            totalCredits
            stripeCustomerId
            currentStripeSubscriptionId
            hasMandate
            createdAt
            updatedAt
            version
            company {
              id
              name
              logo
              stage
              tagline
              description
              address {
                id
                streetAddress
                apartmentAddress
                postalCode
                district
                city
                country
              }
              industry
              associatedWith
              createdAt
              updatedAt
            }
          }
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
          recruiter {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              currentEarnings
              profession
              createdAt
              updatedAt
              version
              recruiterProfileRecruiterId
            }
          }
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      name
      createdAt
      updatedAt
      isActive
      lastMessageAt
      version
      messages {
        items {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          readBy {
            id
            fullName
            email
            phone
            role
          }
          version
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
        }
        nextToken
      }
      conversationParticipants {
        items {
          id
          conversationId
          talentId
          clientId
          recruiterId
          createdAt
          updatedAt
          version
          client {
            id
            fullName
            email
            phone
            country
            role
            companyID
            timezone
            availability {
              day
              date
              availableSlots {
                startTime
                endTime
              }
            }
            googleCalendarId
            remainingCredits
            totalCredits
            stripeCustomerId
            currentStripeSubscriptionId
            hasMandate
            createdAt
            updatedAt
            version
            company {
              id
              name
              logo
              stage
              tagline
              description
              address {
                id
                streetAddress
                apartmentAddress
                postalCode
                district
                city
                country
              }
              industry
              associatedWith
              createdAt
              updatedAt
            }
          }
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
          recruiter {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              currentEarnings
              profession
              createdAt
              updatedAt
              version
              recruiterProfileRecruiterId
            }
          }
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      name
      createdAt
      updatedAt
      isActive
      lastMessageAt
      version
      messages {
        items {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          readBy {
            id
            fullName
            email
            phone
            role
          }
          version
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
        }
        nextToken
      }
      conversationParticipants {
        items {
          id
          conversationId
          talentId
          clientId
          recruiterId
          createdAt
          updatedAt
          version
          client {
            id
            fullName
            email
            phone
            country
            role
            companyID
            timezone
            availability {
              day
              date
              availableSlots {
                startTime
                endTime
              }
            }
            googleCalendarId
            remainingCredits
            totalCredits
            stripeCustomerId
            currentStripeSubscriptionId
            hasMandate
            createdAt
            updatedAt
            version
            company {
              id
              name
              logo
              stage
              tagline
              description
              address {
                id
                streetAddress
                apartmentAddress
                postalCode
                district
                city
                country
              }
              industry
              associatedWith
              createdAt
              updatedAt
            }
          }
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
          recruiter {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              currentEarnings
              profession
              createdAt
              updatedAt
              version
              recruiterProfileRecruiterId
            }
          }
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateRecruiter = /* GraphQL */ `
  subscription OnCreateRecruiter($id: String) {
    onCreateRecruiter(id: $id) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        currentEarnings
        profession
        createdAt
        updatedAt
        version
        recruiterProfileRecruiterId
      }
    }
  }
`;
export const onUpdateRecruiter = /* GraphQL */ `
  subscription OnUpdateRecruiter($id: String) {
    onUpdateRecruiter(id: $id) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        currentEarnings
        profession
        createdAt
        updatedAt
        version
        recruiterProfileRecruiterId
      }
    }
  }
`;
export const onDeleteRecruiter = /* GraphQL */ `
  subscription OnDeleteRecruiter($id: String) {
    onDeleteRecruiter(id: $id) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        currentEarnings
        profession
        createdAt
        updatedAt
        version
        recruiterProfileRecruiterId
      }
    }
  }
`;
export const onCreateRecruiterProfile = /* GraphQL */ `
  subscription OnCreateRecruiterProfile($recruiterProfileRecruiterId: String) {
    onCreateRecruiterProfile(
      recruiterProfileRecruiterId: $recruiterProfileRecruiterId
    ) {
      id
      currentEarnings
      profession
      createdAt
      updatedAt
      version
      recruiterProfileRecruiterId
    }
  }
`;
export const onUpdateRecruiterProfile = /* GraphQL */ `
  subscription OnUpdateRecruiterProfile($recruiterProfileRecruiterId: String) {
    onUpdateRecruiterProfile(
      recruiterProfileRecruiterId: $recruiterProfileRecruiterId
    ) {
      id
      currentEarnings
      profession
      createdAt
      updatedAt
      version
      recruiterProfileRecruiterId
    }
  }
`;
export const onDeleteRecruiterProfile = /* GraphQL */ `
  subscription OnDeleteRecruiterProfile($recruiterProfileRecruiterId: String) {
    onDeleteRecruiterProfile(
      recruiterProfileRecruiterId: $recruiterProfileRecruiterId
    ) {
      id
      currentEarnings
      profession
      createdAt
      updatedAt
      version
      recruiterProfileRecruiterId
    }
  }
`;
export const onCreateReferral = /* GraphQL */ `
  subscription OnCreateReferral {
    onCreateReferral {
      id
      referrerId
      referralReferredId
      recruiterId
      email
      createdAt
      updatedAt
      version
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      referrer {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      referred {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onUpdateReferral = /* GraphQL */ `
  subscription OnUpdateReferral {
    onUpdateReferral {
      id
      referrerId
      referralReferredId
      recruiterId
      email
      createdAt
      updatedAt
      version
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      referrer {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      referred {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onDeleteReferral = /* GraphQL */ `
  subscription OnDeleteReferral {
    onDeleteReferral {
      id
      referrerId
      referralReferredId
      recruiterId
      email
      createdAt
      updatedAt
      version
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      referrer {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      referred {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onCreateTalent = /* GraphQL */ `
  subscription OnCreateTalent($id: String) {
    onCreateTalent(id: $id) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateTalent = /* GraphQL */ `
  subscription OnUpdateTalent($id: String) {
    onUpdateTalent(id: $id) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteTalent = /* GraphQL */ `
  subscription OnDeleteTalent($id: String) {
    onDeleteTalent(id: $id) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateTalentProfile = /* GraphQL */ `
  subscription OnCreateTalentProfile($talentProfileTalentId: String) {
    onCreateTalentProfile(talentProfileTalentId: $talentProfileTalentId) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      workableId
      aiInterviewInProgress
      videoInterviewProvider
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      talentProfileTalentId
      talentType {
        id
        name
        title
        icon
        sequence
        skills {
          name
          title
        }
        interests {
          name
          title
        }
        sortType
        stages {
          type
          isOptional
          sequenceNumber
          passingPercentage
        }
        videoInterviewProvider
        hrInterviewJobId
        hrInterviewAIURL
        atsJobID
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateTalentProfile = /* GraphQL */ `
  subscription OnUpdateTalentProfile($talentProfileTalentId: String) {
    onUpdateTalentProfile(talentProfileTalentId: $talentProfileTalentId) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      workableId
      aiInterviewInProgress
      videoInterviewProvider
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      talentProfileTalentId
      talentType {
        id
        name
        title
        icon
        sequence
        skills {
          name
          title
        }
        interests {
          name
          title
        }
        sortType
        stages {
          type
          isOptional
          sequenceNumber
          passingPercentage
        }
        videoInterviewProvider
        hrInterviewJobId
        hrInterviewAIURL
        atsJobID
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteTalentProfile = /* GraphQL */ `
  subscription OnDeleteTalentProfile($talentProfileTalentId: String) {
    onDeleteTalentProfile(talentProfileTalentId: $talentProfileTalentId) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      workableId
      aiInterviewInProgress
      videoInterviewProvider
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      talentProfileTalentId
      talentType {
        id
        name
        title
        icon
        sequence
        skills {
          name
          title
        }
        interests {
          name
          title
        }
        sortType
        stages {
          type
          isOptional
          sequenceNumber
          passingPercentage
        }
        videoInterviewProvider
        hrInterviewJobId
        hrInterviewAIURL
        atsJobID
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateForexRates = /* GraphQL */ `
  subscription OnCreateForexRates {
    onCreateForexRates {
      id
      currencyType
      usdConversionRate
      createdAt
      updatedAt
      version
    }
  }
`;
export const onUpdateForexRates = /* GraphQL */ `
  subscription OnUpdateForexRates {
    onUpdateForexRates {
      id
      currencyType
      usdConversionRate
      createdAt
      updatedAt
      version
    }
  }
`;
export const onDeleteForexRates = /* GraphQL */ `
  subscription OnDeleteForexRates {
    onDeleteForexRates {
      id
      currencyType
      usdConversionRate
      createdAt
      updatedAt
      version
    }
  }
`;
export const onCreateTalentProfileTransient = /* GraphQL */ `
  subscription OnCreateTalentProfileTransient($talentProfileTalentId: String) {
    onCreateTalentProfileTransient(
      talentProfileTalentId: $talentProfileTalentId
    ) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      talentProfileTalentId
      source
      createdAt
      updatedAt
      version
    }
  }
`;
export const onUpdateTalentProfileTransient = /* GraphQL */ `
  subscription OnUpdateTalentProfileTransient($talentProfileTalentId: String) {
    onUpdateTalentProfileTransient(
      talentProfileTalentId: $talentProfileTalentId
    ) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      talentProfileTalentId
      source
      createdAt
      updatedAt
      version
    }
  }
`;
export const onDeleteTalentProfileTransient = /* GraphQL */ `
  subscription OnDeleteTalentProfileTransient($talentProfileTalentId: String) {
    onDeleteTalentProfileTransient(
      talentProfileTalentId: $talentProfileTalentId
    ) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      talentProfileTalentId
      source
      createdAt
      updatedAt
      version
    }
  }
`;
export const onCreateTalentProfileProcessedIDs = /* GraphQL */ `
  subscription OnCreateTalentProfileProcessedIDs(
    $talentProfileTalentId: String
  ) {
    onCreateTalentProfileProcessedIDs(
      talentProfileTalentId: $talentProfileTalentId
    ) {
      id
      talentProfileId
      createdAt
      updatedAt
      version
      talentProfileTalentId
    }
  }
`;
export const onUpdateTalentProfileProcessedIDs = /* GraphQL */ `
  subscription OnUpdateTalentProfileProcessedIDs(
    $talentProfileTalentId: String
  ) {
    onUpdateTalentProfileProcessedIDs(
      talentProfileTalentId: $talentProfileTalentId
    ) {
      id
      talentProfileId
      createdAt
      updatedAt
      version
      talentProfileTalentId
    }
  }
`;
export const onDeleteTalentProfileProcessedIDs = /* GraphQL */ `
  subscription OnDeleteTalentProfileProcessedIDs(
    $talentProfileTalentId: String
  ) {
    onDeleteTalentProfileProcessedIDs(
      talentProfileTalentId: $talentProfileTalentId
    ) {
      id
      talentProfileId
      createdAt
      updatedAt
      version
      talentProfileTalentId
    }
  }
`;
export const onCreateTalentType = /* GraphQL */ `
  subscription OnCreateTalentType {
    onCreateTalentType {
      id
      name
      title
      icon
      sequence
      skills {
        name
        title
      }
      interests {
        name
        title
      }
      sortType
      stages {
        type
        isOptional
        sequenceNumber
        passingPercentage
      }
      videoInterviewProvider
      hrInterviewJobId
      hrInterviewAIURL
      atsJobID
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTalentType = /* GraphQL */ `
  subscription OnUpdateTalentType {
    onUpdateTalentType {
      id
      name
      title
      icon
      sequence
      skills {
        name
        title
      }
      interests {
        name
        title
      }
      sortType
      stages {
        type
        isOptional
        sequenceNumber
        passingPercentage
      }
      videoInterviewProvider
      hrInterviewJobId
      hrInterviewAIURL
      atsJobID
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTalentType = /* GraphQL */ `
  subscription OnDeleteTalentType {
    onDeleteTalentType {
      id
      name
      title
      icon
      sequence
      skills {
        name
        title
      }
      interests {
        name
        title
      }
      sortType
      stages {
        type
        isOptional
        sequenceNumber
        passingPercentage
      }
      videoInterviewProvider
      hrInterviewJobId
      hrInterviewAIURL
      atsJobID
      isActive
      createdAt
      updatedAt
    }
  }
`;
