import { FC } from "react";
import * as S from "./styles";
import { DevProps } from "./index";

const DeveloperCardBox: FC<DevProps> = ({
  // devImg,
  devName,
  devJob,
  devSummary,
}) => {
  return (
    <S.DevBoxCol
      className="aos-item"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-testid={"default_dev_list"}
    >
      <S.DevBox>
        <S.DevBoxTop>
          {/* <S.DevImgCanvas>
            <S.DevImg src={devImg} alt="Developer Image" />
          </S.DevImgCanvas> */}
          <S.DevTopContent>
            <S.DevName>{devName}</S.DevName>
            <S.DevJob>{devJob}</S.DevJob>
          </S.DevTopContent>
        </S.DevBoxTop>
        <S.DevComment>{devSummary}</S.DevComment>
      </S.DevBox>
    </S.DevBoxCol>
  );
};

export default DeveloperCardBox;
