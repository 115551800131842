export const passwordPlaceholder = "password";
export const emailPlaceholder = "name@company.com";
export const codePlaceholder = "code";

export const emailRequiredError = "Email is a required field";
export const passwordRequiredError = "Password is a required field";
export const invalidEmailError = "Email must be a valid email";
export const codeRequiredError = "Code is a required field";
export const invalidCodeError = "Code must be at least 6 characters";
export const defaultCurrencyCountry = "USD";
export const pdfFile = "application/pdf";
export const docFile = "application/msword";
export const docxFile = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const createProfileResumeType = "File must be in PDF, DOC or DOCX format";
export const createProfileResumeSize = "File size should not be more than 8 MB";
export const confirmNewPassword = "Must be same as password above";
export const unverifiedUser = "User is not confirmed.";
export const incorrectUserPass = "Incorrect username or password.";
export const userNotExists = "User does not exist.";
