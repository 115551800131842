import { FC } from "react";
import { TalentJobs } from "@remotebase/components";
import { ITalentMatchProp, withJobs } from "state/jobs";

const JobsComponent: FC<ITalentMatchProp> = ({ jobsData, talentSkills, loading }) => {
  const componentProps = {
    jobsData,
    talentSkills,
    loading,
  };
  return <TalentJobs {...componentProps} />;
};

export default withJobs(JobsComponent);
