import { FC } from "react";
import { Modal } from "react-bootstrap";
import { withMarketing } from "state/marketingModal";
import { ICONS, imageUrl } from "utils";
import { IMarketingProps } from "state/types/marketing.interface";
import { getIcon } from "hooks/utils";
import * as Styled from "./styles";

const MarketingModal: FC<IMarketingProps> = ({ showMarketingModal, setMarketingModal }) => {
  return (
    <Styled.MarketingModal
      show={showMarketingModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Body>
        <Styled.CrossIconBox onClick={setMarketingModal}>
          {getIcon(ICONS.CLOSE)}
        </Styled.CrossIconBox>

        <Styled.ModalImage src={imageUrl.marketing} alt="img" />
      </Modal.Body>
    </Styled.MarketingModal>
  );
};

export default withMarketing(MarketingModal);
