import styled from "styled-components";

export const TraitsWrapper = styled.div`
  padding: 70px 0;
  position: relative;

  @media only screen and (min-width: 992px) {
    padding: 100px 0 170px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const TraitsHeading = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
`;

export const TraitPara = styled.p`
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  line-height: 180%;
  max-width: 620px;
  margin: 0 auto 50px;
  text-align: center;
`;

export const TraitsCanvas = styled.div`
  position: relative;

  &:after {
    content: "";
    width: 240px;
    height: 60px;
    background-color: rgba(132, 74, 255, 0.1);
    border-radius: 30px;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      width: 478px;
      bottom: -30px;
    }
  }
`;

export const TraitsContent = styled.div`
  background: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media only screen and (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const TraitItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 44px;
  text-align: center;

  &:nth-child(1) {
    & > div {
      background-color: #dbefff;
    }
  }
  &:nth-child(2) {
    & > div {
      background-color: #f5e0eb;
    }
  }
  &:nth-child(3) {
    & > div {
      background-color: #e8e5fe;
    }
  }
  &:nth-child(4) {
    & > div {
      background-color: rgba(255, 117, 92, 0.1);
    }
  }
`;

export const TraitsImgBox = styled.div`
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  padding: 16px;
`;

export const TraitsImg = styled.img`
  max-width: 100%;
`;

export const TraitTitle = styled.h3`
  font-size: 16px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
`;
