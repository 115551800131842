import { IProfileTalent, IProfileTalentType, ITalentLevelContext } from "state/profileSteps";
import * as yup from "yup";
import { currencyAndSymbol } from "@remotebase/components";
import awsConfig from "hooks/apollo/awsConfig";

export enum ContactForm {
  imageLink = "Profile Image",
  firstName = "first name",
  lastName = "last name",
  email = "email",
  phone = "phone",
}

export enum SocialForm {
  githubUrl = "githubUrl",
  linkedinUrl = "linkedinUrl",
  portfolioUrl = "portfolioUrl",
}
export enum SalaryForm {
  currentSalary = "Current Salary",
  expectedSalary = "Expected Salary",
}

export const nameRegex = /^[A-Za-z]*$/;
export const invalidName = "Name can not contain special characters, numbers or spaces";

export const ContactFormSchema = yup.object().shape({
  [ContactForm.imageLink]: yup.string().required().url(),
  [ContactForm.firstName]: yup.string().required().matches(nameRegex, invalidName),
  [ContactForm.lastName]: yup.string().required().matches(nameRegex, invalidName),
  [ContactForm.email]: yup.string().email().required(),
  [ContactForm.phone]: yup.string(),
});

export const SocialFormSchema = yup.object().shape({
  [SocialForm.githubUrl]: yup.string().url().label("Github URL").nullable().optional(),
  [SocialForm.linkedinUrl]: yup.string().url().label("Linkedin URL").nullable().optional(),
  [SocialForm.portfolioUrl]: yup.string().url().label("Portfolio URL").nullable().optional(),
});

export const SalaryFormSchema = yup.object().shape({
  [SalaryForm.currentSalary]: yup
    .number()
    .typeError("salary must be a number")
    .min(0, "salary cannot be negative")
    .required("salary is required"),
  [SalaryForm.expectedSalary]: yup
    .number()
    .typeError("salary must be a number")
    .min(0, "salary cannot be negative")
    .required("salary is required"),
});

export const pageChangeIndex = 1;
export const defaultProfileExperience = "0";
export const initialTalentContext: IProfileTalent = { isAvailable: true };

export const firstNamePlaceholder = "first name";
export const lastNamePlaceholder = "last name";

export const currencyCodes = Object.keys(currencyAndSymbol);

export enum SkillsNameMap {
  Graphql = "GraphQL",
  React = "React.js",
  Angular = "Angular",
  Vue = "Vue.js",
  NodeJs = "Node.js",
  PHP = "PHP",
  Oracle = "Oracle",
  DotNet = ".Net",
  Wordpress = "WordPress",
  Ror = "RoR",
  Python = "Python",
  Django = "Django",
  Javascript = "JavaScript",
  Typescript = "TypeScript",
  Csharp = "C#",
  CPlusPlus = "C++",
  Mysql = "MySQL",
  Postgres = "PostgreSQL",
  d3 = "D3.js",
  Aws = "AWS",
  MongoDb = "MongoDB",
  Docker = "docker",
  DevOps = "DevOps",
  Golang = "GO",
  Redux = "Redux",
  Express = "Express.js",
  ReactAndNode = "React.js + Node.js",
  ReactNative = "React Native",
  Java = "Java",
  Magento = "Magento",
  Amplify = "Amplify",
  Android = "Android",
  Flutter = "Flutter",
  IOS = "IOS",
}

export enum HeardFroms {
  Linkedin = "Linkedin",
  Facebook = "Facebook",
  Google = "Google",
  Hirefest = "Hirefest",
  TeeliVideo = "Teeli Video",
  MoorooVideo = "Mooroo Video",
  Youtube = "Youtube",
  ReachedByRecruiter = "Reached by Recruiter",
  Other = "Other",
}

// make key names of this enum same as value names of its corresponding enum in API.ts
export enum InterestsMap {
  Web = "Web",
  Mobile = "Mobile",
  SoftwareInteghration = "Software Integration",
  ML = "Machine Learning",
  Devops = "DevOps",
  ECommerce = "E-Commerce",
}

export const defaultDropdownValue = "Select";

export enum ProfileDetailsForm {
  englishProficiency = "englishProficiency",
  possibleYearsOfExperience = "experience",
  phoneNumber = "phone",
}

export enum ProfileInfoForm {
  firstName = "firstName",
  lastName = "lastName",
  isAvailable = "isAvailable",
  currentSalary = "currentSalary",
  expectedSalary = "expectedSalary",
}

export const firstNameLabel = "First Name";
export const lastNameLabel = "Last Name";
export const isAvailableLabel = "Availability";
export const currentSalaryLabel = "Current Salary";
export const expectedSalaryLabel = "Expected Salary";
export const perMonthLabel = "(per month)";
export const englishProficiencyLabel = "English proficiency";
export const possibleYearsOfExperienceLabel = "Years of experience";
export const phoneNumberLabel = "Phone";

export const defaultSalaryCountryCode = "US";
export const markAvailable = "available";

export const profilePhotoFileName = "dp.png";
export const profilePhotoType = "image/png";

export const lockConfirmationMessage = {
  title: "Expected salary update will be locked.",
  message: "You will be able to update this in 90 days.",
};

export const generalConfirmationMessage =
  "Expected salary update is locked. You will be able to update this in ";

export const contactSupport = "Contact support for further enquiry.";

export const expectedSalaryLockDuration = 90;

export const availbilityOptions = [
  { value: "available", label: "Available" },
  { value: "unavailable", label: "Not Available" },
];

export const currencyOptions = Object.keys(currencyAndSymbol).map((item) => ({
  value: item,
  label: item,
}));

export const cancelBtnText = "Cancel";

export const saveBtnText = "Save";

export interface ISubmitProfileInput {
  expectedVersion?: number;
  id?: string;
  isAvailable: boolean;
  currentSalary: string;
  expectedSalary?: string;
}

export interface IProfileInfoFormData {
  firstName: string;
  lastName: string;
  isAvailable: boolean;
  currentSalary: string;
  expectedSalary: string;
}

export const initialTalentTypeContext: IProfileTalentType = {
  talentList: [],
  selectedTalent: null,
};

export const initialTalentLevelContext: ITalentLevelContext = {
  activeStages: [],
  level: 1,
};

export const iconBucketName = `https://${awsConfig.awsAmplifyConfig.aws_user_files_s3_bucket.replace(
  "resumes",
  "images",
)}.s3.amazonaws.com`;

export const networkTechHeadline =
  "You have cleared the selection process and have proved to be a software development champion.";

export const networkNonTechHeadline =
  "You have cleared the selection process and are now a part of our Hiring Network.";
