/* eslint-disable max-len */
import { FC } from "react";
import { ILandingSkillsList } from "state/landing";
import { FBPixelCustomEvents } from "@remotebase/constants";
import { fetchUtmParams, UnAuthRoutes } from "utils";
import * as S from "./styles";
import { usePixelTrack } from "../../../../hooks";

type Props = {
  skill: ILandingSkillsList;
};

export const LandingSkillSingle: FC<Props> = ({ skill }) => {
  const { performAction } = usePixelTrack();

  return (
    <S.SkillsItem className="aos-item" data-aos={skill.dataAos}>
      <S.SignupLink
        onClick={(): void => performAction(FBPixelCustomEvents.DEVELOPER_TECH_CLICK, skill.name)}
        to={`${UnAuthRoutes.Register}?${fetchUtmParams()}`}
      >
        <S.SkillsPreview>
          <S.SkillsPhoto src={skill.logo} alt="" />
        </S.SkillsPreview>
        <S.SkillsVacancy>{skill.name}</S.SkillsVacancy>
        <S.SkillsText>{`We are Looking for expert ${skill.name} developers to join our team.`}</S.SkillsText>
        <S.SkillsLink>Apply Now</S.SkillsLink>
      </S.SignupLink>
    </S.SkillsItem>
  );
};

export default LandingSkillSingle;
