/* eslint-disable max-len */
import {
  WorkFigureInterface,
  FaqInterface,
  TeamListInterface,
  PerksListInterface,
} from "state/dashboard";

export const faqEventPrefix = "FAQ";

export const perksList: Array<PerksListInterface> = [
  {
    textOne: "Work directly with global startups",
    textTwo: "Mentored by industry experts",
    textThree: "Work on your own time",
  },
  {
    textOne: "Market competitive salaries",
    textTwo: "Insurance for you and your parents",
    textThree: "Fully remote with office optional",
  },
  {
    textOne: "Bi-annual increments",
    textTwo: "Insane learning and growth",
    textThree: "Bi-annual company retreats",
  },
];

export const teamList: Array<TeamListInterface> = [
  {
    name: "Numan Qamar",
    imgSrc: "/images/numan-qamar.png",
    storyLink:
      "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6826444765190492160?compact=1",
  },
  {
    name: "Hanan Mehmood",
    imgSrc: "/images/hanan-mehmood.png",
    storyLink:
      "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6826140629655937025?compact=1",
  },

  {
    name: "Faizan",
    imgSrc: "/images/faizan-ahmad.png",
    storyLink:
      "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6824951634158776320?compact=1",
  },
  {
    name: "Wajahat Zia",
    imgSrc: "/images/wajahat-zia.png",
    storyLink:
      "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6822872056493813761?compact=1",
  },
];

export const faqList: Array<FaqInterface> = [
  {
    question: "What is Remotebase?",
    answer: `Remotebase is a talent marketplace that hires and matches top global talent with international
    companies to work as their full-time remote employee.`,
    eventKey: "0",
  },
  {
    question: "Who are the clients of Remotebase?",
    answer: `Our clients include companies and startups emerging from Silicon Valley such as Placer,
    Neyborly, Capbase, FireFlies.ai and Atoms, just to name a few.`,
    eventKey: "1",
  },
  {
    question: "Is Remotebase fully remote?",
    answer: `Yes, Remotebase is a remote-first company, and our employees get the leverage to
    work from the comfort of their homes. However, we do have an office in Lahore,
     Pakistan, and multiple co-working spaces, such as Kickstart and Popcorn Studio,
     for those employees who like working in a professional environment. `,
    eventKey: "2",
  },
  {
    question: "What are the social and cultural values of Remotebase?",
    answer: `We try incorporating a culture similar to that of Silicon Valley-based companies
     i.e. maintaining a balanced, cooperative, and friendly environment
     where our people can maximize their growth and learning skills.`,
    eventKey: "3",
  },
  {
    question: "What benefits does Remotebase offer to its employees?",
    answer: `We offer unmatchable benefits to our employees,
     such as market competitive salaries, laptops, medical insurance (employee + family),
     paid leaves, gym allowance, learning opportunities, bi-annual increments,
      company retreats, etc.`,
    eventKey: "4",
  },
  {
    question: "Can I switch to another field?",
    answer: `Yes, we are more than willing to allow our people to grow their skill sets.
     In addition, when switching to another field, Remotebase will provide you with a
     mentor who will guide you in learning and getting familiar
     with the domain you wish to work in.`,
    eventKey: "5",
  },
  {
    question: "If I don’t get hired, can I apply again?",
    answer: `Definitely! We believe in creating and replicating opportunities
    while giving individuals the space to grow. If you do not get selected after
    going through the process, our HR team will reach out to you and will provide
     you with a detailed evaluation of your performance. You can adapt the
     feedback and work on your weak areas before applying again after three months.`,
    eventKey: "6",
  },
];

export const figureList: Array<WorkFigureInterface> = [
  {
    dataAos: "fade-left",
    dataAosDuration: "1500",
    dataScale: "1.3",
    dataOrientation: "up",
    src: "/images/work-cube-1.png",
  },
  {
    dataAos: "fade-up",
    dataAosDuration: "1500",
    dataScale: "1.5",
    dataOrientation: "up left",
    src: "/images/work-cube-2.png",
  },
  {
    dataAos: "fade-up",
    dataAosDuration: "1500",
    dataScale: "1.3",
    dataOrientation: "up right",
    src: "/images/work-cube-3.png",
  },
  {
    dataAos: "fade-up",
    src: "/images/work-cube-4.png",
  },
  {
    dataAos: "fade-down",
    dataAosDuration: "1500",
    dataScale: "1.1",
    dataOrientation: "left",
    src: "/images/work-cube-5.png",
  },
  {
    dataAos: "fade-left",
    dataAosDuration: "1500",
    dataScale: "1.2",
    dataOrientation: "right",
    src: "/images/work-cube-6.png",
  },
  {
    dataAos: "fade-right",
    dataAosDuration: "1500",
    dataScale: "1.2",
    dataOrientation: "down",
    src: "/images/work-cube-7.png",
  },
];

export const dashboardTagline = "Join the top 1% of the country’s leading talent pool.";

export const dashboardHeading = "Realise your future with us!";

export const dashboardText =
  "Becoming part of Remotebase extends further than just a job. We, at the company, aim to make your time with us oriented towards your professional success just as much as your personal progress. We provide opportunities for you to create innovative solutions for real-life startups that help them accelerate their growth while enabling yours";

export const perksText = "Why join Remotebase?";
