import { FC, ReactElement } from "react";
import { imageUrl } from "utils";
import * as Styled from "./styles";

interface Props {
  children: ReactElement<unknown>;
}
const ProfileStepper: FC<Props> = ({ children }) => {
  return (
    <Styled.InformationWrapper>
      <Styled.Logo>
        <img src={imageUrl.logo} />
      </Styled.Logo>
      <Styled.ClientFormMainQuestions>{children}</Styled.ClientFormMainQuestions>
    </Styled.InformationWrapper>
  );
};

export default ProfileStepper;
