import { FC, Fragment } from "react";
import * as Styled from "./styles";

export const JobsShimmer: FC = () => {
  return (
    <Fragment>
      <Styled.H3 />
      <Styled.paragraph />
      <Styled.paragraph />
      {[...Array(3)].map((e, i) => {
        return (
          <Styled.cardWrapper key={`item${i}`}>
            <Styled.innerWrapper>
              <Styled.companyLogo />
              <Fragment>
                <Styled.H5 />
                <Styled.nameLocation>
                  <Styled.span />
                </Styled.nameLocation>
                <Styled.companyDetail />
              </Fragment>
            </Styled.innerWrapper>
            <Styled.ViewMoreButton to="" />
          </Styled.cardWrapper>
        );
      })}
    </Fragment>
  );
};

export default JobsShimmer;
