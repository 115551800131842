import styled from "styled-components";

export const DeveloperWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;
  background-color: rgba(89, 194, 249, 0.1);

  @media screen and (min-width: 768px) {
    padding: 120px 0px 90px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const DevTop = styled.div`
  width: 100%;
  max-width: 508px;
`;

export const DevHeading = styled.h2`
  font-size: 24px;
  color: #000000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    font-size: 36px;
  }

  & span {
    color: red;
    font-size: 48px;
    line-height: 60px;
    padding: 0px 12px;
  }
`;

export const DevPara = styled.p`
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
`;

export const DevCarousel = styled.div`
  width: 100%;
  position: relative;

  & .dev__carousel {
    padding: 20px 0;

    & .devCarousel__ul {
      & .devCarousel__li {
        display: flex;

        &:first-child {
          @media screen and (min-width: 640px) {
            margin-left: -15px;
          }
        }
        &:last-child {
          @media screen and (min-width: 640px) {
            margin-right: -15px;
          }
        }
      }
    }

    & .react-multi-carousel-dot-list {
      & .react-multi-carousel-dot {
        & button {
          width: 12px;
          height: 12px;
          background-color: #c3e6f;
          border: none;
        }

        &--active {
          & button {
            background-color: #3c65fe;
            border: none;
          }
        }
      }
    }
  }
`;

export const DevBoxCol = styled.div`
  display: flex;

  @media screen and (min-width: 640px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const DevBox = styled.div`
  width: 100%;
  position: relative;
  padding: 27px 24px;
  border: 1px solid #3615bd;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.35s;

  &:hover {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-color: #fff;
  }

  @media screen and (min-width: 768px) {
    padding: 27px 40px;
  }
`;

export const DevBoxTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const DevImgCanvas = styled.div`
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
`;

export const DevImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DevTopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DevName = styled.h3`
  font-size: 12px;
  color: #3615bd;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const DevJob = styled.h4`
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  line-height: 120%;
  margin: 0px;
  font-family: "Poppins", sans-serif;
`;

export const DevComment = styled.p`
  font-size: 16px;
  color: #14142b;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 24px;
`;

export const DevBtm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DevVideo = styled.div`
  display: flex;
  align-items: center;
`;

export const DevVideoBtn = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  background: #3615bd;
  overflow: hidden;
  cursor: pointer;

  & svg {
    width: 12px;
    height: 12px;

    & path {
      fill: #fff;
    }
  }
`;

export const DevVideoText = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  line-height: 24px;
`;

export const CardMediaIconRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CardMediaIcon = styled.a`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 10px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.35s;

  & svg {
    width: 10px;
    height: 10px;
    transition: all 0.35s;

    & path {
      fill: #3615bd;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: #3615bd;

    & svg {
      & path {
        fill: #fff;
      }
    }
  }
`;

export const ViewRevCardLink = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #3c65fe;
  margin-top: 30px;
  cursor: pointer;
`;
