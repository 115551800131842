export enum Sidebar {
  Overview = "Overview",
  Profile = "Profile",
  DomainTest = "Domain Test",
  HRInterview = "Video Screening",
  ProblemSolving = "Problem Solving",
  FinalInterview = "Final Interview",
  Make300 = "Make $300 🔥",
  Settings = "Settings",
  SignUp = "Sign up",
  CreateProfile = "Create Profile",
  Network = "Network",
  Jobs = "Jobs",
  CompleteProfile = "Complete Profile",
  FinishSignUp = "Finish sign-up",
  Chat = "Chat",
  // Bookings = "Bookings",
}

export enum SidebarStatusIcons {
  Tick = "tick",
  Normal = "normal",
  Lock = "lock",
  Cross = "cross",
}

export const sideBarLogoWhiteAltText = "remotebase_white_logo";
export const sideBarLogoBlackAltText = "remotebase_black_logo";
