// eslint-disable-next-line import/prefer-default-export
export const imageUrl = {
  newRBLogo: "/images/new-remotebase-logo.png",
  logoRemotebase: "/images/logo-remotebase.png",
  logoRemotebaseWhiteTxt: "/images/logo-white.png",
  loginPeopleWorking: "/images/login_people-working.png",
  signupPeopleWorking: "/images/signup_people-working.png",
  entryCircle: "/images/entry-circle.svg",
  cube: "/images/cube.png",
  notepad: "/images/purple_notepad.png",
  waitlistAdd: "/images/waitlist_add.png",
  sidebarPic: "/images/sidebar-pic.svg",
  logoReact: "/images/react-logo.svg",
  logoReactNode: "/images/react-node-logo.svg",
  logoJobsReact: "/images/landing/react.png",
  logoNode: "/images/nodejs-logo.svg",
  logoJobsNode: "/images/landing/js.png",
  logoAngular: "/images/angular-logo.svg",
  logoPython: "/images/python-logo.svg",
  logoJobsPython: "/images/landing/python.png",
  logoVue: "/images/vuejs-logo.svg",
  logoGraphql: "/images/graphql.png",
  logoRecruiter: "/images/techRecruiter.png",
  logoUIUX: "/images/uiux.png",
  logoPerformanceMarketer: "/images/performanceMarketer.png",
  logoSDR: "/images/sdr.png",
  logoJavasctipt: "/images/javascript-logo.svg",
  logoRails: "/images/rails-logo.svg",
  logoJobsRails: "/images/landing/rubyOnRails.png",
  logoPHP: "/images/php-logo.svg",
  logoOracle: "/images/oracle-logo.svg",
  logoGCP: "/images/gcp-logo.svg",
  logo: "/images/logo.svg",
  landingMainArrowDown: "/images/landing/ArrowDown.svg",
  landingArrowRight: "/images/landing/ArrowRight.svg",
  landingTeam: "/images/landing/team-img.jpg",
  landingTalentPic: "/images/landing/talent-pic-1.png",
  landingTalentPic2: "/images/landing/talent-pic-2.png",
  landingTalentPic3: "/images/landing/talent-pic-3.png",
  landingTalentPic4: "/images/landing/talent-pic-4.png",
  landingTalentFig: "/images/landing/talent-figure.svg",
  growthMindset: "/images/landing/growth-mindset.svg",
  solveProblems: "/images/landing/solve-problems.svg",
  briefcase: "/images/landing/briefcase.svg",
  commSkills: "/images/landing/communication-skills.svg",
  noSignup: "/images/referral-inbox.svg",
  logoDotnet: "/images/dotnet-icon.svg",
  logoIOS: "/images/ios-logo.svg",
  logoAndroid: "/images/android-logo.svg",
  logoDrupal: "/images/drupal-logo.png",
  logoGo: "/images/golang-logo.svg",
  logoDJango: "/images/django-logo.svg",
  logoAws: "/images/amazon-web-services-logo.svg",
  logoFlutter: "/images/flutter-logo.svg",
  google: "/images/google.svg",
  marketing: "/images/marketing.png",
  reactNative: "/images/react-native.svg",
  devOps: "/images/dev-ops-logo.svg",
  azureDevops: "/images/azure-devops.svg",
  java: "/images/java-logo.svg",
  logoDataScience: "/images/data-science-logo.svg",
  folder: "/images/folder-icon.svg",
  logoMagento: "/images/magento.svg",
  heroImageFirst: "/images/landing/hero-img-female.svg",
  heroImageSecond: "/images/landing/hero-img-male.svg",
  infoBg: "/images/landing/info-bg.png",
  processImgFirst: "/images/landing/process-1.png",
  processImgSecond: "/images/landing/process-2.png",
  processImgThird: "/images/landing/process-3.png",
  processImgForth: "/images/landing/process-4.png",
  devImg: "/images/landing/dev-img.png",
  getStartedImg: "/images/landing/getStarted-img.png",
  getOvalFirst: "/images/landing/get-oval-1.svg",
  getOvalSecond: "/images/landing/get-oval-2.svg",
  javaImg: "/images/landing/java.svg",
  dev1Img: "/images/landing/image5.jpeg",
  dev2Img: "/images/landing/image20.jpeg",
  dev3Img: "/images/landing/image6.jpeg",
  dev4Img: "/images/landing/image16.jpeg",
  dev5Img: "/images/landing/image10.png",
  valueImg: "/images/landing/value-img.png",
  noCurrentUserImg: "images/purple_folder.png",
  noUserListImg: "images/message-img.png",
  darkPurpleCubeImg: "/images/dark-purple-cube.png",
  darkPurpleSphereImg: "images/dark-purple-sphere.png",
  logoDefault: "/images/default-logo.svg",
  logoNonTech: "/images/non-tech-logo.svg",
};
