import styled from "styled-components";

export const animate = `
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
  background-size: 1000px 100%;

  @keyframes shimmer {
    0% { background-position: -1000px 0;}
    100% {background-position: 1000px 0;}
  }
`;

const animateDark = `
  animation: shimmer 4s infinite linear;
    background: linear-gradient(to right, #e8ecef 4%, #e2e2e2 25%, #e8ecef 36%);
    background-size: 1000px 100%;

  @keyframes shimmer {
    0% { background-position: -1000px 0;}
    100% {background-position: 1000px 0;}
  }
`;

const shimmerStyle = `
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
`;

export const ShimmersPage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShimmerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;

export const ShimmerTitle = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 92px;
  height: 18px;
`;

export const ShimmerBtn = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 97px;
  height: 45px;
`;

export const ShimmerEdComponent = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  margin-bottom: 48px;
`;

export const ShimmerEd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 14px 84px rgb(222 219 219 / 25%);
  border-radius: 8px;
  padding: 34px 40px 34px 48px;
  min-height: 144px;
`;

export const ShimmerEdContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShimmerEdTitle = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 207px;
  height: 22px;
  margin-bottom: 10px;
`;

export const ShimmerEdPara = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 190px;
  height: 17px;
  margin-bottom: 10px;
`;

export const ShimmerEdParaSm = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 190px;
  height: 15px;
`;

export const ShimmerEdBtnCanvas = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;
  height: 27px;
  margin-right: 6px;
`;

export const ShimmerEdBtn = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 20px;
  height: 20px;
`;

export const ShimmerExpComponent = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  margin-bottom: 48px;
`;

export const ShimmerExp = styled.div`
  display: flex;
  grid-gap: 20px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 14px 84px rgb(222 219 219 / 25%);
  border-radius: 8px;
  padding: 34px 40px 34px 48px;
`;

export const ShimmerExpContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 595px;
`;

export const ShimmerExpTitle = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  max-width: 480px;
  height: 22px;
  margin-bottom: 2px;
`;

export const ShimmerExpPara = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  max-width: 220px;
  height: 18px;
  margin-top: 2px;
  margin-bottom: 20px;
`;

export const ShimmerExpParaSmCanvas = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  margin-bottom: 24px;
`;

export const ShimmerExpParaSm = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  height: 12px;
`;

export const ShimmerExpList = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

export const ShimmerExpItemCanvas = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
`;

export const ShimmerExpItem = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100%;
  max-width: 440px;
  height: 12px;
`;

export const ShimmerLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShimmerLinksTitle = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 150px;
  height: 18px;
  margin-bottom: 52px;
`;

export const ShimmerLinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
`;

export const ShimmerLinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const ShimmerLinksLabel = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 140px;
  height: 12px;
`;

export const ShimmerLinksInput = styled.div`
  ${shimmerStyle}
  ${animate}
  border-radius: 6px;
  width: 100%;
  height: 52px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
`;

export const ShimmerLinksInputText = styled.div`
  ${shimmerStyle}
  ${animateDark}
  width: 180px;
  height: 22px;
`;
