import styled from "styled-components";

export const PurpleFont = styled.span`
  color: #4c40f7;
`;

export const TellUsMoreBtn = styled.button`
  opacity: 1;
  position: relative;
  background: #4c40f7;
  color: #ffffff;
  font-size: 14px;
  background: #ff2c2c;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  transition: all 0.2s;
  color: white !important;
  font-weight: 700;
  border: none;
  min-height: 48px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  .disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  .disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  color: #ffffff;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;
