import { Sidebar } from "utils";
import { IStatusBlock } from "./statusBlock.interface";

export const statusBlocksList: Array<IStatusBlock> = [
  {
    path: "/tests",
    text: Sidebar.DomainTest,
    color: "#FF4367",
  },
  {
    path: "/hrScreening",
    text: Sidebar.HRInterview,
    color: "#FFAF2B",
  },
  {
    path: "/problemSolving",
    text: Sidebar.ProblemSolving,
    color: "#0891FF",
  },
  {
    path: "/finalTechInterview",
    text: Sidebar.FinalInterview,
    color: "#53D5BB",
  },
  {
    path: "/network",
    text: Sidebar.Network,
    color: "#6C5DD3",
  },
];

export default statusBlocksList;
