import { range } from "lodash";
import { useMemo, FC } from "react";
import { Pagination } from "react-bootstrap";
import ShouldRender from "../shouldRender";
import * as Styled from "./styles";

type Props = {
  totalPages: number;
  currentPage: number;
  startingPage: number;
  pageLimit: number;
  hidePagination: boolean;
  changePage: (changedVal: number) => void;
  jumpToPageClick: (num: number) => void;
};

export const PaginationComponent: FC<Props> = ({
  startingPage,
  currentPage,
  totalPages,
  pageLimit,
  changePage,
  jumpToPageClick,
  hidePagination,
}) => {
  const nextPage = (): void => changePage(1);
  const prevPage = (): void => changePage(-1);

  const pages = useMemo(() => {
    const pageArray = [] as JSX.Element[];
    range(startingPage, startingPage + pageLimit).forEach((num) => {
      pageArray.push(
        <Pagination.Item
          className="paginationNos"
          active={num === currentPage}
          key={num}
          onClick={(): void => jumpToPageClick(num)}
        >
          {num + 1}
        </Pagination.Item>,
      );
    });
    return pageArray;
  }, [totalPages, currentPage]);

  return (
    <ShouldRender if={!hidePagination}>
      <Styled.Wrapper>
        <Styled.Pagination>
          <Pagination>
            <Pagination.Item onClick={prevPage} disabled={currentPage === 0}>
              Prev
            </Pagination.Item>
            {pages}
            <Pagination.Item onClick={nextPage} disabled={currentPage === totalPages - 1}>
              Next
            </Pagination.Item>
          </Pagination>
        </Styled.Pagination>
      </Styled.Wrapper>
    </ShouldRender>
  );
};

export default PaginationComponent;
