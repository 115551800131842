import styled from "styled-components";

export const PurpleFont = styled.span`
  color: #4c40f7;
`;

export const NextBtn = styled.button`
  opacity: 1;
  position: relative;
  font-size: 14px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  transition: all 0.2s;
  color: #ffffff !important;
  font-weight: 700;
  border: none;
  min-height: 48px;
  min-width: 74px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  .disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  .disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;

export const ClientFormError = styled.div`
  padding: 5px 15px 5px 5px;
  background: #ffb600;
  border-radius: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  font-weight: 500;
  img {
    margin-right: 12px;
  }
`;

export const ProfilePhotoCover = styled.div`
  padding: 3rem 0;
`;

export const CustomError = styled.p`
  color: red !important;
  margin-bottom: 10px !important;
  font-size: 0.8rem !important;
`;
