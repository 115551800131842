import styled from "styled-components";

export const FeedbackContainer = styled.div`
  display: block;
  padding-left: 1.5rem;
  padding-right: 4.5rem;
`;

export const FeedbackContent = styled.div`
  margin-top: 1.75rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  font-family: Roboto;

  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const FeedbackComment = styled.p`
  margin-bottom: 0;
  margin-top: 0.5625rem;
  color: #717171;
  font-size: 0.875rem;
  font-family: Poppins;
  line-height: 1.4;

  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
`;
