import { FC, useMemo, useState } from "react";
import { ShouldRender } from "@remotebase/components";
import { CalendarBooking } from "@remotebase/amplify-constants/API";
import { TabOptions } from "utils";
import DisplayMeetingTime from "./MeetingTime";
import MeetingLinkComponent from "./MeetingLink";
import ButtonBasedOnTab from "./ButtonBasedOnTab";
import * as S from "../styled";
import Feedback from "./Feedback";

interface IMeetingCard {
  tab: string;
  meetingDetails: CalendarBooking;
}

export const MeetingCard: FC<IMeetingCard> = ({ tab, meetingDetails }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const isPending = useMemo(() => {
    return tab === TabOptions.Recent && !meetingDetails.meetingLink;
  }, [tab, meetingDetails]);

  const noFeedback = useMemo(() => {
    return tab === TabOptions.Completed && !meetingDetails.feedback;
  }, [tab, meetingDetails]);

  const toggleFeedback = (): void => {
    setShowFeedback(!showFeedback);
  };

  return (
    <S.MeetingCardWrap>
      <S.MeetingDetailsWrap>
        <S.LeftSection>
          <S.LogoWrapper>
            <S.CompanyLogo src={meetingDetails?.client?.company?.logo} alt="" />
          </S.LogoWrapper>
          <S.TextContent>
            <S.ContentLine>
              <S.CompanyName>{meetingDetails?.client?.company?.name}.</S.CompanyName>
              <S.CompanyInvite> has just invited you for an Interview</S.CompanyInvite>
            </S.ContentLine>
            <S.MeetingLinkLine>
              <ShouldRender if={meetingDetails?.startTime}>
                <DisplayMeetingTime meetingDetails={meetingDetails} tab={tab} />
              </ShouldRender>
              <ShouldRender if={TabOptions.Recent === tab && meetingDetails?.meetingLink}>
                <MeetingLinkComponent meetingDetails={meetingDetails} />
              </ShouldRender>
            </S.MeetingLinkLine>
          </S.TextContent>
        </S.LeftSection>
        <S.RightSection isFlex={isPending || noFeedback}>
          <ShouldRender if={isPending}>
            <S.PendingText>Pending Booking</S.PendingText>
          </ShouldRender>
          <ShouldRender if={noFeedback}>
            <S.NoFeedbackText>Awaiting Feedback</S.NoFeedbackText>
          </ShouldRender>
          <ButtonBasedOnTab
            meetingDetails={meetingDetails}
            tab={tab}
            showFeedback={showFeedback}
            feedbackHandler={toggleFeedback}
          />
        </S.RightSection>
      </S.MeetingDetailsWrap>
      {showFeedback && <Feedback meetingDetails={meetingDetails} />}
      <S.HorizontalDivider />
    </S.MeetingCardWrap>
  );
};

export default MeetingCard;
