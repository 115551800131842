/* eslint-disable @typescript-eslint/no-explicit-any */
import { Talent } from "@remotebase/amplify-constants/API";
import { FC, Fragment, useEffect, useState } from "react";
import { CheckIcon, CrossIcon, DomainPendingIcon } from "../../assets";
import { EditToolTip } from "../../modal";
import {
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "../../utils";
import { TalentUpdateProps } from "../../../web/src/hooks/types";
import { ShouldRender } from "../../shouldRender";
import * as Styled from "./styles";

interface ITalentInterests {
  data: Talent | null;
  selfEdit?: boolean;
  talentUpdateProps?: TalentUpdateProps;
  setTalentData?: React.Dispatch<any>;
}
export const TalentInterests: FC<ITalentInterests> = ({
  data,
  selfEdit,
  talentUpdateProps,
  setTalentData,
}) => {
  const { profileData, updateProfile, isLoading, refetchProfile, showError } =
    talentUpdateProps || {};
  const initialState = data?.profile?.interests || [];
  const [editMode, setEditMode] = useState(false);
  const [userInterests, setUserInterests] = useState<Array<string | null>>(initialState);
  const { interests, talentType } = data?.profile || {};
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleEditClick = (): void => setEditMode(true);
  const handleCloseEdit = (): void => setEditMode(false);
  const updateInterests = (val: string): void => {
    if (userInterests.includes(val)) {
      const updatedVal = userInterests.filter((item) => item !== val);
      setUserInterests(updatedVal);
    } else {
      setUserInterests((prevState) => [...prevState, val]);
    }
  };
  const handleSave = (): void => {
    setIsSubmitted(true);
    if (updateProfile) {
      updateProfile({
        variables: {
          input: {
            expectedVersion: data?.profile?.version,
            id: data?.profile?.id,
            interests: userInterests,
          },
        },
        onCompleted: () => {
          showError?.({ title: submissionSuccessTitle, message: submissionProfileSuccessMessage });
        },
        onError: () => {
          showError?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
        },
      });
    }
  };
  useEffect(() => {
    if (profileData && data && isSubmitted) {
      if (setTalentData && refetchProfile) {
        setIsSubmitted(false);
        setTalentData({ ...data, profile: profileData });
        refetchProfile();
      }
      handleCloseEdit();
    }
  }, [data, profileData, isSubmitted]);
  const talentInterest = talentType?.interests;
  const interestsFiltered = interests?.filter((interest) =>
    talentInterest?.find((item) => item?.name === interest),
  );
  return (
    <ShouldRender if={interestsFiltered && interestsFiltered.length > 0}>
      <Styled.InterestContainer>
        <ShouldRender if={editMode}>
          <Styled.BtnGroup>
            <Styled.SaveButton onClick={handleCloseEdit}>
              <CrossIcon />
            </Styled.SaveButton>
            <ShouldRender if={isLoading}>
              <Styled.SaveButton>
                <DomainPendingIcon />
              </Styled.SaveButton>
            </ShouldRender>
            <ShouldRender if={!isLoading}>
              <Styled.SaveButton onClick={handleSave}>
                <CheckIcon />
              </Styled.SaveButton>
            </ShouldRender>
          </Styled.BtnGroup>
        </ShouldRender>
        <Styled.HeaderSection>
          <Styled.InterestTitle>Interests</Styled.InterestTitle>
          <ShouldRender if={selfEdit && !editMode}>
            <EditToolTip handleEditClick={handleEditClick} />
          </ShouldRender>
        </Styled.HeaderSection>
        <ShouldRender if={!editMode}>
          <Styled.InterestTagsContainer>
            {(interestsFiltered || []).map((skill: string | null) => (
              <Styled.InterestTags key={skill}>
                {talentInterest?.find((item) => item?.name === skill)?.title}
              </Styled.InterestTags>
            ))}
          </Styled.InterestTagsContainer>
        </ShouldRender>
        <ShouldRender if={editMode}>
          <Fragment>
            <Styled.EditTagsContainer>
              {talentInterest?.map((item, index) => (
                <Styled.InterestTags
                  isEditing
                  isSelected={userInterests.includes(`${item?.name}`)}
                  key={index}
                  onClick={(): void => updateInterests(item?.name || " ")}
                >
                  {talentInterest[index]?.title}
                </Styled.InterestTags>
              ))}
            </Styled.EditTagsContainer>
          </Fragment>
        </ShouldRender>
      </Styled.InterestContainer>
    </ShouldRender>
  );
};
export default TalentInterests;
