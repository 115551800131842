import React from "react";
import { Card, Accordion, Button } from "react-bootstrap";
import { FaqInterface } from "state/dashboard";
import { answerPrefix, faqEventPrefix, questionPrefix } from "utils";
import * as Styled from "./styles";

type Props = {
  question: FaqInterface;
  isActive: boolean;
  setActive: (e: string) => void;
};

const FaqSingleList: React.FC<Props> = ({ question, isActive, setActive }) => {
  const handleClick: () => void = () => {
    setActive(question.eventKey);
  };

  return (
    <Styled.CARD_FAQ className={isActive ? "active" : ""}>
      <Card.Header data-testid={`${questionPrefix}-${question.eventKey}`} onClick={handleClick}>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={`${faqEventPrefix}-${question.eventKey}`}
        >
          {question.question}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse
        data-testid={`${answerPrefix}-${question.eventKey}`}
        eventKey={`${faqEventPrefix}-${question.eventKey}`}
      >
        <Card.Body>
          <p>{question.answer}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Styled.CARD_FAQ>
  );
};

export default FaqSingleList;
