export const HrScreenInstructions: string[] = [
  "Please sit in a well-lit room.",
  "Please ensure that your device has a stable <b>internet</b> connection and <b>power</b> supply",
  "Please check that your <b>camera</b> and <b>microphone</b> are working properly.",
  "The interview duration is 10 minutes. Please complete it in one go.",
  // eslint-disable-next-line max-len
  "After answering the final question, press <b>Submit</b>. Please wait until the video is uploaded completely.",
  "Please don't refresh the page at any point, as you will lose your progress.",
];

export const HrScreenTips: string[] = [
  "Identify your core competencies keeping in mind the role that you are applying for",
  "For behavioural questions, you can cite examples from your professional life or your academic life.",
  "Look up the company on LinkedIn and other places before the interview",
  "Keep your answers concise, relevant and authentic",
];

export const HrTechHeading =
  "Congratulations on clearing your Domain Test. Next up is Recorded Video Screening Interview.";

export const HrNonTechHeading =
  "In the Video Screening Interview, you will be asked a series of questions," +
  "which you have to answer in a recorded video. Its purpose is to know more" +
  "about you, including your professional journey and future career plans to match the right jobs for you.";

export const interviewRecorded = "Interview Completed";
