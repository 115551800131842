import styled from "styled-components";
import { imageUrl } from "utils";
import { Link } from "react-router-dom";

export const GetStartedWrapper = styled.div`
  background-color: #3615bd;
  min-height: 400px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const GetMain = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const GetImgCanvas = styled.div`
  width: 260px;
  position: relative;
  margin: -128px auto 48px;
  display: inline-block;

  @media screen and (min-width: 768px) {
    width: 460px;
    margin: -178px auto 48px;
  }

  @media only screen and (min-width: 1024px) {
    margin: -178px 0 48px;
  }
`;

export const GetImg = styled.img`
  max-width: 100%;
  pointer-events: none;
`;

export const GetOvalFirst = styled.div`
  width: 82px;
  height: 90px;
  background-image: url(${imageUrl.getOvalFirst});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 132px;
  left: -25px;

  @media screen and (min-width: 768px) {
    width: 122px;
    height: 132px;
    top: 158px;
  }
`;

export const GetStackImg = styled.img`
  width: 38px;
  margin: -2px 0 0 -12px;
  pointer-events: none;

  @media screen and (min-width: 768px) {
    width: 55px;
    margin: -4px 0 0 -26px;
  }
`;

export const GetOvalSecond = styled.img`
  width: 68px;
  position: absolute;
  bottom: 0px;
  right: 54px;

  @media screen and (min-width: 768px) {
    width: 92px;
    bottom: -5px;
    right: 117px;
  }
`;

export const OverlayImg = styled.div`
  background: linear-gradient(144.49deg, #f4a38b -15.77%, rgba(247, 193, 178, 0) 122.67%);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -1px;
  right: 40px;
  max-width: 135px;

  @media screen and (min-width: 768px) {
    bottom: 26px;
    right: 76px;
    max-width: 178px;
    padding: 20px 28px;
  }
`;

export const OverlayHeading = styled.h2`
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 4px;

  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
`;

export const OverlayText = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 0;
`;

export const GetContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0 50px;

  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
    margin-bottom: auto;
  }

  @media only screen and (min-width: 1200px) {
    padding-left: 64px;
  }
`;

export const GetHeading = styled.h2`
  font-size: 32px;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
    margin-bottom: 50px;
    text-align: left;
  }
`;

export const RegisterBtnCanvas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 20px;
  width: 100%;

  @media screen and (min-width: 576px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const RegisterBtn = styled(Link)`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  height: 60px;
  border-radius: 40px;
  background-color: #59c2f9;
  border: none;
  font-family: "Poppins", sans-serif;
  transition: all 0.35s;
  width: 100%;

  @media screen and (min-width: 576px) {
    width: auto;
  }

  &:hover {
    background-color: #1da2e7;
    border: none;
    box-shadow: none;
    text-decoration: none;
    color: #fff;
  }
`;

export const RegisterBtnIcon = styled.span`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-left: 10px;

  & svg {
    width: 10px;
    height: 10px;

    & path {
      fill: #3615bd;
    }
  }
`;

export const ContactUsBtn = styled.a`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  height: 60px;
  border-radius: 40px;
  background-color: transparent;
  border: 1px solid #fff;
  font-family: "Poppins", sans-serif;
  transition: all 0.35s;
  width: 100%;

  @media screen and (min-width: 576px) {
    width: auto;
  }

  &:hover {
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: none;
    text-decoration: none;
    color: #3615bd;
  }
`;

export const TalentRightHalf = styled.div`
  position: relative;
  z-index: 3;
  max-width: 570px;
  margin-left: 602px;

  @media only screen and (max-width: 1279px) {
    margin-left: 52%;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const TalentH2 = styled.h2`
  font-weight: 700;
  letter-spacing: -0.5px;
  font-size: 64px;
  line-height: 1.125;
  margin-bottom: 52px;

  @media only screen and (max-width: 1279px) {
    font-size: 48px;
    line-height: 1.16667;
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 40px;
    line-height: 1.2;
  }
`;

export const TalentBtn = styled.button`
  background-color: transparent;
  color: #ffffff;
  position: relative;
  border: 0px solid #232340;
  min-width: 180px;
  height: 64px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:before {
    content: "";
    background: #232340;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 300%;
    border-radius: 50% 50% 0 0/75% 75% 0 0;
    -webkit-transition: height 0.35s;
    -o-transition: height 0.35s;
    transition: height 0.35s;
  }

  &:hover {
    color: #232340;
    border: 2px solid #232340;
    text-decoration: none;
    &:before {
      height: 0;
    }
  }

  @media only screen and (max-width: 1279px) {
    font-size: 16px;
    height: 56px;
  }
`;

export const TalentFigBox = styled.div`
  position: absolute;
  top: 48px;
  right: -64px;
  width: 490px;
  font-size: 0;

  @media only screen and (max-width: 1279px) {
    top: 24px;
    width: 358px;
  }

  @media only screen and (max-width: 1023px) {
    top: 60px;
    right: -30px;
    width: 300px;
  }

  @media only screen and (max-width: 767px) {
    top: auto;
    bottom: -30px;
    right: -32px;
    width: 270px;
  }
`;

export const TalentPinkisBgImg = styled.img`
  width: 100%;
`;
