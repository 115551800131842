import styled from "styled-components";
import { StyledProps } from "utils";

export const MeetingCardWrap = styled.div`
  display: block;
  width: 100%;
`;

export const MeetingDetailsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 4.5rem;
  margin: 1.75rem 0;

  @media (max-width: 1260px) {
    align-items: flex-start;
    gap: 0.5rem;
  }

  @media (max-width: 768px) {
    padding-right: 2.5rem;
  }

  @media (max-width: 600px) {
    display: block;
  }

  @media (max-width: 400px) {
    padding-right: 1rem;
    padding-left: 0.5rem;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex: 0.6;
  gap: 1.5rem;
  align-items: center;

  @media (max-width: 1260px) {
    flex: 1;
  }

  @media (max-width: 600px) {
    gap: 0.75rem;
  }
`;

export const RightSection = styled.div<{
  isFlex?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props): StyledProps => (props.isFlex ? "space-between" : "flex-end")};
  flex: 0.4;

  @media (max-width: 1260px) {
    flex: 0;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-end;
  }

  @media (max-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 400px) {
    gap: 0.5rem;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 50%;
  background-color: #e8e8e8;

  @media (max-width: 900px) {
    width: 40px;
    height: 34px;
  }
`;

export const CompanyLogo = styled.img`
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 900px) {
    width: 18px;
    height: 18px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 0.3rem;
  font-family: Poppins;

  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const ContentLine = styled.div`
  display: block;
`;

export const MeetingLinkLine = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;

  @media (max-width: 1316px) {
    display: block;
  }
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
`;

export const CompanyName = styled.span`
  font-weight: 700;
  margin-bottom: 0;
`;

export const CompanyInvite = styled.span`
  font-weight: 400;
  margin-bottom: 0;
`;

export const PendingText = styled.p`
  font-size: 0.75rem;
  padding: 0.3rem 0.9rem;
  margin-bottom: 0;
  color: #da9c1f;
  background-color: rgba(234, 170, 40, 0.1);
  border-radius: 1.25rem;

  @media (max-width: 600px) {
    padding: 0.2rem 0.6rem;
    font-size: 0.65rem;
  }
`;

export const NoFeedbackText = styled.p`
  font-size: 0.75rem;
  padding: 0.3rem 0.9rem;
  margin-bottom: 0;
  color: #0c2556;
  background-color: rgba(137, 137, 137, 0.1);
  border-radius: 1.25rem;

  @media (max-width: 600px) {
    padding: 0.2rem 0.6rem;
    font-size: 0.65rem;
  }
`;
