/* eslint-disable max-len */
import { imageUrl } from "utils";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DeveloperCardBox from "./DeveloperCardBox";
import * as S from "./styles";
import BtnGroup from "./btnGroup";

export type DevProps = {
  devImg: string;
  devName: string;
  devJob: string;
  devSummary: string;
};

export const devList: DevProps[] = [
  {
    devImg: imageUrl.dev2Img,
    devName: "Lutf",
    devJob: "Python Developer",
    devSummary:
      "It's been five years since I joined Remotebase. I started off as a junior mobile application developer, and today, Remotebase has nourished me into a person who the company could trust with anything at any time. I'm happy that I'm still working with people who embrace me and my skills and treat me like a family from day one.",
  },
  {
    devImg: imageUrl.dev3Img,
    devName: "Hibba",
    devJob: "Talent Acquisition Manager",
    devSummary:
      "I joined Remotebase 3.5 years ago, and it's been an incredible journey so far. I have worked in different leading HR roles, involved in direct projects with the founders. I really appreciate Remotebase's people-centric culture, which made me feel extremely valued and allowed me to grow immensely over the years.",
  },
  {
    devImg: imageUrl.dev4Img,
    devName: "Sami",
    devJob: "Full Stack Developer",
    devSummary:
      "Remotebase has helped me polish my developer skills by giving me excellent mentorship from industry experts. I have grown so much as a developer after joining Remotebase. They allowed me to work on different projects, which made my work more exciting. With the amazing work flexibility that they offer, I'm enjoying the best of both worlds.",
  },
];

const LandingDev = (): JSX.Element => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 640 },
      items: 2,
    },
    mobileSm: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  };
  return (
    <S.DeveloperWrapper>
      <S.Container>
        <S.DevTop>
          <S.DevHeading className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            Talent <span>&hearts;</span> remotebase
          </S.DevHeading>
          <S.DevPara className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            Read remotebase.com reviews from talent across the world and learn what it’s like
            working with top Silicon Valley firms.
          </S.DevPara>
        </S.DevTop>
        <S.DevCarousel>
          <Carousel
            responsive={responsive}
            arrows={false}
            sliderClass="devCarousel__ul"
            itemClass="devCarousel__li"
            containerClass="dev__carousel"
            renderButtonGroupOutside={true}
            customButtonGroup={<BtnGroup />}
          >
            {devList?.map((dev: DevProps, index: number) => (
              <DeveloperCardBox
                key={index}
                devImg={dev.devImg}
                devName={dev.devName}
                devJob={dev.devJob}
                devSummary={dev.devSummary}
              />
            ))}
          </Carousel>
        </S.DevCarousel>
        {/* <S.ViewRevCardLink>View all reviews</S.ViewRevCardLink> */}
      </S.Container>
    </S.DeveloperWrapper>
  );
};

export default LandingDev;
