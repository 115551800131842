import styled from "styled-components";
import { Link } from "react-router-dom";

export const Footer = styled.footer`
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const FooterBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  border-bottom: 1px solid #d8d8d8;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FooterLogoBox = styled(Link)`
  display: inline-flex;
  width: 186px;
`;

export const FooterLogoImg = styled.img`
  max-width: 100%;
`;

export const FooterMenu = styled.div`
  display: flex;
  margin: 24px 0;

  @media only screen and (min-width: 768px) {
    justify-content: center;
    margin: 0;
  }
`;

export const FooterMenuLink = styled.a`
  font-size: 14px;
  color: #000;
  font-weight: 400;
  line-height: 170%;
  transition: color 0.25s;

  &:hover {
    color: #5468e7;
    text-decoration: none;
  }
  &:not(:last-child) {
    margin: 0 10px;

    @media only screen and (min-width: 768px) {
      margin-right: 60px;
      margin-left: 0;
    }
  }
`;

export const CardMediaIconRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CardMediaIcon = styled(Link)`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 10px;
  overflow: hidden;
  background-color: #dbefff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.35s;

  @media only screen and (min-width: 768px) {
    margin-right: 0;
    margin-left: 20px;
  }

  & svg {
    width: 14px;
    height: 14px;
    transition: all 0.35s;

    & path {
      fill: #3615bd;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: #3615bd;

    & svg {
      & path {
        fill: #fff;
      }
    }
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FooterCopyright = styled.div`
  font-size: 12px;
  color: #14142b;
  font-weight: 400;
  line-height: 120%;
`;

export const FooterList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

export const FooterLink = styled(Link)`
  font-size: 12px;
  color: #14142b;
  font-weight: 400;
  line-height: 120%;
  margin: 0 10px;

  @media only screen and (min-width: 768px) {
    margin-left: 28px;
    margin-right: 0;
  }

  &:hover {
    color: #5468e7;
    text-decoration: none;
  }
`;
