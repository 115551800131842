import {
  ProfileProps,
  skillNonTechHeading,
  skillNonTechSubHeading,
  skillTechHeading,
  skillTechSubHeading,
} from "utils";
import { withProfile } from "state/profileSteps";
import { NoDomainTestPage, TestBlockList } from "components";
import { FC, useContext, useMemo } from "react";
import * as Styled from "./styles";
import DomainTestProvider, { domainTextContext } from "../../state/domainTestcontext/provider";

export const TestsPage: FC<ProfileProps> = ({ profileState: { techProfile } }) => {
  const getHeading = (): string => (techProfile ? skillTechHeading : skillNonTechHeading);
  const getSubHeading = (): string => (techProfile ? skillTechSubHeading : skillNonTechSubHeading);
  const { hasDomainTest } = useContext(domainTextContext);

  const content = useMemo(() => {
    if (!hasDomainTest) {
      return <NoDomainTestPage />;
    }
    return (
      <div>
        <Styled.H1>{getHeading()}</Styled.H1>
        <Styled.TestPara>{getSubHeading()}</Styled.TestPara>
        <Styled.TestGrid>
          <TestBlockList />
        </Styled.TestGrid>
      </div>
    );
  }, [hasDomainTest]);

  return content;
};

export default () => {
  const Page = withProfile(TestsPage);
  return (
    <DomainTestProvider>
      <Page />
    </DomainTestProvider>
  );
};
