import { Link } from "react-router-dom";
import styled from "styled-components";

export const InfoWrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1328px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const ValueComponent = styled.div`
  background-color: #f4f7ff;
  padding: 80px 0;

  @media only screen and (min-width: 992px) {
    padding: 145px 0;
  }
`;

export const ValueGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ValuePreview = styled.div`
  display: inline-flex;
  position: relative;
  margin: 0 auto auto;

  @media only screen and (min-width: 768px) {
    margin-top: -32px;
  }
`;

export const ValuePreviewImg = styled.div`
  width: 280px;
  height: 360px;
  position: relative;
  margin-left: -32px;

  @media only screen and (min-width: 576px) {
    margin-left: 0;
  }

  @media only screen and (min-width: 992px) {
    width: 320px;
    height: 410px;
  }

  @media only screen and (min-width: 1200px) {
    width: 400px;
    height: 512px;
  }
`;

export const HeroImgFemale = styled.img`
  max-width: 100%;
  object-fit: contain;
  pointer-events: none;
`;

export const ValuePreviewOverlay = styled.div`
  min-width: 100px;
  min-height: 88px;
  border-radius: 12px;
  padding: 16px 18px 10px 18px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(
    144.49deg,
    rgba(151, 71, 255, 0.3) -15.77%,
    rgba(60, 101, 254, 0) 122.67%
  );
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(20px);
  position: absolute;
  right: -28px;
  bottom: 60px;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    min-width: 190px;
    min-height: 96px;
    padding: 20px 28px;
    right: -28px;
    bottom: 60px;
  }
`;

export const ValuePreviewName = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #ffffff;
  margin-bottom: 6px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const ValuePreviewJob = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  color: #ffffff;
  display: inline-flex;

  @media only screen and (min-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 3px;
  }
`;

export const ValuePreviewWork = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  color: #ffffff;
  margin-bottom: 0;

  @media only screen and (min-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
export const ValueContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;

  @media only screen and (min-width: 992px) {
    max-width: 550px;
    margin-top: 0;
  }
`;

export const ValueCardLabel = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #3c65fe;
  margin-bottom: 15px;
`;

export const ValueCardHeading = styled.h2`
  font-weight: 600;
  font-size: 38px;
  line-height: 120%;
  color: #1c1b1f;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }

  & > span {
    color: #3c65fe;
  }
`;

export const ValueCardDescrip = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: #323232;
  font-family: "Roboto400", sans-serif;
  margin-bottom: 50px;
`;

export const AppNowBtnCanvas = styled.div`
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }
`;

export const AppNowBtn = styled(Link)`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  height: 60px;
  border-radius: 40px;
  background-color: #3615bd;
  border: none;
  font-family: "Poppins", sans-serif;
  transition: all 0.25s;

  &:hover {
    background-color: #1c0381;
    border: none;
    box-shadow: none;
    text-decoration: none;
    color: #fff;
  }
`;

export const AppNowBtnIcon = styled.span`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-left: 10px;

  & svg {
    width: 10px;
    height: 10px;

    & path {
      fill: #3615bd;
    }
  }
`;

export const PerksContent = styled.div`
  background-color: #fff;
  border-radius: 0px 0px 20px 20px;
  padding: 40px 24px 20px;

  @media only screen and (min-width: 768px) {
    padding: 60px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 92px 48px 40px 110px;
  }
`;

export const PerksHeading = styled.div`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 50px;
  }
`;

export const PerksSubHeading = styled.div`
  font-size: 14px;
  color: #000;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
    margin-bottom: 60px;
  }
`;
