import _ from "lodash";
import { FC, useState, useEffect } from "react";
import { Talent, EducationEntry, EducationEntryInput } from "@remotebase/amplify-constants/API";
import EducationForm from "../educationForm";
import ShouldRender from "../../../shouldRender";
import { DeleteConfirmationModal, ModalComponent } from "../../../modal";
import { TrashIcon, PencilIcon } from "../../../assets";
import {
  FormErrors,
  FormData,
  getinitialFormDataState,
  getinitialFormErrorsState,
  cancelBtnText,
  confirmBtnText,
  deleteEducationMessage,
  extractMonthAndYear,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "../../../utils";
import { TalentUpdateProps } from "../../../../web/src/hooks/types";
import * as S from "../styles";

interface Props {
  data: Talent | null;
  talentUpdateProps?: TalentUpdateProps;
  setTalentData?: React.Dispatch<any>;
}

export const EducationHistory: FC<Props> = ({ data, talentUpdateProps, setTalentData }) => {
  const { profile } = data || {};
  const {
    profileData,
    data: updateTalentData,
    updateProfile,
    refetchProfile,
    isLoading,
    showError: showMessage,
  } = talentUpdateProps || {};

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openModal, setModalVisibility] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<undefined | number>(undefined);
  const [formData, setFormData] = useState<FormData>(getinitialFormDataState());
  const [formErrors, setFormErrors] = useState<FormErrors>(getinitialFormErrorsState());
  const [educationHistory, setEducationHistory] = useState<EducationEntryInput[]>(() => {
    return _.map(
      profile?.educationHistory,
      (e) => _.pick(e, ["degree", "school", "startDate", "endDate"]) as EducationEntryInput,
    );
  });

  useEffect(() => {
    if (!isLoading) {
      setModalVisibility(false);
      setOpenDeleteModal(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (formData.isEdit) {
      setModalVisibility(true);
    }
  }, [formData]);

  useEffect(() => {
    if (updateTalentData && profileData && isSubmitted) {
      if (setTalentData && refetchProfile) {
        setIsSubmitted(false);
        setTalentData({ ...updateTalentData, profile: profileData });
        refetchProfile();
      }
    }
  }, [updateTalentData, profileData, isSubmitted]);

  const getStartAndEndDates = (): [string | null, string] => {
    const getMonthNumber = (monthName: string): string => {
      const monthNumber = (
        new Date(Date.parse(`${+monthName + 1} 1, 2012`)).getMonth() + 1
      ).toString();
      if (monthNumber.length > 1) {
        return monthNumber;
      }
      return `0${monthNumber}`;
    };
    const startDate = `${formData.startYear}-${getMonthNumber(formData.startMonth)}-01`;
    const endDate = `${formData.endYear}-${getMonthNumber(formData.endMonth)}-01`;
    return [startDate, endDate];
  };

  const deleteEducationEntry = (index?: number): void => {
    if (index !== undefined) {
      educationHistory.splice(index, 1);
      if (updateProfile) {
        updateProfile({
          variables: {
            input: {
              expectedVersion: data?.profile?.version,
              id: data?.profile?.id,
              educationHistory,
            },
          },
          onCompleted: () => {
            showMessage?.({
              title: submissionSuccessTitle,
              message: submissionProfileSuccessMessage,
            });
          },
          onError: () => {
            showMessage?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
          },
        });
      }
      setIsSubmitted(true);
      setEducationHistory(educationHistory);
      setModalVisibility(false);
    }
  };

  const getNewEducationHistory = (): EducationEntryInput[] => {
    const [startDate, endDate] = getStartAndEndDates();
    const educationEntry: EducationEntryInput = {
      startDate,
      endDate,
      degree: formData.degree,
      school: formData.school,
    };

    let newEducationHistory: EducationEntryInput[] = [];
    if (formData.isEdit) {
      const index = formData.selectedEducationIndex;
      educationHistory[index].endDate = endDate;
      educationHistory[index].startDate = startDate;
      educationHistory[index].degree = formData.degree;
      educationHistory[index].school = formData.school;
      newEducationHistory = educationHistory;
    } else {
      newEducationHistory = [...educationHistory, educationEntry];
    }
    return newEducationHistory;
  };

  const checkForFormErrors = (): boolean => {
    let doErrorsExist = false;
    const newFormErrors: FormErrors = getinitialFormErrorsState();

    if (!formData.degree) {
      newFormErrors.degree = "Degree is required";
      doErrorsExist = true;
    }
    if (!formData.school) {
      newFormErrors.school = "School is required";
      doErrorsExist = true;
    }
    if (!formData.endMonth) {
      newFormErrors.endMonth = "End month is required";
      doErrorsExist = true;
    }
    if (!formData.endYear) {
      newFormErrors.endYear = "End year is required";
      doErrorsExist = true;
    }
    if (!formData.startMonth || formData.startMonth?.length < 1) {
      newFormErrors.startMonth = "Start month is required";
      doErrorsExist = true;
    }
    if (!formData.startYear || formData.startYear?.length < 1) {
      newFormErrors.startYear = "Start year is required";
      doErrorsExist = true;
    }
    if (formData.startMonth && formData.startYear && formData.startYear > formData.endYear) {
      newFormErrors.startYear = "Start date must be less than end date";
    }
    if (doErrorsExist) {
      newFormErrors.doErrorsExist = true;
    }
    setFormErrors(newFormErrors);
    return doErrorsExist;
  };

  const handleNewEducationAddition = (): void => {
    setFormData(getinitialFormDataState());
    setModalVisibility(true);
  };

  const handleModalClose = (): void => {
    setFormErrors(getinitialFormErrorsState());
    setModalVisibility(false);
  };

  const handleDeleteIconClick = (index?: number): void => {
    setDeleteIndex(index);
    setOpenDeleteModal(true);
  };

  const closeDeleteModal = (): void => {
    setOpenDeleteModal(false);
    setDeleteIndex(undefined);
  };

  const handleFormSubmission = (): void => {
    const formValidationFailed = checkForFormErrors();
    if (formValidationFailed) {
      return;
    }
    const newEducationHistory = getNewEducationHistory();
    if (updateProfile) {
      updateProfile({
        variables: {
          input: {
            expectedVersion: data?.profile?.version,
            id: data?.profile?.id,
            educationHistory: newEducationHistory,
          },
        },
        onCompleted: () => {
          showMessage?.({
            title: submissionSuccessTitle,
            message: submissionProfileSuccessMessage,
          });
        },
        onError: () => {
          showMessage?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
        },
      });
    }
    setIsSubmitted(true);
    setEducationHistory(newEducationHistory);
  };

  const handleEducationEdit = (index: number): void => {
    const educationToEdit = educationHistory[index];
    const newFormData: FormData = getinitialFormDataState();

    newFormData.isEdit = true;
    newFormData.selectedEducationIndex = index;
    newFormData.degree = educationToEdit.degree;
    newFormData.school = educationToEdit.school;
    newFormData.endMonth = (new Date(educationToEdit.endDate).getMonth() + 1).toString();
    newFormData.endYear = new Date(educationToEdit.endDate).getFullYear().toString();
    newFormData.startMonth = educationToEdit.startDate
      ? (new Date(educationToEdit.startDate).getMonth() + 1).toString()
      : "";
    newFormData.startYear = educationToEdit.startDate
      ? new Date(educationToEdit.startDate).getFullYear().toString()
      : "";

    setFormData(newFormData);
    setModalVisibility(true);
  };

  return (
    <S.EducationHistoryCover>
      <S.HeadingSection>
        <S.HeadingTitle>Education</S.HeadingTitle>
        <S.AddNew onClick={handleNewEducationAddition}>Add New</S.AddNew>
      </S.HeadingSection>
      <S.EducationHistory>
        {profile?.educationHistory?.map((education: EducationEntry | null, index: number) => (
          <S.EducationCover key={index}>
            <S.RowContainer>
              <S.TitleLabel>{education?.degree}</S.TitleLabel>
              <S.IconGroup>
                <PencilIcon
                  width={20}
                  onClick={(): void => handleEducationEdit(index)}
                ></PencilIcon>
                <ShouldRender if={deleteIndex !== index}>
                  <TrashIcon
                    width={20}
                    onClick={(): void => handleDeleteIconClick(index)}
                  ></TrashIcon>
                </ShouldRender>
              </S.IconGroup>
            </S.RowContainer>
            <S.TextContent>{education?.school} &#9; &#9;</S.TextContent>
            <ShouldRender if={education?.startDate}>
              <S.TextContent>
                {extractMonthAndYear(education?.startDate)} -{" "}
                {extractMonthAndYear(education?.endDate)}
              </S.TextContent>
            </ShouldRender>
            <ShouldRender if={!education?.startDate}>
              <S.TextContent>{extractMonthAndYear(education?.endDate)}</S.TextContent>
            </ShouldRender>
          </S.EducationCover>
        ))}
      </S.EducationHistory>
      <ModalComponent show={openModal} handleClose={handleModalClose}>
        <EducationForm
          isLoading={isLoading}
          formData={formData}
          formErrors={formErrors}
          handleFormSubmission={handleFormSubmission}
          setFormData={setFormData}
          setFormErrors={setFormErrors}
          close={handleModalClose}
        />
      </ModalComponent>

      <ModalComponent show={openDeleteModal} handleClose={closeDeleteModal}>
        <DeleteConfirmationModal
          message={deleteEducationMessage}
          cancelBtnText={cancelBtnText}
          confirmBtnText={confirmBtnText}
          isLoading={isLoading}
          closeBtnHandler={closeDeleteModal}
          confirmBtnHandler={(): void => deleteEducationEntry(deleteIndex)}
        />
      </ModalComponent>
    </S.EducationHistoryCover>
  );
};

export default EducationHistory;
