import React from "react";
import MeetTeamList from "./MeetTeamList";
import * as Styled from "./styles";

const MeetTeam: React.FC = () => {
  return (
    <Styled.TEAM_BOX_ROW>
      <MeetTeamList />
    </Styled.TEAM_BOX_ROW>
  );
};

export default MeetTeam;
