/* eslint-disable max-len */
import { FC } from "react";
import * as S from "./styles";
import FAQCard from "./FAQCard";

export const faqList = [
  {
    eventKey: "0",
    number: "01",
    title: "What is Remotebase?",
    description:
      "Remotebase is a modern company that helps global talent to work remotely for Silicon Valley companies. We hire, provide training, and place talented individuals in job vacancies matching their skills and experience.",
  },
  {
    eventKey: "1",
    number: "02",
    title: "What type of talent does Remotebase hire?",
    description:
      "At Remotebase, we hire full-time remote talent for various tech and non-tech fields, including software developers, UI/UX designers, tech recruiters, performance marketers, etc.",
  },
  {
    eventKey: "2",
    number: "03",
    title: "What is the process of joining Remotebase?",
    description:
      "Sign up on our website and complete your profile. Submit a video HR screening interview followed by the skill test(s) and interview(s). You will be taken on board after you pass them.",
  },
  {
    eventKey: "3",
    number: "04",
    title: "How is Remotebase different from other recruiters?",
    description:
      "Remotebase doesn’t hunt for heads only or take in any other talent who comes our way. We only hire the top 1% of the candidates applying for remote jobs to ensure we get the best talent on board.",
  },
  {
    eventKey: "4",
    number: "05",
    title: "Do I need to be proficient in English to join Remotebase?",
    description:
      "Yes, every candidate needs to have good communication skills and be proficient in English because they will be working directly with top tech startups and international clients.",
  },
];

const LandingFaq: FC = () => {
  return (
    <S.FaqWrapper>
      <S.Container>
        <S.FaqTitleRow>
          <S.FaqTitle className="aos-item" data-aos="fade-up" data-aos-duration="1000">
            FAQs
          </S.FaqTitle>
          {/* <S.FaqViewLink className="aos-item" data-aos="fade-left" data-aos-duration="1000">
            View all FAQs
          </S.FaqViewLink> */}
        </S.FaqTitleRow>
        <S.HomepageAccordion>
          {faqList.map((faq, index) => (
            <FAQCard
              key={index}
              eventKey={faq.eventKey}
              number={faq.number}
              title={faq.title}
              description={faq.description}
            />
          ))}
        </S.HomepageAccordion>
      </S.Container>
    </S.FaqWrapper>
  );
};

export default LandingFaq;
