import { CognitoUser } from "@aws-amplify/auth";
import { useCallback, useContext, useState } from "react";
import {
  ApiCustomHookStateType,
  apiInitialState,
  SignPayloadType,
  ApiHookReturnType,
  getErrorResponse,
} from "hooks/utils";
import ErrorContext from "state/error/error.context";
import { IErrorContextType } from "@remotebase/constants";
import { signUpErrorHeading } from "utils";
import { updateErrorState } from "@remotebase/components";
import useUserCanSignUp from "hooks/query/useUserCanSignUp";
import useSignUp from "./useSignUp";

export const usePreSignUp = (): ApiHookReturnType<CognitoUser, SignPayloadType> => {
  const [res, setRes] = useState<ApiCustomHookStateType<CognitoUser>>(apiInitialState);
  const { setErrorState, errorState } = useContext<IErrorContextType>(ErrorContext);
  const {
    performAction: performSignUp,
    res: { isLoading: signupLoading },
  } = useSignUp();

  const { checkIfUserCanSignUp } = useUserCanSignUp();

  const performPreSignup = useCallback(
    async (payload: SignPayloadType): Promise<void> => {
      const { email } = payload;
      setRes({ ...apiInitialState, isLoading: true });
      try {
        const { data } = await checkIfUserCanSignUp({ variables: { email } });
        const { httpStatusCode, message } = data?.getUserCanSignUpStatus ?? {};

        if (httpStatusCode === 403) {
          throw new Error(message!);
        }
        if (httpStatusCode !== 200) {
          throw new Error(message || "Unable to complete request. Please try again");
        }

        // user can sign up
        performSignUp(payload);

        // set loading state for preSignUp to be false
        setRes({ ...res, isLoading: false });
      } catch (error) {
        const { message } = error;
        setRes(getErrorResponse(message));
        updateErrorState({ title: signUpErrorHeading, message }, setErrorState);
      }
    },
    [errorState],
  );

  return {
    res: { ...res, isLoading: res.isLoading || signupLoading },
    performAction: performPreSignup,
  };
};

export default usePreSignUp;
