import { FC, useMemo } from "react";
import { ShouldRender } from "@remotebase/components";
import { RefProps, RefTableColumns, paginationDataLimit } from "utils";
import { withReferral } from "state/referral";
import EmptyTrackerTable from "./RefEmptyTable";
import * as Styled from "./styles";
import RefPagination from "../refPagination";

export const RefTrackerTable: FC<RefProps> = ({ filteredTalentData }) => {
  const isPaginated = useMemo(
    () => (filteredTalentData && Math.ceil(filteredTalentData?.length / paginationDataLimit)) ?? 0,
    [filteredTalentData],
  );
  return (
    <Styled.RefTableWrapper>
      <Styled.RefTable responsive={!!filteredTalentData?.length} $isPaginated={isPaginated > 1}>
        <thead>
          <tr>
            <th>{RefTableColumns.Email}</th>
            <th>{RefTableColumns.Status}</th>
            <th>{RefTableColumns.PhoneNo}</th>
            <th>{RefTableColumns.Updated}</th>
            <th>{RefTableColumns.ReInviteButton}</th>
          </tr>
        </thead>
        <ShouldRender if={!!filteredTalentData?.length}>
          <RefPagination />
        </ShouldRender>
      </Styled.RefTable>
      <ShouldRender if={!filteredTalentData?.length}>
        <Styled.NoSignupsWrapper>
          <EmptyTrackerTable />
        </Styled.NoSignupsWrapper>
      </ShouldRender>
    </Styled.RefTableWrapper>
  );
};

export default withReferral(RefTrackerTable);
