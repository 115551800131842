import styled from "styled-components";

export const NameEditIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SectionHeading = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0rem 0.5rem 2rem 0.75rem;
`;

export const ProfileFormBody = styled.div`
  display flex;
  flex-direction: column;
  margin: 0rem 0.5rem 2rem 0.75rem;
`;
