import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #11142d;
  font-family: "Poppins", sans-serif;
  margin-bottom: 18px;

  @media (max-width: 575px) {
    font-size: 24px;
  }
`;
export const TestModal = styled(Modal)`
  .modal-dialog {
    max-width: 640px;
  }
  .modal-content {
    border-radius: 16px;
  }
  .modal-body {
    padding: 60px 60px;
  }
  @media (max-width: 767px) {
    padding-left: 0 !important;
    .modal-dialog {
      margin: 20px;
    }
    .modal-body {
      padding: 60px 32px 32px;
    }
  }
`;
export const CrossIconBox = styled.span`
  font-size: 16px;
  color: #11142d;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  right: 18px;
  top: 18px;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: #11142d;
    color: #fff;
  }
  @media (max-width: 767px) {
    top: 15px;
    right: 15px;
  }
`;
export const ModalPara = styled.p`
  font-size: 14px;
  color: #11142d;
  margin-bottom: 22px;
  font-family: "Poppins", sans-serif;

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;
export const ModalBtn = styled(Button)`
  font-size: 14px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 10px;
  min-width: 150px;
  background-color: #3f73f6;
  border: none;
  transition: all 0.2s ease;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #2a5bd7;
    color: #fff;
    box-shadow: none;
  }
`;
