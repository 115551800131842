import React, { PropsWithChildren } from "react";
import { resumeInputId } from "@remotebase/constants";
import { Accept, useDropzone } from "react-dropzone";

interface Props {
  onChange(file: File): void;
  disabled: boolean;
  accept: Accept;
}

export const DragAndDrop: React.FC<PropsWithChildren<Props>> = ({
  children,
  onChange,
  disabled,
  accept,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (fileArr) => onChange(fileArr[0]),
    disabled,
    accept,
  });
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} multiple={false} data-testid={resumeInputId} />
      {children}
    </div>
  );
};

export default DragAndDrop;
