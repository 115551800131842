import React, { useContext, useEffect } from "react";
import { SidebarContext, SidebarProfileOptions } from "state/sidebar";
import {
  CanNullOrUndefined,
  getClearedTests,
  isTestCleared,
  Sidebar,
  SidebarProps,
  SidebarType,
  TalentLevelProps,
  TestsClearedType,
  TestsType,
} from "utils";
import { ProfileContextType, TalentLevelContextType } from "./profileSteps.interface";
import { ProfileContext } from "./profile.context";
import { TalentLevelContext } from "./profileSteps.context";

export function withTalentLevel<T>(Component: React.FC<T & TalentLevelProps>): React.FC<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const TalentLevelConsumer: React.FC = (props: T) => {
    const {
      profileState: { data },
    } = useContext<ProfileContextType>(ProfileContext);
    const { sidebarType } = useContext<SidebarProps>(SidebarContext);
    const { talentLevel, setTalentLevel } = useContext<TalentLevelContextType>(TalentLevelContext);

    const getActiveStages = (): Array<Sidebar> => {
      const stages = data?.profile?.talentType?.stages;
      if (stages) {
        const activeStages = stages.map((item) => Sidebar[item.type]);
        return [
          ...SidebarProfileOptions.filter((item) => activeStages.includes(item)),
          Sidebar.Network,
        ];
      }
      return SidebarProfileOptions;
    };

    const getActiveTests = (activeStages): TestsClearedType => {
      const testsCleared = getClearedTests(data);
      Object.keys(testsCleared).map((item) => {
        if (!activeStages.includes(item)) {
          delete testsCleared[item];
        }
        return null;
      });
      return testsCleared;
    };

    const isTestPassed = (stageType, testType): CanNullOrUndefined<boolean> => {
      const stages = getActiveStages();
      if (stages.includes(stageType)) return !isTestCleared(data, testType);
      return false;
    };

    const isFinalTechPass = (): CanNullOrUndefined<boolean> => {
      const stages = getActiveStages();
      if (stages.includes(Sidebar.ProblemSolving))
        return !isTestCleared(data, TestsType.ProblemSolving);
      if (stages.includes(Sidebar.HRInterview)) return !isTestCleared(data, TestsType.HRInterview);
      return false;
    };

    const isNetworkPass = (): CanNullOrUndefined<boolean> => {
      const stages = getActiveStages();
      if (stages.includes(Sidebar.FinalInterview))
        return !isTestCleared(data, TestsType.FinalInterview);
      if (stages.includes(Sidebar.ProblemSolving))
        return !isTestCleared(data, TestsType.ProblemSolving);
      if (stages.includes(Sidebar.HRInterview)) return !isTestCleared(data, TestsType.HRInterview);
      return false;
    };

    const validateRoute = (stage): CanNullOrUndefined<boolean> => {
      const stages = getActiveStages();
      if (stages.includes(stage)) {
        console.log({ stages });
        switch (stage) {
          case Sidebar.DomainTest:
            return false;
          case Sidebar.HRInterview:
            return isTestPassed(Sidebar.DomainTest, TestsType.DomainTest);
          case Sidebar.ProblemSolving:
            return isTestPassed(Sidebar.HRInterview, TestsType.HRInterview);
          case Sidebar.FinalInterview:
            return isFinalTechPass();
          case Sidebar.Network:
            return isNetworkPass();
          default:
            return false;
        }
      }
      return true;
    };

    const getFailedTestsScore = (activeStages): number => {
      const activeTests = getActiveTests(activeStages);
      let testIndex = 0;
      Object.values(activeTests).forEach((isPass, i) => {
        if (isPass === false && i >= testIndex) testIndex = i + 1;
      });
      return -testIndex;
    };
    const getClearedTestsCount = (activeStages): number => {
      const activeTests = getActiveTests(activeStages);
      return Object.values(activeTests).filter(Boolean).length;
    };

    const getProfileLevel = (activeStages): number => {
      const failedScore = getFailedTestsScore(activeStages);
      const score = failedScore < 0 ? failedScore : getClearedTestsCount(activeStages);
      if (sidebarType === SidebarType.Dashboard) return score;
      if (sidebarType === SidebarType.Create_Profile) {
        if (!data) return 0;
        if (!data.profile) return 1;
        if (data.profile.isProfileCompleted === false) return 2;
      }
      return 0;
    };

    useEffect(() => {
      const activeStages = getActiveStages();
      setTalentLevel({ activeStages, level: getProfileLevel(activeStages) });
    }, [data, data?.profile]);

    const profileProps: TalentLevelProps = {
      talentLevel,
      validateRoute,
    };
    return <Component {...props} {...profileProps} />;
  };
  return TalentLevelConsumer;
}
export default withTalentLevel;
