import styled from "styled-components";

export const CurrencyContainer = styled.div`
  display: flex;
`;

export const CurrencyCode = styled.div`
  width: 175px;
  height: 52px;
`;

export const CurrencyInput = styled.input`
  padding-left: 10px !important;
  position: relative;
  border-radius: 0 6px 6px 0 !important;
`;
