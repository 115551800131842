import styled from "styled-components";
import { Row } from "react-bootstrap";

export const TEAM_BOX_ROW = styled(Row)`
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 46px;

  @media only screen and (min-width: 1200px) {
    .col-12 {
      flex: initial;
      width: auto;
    }
  }
`;

export default TEAM_BOX_ROW;
