import { Job, TechDomainSkills } from "@remotebase/amplify-constants/API";
import { TalentJobDetails } from "@remotebase/components";
import { RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import { useAnalyticsEventCreator, useCreateJobMatch } from "hooks";
import { FC } from "react";
import { CreateMatchJobProps, withJobs } from "state/jobs";

export interface IJobProps {
  talentSkills?: (TechDomainSkills | undefined)[];
  jobLoading: boolean;
  jobFound: Job | null;
  profileId?: string;
  isApplied?: boolean;
  isMatched?: boolean;
}

const TalentMatchDetails: FC<IJobProps> = ({
  talentSkills,
  jobFound,
  jobLoading,
  profileId,
  isApplied,
  isMatched,
}) => {
  const { submitCreateMatch, result, loading: createMatchLoading } = useCreateJobMatch();
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const handleCreateJobMatch = (payload: CreateMatchJobProps): void => {
    submitCreateMatch({
      variables: {
        input: { ...payload },
      },
      onCompleted: () => {
        analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_JOB_APPLIED);
      },
      onError: () => {
        analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_JOB_APPLIED_FAILED);
      },
    });
  };

  const componentProps = {
    talentSkills,
    jobFound,
    jobLoading,
    profileId,
    isTalent: true,
    createMatchLoading,
    result,
    handleCreateJobMatch,
    isApplied,
    isMatched,
  };

  return <TalentJobDetails {...componentProps} />;
};

export default withJobs(TalentMatchDetails);
