import styled from "styled-components";

export const TimeDate = styled.span`
  font-weight: 600;
  margin-bottom: 0;
  color: #717171;
  font-family: Poppins;
`;

export const TextTime = styled.span`
  font-weight: 400;
  margin-bottom: 0;
  color: #717171;
  font-family: Poppins;
`;
