import { FC, useState } from "react";
import { CalendarBooking } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { ContentCopyIcon } from "assets/icons";
import * as S from "../styled";

interface IMeetingLink {
  meetingDetails: CalendarBooking;
}

const MeetingLink: FC<IMeetingLink> = ({ meetingDetails }) => {
  const [displayMessage, setDisplayMessage] = useState<null | string>(null);

  const copyToClipboard = (): void => {
    if (meetingDetails.meetingLink) {
      navigator.clipboard.writeText(meetingDetails.meetingLink);
      setDisplayMessage("Link copied");
      setTimeout(() => {
        setDisplayMessage(null);
      }, 1000);
    }
  };
  return (
    <S.LinkCopyWrap onClick={copyToClipboard}>
      <S.MeetingLink>{meetingDetails?.meetingLink?.replace(/^https?:\/\//, "")}</S.MeetingLink>
      <ContentCopyIcon />
      <ShouldRender if={displayMessage}>
        <S.BriefMessage>{displayMessage}</S.BriefMessage>
      </ShouldRender>
    </S.LinkCopyWrap>
  );
};

export default MeetingLink;
