import styled from "styled-components";
import { Link } from "react-router-dom";

export const JobsWrapper = styled.div`
  padding: 70px 0;
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-bottom: 190px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const JobsHeading = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
`;

export const JobsPara = styled.p`
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  line-height: 180%;
  max-width: 620px;
  margin: 0 auto 50px;
  text-align: center;
`;

export const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media only screen and (min-width: 1200px) {
    grid-template-columns: calc(37% - 10px) calc(37% - 10px) 24%;
  }
`;

export const JobsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 20px;

  @media only screen and (min-width: 576px) {
    padding: 30px;
  }

  &.job__box-1 {
    border: 1px solid #3615bd;

    & label {
      color: #3615bd;
    }
    & p {
      color: #14142b;
    }
  }
  &.job__box-2 {
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

    & label {
      color: #323232;
    }
    & p {
      color: #323232;
    }
  }
`;

export const JobsHead = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media only screen and (min-width: 576px) {
    flex-direction: row;
  }
`;

export const JobsIcon = styled.div`
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e5fe;
  border-radius: 50%;
  padding: 12px;
  margin-bottom: 20px;

  @media only screen and (min-width: 576px) {
    margin: 0 20px 0 0;
  }
`;

export const JobsIconImg = styled.img`
  max-width: 100%;
`;

export const JobsHeadDiv = styled.div`
  flex-grow: 1;
`;

export const JobsType = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 5px;
`;

export const JobsName = styled.h3`
  font-size: 20px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
`;

export const RequirementSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const divWrapper = styled.div``;

export const RequirementSectionUL = styled.ul`
  padding-left: 15px;
`;

export const RequirementSectionLI = styled.li``;

export const JobsDescrip = styled.p`
  font-size: 16px;
  height: 80px;
  font-weight: 400;
  line-height: 180%;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const JobsInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media only screen and (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const JobsInfoCol = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 10px;
`;

export const JobsInfoIcon = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbefff;
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px;
`;

export const JobsInfoIconImg = styled.img`
  max-width: 100%;
`;

export const JobsInfoText = styled.span`
  font-size: 14px;
  color: #14142b;
  font-weight: 400;
  line-height: 170%;
  display: inline-flex;
`;

export const JobsBadgeCanvas = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.badgeCanvas__1 {
    & span {
      color: #844aff;
    }
  }
  &.badgeCanvas__2 {
    & span {
      color: #000;
    }
  }
`;

export const JobsBadge = styled(Link)`
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  padding: 7px 12px;
  border: 1px solid #844aff;
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }
`;

export const JobsBrowse = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 24px;
  text-align: center;
  border: 1px solid #3615bd;

  @media only screen and (min-width: 576px) {
    padding: 30px;
    text-align: left;
  }
`;

export const JobsBrowseHeading = styled.h3`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
`;

export const JobsBrowseBtn = styled(Link)`
  font-size: 16px;
  color: #3615bd;
  font-weight: 400;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #3615bd;
  border-radius: 40px;
  width: 100%;
  min-height: 55px;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  transition: all 0.25s;

  @media only screen and (min-width: 576px) {
    max-width: 200px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 100%;
  }

  &:hover,
  &:focus {
    background-color: #3615bd;
    box-shadow: none;
    border-color: #3615bd;
    color: #fff;
  }
`;

export const JobsBrowseText = styled.p`
  font-size: 14px;
  color: #000;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 8px;
`;

export const JobsBrowseLink = styled.a`
  color: #3c65fe;
  margin-left: 4px;
  cursor: pointer;
`;
