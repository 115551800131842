import { FC, useEffect, useState } from "react";
import { Color } from "@remotebase/constants";
import { ActionBtn } from "../../buttons";
import { actionBtnWidth } from "../../utils";
import * as S from "./styles";

interface IConfirmationModal {
  message: string;
  heading: string;
  cancelBtnText: string;
  confirmBtnText: string;
  isLoading?: boolean;
  closeBtnHandler: () => void;
  confirmBtnHandler: () => void;
}

export const ConfirmationModal: FC<IConfirmationModal> = ({
  heading,
  message,
  closeBtnHandler,
  confirmBtnHandler,
  cancelBtnText,
  confirmBtnText,
  isLoading,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleContinueBtn = (): void => {
    setIsSubmitting(true);
    confirmBtnHandler();
  };

  useEffect(() => {
    if (!isLoading && isSubmitting) {
      closeBtnHandler();
      setIsSubmitting(false);
    }
  });

  return (
    <S.ConfirmationWrapper>
      <S.ConfirmationHeading>{heading}</S.ConfirmationHeading>
      <S.ConfirmationMessage>{message}</S.ConfirmationMessage>
      <S.BtnGroup>
        <ActionBtn
          btnText={cancelBtnText}
          clickHandler={closeBtnHandler}
          width={actionBtnWidth}
          color={Color.LIGHT_BLUE}
          backgroundColor={Color.TRANSPARENT}
        />
        <ActionBtn
          btnText={confirmBtnText}
          isLoading={isLoading}
          clickHandler={handleContinueBtn}
          width={actionBtnWidth}
          color={Color.WHITE}
          backgroundColor={Color.LIGHT_BLUE}
          disabled={isLoading}
        />
      </S.BtnGroup>
    </S.ConfirmationWrapper>
  );
};
export default ConfirmationModal;
