import { FC, useEffect } from "react";
import { ProblemSolving } from "components";
import { useHistory } from "react-router-dom";
import { ProfileProps, Sidebar, TalentLevelProps, AuthRoutes as Route } from "utils";
import { withProfile, withTalentLevel } from "state/profileSteps";
import * as Styled from "./styles";

export const ProblemSolvingPage: FC<ProfileProps & TalentLevelProps> = ({
  profileState: { data },
  validateRoute,
}) => {
  const history = useHistory();
  const showGrey = data?.profile?.isPassPS === false;

  useEffect(() => {
    if (validateRoute(Sidebar.ProblemSolving)) history.push(Route.Dashbord);
  }, []);
  return (
    <Styled.ProblemSolvingWrapper isGrey={showGrey}>
      <Styled.H3>We will be back soon</Styled.H3>
      <Styled.ProblemSolUl>
        <Styled.ProblemSolLi>
          The coding assessment is currently unavailable as we are diligently working to enhance
          your experience.
        </Styled.ProblemSolLi>

        <Styled.ProblemSolLi>
          If any recruiters have directed you to this site, please contact them for further
          assistance.
        </Styled.ProblemSolLi>
      </Styled.ProblemSolUl>
      <ProblemSolving />
    </Styled.ProblemSolvingWrapper>
  );
};

export default withProfile(withTalentLevel(ProblemSolvingPage));
