import React, { Fragment, useState } from "react";
import { FaqInterface, mapOutput } from "state/dashboard";
import { faqList } from "utils";
import FaqSingleList from "./FaqSingleList";

const FaqList: React.FC = () => {
  const [currentActive, setCurrentActive] = useState("");
  const handleActive = (value: string): void => {
    setCurrentActive(currentActive === value ? "" : value);
  };

  const getFaq = (question: FaqInterface): JSX.Element => {
    return (
      <FaqSingleList
        key={question.eventKey}
        question={question}
        isActive={currentActive === question.eventKey}
        setActive={handleActive}
      />
    );
  };

  const listFaq = mapOutput<FaqInterface>(faqList, getFaq, currentActive);

  return <Fragment>{listFaq}</Fragment>;
};

export default FaqList;
