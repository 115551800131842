import { SidebarLockIcon, SidebarCheckIcon, DomainFailIcon } from "assets/icons";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { sidebarRoutes, sidebarIcons } from "state/sidebar";
import { defaultRoute, SidebarStatusIcons } from "utils";
import * as Styled from "./styles";

interface Props {
  text: string;
  status?: string;
}

const SidebarItem: FC<Props> = ({ status, text }) => {
  const { pathname } = useLocation();

  const isActive = (): boolean =>
    pathname === sidebarRoutes[text] && status !== SidebarStatusIcons.Lock;

  const isDisabled = (): boolean => status === SidebarStatusIcons.Lock;

  const getRef = (): string => {
    const ref = sidebarRoutes[text];
    return status !== SidebarStatusIcons.Lock && ref ? ref : defaultRoute;
  };

  const setIcon = (): JSX.Element => {
    switch (status) {
      case SidebarStatusIcons.Tick:
        return <SidebarCheckIcon />;
      case SidebarStatusIcons.Cross:
        return <DomainFailIcon />;
      case SidebarStatusIcons.Lock:
        return <SidebarLockIcon />;
      default:
        return sidebarIcons[text];
    }
  };
  return (
    <Styled.SidebarItem
      $active={isActive()}
      $disabled={isDisabled()}
      $pointer={getRef()}
      to={getRef()}
    >
      <Styled.SidebarIcon>{setIcon()}</Styled.SidebarIcon>
      <Styled.SidebarText>{text}</Styled.SidebarText>
    </Styled.SidebarItem>
  );
};

export default SidebarItem;
