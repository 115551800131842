import { FC } from "react";
import * as Styled from "./styles";

interface Props {
  content: string;
}
const MyMessage: FC<Props> = ({ content }) => {
  return <Styled.ChatBodyMsgBox>{content}</Styled.ChatBodyMsgBox>;
};

export default MyMessage;
