import { FC, Fragment, useContext, useEffect, useState } from "react";
import { RadioInput } from "components";
import { CheckMarkIcon } from "assets/icons";
import { englishProficiency } from "API";
import { englishProficiencyMap, englishProficiencyMapReverse } from "@remotebase/constants";
import { ProfileTalentContext, ProfileOptions, withProfile } from "state/profileSteps";
import { EnglishBtnId, EnglishInputId, ProfileProps } from "utils";
import * as Styled from "./styles";

const EnglishSection: FC<ProfileProps> = ({ profileState: { data } }) => {
  const [level, setLevel] = useState<englishProficiency>();
  const radioType = "language_section_radio";
  const { talent, setTalent } = useContext(ProfileTalentContext);

  const handleSubmit = (): void => {
    setTalent({ englishProficiency: level });
  };
  const getInput = (val: englishProficiency): JSX.Element => (
    <RadioInput
      testingId={EnglishInputId}
      key={val}
      type={radioType}
      value={englishProficiencyMap[val]}
      onSelect={(e: englishProficiency): void => {
        setLevel(englishProficiencyMapReverse[e] as englishProficiency);
      }}
      isChecked={level === val}
      isBox
    />
  );

  const proficiencyLevel = ProfileOptions(englishProficiency, getInput, level);

  useEffect(() => {
    if (data?.profile?.englishProficiency) setLevel(data?.profile?.englishProficiency || undefined);
  }, []);

  useEffect(() => {
    if (talent.englishProficiency) setLevel(talent?.englishProficiency || undefined);
  }, [talent]);

  return (
    <Fragment>
      <h2>
        How well can you communicate in <Styled.PurpleFont>English</Styled.PurpleFont>?
      </h2>
      <p></p>
      <Styled.ProjectTypeSelect>{proficiencyLevel}</Styled.ProjectTypeSelect>
      <Styled.NextBtn
        data-testid={EnglishBtnId}
        onClick={handleSubmit}
        type="button"
        id="multiSelect_nextButton_projectType"
        disabled={!level}
      >
        Next
        <CheckMarkIcon />
      </Styled.NextBtn>
    </Fragment>
  );
};

export default withProfile(EnglishSection);
