import React, { useEffect, useState } from "react";
import * as Styled from "./styles";

export const CtoBanner: React.FC = () => {
  const targetDate = new Date("2024-05-22 23:59:59");
  const today = new Date();
  const diffTime = targetDate.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const [daysLeft, setDaysLeft] = useState<number>(diffDays);

  useEffect(() => {
    setDaysLeft(diffDays);
  }, []);

  const [addClass, setAddClass] = useState(false);

  const handleButtonClick = () => {
    setAddClass(true);
  };

  return (
    <>
      {daysLeft > 0 && (
        <Styled.Banner className={`${addClass ? "banner__close" : ""} banner__page`}>
          <Styled.BannerText>
            <span>CTO-Con 2024</span> - Virtual Tech Conference: Get the latest on AI, SaaS, Data &
            Leadership
          </Styled.BannerText>
          <Styled.BannerDays>
            {daysLeft} {daysLeft === 1 ? "Day" : "Days"} left
          </Styled.BannerDays>
          <Styled.RegisterBtn href="https://www.ctocon.remotebase.com/?utm_source=website_banner&utm_medium=remotebase&utm_campaign=ctocon24">
            Register Now
          </Styled.RegisterBtn>
          <Styled.CloseBtn onClick={handleButtonClick}>
            <img src="https://i.ibb.co/pQh1G9X/close.png" alt="close" width={10} height={10} />
          </Styled.CloseBtn>
        </Styled.Banner>
      )}
    </>
  );
};

export default CtoBanner;
