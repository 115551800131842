import styled from "styled-components";
import { StyledProps } from "utils";

export const FormGroup = styled.div`
  display: block;
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const SalaryFieldContainer = styled.div<{ disableExpected?: boolean }>`
  position: relative;
  opacity: ${(props): StyledProps => (props.disableExpected ? "0.3" : "1")};
  display: flex;
`;

export const CurrencyWrapper = styled.div`
  width: 125px;
`;

export const SalaryInput = styled.input`
  width: 100%;
  padding-left: 10px !important;
  border-radius: 0 0.35rem 0.35rem 0;
  height: auto;
  border: 1px solid #dddfe2;
`;

export const FormLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  line-height: 1.2;
  color: #808191;
  font-weight: 500;
  margin-bottom: 0.5rem;
  width: 100%;

  @media only screen and (max-width: 767px) {
    font-size: 12px;
    margin-bottom: 0.35rem;
  }
`;

export const SalaryError = styled.p`
  color: red !important;
  margin-top: -30px !important;
  font-size: 0.8rem !important;
  position: absolute;
  right: 15px;
`;
