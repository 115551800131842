import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons";
import * as S from "./styles";

type CarouselState = {
  totalItems: number;
  currentSlide: number;
};

type Props = {
  next?: () => void;
  previous?: () => void;
  carouselState?: CarouselState;
};

// eslint-disable-next-line
export const BtnGroup: React.FC<Props> = ({ next, previous, carouselState }) => {
  const { totalItems, currentSlide } = carouselState || {};
  const onNextClickHandler = (): void => {
    if (next) {
      next();
    }
  };
  const onPreviousClickHandler = (): void => {
    if (previous) {
      previous();
    }
  };
  return (
    <S.CarouselBtnCanvas>
      <S.CarouselBtnPrev
        aria-label="Go to previous slide"
        onClick={onPreviousClickHandler}
        disabled={currentSlide === 0}
      >
        <ArrowLeftIcon />
      </S.CarouselBtnPrev>
      <S.CarouselBtnNext
        aria-label="Go to next slide"
        onClick={onNextClickHandler}
        disabled={currentSlide === totalItems}
      >
        <ArrowRightIcon />
      </S.CarouselBtnNext>
    </S.CarouselBtnCanvas>
  );
};

export default BtnGroup;
