import { AssessmentStage } from "@remotebase/amplify-constants/API";
import { ProfileTalentTypeContextType } from "state/profileSteps";

export const experienceInputId = "experience-input-id";
export const experienceBtnId = "experience-btn-id";
export const EnglishInputId = "english-input-id";
export const EnglishBtnId = "english-btn-id";
export const IntrestFieldInputId = "interest-input-id";
export const IntrestFieldBtnId = "interest-btn-id";
export const TalentFieldBtnId = "talent-btn-id";
export const SalaryBtnId = "salary-btn-id";
export const ExpectedSalaryId = "expected-salary-input";
export const CurrentSalaryId = "current-salary-input";
export const HeardFromInputId = "heardFrom-input-id";
export const HeardFromBtnId = "heardFrom-btn-id";
export const SkillsInputId = "skills-input-id";
export const SkillsBtnId = "skills-btn-id";

export const mockTalentProps: ProfileTalentTypeContextType = {
  talentType: {
    talentList: [],
    selectedTalent: {
      id: "d6841fae-3487-4689-85be-7defb86346de",
      name: "SOFTWARE_DEVELOPER",
      title: "Software Developer",
      hrInterviewJobId: "12345678",
      icon: "/talent_icons/software-developer.svg",
      sequence: 1,
      skills: [
        {
          name: "NodeJs",
          title: "Node.js",
          __typename: "TalentSkill",
        },
        {
          name: "React",
          title: "React.js",
          __typename: "TalentSkill",
        },
        {
          name: "ReactNative",
          title: "React Native",
          __typename: "TalentSkill",
        },
        {
          name: "Python",
          title: "Python",
          __typename: "TalentSkill",
        },
        {
          name: "Graphql",
          title: "GraphQL",
          __typename: "TalentSkill",
        },
        {
          name: "Angular",
          title: "Angular",
          __typename: "TalentSkill",
        },
        {
          name: "Vue",
          title: "Vue.js",
          __typename: "TalentSkill",
        },
        {
          name: "PHP",
          title: "PHP",
          __typename: "TalentSkill",
        },
        {
          name: "Oracle",
          title: "Oracle",
          __typename: "TalentSkill",
        },
        {
          name: "DotNet",
          title: ".Net",
          __typename: "TalentSkill",
        },
        {
          name: "Wordpress",
          title: "WordPress",
          __typename: "TalentSkill",
        },
        {
          name: "Ror",
          title: "RoR",
          __typename: "TalentSkill",
        },
        {
          name: "Django",
          title: "Django",
          __typename: "TalentSkill",
        },
        {
          name: "Javascript",
          title: "JavaScript",
          __typename: "TalentSkill",
        },
        {
          name: "Typescript",
          title: "TypeScript",
          __typename: "TalentSkill",
        },
        {
          name: "Csharp",
          title: "C#",
          __typename: "TalentSkill",
        },
        {
          name: "CPlusPlus",
          title: "C++",
          __typename: "TalentSkill",
        },
        {
          name: "Mysql",
          title: "MySQL",
          __typename: "TalentSkill",
        },
        {
          name: "Postgres",
          title: "PostgreSQL",
          __typename: "TalentSkill",
        },
        {
          name: "d3",
          title: "D3.js",
          __typename: "TalentSkill",
        },
        {
          name: "Amplify",
          title: "Amplify",
          __typename: "TalentSkill",
        },
        {
          name: "Aws",
          title: "AWS",
          __typename: "TalentSkill",
        },
        {
          name: "MongoDb",
          title: "MongoDB",
          __typename: "TalentSkill",
        },
        {
          name: "Docker",
          title: "Docker",
          __typename: "TalentSkill",
        },
        {
          name: "DevOps",
          title: "DevOps",
          __typename: "TalentSkill",
        },
        {
          name: "Golang",
          title: "GO",
          __typename: "TalentSkill",
        },
        {
          name: "Redux",
          title: "Redux",
          __typename: "TalentSkill",
        },
        {
          name: "Express",
          title: "Express.js",
          __typename: "TalentSkill",
        },
        {
          name: "ReactAndNode",
          title: "React.js +  Node.js",
          __typename: "TalentSkill",
        },
        {
          name: "IOS",
          title: "IOS",
          __typename: "TalentSkill",
        },
        {
          name: "Android",
          title: "Android",
          __typename: "TalentSkill",
        },
        {
          name: "Flutter",
          title: "Flutter",
          __typename: "TalentSkill",
        },
        {
          name: "Java",
          title: "Java",
          __typename: "TalentSkill",
        },
        {
          name: "Magento",
          title: "Magento",
          __typename: "TalentSkill",
        },
      ],
      interests: [
        {
          name: "Web",
          title: "Web",
          __typename: "TalentInterest",
        },
        {
          name: "Mobile",
          title: "Mobile",
          __typename: "TalentInterest",
        },
        {
          name: "SoftwareInteghration",
          title: "Software Integration",
          __typename: "TalentInterest",
        },
        {
          name: "ML",
          title: "ML",
          __typename: "TalentInterest",
        },
        {
          name: "Devops",
          title: "Devops",
          __typename: "TalentInterest",
        },
        {
          name: "ECommerce",
          title: "ECommerce",
          __typename: "TalentInterest",
        },
      ],
      sortType: "TalentType",
      stages: [
        {
          type: AssessmentStage.HRInterview,
          isOptional: false,
          sequenceNumber: 0,
          passingPercentage: 0,
          __typename: "Stage",
        },
        {
          type: AssessmentStage.DomainTest,
          isOptional: false,
          sequenceNumber: 0,
          passingPercentage: 50,
          __typename: "Stage",
        },
        {
          type: AssessmentStage.ProblemSolving,
          isOptional: false,
          sequenceNumber: 0,
          passingPercentage: 60,
          __typename: "Stage",
        },
        {
          type: AssessmentStage.FinalInterview,
          isOptional: false,
          sequenceNumber: 0,
          passingPercentage: 50,
          __typename: "Stage",
        },
      ],
      atsJobID: "9157E99505",
      isActive: true,
      createdAt: "2022-11-04T09:22:23.356Z",
      updatedAt: "2022-11-04T09:22:23.356Z",
      __typename: "TalentType",
    },
  },
  setTalentType: (): void => {},
  loading: false,
};

export const invalidSalary = "invalidSalary";
export const negativeSalary = "-22121";
export const invalidSalaryError = "Salary must be a number";
export const invalidNegativeSalary = "Salary cannot be negative";
export const nextButtonName = "Next CheckMark.svg";
