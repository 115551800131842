import styled from "styled-components";

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
`;

export const EmptyHeading = styled.h3`
  margin-bottom: 1.125rem;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.5rem;
  color: #11142d;
`;

export const EmptyParagraph = styled.p`
  margin-bottom: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 1.125rem;
  color: #808191;
`;
