export const allowedResumeSize = 8388608; // 8 * 1024(M) * 1024(K)
export const createProfileResumeSize = "File size should not be more than 8 MB";
export const createProfileResumeType = "File must be in PDF, DOC or DOCX format";
export const csvTypeError = "File must be in CSV format";
export const pdfFile = "application/pdf";
export const docFile = "application/msword";
export const docxFile = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const csvFile = "text/csv";

export const resumeInputId = "resume-input-id";
export const resumeBtnId = "resume-btn-id";
