import React from "react";
import { WorkFigureInterface } from "state/dashboard";
import { WorkFigureImgId } from "utils";
import * as Styled from "./styles";

type Props = {
  figure: WorkFigureInterface;
};

const WorkFigureSingleList: React.FC<Props> = ({ figure }) => {
  return (
    <Styled.WorkFigure>
      <Styled.WorkImg
        data-testid={WorkFigureImgId}
        className=" aos-item"
        data-aos={figure?.dataAos}
        data-aos-duration={figure?.dataAosDuration}
        data-scale={figure?.dataScale}
        data-orientation={figure?.dataOrientation}
        src={figure.src}
        alt=""
      />
    </Styled.WorkFigure>
  );
};

export default WorkFigureSingleList;
