import { MessageResponse } from "@remotebase/amplify-constants/API";
import { PaperPlaneIcon } from "assets/icons";
import { useSendMessage } from "hooks";
import { FC, useState } from "react";
import { withProfile } from "state/profileSteps";
import { ProfileProps } from "utils";
import * as Styled from "./styles";

interface Props {
  conversationId?: string;
  loading?: boolean;
  setNewMessages: (data: MessageResponse) => void;
}

const NewMessage: FC<Props & ProfileProps> = ({
  conversationId,
  setNewMessages,
  profileState: { data },
  loading,
}) => {
  const [msgContent, setMsgContent] = useState<string>("");
  const { sendNewMessage } = useSendMessage();
  const handleSubmit = (e): void => {
    e.preventDefault();
    const newMessage = {
      __typename: "MessageResponse",
      content: msgContent,
      conversationId,
      createdAt: new Date().toISOString(),
      senderInfo: {
        __typename: "PersonInfo",
        id: data?.id,
        fullName: data?.fullName,
        email: data?.email,
        phone: data?.phone,
        role: data?.role,
      },
    };
    setNewMessages(newMessage as MessageResponse);
    setMsgContent("");
    sendNewMessage({
      variables: {
        input: { content: msgContent, conversationId },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Styled.ChatBodySearchWrapper>
        <Styled.ChatBodySearchInput>
          <input
            type="text"
            placeholder="Type message here ... (Press ENTER to send)"
            value={msgContent}
            disabled={loading}
            onChange={(e): void => setMsgContent(e.target.value)}
            autoFocus
          />
        </Styled.ChatBodySearchInput>
        <Styled.ChatBodyFooterIcon
          data-testid={"new-message"}
          onClick={handleSubmit}
          disabled={!msgContent.length}
        >
          <PaperPlaneIcon />
        </Styled.ChatBodyFooterIcon>
      </Styled.ChatBodySearchWrapper>
    </form>
  );
};
export default withProfile(NewMessage);
