import React from "react";
import { mapOutput, PerksListInterface } from "state/dashboard";
import { perksList } from "utils";
import PerksSingleList from "./PerksSingleList";
import * as Styled from "./styles";

const PerksList: React.FC = () => {
  const getPerkColumn = (perk: PerksListInterface, index): JSX.Element => {
    return <PerksSingleList key={index} perk={perk} />;
  };

  const listPerkColumn = mapOutput<PerksListInterface>(perksList, getPerkColumn);

  return <Styled.PerksListDiv>{listPerkColumn}</Styled.PerksListDiv>;
};

export default PerksList;
