import { getIcon } from "hooks/utils";
import React from "react";
import { Modal } from "react-bootstrap";
import { domainTestModalCrossIconTestId, domainTestModalTestId, ICONS } from "utils";
import * as Styled from "./styles";

interface Props {
  heading?: string;
  body: string;
  btnText?: string;
  onOkay?: () => void;
  onCancel?: () => void;
}

export const DomainTestModal: React.FC<Props> = (props) => {
  const { heading, body, btnText, onOkay, onCancel } = props;
  return (
    <Styled.TestModal
      show
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      data-testid={domainTestModalTestId}
    >
      <Modal.Body>
        <Styled.CrossIconBox data-testid={domainTestModalCrossIconTestId} onClick={onCancel}>
          {getIcon(ICONS.CLOSE)}
        </Styled.CrossIconBox>
        <Styled.H1>{heading}</Styled.H1>
        <Styled.ModalPara dangerouslySetInnerHTML={{ __html: body }} />
        <Styled.ModalBtn variant="secondary" onClick={onOkay}>
          {btnText}
        </Styled.ModalBtn>
      </Modal.Body>
    </Styled.TestModal>
  );
};

export default DomainTestModal;
