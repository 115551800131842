/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useRef, useEffect, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AvatarEditor from "react-avatar-editor";
import { TalentProfile } from "@remotebase/amplify-constants/API";

import {
  ModalComponent,
  networkFailureErrorMsg,
  networkFailureErrorTitle,
  ActionBtn,
  cancelBtnText,
  actionBtnWidth,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "@remotebase/components";
import { IErrorStateType, Color } from "@remotebase/constants";
import { RotateIcon, ZoomIcon } from "@remotebase/components/assets";
import { useFileUpload } from "hooks";
import { profilePhotoFileName, profilePhotoType, saveBtnText } from "utils";
import * as S from "../styles";

interface IEditProfilePhotoProps {
  selectedImage: File;
  close: () => void;
  talentProfile: TalentProfile | null | undefined;
  updateProfile: (unknown: any) => void;
  refetchProfile?: () => void;
  showError: (data: IErrorStateType) => void;
}

export const UpdateProfilePhoto: FC<IEditProfilePhotoProps> = ({
  selectedImage,
  close,
  talentProfile,
  updateProfile,
  refetchProfile,
  showError: showMessage,
}) => {
  const [imgScale, setImgScale] = useState(1);
  const [imgRotation, setImgRotation] = useState(0);
  const [showPhotoUploadErrorModal, setPhotoUploadErrorModal] = useState(false);

  const { res, uploadImage } = useFileUpload();
  const editorRef = useRef<AvatarEditor>(null);

  useEffect(() => {
    if (!talentProfile) {
      setPhotoUploadErrorModal(true);
      return;
    }
    if (!res.isLoading) {
      if (res.success) {
        updateProfile({
          variables: {
            input: {
              imgLink: res.data,
              expectedVersion: talentProfile?.version,
              id: talentProfile?.id,
            },
          },
          onCompleted: () => {
            showMessage({
              title: submissionSuccessTitle,
              message: submissionProfileSuccessMessage,
            });
          },
          onError: () => {
            showMessage({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
          },
        });
        refetchProfile?.();
        close();
      } else if (res.error) {
        setPhotoUploadErrorModal(true);
      }
    }
  }, [res]);

  const handleSave = (): void => {
    if (!editorRef.current) return;
    const scaledImage = editorRef.current.getImageScaledToCanvas();
    scaledImage.toBlob((blob: any) => {
      const photoFile = new File([blob], profilePhotoFileName, { type: profilePhotoType });
      uploadImage(photoFile);
    });
  };
  const handleRotation = (): void => {
    let newImgRotation = imgRotation + 90;
    if (newImgRotation === 360) newImgRotation = 0;
    setImgRotation(newImgRotation);
  };
  const closePhotoUploadErrorModal = (): void => setPhotoUploadErrorModal(false);
  const handleImageUploadFailure = (): void => setPhotoUploadErrorModal(true);

  return (
    <S.SlimmerWrapper>
      <S.SlimmerHeading>Update your avatar</S.SlimmerHeading>

      <S.FormBody>
        <S.AvatarEditorContainer>
          <AvatarEditor
            ref={editorRef}
            image={selectedImage}
            width={300}
            height={300}
            border={[75, 25]}
            rotate={imgRotation}
            borderRadius={200}
            scale={imgScale}
            onLoadFailure={handleImageUploadFailure}
          />
        </S.AvatarEditorContainer>

        <S.ImageEditContainer>
          <S.ZoomIconText>
            <ZoomIcon width={17.5} height={17.5} />
            <p>
              <small>Zoom</small>
            </p>
          </S.ZoomIconText>
          <Form.Control
            type="range"
            min="1"
            max="2"
            step="0.01"
            defaultValue="1"
            onChange={(event): void => {
              setImgScale(parseFloat(event.target.value));
            }}
          />
          <S.VerticalLine></S.VerticalLine>
          <S.RotateIconText onClick={handleRotation}>
            <RotateIcon width={20} height={20} />
            <p>
              <small>Rotate</small>
            </p>
          </S.RotateIconText>
        </S.ImageEditContainer>
      </S.FormBody>

      <S.BtnGroup>
        <ActionBtn
          btnText={cancelBtnText}
          clickHandler={close}
          width={actionBtnWidth}
          color={Color.LIGHT_BLUE}
          backgroundColor={Color.TRANSPARENT}
        />
        <ActionBtn
          btnText={saveBtnText}
          isLoading={res.isLoading}
          clickHandler={handleSave}
          width={actionBtnWidth}
          color={Color.WHITE}
          backgroundColor={Color.LIGHT_BLUE}
          disabled={res.isLoading}
        />
      </S.BtnGroup>

      <ModalComponent show={showPhotoUploadErrorModal} handleClose={closePhotoUploadErrorModal}>
        <Fragment>
          <Modal.Header>
            <Modal.Title>{networkFailureErrorTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{networkFailureErrorMsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closePhotoUploadErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Fragment>
      </ModalComponent>
    </S.SlimmerWrapper>
  );
};

export default UpdateProfilePhoto;
