import { FC } from "react";
import * as Styled from "./styles";

const ParsedResumeShimmer: FC = () => {
  return (
    <Styled.ShimmersPage>
      <Styled.ShimmerHead>
        <Styled.ShimmerTitle />
        <Styled.ShimmerBtn />
      </Styled.ShimmerHead>
      <Styled.ShimmerEdComponent>
        <Styled.ShimmerEd>
          <Styled.ShimmerEdContent>
            <Styled.ShimmerEdTitle />
            <Styled.ShimmerEdPara />
            <Styled.ShimmerEdParaSm />
          </Styled.ShimmerEdContent>
          <Styled.ShimmerEdBtnCanvas>
            <Styled.ShimmerEdBtn />
            <Styled.ShimmerEdBtn />
          </Styled.ShimmerEdBtnCanvas>
        </Styled.ShimmerEd>
      </Styled.ShimmerEdComponent>
      <Styled.ShimmerHead>
        <Styled.ShimmerTitle />
        <Styled.ShimmerBtn />
      </Styled.ShimmerHead>
      <Styled.ShimmerExpComponent>
        <Styled.ShimmerExp>
          <Styled.ShimmerExpContent>
            <Styled.ShimmerExpTitle />
            <Styled.ShimmerExpPara />
            <Styled.ShimmerExpParaSmCanvas>
              <Styled.ShimmerExpParaSm />
              <Styled.ShimmerExpParaSm />
              <Styled.ShimmerExpParaSm />
            </Styled.ShimmerExpParaSmCanvas>
            <Styled.ShimmerExpList>
              <Styled.ShimmerExpItemCanvas>
                <Styled.ShimmerExpItem />
                <Styled.ShimmerExpItem />
              </Styled.ShimmerExpItemCanvas>
              <Styled.ShimmerExpItemCanvas>
                <Styled.ShimmerExpItem />
                <Styled.ShimmerExpItem />
              </Styled.ShimmerExpItemCanvas>
              <Styled.ShimmerExpItemCanvas>
                <Styled.ShimmerExpItem />
                <Styled.ShimmerExpItem />
              </Styled.ShimmerExpItemCanvas>
            </Styled.ShimmerExpList>
          </Styled.ShimmerExpContent>
          <Styled.ShimmerEdBtnCanvas>
            <Styled.ShimmerEdBtn />
            <Styled.ShimmerEdBtn />
          </Styled.ShimmerEdBtnCanvas>
        </Styled.ShimmerExp>
      </Styled.ShimmerExpComponent>
      <Styled.ShimmerLinks>
        <Styled.ShimmerLinksTitle />
        <Styled.ShimmerLinksGrid>
          <Styled.ShimmerLinksGroup>
            <Styled.ShimmerLinksLabel />
            <Styled.ShimmerLinksInput>
              <Styled.ShimmerLinksInputText />
            </Styled.ShimmerLinksInput>
          </Styled.ShimmerLinksGroup>
          <Styled.ShimmerLinksGroup>
            <Styled.ShimmerLinksLabel />
            <Styled.ShimmerLinksInput>
              <Styled.ShimmerLinksInputText />
            </Styled.ShimmerLinksInput>
          </Styled.ShimmerLinksGroup>
          <Styled.ShimmerLinksGroup>
            <Styled.ShimmerLinksLabel />
            <Styled.ShimmerLinksInput>
              <Styled.ShimmerLinksInputText />
            </Styled.ShimmerLinksInput>
          </Styled.ShimmerLinksGroup>
          <Styled.ShimmerLinksGroup>
            <Styled.ShimmerLinksLabel />
            <Styled.ShimmerLinksInput>
              <Styled.ShimmerLinksInputText />
            </Styled.ShimmerLinksInput>
          </Styled.ShimmerLinksGroup>
        </Styled.ShimmerLinksGrid>
      </Styled.ShimmerLinks>
    </Styled.ShimmersPage>
  );
};

export default ParsedResumeShimmer;
