import React, { Fragment, useEffect, useState } from "react";
import {
  ProfileProps,
  TalentLevelProps,
  Sidebar,
  getClearedTestsCount,
  shouldUseIntercom,
} from "utils";
import { Username } from "@remotebase/components";
import { IStatusBlock, mapOutput, statusBlocksList } from "state/dashboard";
import { withProfile, withTalentLevel } from "state/profileSteps";
import { useIntercom } from "react-use-intercom";
import StatusSingleBlock from "./StatusSingleBlock";
import * as Styled from "./styles";

const ApplicationStatus: React.FC<ProfileProps & TalentLevelProps> = ({
  profileState: { data },
  talentLevel: { activeStages },
}) => {
  const [enableCount, setEnableCount] = useState(0);
  const { update } = useIntercom();
  const getStatusBlock = (block: IStatusBlock, index: number): JSX.Element => (
    <StatusSingleBlock
      key={index}
      index={index + 1}
      block={block}
      isActive={enableCount >= index}
    />
  );
  useEffect(() => {
    setEnableCount(getClearedTestsCount(data));
    if (data && shouldUseIntercom) update({ name: `${data?.fullName}  (${data?.role})` });
  }, [data]);

  const filterActiveStages = (): Array<IStatusBlock> => {
    return statusBlocksList.filter((item) => activeStages?.includes(item.text as Sidebar));
  };

  const listStatusBlock = mapOutput(filterActiveStages(), getStatusBlock, enableCount);

  return (
    <Fragment>
      <Styled.HeadingSmall>
        Hi <Username showFirstName fullName={data?.fullName} />,
      </Styled.HeadingSmall>
      <Styled.HeadingMain>Welcome 👋</Styled.HeadingMain>

      <Styled.DASHBOARD_BOX_ROW>
        <Styled.DashboardBoxLine1 />
        {listStatusBlock}
        <Styled.DashboardBoxLine2 />
      </Styled.DASHBOARD_BOX_ROW>
    </Fragment>
  );
};

export default withProfile(withTalentLevel(ApplicationStatus));
