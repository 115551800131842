import { upperFirst } from "lodash";
import { FC, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { invalidNumber, phoneNumberPlaceholder } from "@remotebase/constants";
import { CountryCode, E164Number } from "libphonenumber-js";
import { Country } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "..";
import * as S from "./styles";

interface IProfilePhoneInput {
  setPhoneNumber: (value: string) => void;
  setCountry: React.Dispatch<React.SetStateAction<Country | undefined>>;
  phoneNumber: string | undefined;
  label?: string;
}

export const ProfilePhoneInput: FC<IProfilePhoneInput> = ({
  setPhoneNumber,
  phoneNumber,
  setCountry,
  label,
}) => {
  const [localPhoneNumber, setLocalPhoneNumber] = useState("");

  useEffect(() => {
    if (!localPhoneNumber && phoneNumber) setLocalPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  const handleOnChange = (phone: E164Number): void => {
    if (phone && isValidPhoneNumber(phone as string)) setPhoneNumber(phone as string);
    else setPhoneNumber("");
  };

  return (
    <S.WrapPhoneInput>
      {label && <S.PhoneInputLabel>{label}</S.PhoneInputLabel>}
      <S.ProfilePhoneInputCanvas>
        <PhoneInput
          onCountryChange={(country: CountryCode): void => setCountry(country as Country)}
          placeholder={phoneNumberPlaceholder}
          value={localPhoneNumber}
          onChange={handleOnChange}
        />
      </S.ProfilePhoneInputCanvas>
      <ShouldRender if={phoneNumber === ""}>
        <S.PhoneInputError>{upperFirst(invalidNumber)}</S.PhoneInputError>
      </ShouldRender>
    </S.WrapPhoneInput>
  );
};

export default ProfilePhoneInput;
