import { FC, Fragment, useEffect, useMemo, useState } from "react";
import {
  lockConfirmationMessage,
  defaultSalaryCountryCode,
  markAvailable,
  ProfileInfoForm,
  extractSalaryAmount,
  splitFullName,
  expectedSalaryStatus,
  ISubmitProfileInput,
  saveBtnText,
} from "utils";
import {
  ShouldRender,
  ModalComponent,
  cancelBtnText,
  confirmBtnText,
  TalentEditProfile,
  ActionBtn,
  ConfirmationModal,
  actionBtnWidth,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "@remotebase/components";
import { useAnalyticsEventCreator } from "hooks";
import { Color, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import * as S from "../styles";
import ProfileInfoFormBody from "./ProfileInfoFormBody";

export const UpdateProfileInfo: FC<TalentEditProfile> = ({
  data,
  close,
  setTalentData,
  talentUpdateProps,
  openConfirmModal,
  setOpenConfirmModal,
}) => {
  const statusMeta = data?.profile?.statusMeta;

  const {
    isLoading,
    updateInfo,
    profileData,
    data: updateTalentData,
    updateProfile,
    refetchProfile,
    showError: showMessage,
  } = talentUpdateProps || {};
  const [formData, setFormData] = useState({
    [ProfileInfoForm.firstName]: splitFullName(data?.fullName)?.talentFirstName || "",
    [ProfileInfoForm.lastName]: splitFullName(data?.fullName)?.talentLastName || "",
    [ProfileInfoForm.isAvailable]: data?.profile?.isAvailable || false,
    [ProfileInfoForm.currentSalary]:
      extractSalaryAmount({ salary: data?.profile?.currentSalary }) || "",
    [ProfileInfoForm.expectedSalary]:
      extractSalaryAmount({ salary: data?.profile?.expectedSalary }) || "",
  });
  const [currentSalaryCountry, setCurrentSalaryCountry] = useState(
    extractSalaryAmount({ salary: data?.profile?.currentSalary, unit: true }) ||
      data?.country ||
      defaultSalaryCountryCode,
  );
  const [expectedSalaryCountry, setExpectedSalaryCountry] = useState(
    extractSalaryAmount({ salary: data?.profile?.expectedSalary, unit: true }) ||
      data?.country ||
      defaultSalaryCountryCode,
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const closeConfirmModal = (): void => setOpenConfirmModal(false);
  const confirmModalHandler = (): void => {
    setOpenConfirmModal(true);
  };
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const updateFormData = (key: string, value: string): void => {
    if (key === ProfileInfoForm.isAvailable) {
      setFormData({ ...formData, isAvailable: value === markAvailable });
    } else setFormData({ ...formData, [key]: value });
  };

  const hasSalaryChanged = (): boolean => {
    const isValueChange =
      extractSalaryAmount({ salary: data?.profile?.expectedSalary }) !==
      formData[ProfileInfoForm.expectedSalary];
    const isUnitChange =
      extractSalaryAmount({ salary: data?.profile?.expectedSalary, unit: true }) !==
      expectedSalaryCountry;
    return isValueChange || isUnitChange;
  };

  const expectedSalaryCheck = useMemo(() => expectedSalaryStatus(statusMeta), [statusMeta]);

  const handleSave = (): void => {
    setIsSubmitted(true);
    const input: ISubmitProfileInput = {
      expectedVersion: data?.profile?.version,
      id: data?.profile?.id,
      isAvailable: formData[ProfileInfoForm.isAvailable],
      currentSalary: `${formData[ProfileInfoForm.currentSalary]} ${currentSalaryCountry}`,
    };
    if (hasSalaryChanged()) {
      input.expectedSalary = `${formData[ProfileInfoForm.expectedSalary]} ${expectedSalaryCountry}`;
    }
    updateProfile?.({
      variables: {
        input,
      },
      onCompleted: () => {
        showMessage?.({ title: submissionSuccessTitle, message: submissionProfileSuccessMessage });
        if (hasSalaryChanged()) {
          analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE_SALARY);
        }
      },
      onError: () => {
        showMessage?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
        if (hasSalaryChanged()) {
          analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE_SALARY_FAILED);
        }
      },
    });
    updateInfo?.({
      variables: {
        input: {
          id: data?.id,
          fullName: `${formData[ProfileInfoForm.firstName]} ${formData[ProfileInfoForm.lastName]}`,
          expectedVersion: data?.version,
        },
      },
    });
  };

  useEffect(() => {
    if (updateTalentData && profileData && isSubmitted) {
      setIsSubmitted(false);
      setTalentData?.({ ...updateTalentData, profile: profileData });
      refetchProfile?.();
      close?.();
    }
  }, [updateTalentData, profileData, isSubmitted]);

  return (
    <Fragment>
      <S.SlimmerWrapper>
        <ShouldRender if={!openConfirmModal}>
          <Fragment>
            <S.SlimmerHeading>Edit Your Profile</S.SlimmerHeading>
            <ProfileInfoFormBody
              formData={formData}
              updateFormData={updateFormData}
              currentSalaryCountry={currentSalaryCountry}
              expectedSalaryCountry={expectedSalaryCountry}
              setCurrentSalaryCountry={setCurrentSalaryCountry}
              setExpectedSalaryCountry={setExpectedSalaryCountry}
              expectedSalaryCheck={expectedSalaryCheck}
            />
            <S.BtnGroup>
              <ActionBtn
                btnText={cancelBtnText}
                clickHandler={close}
                width={actionBtnWidth}
                color={Color.LIGHT_BLUE}
                backgroundColor={Color.TRANSPARENT}
              />
              <ActionBtn
                btnText={saveBtnText}
                isLoading={hasSalaryChanged() ? false : isLoading}
                clickHandler={(): void =>
                  hasSalaryChanged() ? confirmModalHandler() : handleSave()
                }
                width={actionBtnWidth}
                color={Color.WHITE}
                backgroundColor={Color.LIGHT_BLUE}
                disabled={hasSalaryChanged() ? false : isLoading}
              />
            </S.BtnGroup>
          </Fragment>
        </ShouldRender>
        <ModalComponent show={openConfirmModal} handleClose={closeConfirmModal} w94>
          <ConfirmationModal
            heading={lockConfirmationMessage.title}
            message={expectedSalaryCheck.message}
            cancelBtnText={cancelBtnText}
            confirmBtnText={confirmBtnText}
            isLoading={isLoading}
            closeBtnHandler={closeConfirmModal}
            confirmBtnHandler={handleSave}
          />
        </ModalComponent>
      </S.SlimmerWrapper>
    </Fragment>
  );
};

export default UpdateProfileInfo;
