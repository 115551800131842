import { FC, Fragment, useContext, useEffect, useState } from "react";
import { CheckMarkIcon } from "assets/icons";
import {
  ISkillsType,
  ProfileTalentContext,
  ProfileOptions,
  ProfileTalentTypeContext,
  withProfile,
} from "state/profileSteps";
import { CheckBoxInput } from "components";
import { IntrestFieldBtnId, IntrestFieldInputId, ProfileProps } from "utils";
import * as Styled from "./styles";

const InterestedFields: FC<ProfileProps> = ({ profileState: { data } }) => {
  const [interests, setInterests] = useState<Array<string | null>>(data?.profile?.interests || []);
  const checkBoxType = "interests_section_checkbox";
  const {
    talentType: { selectedTalent },
  } = useContext(ProfileTalentTypeContext);
  const { talent, setTalent } = useContext(ProfileTalentContext);
  const updateInterests = (val: string): void => {
    if (interests.includes(val)) {
      const updatedVal = interests.filter((item) => item !== val);
      setInterests(updatedVal);
    } else {
      setInterests((prevState) => [...prevState, val]);
    }
  };
  const handleSubmit = (): void => {
    setTalent({ interests });
  };
  const getInput = (val: ISkillsType): JSX.Element => {
    return (
      <CheckBoxInput
        testingId={IntrestFieldInputId}
        key={val.name}
        type={checkBoxType}
        value={val.name}
        onSelect={updateInterests}
        isChecked={interests.includes(val.name)}
        isBox
        text={val.title}
      />
    );
  };
  const interestedFields = ProfileOptions(selectedTalent?.interests || [], getInput, interests);

  useEffect(() => {
    if (data?.profile?.interests) setInterests(data?.profile?.interests || []);
  }, []);
  useEffect(() => {
    if (talent.interests) setInterests(talent.interests);
  }, [talent]);

  return (
    <Fragment>
      <h2 className="pb-2">
        What kind of <Styled.PurpleFont>work</Styled.PurpleFont> are you most interested in?
      </h2>
      <p />
      <Styled.ProjectTypeSelect>{interestedFields}</Styled.ProjectTypeSelect>
      <Styled.NextBtn
        data-testid={IntrestFieldBtnId}
        onClick={handleSubmit}
        type="button"
        id="multiSelect_nextButton_projectType"
        disabled={!interests.length}
      >
        Next
        <CheckMarkIcon />
      </Styled.NextBtn>
    </Fragment>
  );
};
export default withProfile(InterestedFields);
