import { FC, useEffect, useState } from "react";
import { Color } from "@remotebase/constants";
import { UseFormReturn } from "react-hook-form";
import moment from "moment";
import { EducationEntry } from "@remotebase/amplify-constants/API";
import {
  addBtnText,
  addNewEducationTitle,
  cancelBtnText,
  certificateLabel,
  certificatePlaceholder,
  educationBtnWidth,
  EducationFormData,
  EducationFormEnum,
  endDateLabel,
  formInputText,
  institutionLabel,
  institutionPlaceholder,
  mandatoryNotification,
  monthPlaceholder,
  SelectOption,
  startDateLabel,
  updateBtnText,
  updateEducationTitle,
  yearPlaceholder,
} from "../../utils";
import { ActionBtn } from "../../buttons";
import { ModalComponent } from "../modalComponent";
import { ProfileEditInput, SelectInput } from "../../formInput";
import { getMonthsDropdown, getYearsDropdown } from "../../helpers";
import * as Styled from "./style";

interface IEducationForm {
  formHook: UseFormReturn<EducationFormData>;
  handleFormSubmission: (data: EducationEntry, index?: number | null) => void;
  close: () => void;
  show: boolean;
}

export const EducationFormModal: FC<IEducationForm> = ({
  handleFormSubmission,
  formHook,
  close,
  show,
}) => {
  const {
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = formHook;

  const [months, setMonths] = useState<SelectOption[]>([]);
  const [startYears, setStartYears] = useState<SelectOption[]>([]);
  const [endYears, setEndYears] = useState<SelectOption[]>([]);

  const [startMonth, startYear, endMonth, endYear, isEdit] = watch([
    EducationFormEnum.startMonth,
    EducationFormEnum.startYear,
    EducationFormEnum.endMonth,
    EducationFormEnum.endYear,
    EducationFormEnum.isEdit,
  ]);

  const onSubmit = (data: EducationFormData): void => {
    const { degree, school, selectedEducationIndex } = data;
    console.log(data);

    const startDate = moment({
      year: Number(startYear),
      month: Number(startMonth),
      day: 1,
    });

    const endDate = moment({
      year: Number(endYear),
      month: Number(endMonth),
      day: 1,
    });

    handleFormSubmission(
      {
        degree,
        school,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      } as EducationEntry,
      isEdit ? selectedEducationIndex : -1,
    );

    close();
  };

  useEffect(() => {
    setMonths(getMonthsDropdown());
    setStartYears(getYearsDropdown());
    setEndYears(getYearsDropdown());
  }, []);

  useEffect(() => {
    setEndYears(getYearsDropdown(startYear));
  }, [startYear]);

  return (
    <ModalComponent show={show} handleClose={close}>
      <Styled.EditorWrapper>
        <Styled.EditSectionHeading>
          {isEdit ? updateEducationTitle : addNewEducationTitle}
        </Styled.EditSectionHeading>
        <Styled.FormBody>
          <Styled.FormGroup>
            <ProfileEditInput
              type={formInputText}
              label={institutionLabel}
              placeholder={institutionPlaceholder}
              title={EducationFormEnum.institution}
              formHook={formHook}
            />
          </Styled.FormGroup>
          <Styled.FormGroup>
            <ProfileEditInput
              type={formInputText}
              label={certificateLabel}
              placeholder={certificatePlaceholder}
              title={EducationFormEnum.certificate}
              formHook={formHook}
            />
          </Styled.FormGroup>
          <Styled.FormGroup>
            <Styled.SelectLabel>{startDateLabel}</Styled.SelectLabel>
            <Styled.MonthYearWrapper>
              <SelectInput
                placeholder={monthPlaceholder}
                selected={months.find((it) => it.value === String(startMonth))}
                options={months}
                handleSingleSelect={(option): void =>
                  setValue(EducationFormEnum.startMonth, option.value)
                }
                errorMsg={errors[EducationFormEnum.startMonth]?.message}
                removeMargin
              />
              <SelectInput
                placeholder={yearPlaceholder}
                selected={startYears.find((it) => it.value === String(startYear))}
                options={startYears}
                handleSingleSelect={(option): void =>
                  setValue(EducationFormEnum.startYear, option.value)
                }
                errorMsg={errors[EducationFormEnum.startYear]?.message}
                removeMargin
              />
            </Styled.MonthYearWrapper>
          </Styled.FormGroup>

          <Styled.FormGroup>
            <Styled.SelectLabel>{endDateLabel}</Styled.SelectLabel>
            <Styled.MonthYearWrapper>
              <SelectInput
                placeholder={monthPlaceholder}
                selected={months.find((it) => it.value === String(endMonth))}
                options={months}
                handleSingleSelect={(option): void =>
                  setValue(EducationFormEnum.endMonth, option.value)
                }
                errorMsg={errors[EducationFormEnum.endMonth]?.message}
                removeMargin
              />
              <SelectInput
                placeholder={yearPlaceholder}
                selected={endYears.find((it) => it.value === String(endYear))}
                options={endYears}
                handleSingleSelect={(option): void =>
                  setValue(EducationFormEnum.endYear, option.value)
                }
                errorMsg={errors[EducationFormEnum.endYear]?.message}
                removeMargin
              />
            </Styled.MonthYearWrapper>
          </Styled.FormGroup>
          <Styled.FootNote>{mandatoryNotification}</Styled.FootNote>
        </Styled.FormBody>
        <Styled.BtnGroup>
          <ActionBtn
            btnText={cancelBtnText}
            clickHandler={close}
            width={educationBtnWidth}
            color={Color.LIGHT_BLUE}
            backgroundColor={Color.TRANSPARENT}
          />
          <ActionBtn
            btnText={isEdit ? updateBtnText : addBtnText}
            clickHandler={handleSubmit(onSubmit)}
            width={educationBtnWidth}
            color={Color.WHITE}
            backgroundColor={Color.LIGHT_BLUE}
          />
        </Styled.BtnGroup>
      </Styled.EditorWrapper>
    </ModalComponent>
  );
};

export default EducationFormModal;
