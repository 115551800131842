import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledProps } from "utils";

export const JoinMeeting = styled(Link)`
  background-color: #1565d8;
  color: #ffffff;
  padding: 0.875rem 1.5rem;
  width: 9rem;
  border-radius: 0.25rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
    width: 7rem;
  }
`;

export const BookSlot = styled.button<{
  isCompleted?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  background-color: transparent;
  padding: ${(props): StyledProps => (props.isCompleted ? "0.875rem 1rem" : "0.875rem 1.5rem")};
  width: ${(props): StyledProps => (props.isCompleted ? "10rem" : "9rem")};
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
  color: #717171;
  cursor: ${(props): StyledProps => (props.disabled ? "normal" : "pointer")};
  opacity: ${(props): StyledProps => (props.disabled ? "0.4" : "1")};

  @media (max-width: 600px) {
    font-size: 0.8rem;
    padding: ${(props): StyledProps => (props.isCompleted ? "0.65rem 1rem" : "0.65rem 1.2rem")};
    width: ${(props): StyledProps => (props.isCompleted ? "8rem" : "7rem")};
  }
`;

export const BtnText = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-size: 0.9325rem;

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;
