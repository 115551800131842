import styled from "styled-components";

export const ChatPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 1600px;
  margin: 0px auto;
  overflow-x: hidden;
  z-index: 2;
  @media only screen and (min-width: 768px) {
    overflow: hidden;
  }
`;

export default ChatPageContainer;
