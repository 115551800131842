import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const ChatBodyHeader = styled.div`
  width: 100%;
  padding: 10px 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f0f2f5;
  border-left: #d1d7db;
  flex-shrink: 0;
  position: fixed;
  top: 0px;
  bottom: auto;
  left: 0px;
  right: 0px;
  @media only screen and (min-width: 768px) {
    border-left: 1px solid #d1d7db;
    position: relative;
  }
`;

export const ChatBodyProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
`;

export const ImgAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  background: #dee2e6;
  color: #000000;
  text-align: center;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

export const ChatBodyProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChatBodyProfileTitle = styled.div`
  display: flex;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  color: #111b21;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  margin-left: 16px;
`;

export const ChatBodyHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ChatBodyHeaderDropDown = styled(Dropdown)`
  & .btn,
  & .btn-success {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 18px;
    color: #3b4a54;
    font-weight: 500;
    min-width: auto;
    min-height: auto;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 0.3s ease;
    svg {
      fill: #54656f;
      width: 20px;
      height: 20px;
    }
  }

  &.show,
  &.show > {
    & .btn-success.dropdown-toggle,
    & .btn-success.dropdown-toggle:focus {
      background-color: rgba(11, 20, 26, 0.1);
      border: none;
      border-radius: 50%;
      box-shadow: none;
      color: #3b4a54;
    }
  }
  & .dropdown-toggle:not(:disabled):not(.disabled):active:focus,
  & .dropdown-toggle:not(:disabled):not(.disabled):active,
  & .dropdown-toggle:hover,
  & .dropdown-toggle:focus {
    background-color: rgba(11, 20, 26, 0.1);
    border: none;
    border-radius: 50%;
    box-shadow: none;
    color: #3b4a54;
    svg {
      fill: #54656f;
      width: 20px;
      height: 20px;
    }
  }

  & .dropdown-toggle:after {
    content: none;
  }

  & .dropdown-toggle svg {
    fill: #54656f;
    width: 20px;
    height: 20px;
  }

  & .dropdown-menu {
    border-radius: 12px;
  }
  & .dropdown-item {
    font-size: 14px;
    color: #383838;
    padding: 8px 20px;
  }

  & .dropdown-item:hover,
  & .dropdown-item:focus,
  & .dropdown-item:active {
    color: #383838;
    background-color: #e9ecef;
  }
  @media screen and (max-width: 575px) {
    & .dropdown-toggle {
      width: 100%;
    }
    & .dropdown-menu {
      width: 100%;
    }
  }
`;
