import { Job, TechDomainSkills, matchStage, JobMatch } from "@remotebase/amplify-constants/API";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BtnLoader, ShouldRender } from "../..";
import {
  EmploymentTypeIcon,
  IndustryIcon,
  JobFunctionIcon,
  LandingSkillsLine,
  VerifiedIcon,
} from "../../assets/icons";
import { ToastDisplay } from "../../toast/ToastDisplay";
import { checkJobSkill, getTimeDifferenceAndLabel, isEligible } from "../../helpers";
import {
  AuthRoutes as Route,
  EmploymentType,
  QueryParams,
  JOB_PERKS_DESCRIPTION,
  CreateMatchJobProps,
  SkillsNameMap,
} from "../../utils";
import JobDetailShimmer from "./jobDetailsShimmer";
import * as Styled from "./styles";

export interface IJobProps {
  talentSkills?: (TechDomainSkills | undefined)[];
  jobLoading: boolean;
  jobFound: Job | null;
  isTalent?: boolean;
  createMatchLoading?: boolean;
  result?: JobMatch | null;
  profileId?: string;
  isApplied?: boolean;
  isMatched?: boolean;
  handleCreateJobMatch?: (payload: CreateMatchJobProps) => void;
}

export const TalentJobDetails: FC<IJobProps> = ({
  talentSkills,
  jobFound,
  jobLoading,
  isTalent,
  profileId,
  createMatchLoading,
  result,
  handleCreateJobMatch,
  isApplied,
  isMatched,
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const { ID, PAGE } = QueryParams;
  const pathState = location.state as { [PAGE]: string };
  const query = new URLSearchParams(location.search);
  const [currentStatus, setCurrentStatus] = useState(isApplied);

  const id = query.get(ID);

  if (!id) {
    push(Route.Jobs);
  }

  const backArrowOnClick = (): string => {
    if (!pathState) return Route.Jobs;
    return `${Route.Jobs}?${PAGE}=${pathState[PAGE]}`;
  };

  const getLastUpdatedTime = (updatedAt?: string): string => {
    if (!updatedAt) return "";
    const [value, label] = getTimeDifferenceAndLabel(new Date(updatedAt), new Date());
    const s = value === 1 ? "" : "s";
    return `${value} ${label}${s} ago`;
  };

  const jobResponsibilites = jobFound?.responsibilites?.split("\n").filter((item) => item);
  const jobType = jobFound?.isPermanent ? EmploymentType.PERMANENT : EmploymentType.CONTRACTUAL;

  const isDataFetched = !jobLoading && jobFound;

  const eligibility = useMemo(() => {
    if (!talentSkills) return false;
    return isEligible(talentSkills, jobFound?.requiredTechDomainSkills);
  }, [talentSkills, jobFound]);

  const handleInterestedClick = (): void => {
    const payload = {
      talentID: profileId,
      jobID: jobFound?.id,
      stage: matchStage.interested,
    };
    handleCreateJobMatch?.(payload);
  };

  useEffect(() => {
    if (isApplied) setCurrentStatus(isApplied);
  }, [isApplied]);

  useEffect(() => {
    if (!createMatchLoading && result) {
      setCurrentStatus(result.stage === matchStage.interested);
    }
  }, [result, createMatchLoading]);

  const showApplyBtn = useMemo(() => {
    if (isTalent && eligibility) {
      if (isMatched && !currentStatus) return false;
      return true;
    }
    return false;
  }, [isTalent, eligibility, isMatched, currentStatus]);

  return (
    <Fragment>
      <ShouldRender if={!isDataFetched}>
        <JobDetailShimmer />
      </ShouldRender>

      <ShouldRender if={isDataFetched}>
        <Styled.CardWrapper>
          <Styled.BackArrow to={backArrowOnClick}>
            <LandingSkillsLine />
          </Styled.BackArrow>
          <Styled.H3>{jobFound?.title}</Styled.H3>

          <Styled.NameLocation>
            <Styled.Span>{jobFound?.company?.name}</Styled.Span>
            <Styled.Dot></Styled.Dot>
            <Styled.Span>{getLastUpdatedTime(jobFound?.updatedAt)}</Styled.Span>
          </Styled.NameLocation>
          <Styled.HR />

          <Styled.JobDetails>
            <Styled.AboutSection>
              <Styled.H5>About the Job</Styled.H5>
              <Styled.Paragraph
                dangerouslySetInnerHTML={{ __html: jobFound?.description! }}
              ></Styled.Paragraph>

              <ShouldRender if={jobResponsibilites?.length}>
                <>
                  <Styled.H6>Responsibilities:</Styled.H6>
                  <Styled.UL>
                    {jobResponsibilites?.map((responsibility) => (
                      <Styled.LI key={responsibility}>{responsibility}</Styled.LI>
                    ))}
                  </Styled.UL>
                </>
              </ShouldRender>

              <ShouldRender
                if={
                  jobFound?.jobPerks &&
                  jobFound?.jobPerks?.length > 0 &&
                  !jobFound?.description.includes("Benefits")
                }
              >
                <React.Fragment>
                  <Styled.H6>Benefits:</Styled.H6>
                  <Styled.UL>
                    {jobFound?.jobPerks?.map(
                      (jobPerkKey) =>
                        jobPerkKey && (
                          <Styled.LI key={jobPerkKey}>
                            {JOB_PERKS_DESCRIPTION[jobPerkKey]}
                          </Styled.LI>
                        ),
                    )}
                  </Styled.UL>
                </React.Fragment>
              </ShouldRender>

              <ShouldRender if={jobFound?.requiredTechDomainSkills?.length}>
                <>
                  <Styled.H6>Skills:</Styled.H6>
                  <Styled.BtnGroup>
                    {jobFound?.requiredTechDomainSkills?.map((skill, index) => (
                      <Styled.StackBtn key={index}>
                        {skill && SkillsNameMap[skill]}
                        <Styled.Span>
                          <ShouldRender if={talentSkills && checkJobSkill(talentSkills, skill)}>
                            <VerifiedIcon />
                          </ShouldRender>
                        </Styled.Span>
                      </Styled.StackBtn>
                    ))}
                  </Styled.BtnGroup>
                </>
              </ShouldRender>
            </Styled.AboutSection>

            <Styled.PriceSection>
              <Styled.JobPost>
                <Styled.JobText>Job posted by</Styled.JobText>
                <Styled.PostLogoWrap>
                  <Styled.LogoWrapper>
                    <Styled.CompanyLogo src={jobFound?.company?.logo} alt="company logo" />
                  </Styled.LogoWrapper>
                  <Styled.CompanyName>
                    <Styled.H6>{jobFound?.company?.name}</Styled.H6>
                    <Styled.Span>{jobFound?.company?.address?.city}</Styled.Span>
                  </Styled.CompanyName>
                </Styled.PostLogoWrap>
                <Styled.JobText>{jobFound?.company?.description}</Styled.JobText>
              </Styled.JobPost>

              <Styled.DetailsSection>
                <Styled.Span>Details</Styled.Span>

                <Styled.DetailType>
                  <Styled.IconWrapper>
                    <IndustryIcon width={15} />
                  </Styled.IconWrapper>
                  <Styled.DetailText>
                    <Styled.H6>{jobFound?.company?.industry}</Styled.H6>
                    <Styled.Span>Industry</Styled.Span>
                  </Styled.DetailText>
                </Styled.DetailType>

                <Styled.DetailType>
                  <Styled.LogoWrapper>
                    <EmploymentTypeIcon width={23} />
                  </Styled.LogoWrapper>
                  <Styled.DetailText>
                    <Styled.H6>{jobType}</Styled.H6>
                    <Styled.Span>Employment Type</Styled.Span>
                  </Styled.DetailText>
                </Styled.DetailType>

                <Styled.DetailType>
                  <Styled.LogoWrapper>
                    <JobFunctionIcon width={23} />
                  </Styled.LogoWrapper>
                  <Styled.DetailText>
                    <Styled.H6>{jobFound?.department}</Styled.H6>
                    <Styled.Span>Job Functions</Styled.Span>
                  </Styled.DetailText>
                </Styled.DetailType>
              </Styled.DetailsSection>

              <ShouldRender if={showApplyBtn}>
                <Fragment>
                  <ShouldRender if={createMatchLoading}>
                    <Styled.ApplyBtn>
                      <BtnLoader />
                    </Styled.ApplyBtn>
                  </ShouldRender>
                  <ShouldRender if={!createMatchLoading}>
                    <Styled.ApplyBtn
                      isApplied={currentStatus}
                      disabled={currentStatus || isMatched}
                      onClick={handleInterestedClick}
                    >
                      {currentStatus || isMatched ? "Applied" : "Apply"}
                    </Styled.ApplyBtn>
                  </ShouldRender>
                </Fragment>
              </ShouldRender>
            </Styled.PriceSection>
          </Styled.JobDetails>
          <ShouldRender if={result && !createMatchLoading}>
            <Styled.ToastWrapper>
              <ToastDisplay title="Success" message="Thank you for applying." />
            </Styled.ToastWrapper>
          </ShouldRender>
        </Styled.CardWrapper>
      </ShouldRender>
    </Fragment>
  );
};
