import React from "react";
import { CompleteProfile } from "components";
import ProfileStepper from "./common";

export const CompleteProfilePage: React.FC = () => {
  return (
    <ProfileStepper>
      <CompleteProfile />
    </ProfileStepper>
  );
};

export default CompleteProfilePage;
