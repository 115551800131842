import React, { useState } from "react";
import { ResumeData } from "@remotebase/amplify-constants/API";
import { ParsedResumeContext } from "./parsedResume.context";

export const ResumeParsedProvider: React.FC = (props) => {
  const [parsedResumeData, setParsedResumeData] = useState<ResumeData | null | undefined>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  return (
    <ParsedResumeContext.Provider
      value={{
        parsedResumeData,
        setParsedResumeData,
        initialized,
        setInitialized,
      }}
    >
      {props.children}
    </ParsedResumeContext.Provider>
  );
};

export default ResumeParsedProvider;
