import { Chat } from "components";
import React from "react";
import { ChatProvider } from "state/chat";
import * as Styled from "./styles";

export const ChatPage: React.FC = () => {
  return (
    <Styled.ChatPageWrapper>
      <ChatProvider>
        <Chat />
      </ChatProvider>
    </Styled.ChatPageWrapper>
  );
};

export default ChatPage;
