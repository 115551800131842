import { ShouldRender } from "@remotebase/components";
import { BtnLoader } from "components";
import { getIcon } from "hooks/utils";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { ICONS, purpleBlueColor, TestimonialModalId } from "utils";
import * as Styled from "./styles";

interface Props {
  onCancel?: () => void;
  storyLink: string;
  openModal: boolean;
}

export const MeetTeamModal: FC<Props> = ({ onCancel, storyLink, openModal }) => {
  const [toggleLoader, setToggleLoader] = useState(true);

  const setLoader = (): void => {
    setToggleLoader(!toggleLoader);
  };

  return (
    <Styled.MeetTeamModal
      data-testid={TestimonialModalId}
      show={openModal}
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Body>
        <ShouldRender if={toggleLoader}>
          <BtnLoader color={purpleBlueColor} />
        </ShouldRender>
        <Styled.CrossIconBox onClick={onCancel}>{getIcon(ICONS.CLOSE)}</Styled.CrossIconBox>
        <div className="embed-responsive embed-responsive-16by9">
          <Styled.Iframe src={storyLink} onLoad={setLoader}></Styled.Iframe>
        </div>
      </Modal.Body>
    </Styled.MeetTeamModal>
  );
};

export default MeetTeamModal;
