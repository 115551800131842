export const listCalendarBookingsForDeveloper = /* GraphQL */ `
  query ListCalendarBookings(
    $filter: ModelCalendarBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        eventId
        startTime
        endTime
        meetingLink
        engineerId
        jobMatchId
        inviteToken
        feedback
        createdAt
        updatedAt
        version
        client {
          id
          fullName
          company {
            id
            name
            logo
          }
        }
      }
      nextToken
    }
  }
`;

export default listCalendarBookingsForDeveloper;
