import React from "react";
import { BasicTalentInfo } from "components";
import ProfileStepper from "./common";

export const BasicTalentInfoPage: React.FC = () => {
  return (
    <ProfileStepper>
      <BasicTalentInfo />
    </ProfileStepper>
  );
};

export default BasicTalentInfoPage;
