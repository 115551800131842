import styled from "styled-components";
import { Button } from "react-bootstrap";

export const BtnsBox = styled.div`
  padding-top: calc(1rem * 0.5);
`;

export const BtnStart = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 124px;
  min-height: 34px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #11142d;
  transition: all 0.25s;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #3c405c;
    box-shadow: none;
    border: none;
    color: #fff;
  }
  &:disabled {
    background: black;
    opacity: 0.3;
  }
`;

export const BtnLoaderBox = styled(Button)`
  font-size: 16px;
  position: relative;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.025em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #11142d;
  border: none;
  border-radius: 10px;
  width: auto;
  min-height: 48px;
  min-width: 190px;
  padding: 4px 12px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #3c405c;
    box-shadow: none;
    border: none;
    color: #fff;
  }
  &:disabled {
    background: black;
    opacity: 0.3;
  }

  & div {
    left: initial;
    right: initial;
    top: initial;
    bottom: initial;
    position: relative;
    max-width: 28px;
    max-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 auto 8px;
  }
`;

export const Btn = styled.div`
  margin-top: 16px;
  & a {
    color: #270beb;
    font-size: 16px;
    &:hover,
    &:focus {
      color: #5441d7;
    }
  }
`;

export const TalentlyBtn = styled(Button)`
  position: relative;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 200px;
  min-height: 48px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #5aa4f5;
  transition: all 0.25s;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #3c405c;
    box-shadow: none;
    border: none;
    color: #fff;
  }
  &:disabled {
    background: black;
    opacity: 0.3;
  }
`;

export const LinkBtn = styled.a`
  display: block;
  text-align: left;
  color: #270beb;
  font-size: 14px;
  padding-top: 12px;
  min-width: 200px;
  font-family: "Poppins", sans-serif;
  &:hover {
    cursor: pointer;
  }
`;
