import styled from "styled-components";

export const PurpleFont = styled.span`
  color: #4c40f7;
`;

export const NextBtn = styled.button`
  opacity: 1;
  position: relative;
  background: #4c40f7;
  color: #ffffff;
  font-size: 14px;
  background: #ff2c2c;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  transition: all 0.2s;
  color: white !important;
  font-weight: 700;
  border: none;
  min-height: 48px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  &:disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  color: #ffffff;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;

export const MembersSelect = styled.div`
  width: 100%;
  border-radius: 6px;
  background: white;
  box-shadow: 0 12px 20px 2px rgba(0, 0, 0, 0.17);
  padding: 25px 25px 20px;
  margin-bottom: 30px;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + label {
    background: #001b4e;
    color: white;
  }
  input[type="radio"] + label {
    width: 100%;
    height: 52px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  input[type="radio"] + label {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-right: 1px solid #c6cbd3;
  }

  @media (max-width: 767px) {
    padding: 15px;
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked + label {
      background: #001b4e;
      color: white;
    }
    input[type="radio"] + label {
      height: 50px;
      padding: 0 10px;
      font-size: 20px;
    }
    input[type="radio"] + label:last-child {
      width: 100%;
      border-top: 1px solid #c6cbd3;
      border-right: 0;
      flex-basis: auto;
    }
    input[type="radio"] + label:nth-last-of-type(2) {
      border-right: none;
    }
  }
  @media (max-width: 390px) {
    .includes {
      display: none;
    }
  }
`;

export const MembersSelectRadios = styled.div`
  border-radius: 6px;
  border: 1px solid #c6cbd3;
  overflow: hidden;
  .row .radio:not(:last-child) input[type="radio"] + label {
    border-right: 1px solid #c6cbd3;
  }

  @media (max-width: 767px) {
    border-radius: 6px;
    border: 1px solid #c6cbd3;
    overflow: hidden;
    .row .radio:not(:last-child) input[type="radio"] + label {
      border-right: 1px solid #c6cbd3;
    }
    .row {
      margin: 0;
    }
    .col-auto {
      padding: 0;
    }
  }
`;
