import React, { useMemo } from "react";
import AOS from "aos";
import { LandingSkillsList } from "utils";
import { ILandingSkillsList } from "state/landing";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { Link } from "react-router-dom";
// import { truncate } from "lodash";
import LandingSkillSingle from "./LandingSkillSingle";
import * as S from "./styles";
import BtnGroup from "./btnGroup";

export const LandingSkills: React.FC = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    tabletL: {
      breakpoint: { max: 1199, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const getSkillList = (skill: ILandingSkillsList, index: number): JSX.Element => {
    return <LandingSkillSingle data-testid={"landing_skill"} key={`skill${index}`} skill={skill} />;
  };

  const mapSkills = useMemo(
    () => LandingSkillsList?.map((skill, index) => getSkillList(skill, index)),
    [LandingSkillsList],
  );

  AOS.init();
  return (
    <S.SkillsWrapper id="skills">
      <S.Container>
        <S.SkillsTitle className="aos-item" data-aos="fade-up" data-aos-offset="200">
          We Are Looking For You!
        </S.SkillsTitle>
        <S.SkillsInfo className="aos-item" data-aos="fade-up" data-aos-offset="300">
          These are the top skills we are currently hiring for. If you think you are an expert in
          them and passionate about growth, then apply away! Check out these remote jobs, which can
          bring you great exposure, insane career growth, and benefits.
        </S.SkillsInfo>
        <S.SkillsContent>
          <S.DevCarousel>
            <Carousel
              showDots={true}
              responsive={responsive}
              arrows={false}
              sliderClass="devCarousel__ul"
              itemClass="devCarousel__li"
              containerClass="dev__carousel"
              renderButtonGroupOutside={true}
              customButtonGroup={<BtnGroup />}
            >
              {/* <S.SkillsList> */}
              {mapSkills}
              {/* </S.SkillsList> */}
            </Carousel>
          </S.DevCarousel>
          {/* <S.SkillsBtnCanvas className="aos-item" data-aos="fade-up" data-aos-delay="400">
            <Link to={UnAuthRoutes.Login}>Take the Test</Link>
          </S.SkillsBtnCanvas> */}
        </S.SkillsContent>
      </S.Container>
    </S.SkillsWrapper>
  );
};
export default LandingSkills;
