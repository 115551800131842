import { FC, useEffect, useMemo, useState, Fragment } from "react";
import moment from "moment";
import { TabOptions, ProfileProps } from "utils";
import { ErrorProps } from "@remotebase/constants";
import { ShouldRender } from "@remotebase/components";
import { CalendarBooking } from "@remotebase/amplify-constants/API";
import withError from "state/error/withErrorHoc";
import { withProfile } from "state/profileSteps";
import { useListCalendarBooking } from "hooks";
import { CalenderBookingShimmer } from "shimmerPages";
import { MeetingCard, EmptyBooking } from "./components";
import * as S from "./styled";

export const BookingsDisplay: FC<ProfileProps & ErrorProps> = ({ profileState: { data } }) => {
  const { listBooking, loading, result } = useListCalendarBooking();
  const [currentTab, setCurrentTab] = useState("recent");

  const handleTabChange = (tab: string): void => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (data?.id)
      listBooking({
        variables: {
          filter: { engineerId: { eq: data.id } },
        },
      });
  }, [data]);

  const tabData = useMemo(() => {
    if (!result) return [];

    const unScheduledBookings: CalendarBooking[] = [];
    const pastScheduledBookings: CalendarBooking[] = [];
    const futureScheduledBookings: CalendarBooking[] = [];

    for (const booking of result) {
      if (booking && !booking.meetingLink) {
        unScheduledBookings.push(booking);
      }
      if (booking?.meetingLink && moment(booking.endTime) > moment()) {
        futureScheduledBookings.push(booking);
      }
      if (booking?.meetingLink && moment(booking.endTime) <= moment()) {
        pastScheduledBookings.push(booking);
      }
    }
    futureScheduledBookings.sort(
      (a, b) => moment(a?.startTime).unix() - moment(b?.startTime).unix(),
    );
    pastScheduledBookings.sort((a, b) => moment(b?.endTime).unix() - moment(a?.endTime).unix());

    if (TabOptions.Completed === currentTab) {
      return pastScheduledBookings;
    }
    return [...futureScheduledBookings, ...unScheduledBookings];
  }, [currentTab, result]);

  return (
    <Fragment>
      <ShouldRender if={loading}>
        <CalenderBookingShimmer />
      </ShouldRender>
      <ShouldRender if={!loading}>
        <S.TabWrapper>
          <S.HeadingWrapper>
            {Object.keys(TabOptions).map((option) => (
              <S.TabHeading
                onClick={(): void => handleTabChange(TabOptions[option])}
                isActive={currentTab === TabOptions[option]}
              >
                {option}
              </S.TabHeading>
            ))}
          </S.HeadingWrapper>
          <S.HorizontalDivider />
          <ShouldRender if={tabData.length >= 1}>
            <Fragment>
              {tabData?.map((meeting) => (
                <ShouldRender key={meeting?.id} if={meeting}>
                  <MeetingCard key={meeting?.id} meetingDetails={meeting} tab={currentTab} />
                </ShouldRender>
              ))}
            </Fragment>
          </ShouldRender>
          <ShouldRender if={tabData.length < 1}>
            <EmptyBooking />
          </ShouldRender>
        </S.TabWrapper>
      </ShouldRender>
    </Fragment>
  );
};

export default withProfile(withError(BookingsDisplay));
