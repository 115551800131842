import styled from "styled-components";

export const ChatBodyMsgsLoader = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatBodyMsgsInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
`;

export const ChatBodyMsgTimeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 16px auto 0px auto;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  text-align: center;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 1px 2.5px rgba(11, 20, 26, 0.25);
  border-radius: 8px;
  padding: 8px 16px;
  text-transform: capitalize;

  &:first-child {
    margin: 0px auto 0px auto;
  }
`;

export const ChatBodyMsgBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 13px;
  line-height: 18px;
  color: #111b21;
  max-width: 250px;
  width: auto;
  text-align: left;
  margin: 16px auto 0px 0px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 1px 3.5px rgba(11, 20, 26, 0.25);
  border-radius: 10px;
  padding: 8px 12px;
  @media only screen and (min-width: 420px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 320px;
    border-radius: 12px;
    padding: 8px 16px;
  }
  @media only screen and (min-width: 576px) {
    max-width: 460px;
  }
`;

export const ChatBodyReplyBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  text-align: left;
  max-width: 460px;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.9);
  margin: 16px 0px 0px auto;
  background-color: rgb(54, 21, 189);
  border: none;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  border-radius: 10px;
  padding: 8px 12px;
  @media only screen and (min-width: 420px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 320px;
    border-radius: 12px;
    padding: 8px 16px;
  }
  @media only screen and (min-width: 576px) {
    max-width: 460px;
  }
`;

export const ChatBodyMessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 24px;
  background-color: rgba(243, 243, 241, 0.3);
  margin-top: 60px;
  margin-bottom: 54px;
  flex-grow: 1;
  height: calc(100vh - 114px);
  & > div {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dfdbdb;
    border-radius: 0px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 0px;
    margin-bottom: 0px;
    height: auto;
  }
`;
