import { IRefStatus } from "state/referral";
import { ReferralStages as RefStatus } from "@remotebase/constants";

export const refInvalidEmail = "abc@asd";

export const refCopyLinkButtonTestId = "ref-copylink-button";
export const refCopyLinkBoxTestId = "ref-copylink-box";

export const refTwitterIconTestId = "ref-twitter-icon";
export const refFacebookIconTestId = "ref-facebook-icon";
export const refLinkedInIconTestId = "ref-linkedin-icon";

export const refTrackerBoxTestId = "ref-trackerbox-";
export const refCombinedDataTesting: IRefStatus = {
  [RefStatus.Total]: 10,
  [RefStatus.Joinned]: 1,
  [RefStatus.ClearedDomainTest]: 1,
  [RefStatus.FailedDomainTest]: 1,
  [RefStatus.ClearedPSTest]: 1,
  [RefStatus.FailedPSTest]: 1,
  [RefStatus.ClearedInterviews]: 1,
  [RefStatus.Rejected]: 1,
  [RefStatus.Networked]: 1,
  [RefStatus.Hired]: 1,
  [RefStatus.ThreeMonthsCompleted]: 1,
};

export const refFilledTableTestId = "ref-tracker-table-filled";
export const refEmptyTableTestId = "ref-tracker-table-empty";
export const mockReferralData = [
  {
    email: "remote.test1@mailinator.com",
    status: RefStatus.Joinned,
  },
  {
    email: "remote.test2@mailinator.com",
    status: RefStatus.Hired,
  },
  {
    email: "remote.test3@mailinator.com",
    status: RefStatus.ThreeMonthsCompleted,
  },
  {
    email: "remote.test4@mailinator.com",
    status: RefStatus.ClearedDomainTest,
  },
];
