import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.div`
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // z-index: 10;
  padding: 22px 0;
  font-family: "SF Pro Display";

  background: transparent;
  transition-property: background;
  &.aos-animate {
    // position: fixed;
    padding: 22px 0;
    background-color: #fff;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1328px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const HeaderLogoBox = styled(Link)`
  position: relative;
  z-index: 15;
  margin-right: auto;
  font-size: 0;
  width: 186px;
`;

export const HeaderLogoImg = styled.img`
  max-width: 100%;
`;

export const HeaderLineBox = styled.div`
  display: none;
`;

export const HeaderWrap = styled.div`
  display: flex;
  flex-grow: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 16;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  max-height: 100%;
  padding: 120px 32px 40px;
  background: #232340;
  transform: translateX(-100%);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  &.visible {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  @media only screen and (min-width: 768px) {
    position: static;
    flex-direction: row;
    transform: none;
    background: transparent;
    padding: 0;
    min-height: 10px;
  }
`;

export const HeaderCross = styled.a`
  font-size: 24px;
  color: #606060;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 28px;
  top: 36px;
  background-color: #fff;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 auto;
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: auto;
  }
`;

export const HeaderItem = styled.a`
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  position: relative;
  transition: color 0.25s;
  margin-bottom: 24px;

  &:hover {
    color: #3615bd;
    text-decoration: none;
  }
  &.active {
    color: #3615bd;
  }

  @media only screen and (min-width: 768px) {
    color: #000;
    margin: 0 30px;
    &:hover {
      color: #3615bd;
      text-decoration: none;
    }
    &.active {
      color: #3615bd;
    }
  }
`;

export const HeaderBtnCanvas = styled.div`
  align-items: center;
  justify-content: center;
  display: none;

  & a {
    &:first-child {
      border: none;
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const HeaderBtn = styled(Link)`
  font-size: 16px;
  color: #3615bd;
  font-weight: 400;
  position: relative;
  border: 1px solid #3615bd;
  min-width: 200px;
  height: 55px;
  border-radius: 40px;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  overflow: hidden;
  background-color: transparent;

  &:before {
    content: "";
    background: #3615bd;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 0;
    border-radius: 50% 50% 0 0/75% 75% 0 0;
    -webkit-transition: height 0.35s;
    -o-transition: height 0.35s;
    transition: height 0.35s;
  }

  &:hover {
    color: #fff;
    border: 1px solid #3615bd;
    text-decoration: none;
    &:before {
      height: 200%;
    }
  }
`;

export const HeaderBurger = styled.button`
  position: relative;
  z-index: 15;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background-color: transparent;
  text-align: center;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:before,
  &:after {
    content: "";
    display: block;
    width: 32px;
    height: 2px;
    background: #232340;
    -webkit-transition: width 0.3s, background 0.3s, -webkit-transform 0.3s;
    transition: width 0.3s, background 0.3s, -webkit-transform 0.3s;
    -o-transition: transform 0.3s, width 0.3s, background 0.3s;
    transition: transform 0.3s, width 0.3s, background 0.3s;
    transition: transform 0.3s, width 0.3s, background 0.3s, -webkit-transform 0.3s;
  }

  &:before {
    margin-bottom: 8px;
  }

  &.active:before,
  &.active:after {
    background: #ffffff;
  }

  &.active:before {
    -webkit-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg);
  }

  &.active:after {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg);
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const HeaderBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
`;
