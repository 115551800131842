import React, { Fragment } from "react";
import {
  LandingFooter,
  LandingHeader,
  LandingInfo,
  LandingMain,
  LandingProcess,
  LandingSkills,
  LandingGetStarted,
  LandingTraits,
  LandingJobs,
  LandingDev,
  LandingFaq,
  Ctobanner,
} from "./components";

export const LandingInner: React.FC = () => {
  // refactoting start from here!
  return (
    <Fragment>
      <Ctobanner />
      <LandingHeader />
      <LandingMain />
      <LandingSkills />
      <LandingJobs />
      <LandingInfo />
      <LandingTraits />
      <LandingDev />
      <LandingProcess />
      <LandingFaq />
      {/* NOTE: SKIPPING TESTIMONIALS HERE FOR NOW (FOR SAKE OF COMPLETION) */}
      <LandingGetStarted />
      <LandingFooter />
    </Fragment>
  );
};

export default LandingInner;
