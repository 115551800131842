import { useMemo } from "react";

export const mapOutput = <InputType>(
  inputObject: Array<InputType>,
  outputJSX: (e: InputType, index: number, dependency?: unknown) => JSX.Element,
  dependency?: unknown,
): Array<JSX.Element> =>
  useMemo(
    () => Object.values(inputObject).map((e, index) => outputJSX(e, index)),
    [dependency || inputObject],
  );

export default mapOutput;
