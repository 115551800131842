import styled from "styled-components";

export const CustomLabel = styled.label`
  font-size: 16px;
  line-height: 18px;
  color: #7f8a9c;
  margin-bottom: 14px;
  width: 100%;

  @media (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const CustomError = styled.p`
  color: red !important;
  margin-top: -30px !important;
  margin-bottom: 10px !important;
  font-size: 0.8rem !important;
  float: right;
`;
