import { FC, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withProfile, withTalentLevel } from "state/profileSteps";
import {
  AuthRoutes as Route,
  HrScreenInstructions,
  TalentLevelProps,
  Sidebar,
  ProfileProps,
  HrTechHeading,
  HrNonTechHeading,
} from "utils";

import { VideoWidget } from "components";
import * as Styled from "./styles";

export const HrScreeningPage: FC<TalentLevelProps & ProfileProps> = ({
  validateRoute,
  profileState: { techProfile },
}) => {
  const history = useHistory();
  const getListItem = (inputArray: string[], keyId: string): JSX.Element => {
    return (
      <Fragment>
        {inputArray.map((list, index) => {
          return (
            <Styled.HrScreenLi
              key={`${index} ${keyId}`}
              dangerouslySetInnerHTML={{ __html: list }}
            />
          );
        })}
      </Fragment>
    );
  };

  useEffect(() => {
    if (validateRoute(Sidebar.HRInterview)) history.push(Route.Dashbord);
  }, []);

  const getDisplay = (): JSX.Element => {
    return (
      <>
        {techProfile && (
          <Styled.HrScreeningWrapper>
            <Styled.HrHead> Our assessments have moved. </Styled.HrHead>
            <Styled.HrPara>
              Follow this link: <a href="https://bit.ly/3Z49nE9">https://bit.ly/3Z49nE9</a> to view
              available positions and complete the relevant assessments. Please make sure to apply
              only to roles for which you qualify.
            </Styled.HrPara>
          </Styled.HrScreeningWrapper>
        )}

        {!techProfile && (
          <Styled.HrScreeningWrapper>
            <Styled.HrHead>{techProfile ? HrTechHeading : HrNonTechHeading}</Styled.HrHead>
            <Styled.H3>Instructions:</Styled.H3>
            <Styled.HrPara>
              To ensure a smooth recording of your Video screening, please follow the below
              mentioned:
            </Styled.HrPara>
            <Styled.HrScreenUl>
              {getListItem(HrScreenInstructions, "instruction")}
            </Styled.HrScreenUl>
            <Styled.H3>Evaluation Criteria:</Styled.H3>
            <Styled.HrPara>
              Tell us about yourself and your work experience We will ask you about your past
              projects, product involvement and experience working with other engineers. This will
              help us match the right jobs for you.
            </Styled.HrPara>
            <Styled.H3>What's Next?</Styled.H3>
            <Styled.HrPara>
              Once you have successfully submitted the Video Screening interview, you can proceed to
              the next step i.e. the Problem Solving test.
            </Styled.HrPara>
            <VideoWidget />
          </Styled.HrScreeningWrapper>
        )}
      </>
    );
  };
  return getDisplay();
};

export default withTalentLevel(withProfile(HrScreeningPage));
