import React from "react";
import { FacebookLogo, InstagramLogo, LinkedinLogo } from "assets/icons";
import { imageUrl, redirectToExperience } from "utils";
import * as S from "./styles";

export const LandingFooter: React.FC = () => {
  return (
    <S.Footer id="footer">
      <S.Container>
        <S.FooterBody>
          <S.FooterLogoBox target="_blank" to={{ pathname: redirectToExperience }}>
            <S.FooterLogoImg src={imageUrl.newRBLogo} alt="" />
          </S.FooterLogoBox>

          <S.FooterMenu>
            <S.FooterMenuLink href="#aboutUs">About</S.FooterMenuLink>
            <S.FooterMenuLink href="#skills">Skills</S.FooterMenuLink>
            <S.FooterMenuLink href="#process">Process</S.FooterMenuLink>
          </S.FooterMenu>
          <S.CardMediaIconRow>
            <S.CardMediaIcon
              target="_blank"
              to={{ pathname: "https://www.facebook.com/RemotebaseHQ/" }}
            >
              <FacebookLogo />
            </S.CardMediaIcon>
            <S.CardMediaIcon
              target="_blank"
              to={{ pathname: "https://www.linkedin.com/company/remotebasehq" }}
            >
              <LinkedinLogo />
            </S.CardMediaIcon>
            <S.CardMediaIcon
              target="_blank"
              to={{ pathname: "https://instagram.com/remotebasehq" }}
            >
              <InstagramLogo />
            </S.CardMediaIcon>
          </S.CardMediaIconRow>
        </S.FooterBody>
        <S.FooterBottom>
          <S.FooterCopyright>© 2021, Remotebase.</S.FooterCopyright>
          <S.FooterList>
            <S.FooterLink
              to={"#"}
              onClick={(): void => window.location.replace("https://remotebase.com/privacy")}
            >
              Terms & Conditions
            </S.FooterLink>
            <S.FooterLink
              to={"#"}
              onClick={(): void => window.location.replace("https://remotebase.com/privacy")}
            >
              Privacy
            </S.FooterLink>
          </S.FooterList>
        </S.FooterBottom>
      </S.Container>
    </S.Footer>
  );
};

export default LandingFooter;
