import { useLazyQuery, useMutation } from "@apollo/client";
import { RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import {
  CreateTalentProfileMutation,
  CreateTalentProfileMutationVariables,
  UpdateTalentMutation,
  UpdateTalentMutationVariables,
  GetTalentQuery,
  CreateTalentMutation,
  CreateTalentMutationVariables,
  UpdateTalentProfileWithUsdSalaryMutation,
  UpdateTalentProfileWithUsdSalaryMutationVariables,
  GetTalentQueryVariables,
  TalentProfile,
  Talent,
  GetTalentTypeSortedQuery,
  GetTalentTypeSortedQueryVariables,
  GetTalentTypeQuery,
  GetTalentTypeQueryVariables,
  CreateCandidateMutation,
  CompleteTalentProfileMutation,
} from "API";
import {
  createTalent,
  createTalentProfile,
  updateTalent,
  updateTalentProfileWithUsdSalary,
  createCandidate,
  completeTalentProfile,
} from "graphql/mutations";
import { getTalent, getTalentType } from "graphql/queries";
import { getTalentTypeSorted } from "@remotebase/amplify-constants/modified";
import { useAnalyticsEventCreator } from "hooks/analytics";
import {
  ReturnPropsTalentProfile,
  UseTalentProfileProps,
  GetTalentProfileProps,
  GetTalentTypesProps,
  GetTalentTypesDetailsProps,
  CreateCandidateReturnType,
  CompleteTalentProfileReturnType,
} from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

export const useTalentProfile = (): ReturnPropsTalentProfile => {
  const [
    createProfile,
    { data: createProfileData, loading: createProfileLoading, error: createProfileError },
  ] = useMutation<CreateTalentProfileMutation, CreateTalentProfileMutationVariables>(
    getQuery(createTalentProfile),
  );

  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const [
    updateProfile,
    { data: updateProfileData, loading: updateProfileLoading, error: updateProfileError },
  ] = useMutation<
    UpdateTalentProfileWithUsdSalaryMutation,
    UpdateTalentProfileWithUsdSalaryMutationVariables
  >(getQuery(updateTalentProfileWithUsdSalary), {
    onCompleted() {
      analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE);
    },
    onError() {
      analyticsRegisterEvent(RemoteBaseAnalyticsEvents.TALENT_PROFILE_UPDATE_FAILED);
    },
  });
  return {
    createProfile,
    updateProfile,
    data: (updateProfileData?.updateTalentProfileWithUsdSalary?.data ||
      createProfileData?.createTalentProfile) as TalentProfile,
    isLoading: createProfileLoading || updateProfileLoading,
    error: createProfileError || updateProfileError,
  };
};

export const useTalentsProfile = (): UseTalentProfileProps => {
  const [createInfo, { data: createInfoData, loading: createInfoLoading, error: createInfoError }] =
    useMutation<CreateTalentMutation, CreateTalentMutationVariables>(getQuery(createTalent));

  const [updateInfo, { data: updateInfoData, loading: updateInfoLoading, error: updateInfoError }] =
    useMutation<UpdateTalentMutation, UpdateTalentMutationVariables>(getQuery(updateTalent));

  return {
    createInfo,
    updateInfo,
    data: (updateInfoData?.updateTalent || createInfoData?.createTalent) as Talent,
    isLoading: updateInfoLoading || createInfoLoading,
    error: createInfoError || updateInfoError,
  };
};

export const getTalentProfile = (): GetTalentProfileProps => {
  const [getProfile, { data, loading, error }] = useLazyQuery<
    GetTalentQuery,
    GetTalentQueryVariables
  >(getQuery(getTalent));
  const profileData = data?.getTalent || null;
  const errorData = error || (profileData ? undefined : new Error("No Talent Found"));
  return { loading, getProfile, profileData, error: errorData };
};

export const useGetTalentType = (): GetTalentTypesProps => {
  const [getTalentTypes, { data, loading, error }] = useLazyQuery<
    GetTalentTypeSortedQuery,
    GetTalentTypeSortedQueryVariables
  >(getQuery(getTalentTypeSorted));
  const talentTypes = data?.getTalentTypeSorted?.items || null;
  const errorData = error || (talentTypes ? undefined : new Error("No Talent Types Found"));
  return { loading, getTalentTypes, talentTypes, error: errorData };
};

export const useGetTalentTypeDetails = (): GetTalentTypesDetailsProps => {
  const [getTalentTypeDetail, { data, loading, error }] = useLazyQuery<
    GetTalentTypeQuery,
    GetTalentTypeQueryVariables
  >(getQuery(getTalentType));
  const talentType = data?.getTalentType || null;
  const errorData = error || (talentType ? undefined : new Error("No Talent Type Found"));
  return { loading, getTalentTypeDetail, talentType, error: errorData };
};

export const useCreateCandidate = (): CreateCandidateReturnType => {
  const [createCandidateW, { data, loading, error }] = useMutation<CreateCandidateMutation>(
    getQuery(createCandidate),
  );
  return { createCandidateW, data, loading, error };
};
export const useCompleteTalentProfile = (): CompleteTalentProfileReturnType => {
  const [completeProfile, { data: response, loading, error }] =
    useMutation<CompleteTalentProfileMutation>(getQuery(completeTalentProfile));

  const data = response?.completeTalentProfile?.code === 200;
  return { completeProfile, data, loading, error };
};
