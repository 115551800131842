import styled from "styled-components";

export const FinalTechWrapper = styled.div`
  border: 1px solid #e4e4e4;
  padding: 20px;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  max-width: 840px;
  margin: 0 auto;
  background-color: #fcfcfc;
  margin-top: calc(1rem * 1.5);
`;

export const H3 = styled.h3`
  font-size: 24px;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
`;

export const FinalTechUl = styled.ul`
  padding-left: 28px;
  margin-bottom: 18px;
`;

export const FinalTechLi = styled.li`
  list-style-type: disc;
  margin-bottom: 5px;
  font-size: 14px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;
