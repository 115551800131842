import { FC, Fragment, useContext, useState, useEffect, useMemo } from "react";
import { getParamByISO } from "iso-country-currency";
import { CheckMarkIcon } from "assets/icons";
import { ISalaryData, ProfileTalentContext, withProfile } from "state/profileSteps";
import {
  defaultCurrencyCountry,
  ProfileProps,
  SalaryBtnId,
  SalaryForm,
  SalaryFormSchema,
} from "utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "./styles";
import { CurrencyInput } from "../../../formInput";

const SalarySection: FC<ProfileProps> = ({ profileState: { data } }) => {
  const { setTalent } = useContext(ProfileTalentContext);
  const [currentSalaryCurrency, setCurrentSalaryCurrency] = useState(
    data?.country || defaultCurrencyCountry,
  );
  const [expectedSalaryCurrency, setExpectedSalaryCurrency] = useState(
    data?.country || defaultCurrencyCountry,
  );
  useEffect(() => {
    if (data?.country) {
      const currency = getParamByISO(data.country, "currency");
      setCurrentSalaryCurrency(currency);
      setExpectedSalaryCurrency(currency);
    }
  }, [data]);
  const formHook = useForm({ resolver: yupResolver(SalaryFormSchema), mode: "onChange" });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = formHook;
  const onSubmit = (formData: ISalaryData): void => {
    const currentSalary = `${formData[SalaryForm.currentSalary]} ${currentSalaryCurrency}`;
    const expectedSalary = `${formData[SalaryForm.expectedSalary]} ${expectedSalaryCurrency}`;
    setTalent({ currentSalary, expectedSalary });
  };

  const submitDisabled = useMemo(
    () => (data?.profile && data.profile?.isProfileCompleted !== false && isDirty) || !isValid,
    [data?.profile, isDirty, isValid],
  );

  useEffect(() => {
    if (data?.profile) {
      const currentSalary = data.profile.currentSalary?.split(" ");
      const expectedSalary = data.profile.expectedSalary?.split(" ");

      reset({
        [SalaryForm.currentSalary]: currentSalary?.[0],
        [SalaryForm.expectedSalary]: expectedSalary?.[0],
      });

      setCurrentSalaryCurrency(currentSalary?.[1] || "");
      setExpectedSalaryCurrency(expectedSalary?.[1] || "");
    }
  }, [data?.profile]);

  return (
    <Fragment>
      <h2 className="pb-2">
        What are your <Styled.PurpleFont>salary</Styled.PurpleFont> expectations?
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CurrencyInput
            type="text"
            title={SalaryForm.currentSalary}
            salaryCurrency={currentSalaryCurrency}
            setSalaryCurrency={setCurrentSalaryCurrency}
            handler={formHook}
          />
          <CurrencyInput
            type="text"
            title={SalaryForm.expectedSalary}
            salaryCurrency={expectedSalaryCurrency}
            setSalaryCurrency={setExpectedSalaryCurrency}
            handler={formHook}
          />
        </div>

        <Styled.NextBtn
          data-testid={SalaryBtnId}
          id="multiSelect_nextButton_projectType"
          disabled={submitDisabled}
        >
          Next
          <CheckMarkIcon />
        </Styled.NextBtn>
      </form>
    </Fragment>
  );
};

export default withProfile(SalarySection);
