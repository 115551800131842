import { Button, Tooltip } from "react-bootstrap";
import styled from "styled-components";

export const SchedularRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: rgb(255, 255, 255);
  grid-gap: 20px;
  padding-bottom: 8px;
  margin-bottom: 10px;

  @media only screen and (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &::-webkit-scrollbar {
    width: 200px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const SchedularCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 0.5px solid #d6d6d6;
  border-radius: 6px;
  min-height: 320px;
`;
export const SchedularCardTop = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #1c1f28;
  padding: 16px 16px 20px;
  border-bottom: 0.5px solid #d6d6d6;
  margin-bottom: 20px;
`;
export const SchedularCardBody = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 232px;
  padding: 0px 16px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const DateInput = styled.div`
  input:checked + label {
    background: #3f73f6;
    color: #ffffff;
    border: 1px solid rgb(76, 64, 247);
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }
`;

export const Error = styled.div`
  color: red;
`;

export const SchedularDateBtn = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #1c1f28;
  border: 1px solid #1c1f28;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  border-radius: 8px;
  background: transparent;
  padding: 4px 8px;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;

  &:hover,
  &:focus,
  &.active {
    background: #3f73f6;
    color: #ffffff;
    border: 1px solid rgb(76, 64, 247);
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }
`;

export const SearchButton = styled(Button)`
  font-size: 14px;
  color: #fff;
  position: relative;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 120px;
  min-height: 40px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  margin-top: 10px;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const ButtonTooltip = styled(Tooltip)`
  &.tooltip__null {
    display: none;
  }
`;

export const NoSlotParagraph = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  width: 100%;
  margin: 20% auto;
  text-align: center;
`;
