import { FC, useMemo, useState } from "react";
import { BtnLoader, ShouldRender } from "@remotebase/components";
import { IProfileDetails, withProfile } from "state/profileSteps";
import { useAnalyticsEventCreator, useTalentProfile } from "hooks";
import { ProfileProps } from "utils";
import { RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import * as Styled from "./styles";

export const TalentlyInterview: FC<ProfileProps> = ({ profileState, updateUserProfile }) => {
  const { updateProfile } = useTalentProfile();
  const { profile } = profileState.data || {};
  const { performAction: analyticsRegisterEvent } = useAnalyticsEventCreator();

  const [startingAiInterview, setStartingAiInterview] = useState(false);

  const interviewInProgress = useMemo(() => profile?.aiInterviewInProgress, [profile]);
  let candidateVideoUrl = "";
  if (profile?.talentType?.hrInterviewAIURL) {
    const videoUrl = profile?.talentType?.hrInterviewAIURL;
    candidateVideoUrl = `${videoUrl}?name=${profileState?.data?.fullName}&email=${profileState?.data?.email}`;
  }

  const updateAiInterviewInProgress = (): void => {
    setStartingAiInterview(true);
    analyticsRegisterEvent(RemoteBaseAnalyticsEvents.START_HR);
    analyticsRegisterEvent(RemoteBaseAnalyticsEvents.START_HR_TALENTLY);
    updateProfile({
      variables: {
        input: {
          aiInterviewInProgress: true,
          expectedVersion: profile?.version,
          id: profile?.id,
        },
      },
      onCompleted: (updatedData) => {
        setStartingAiInterview(false);
        updateUserProfile(updatedData?.updateTalentProfileWithUsdSalary.data as IProfileDetails);
        if (profile?.talentType?.hrInterviewAIURL) {
          window.open(candidateVideoUrl);
        }
      },
      onError: () => setStartingAiInterview(false),
    });
  };

  return (
    <>
      <Styled.TalentlyBtn
        onClick={updateAiInterviewInProgress}
        disabled={startingAiInterview || interviewInProgress}
      >
        <ShouldRender if={startingAiInterview}>
          <BtnLoader />
        </ShouldRender>
        <ShouldRender if={!startingAiInterview && !interviewInProgress}>
          <span>START VIDEO INTERVIEW</span>
        </ShouldRender>
        <ShouldRender if={!startingAiInterview && interviewInProgress}>
          <span>WAITING FOR RESULT</span>
        </ShouldRender>
      </Styled.TalentlyBtn>

      <ShouldRender
        if={
          !startingAiInterview &&
          interviewInProgress &&
          typeof profile?.talent?.profile?.isPassHR !== "boolean"
        }
      >
        <Styled.LinkBtn target="_blank" href={candidateVideoUrl ?? ""}>
          Take me back to the interview
        </Styled.LinkBtn>
      </ShouldRender>
    </>
  );
};

export default withProfile(TalentlyInterview);
