import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useFileUpload } from "hooks";
import { ProfileProps, shouldParseResume } from "utils";
import { ProfileTalentContext, withProfile } from "state/profileSteps";
import { DocumentUploaderCard } from "@remotebase/components";

interface IResumeState {
  file?: File;
  isUploaded: boolean;
  isSubmitted?: boolean;
}

interface Props {
  parseResume: (resumeLink: string) => void;
}

const initialResumeState: IResumeState = { isUploaded: false };

export const ResumeSection: FC<Props & ProfileProps> = ({
  profileState: { data: profileData },
  parseResume,
}) => {
  const { talent, setTalent } = useContext(ProfileTalentContext);
  const [resume, setResume] = useState(initialResumeState);
  const {
    res: { isLoading, data },
    uploadResume,
  } = useFileUpload();

  const handleSubmit = (file: File): void => {
    if (file) uploadResume(file);
    else setTalent({ resumeLink: profileData?.profile?.resumeLink || undefined });
  };

  useEffect(() => {
    if (!isLoading && data) {
      if (shouldParseResume) parseResume(data);
      setTalent({ resumeLink: data });
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (talent?.resumeLink) setResume({ isUploaded: true });
  }, [talent]);

  return (
    <Fragment>
      <h2>Upload your resume.</h2>
      <p>Don’t worry if your resume is outdated, we will help you polish it up later.</p>

      <DocumentUploaderCard
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        document={resume}
        setDocument={setResume}
        pastDocumentUpload={profileData?.profile?.resumeLink}
      />
    </Fragment>
  );
};

export default withProfile(ResumeSection);
