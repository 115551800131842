import styled from "styled-components";

export const TeamBox = styled.div`
  margin: 16px 0;
  text-align: center;
  cursor: pointer;

  @media (max-width: 575px) {
    margin-bottom: 32px;
  }
`;

export const TeamBoxBG = styled.div`
  background-color: #53d5bb;
  border-radius: 20px;
  height: 210px;
  width: 154px;
  position: relative;

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const TeamBoxImageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  img {
    max-width: initial;
    width: 188px;
    height: 250px;
  }
`;

export const TeamBoxName = styled.span`
  display: inline-flex;
  margin-top: 1rem;
  font-size: 14px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;
