import styled from "styled-components";

export const ChatBodyNoMsgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 110px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const ChatBodyNoMsgInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  margin: auto;
`;

export const ChatBodyNoMsgImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 170px;
  max-height: 170px;
  margin-bottom: 20px;
  & img {
    max-width: 100%;
  }
`;

export const ChatBodyNoMsgHd = styled.div`
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  color: #111b21;
  margin: 0px 0px 8px 0px;
  text-align: center;
`;

export const ChatBodyNoMsgPara = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #667781;
  margin: 0px;
  text-align: center;
`;
