import { MessageResponse } from "@remotebase/amplify-constants/API";
import { AuthStateType } from "hooks/utils";
import { IProfile, IProfileDetails, IProfileState, ITalentLevelContext } from "state/profileSteps";
import { ChatStateType } from "state/types/chatTypes";
import { FlattenSimpleInterpolation } from "styled-components";
import { Sidebar } from "./sidebar";

export enum SidebarType {
  Login = "login",
  Create_Profile = "Create profile",
  Dashboard = "Dashboard",
}

export const initialChatContext = {
  recentMessages: [],
  isActiveConvo: null,
};

export type StyledProps = FlattenSimpleInterpolation | string;
export type AuthProps = {
  authState: AuthStateType;
  getAuth: () => void;
  setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>;
};
export type ChatProps = {
  recentMessages: Array<ChatStateType>;
  updateRecentMessage: (message: MessageResponse | null, currentUserId: string) => void;
  markMessageAsRead: (message: MessageResponse | null, convoId: string) => void;
};
export type CurrentChatProps = {
  chatMessages: Array<MessageResponse | null>;
  hasMoreData: boolean;
  chatBoxRef: React.MutableRefObject<HTMLDivElement | null>;
  recentMessages: Array<ChatStateType>;
  updateRecentMessage: (message: MessageResponse | null, convoId?: string) => void;
  fetchMore: (convoId: string) => void;
  updateLocalMessage: (message: MessageResponse | null) => void;
  updateSubscribedMessage: (message: MessageResponse | null) => void;
};
export type ProfileProps = {
  profileState: IProfileState;
  refetchProfile: () => void;
  updateUserInfo: (data: IProfile) => void;
  updateHrStatus: () => void;
  updateUserProfile: (profile: IProfileDetails) => void;
  cleanProfileState: () => void;
  saveProfileImage: (img: string) => void;
  pendingImage?: string | null;
};

export type TalentLevelProps = {
  talentLevel: ITalentLevelContext;
  validateRoute: (stage: Sidebar) => CanNullOrUndefined<boolean>;
};

export type SidebarProps = {
  sidebarType: SidebarType;
  setSidebarType: React.Dispatch<React.SetStateAction<SidebarType>>;
};

export type AnalyticsParams = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  referral: string | null;
};

export type CanNullOrUndefined<T> = T | null | undefined;

export enum AuthType {
  Login = "login",
  Register = "register",
  ForgotPassword = "forgotPassword",
  ResetPassword = "resetPassword",
  Reverify = "reverify",
}

export enum TestStatus {
  PASSED = "Passed",
  FAILED = "Did Not Pass",
  READY = "Ready",
  PENDING = "Pending",
}

export const profileExperienceBoxes = 7;

export const requiredDomainTests = 1;
export const sampleTestLink = "https://www.hackerrank.com/test/sample";

export const joinOnSlackLink =
  "https://join.slack.com/t/pakdevforum/shared_invite/zt-v6eroohc-A0CknLIf32yDhvRr821lrg";

export const initialAvatarValue = "?";

export const environment = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};
export const purpleBlueColor = "#4c40f7";
export const defaultRoute = "#";
export const shouldUseIntercom = process.env.REACT_APP_USE_INTERCOM === "true";
export const shouldParseResume = process.env.REACT_APP_ENABLE_RESUME_PARSING === "true";
export const shouldShowMaintenence = process.env.REACT_APP_MAINTENANCE_MODE === "true";
export const useAnalytics = process.env.REACT_APP_USE_ANALYTICS === "true";
export const sentryDSN = !process.env.REACT_APP_IS_DEV && process.env.REACT_APP_SENTRY_DSN;
export const SOFTWARE_DEVELOPER = "SOFTWARE_DEVELOPER";
