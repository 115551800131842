import { MeetTeamModal } from "components";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { TeamListInterface } from "state/dashboard";
import { TestimonialPersonId } from "utils";
import * as Styled from "./styles";

type Props = {
  teamMember: TeamListInterface;
};

const MeetTeamSingleList: React.FC<Props> = ({ teamMember }) => {
  const [openModal, setOpenModal] = useState(false);
  const { name, imgSrc, storyLink } = teamMember;

  const toggleModal = (): void => {
    setOpenModal(!openModal);
  };
  return (
    <Col xs={12} sm={6} lg={3}>
      <Styled.TeamBox data-testid={TestimonialPersonId} onClick={toggleModal}>
        <Styled.TeamBoxBG>
          <Styled.TeamBoxImageContainer>
            <img src={imgSrc} alt="" />
          </Styled.TeamBoxImageContainer>
        </Styled.TeamBoxBG>
        <Styled.TeamBoxName>{name}</Styled.TeamBoxName>
      </Styled.TeamBox>
      <MeetTeamModal onCancel={toggleModal} storyLink={storyLink} openModal={openModal} />
    </Col>
  );
};

export default MeetTeamSingleList;
