import { TechDomainSkills } from "API";
import { nonTechTalentCategorySkills } from "@remotebase/constants";
import { ITestArray } from "state/codeTests";
import { CanNullOrUndefined } from "utils";
import { imageUrl } from "./imagesUrls";

export const testBlockList: Array<ITestArray> = [
  {
    skillName: TechDomainSkills.ReactAndNode,
    color: "cerise",
    icon: imageUrl.logoReactNode,
    title: "React + Node",
  },
  {
    skillName: TechDomainSkills.NodeJs,
    color: "sushi",
    icon: imageUrl.logoNode,
    title: "NodeJS",
  },
  {
    skillName: TechDomainSkills.React,
    color: "malibu",
    icon: imageUrl.logoReact,
    title: "ReactJS",
  },
  {
    skillName: TechDomainSkills.Ror,
    color: "cream-can",
    icon: imageUrl.logoRails,
    title: "Rails",
  },
  {
    skillName: TechDomainSkills.Python,
    color: "shandy",
    icon: imageUrl.logoPython,
    title: "Python",
  },
  {
    skillName: TechDomainSkills.Angular,
    color: "fireBrick",
    icon: imageUrl.logoAngular,
    title: "Angular",
  },
  {
    skillName: TechDomainSkills.DotNet,
    icon: imageUrl.logoDotnet,
    color: "dotnetcolor",
    title: ".NET",
  },
  {
    skillName: TechDomainSkills.Golang,
    icon: imageUrl.logoGo,
    color: "gopher-blue",
    title: "GoLang",
  },
  {
    skillName: TechDomainSkills.Vue,
    icon: imageUrl.logoVue,
    color: "ocean-green",
    title: "VueJS",
  },
  {
    skillName: TechDomainSkills.Django,
    icon: imageUrl.logoDJango,
    color: "dark-green",
    title: "Django",
  },
  {
    skillName: TechDomainSkills.Aws,
    icon: imageUrl.logoAws,
    color: "orange-yellow",
    title: "AWS",
  },
  {
    skillName: TechDomainSkills.Flutter,
    icon: imageUrl.logoFlutter,
    color: "flutter-blue",
    title: "Flutter",
  },
  {
    skillName: TechDomainSkills.IOS,
    icon: imageUrl.logoIOS,
    color: "IOS-dark",
    title: "IOS",
  },
  {
    skillName: TechDomainSkills.ReactNative,
    icon: imageUrl.reactNative,
    color: "reactNative-blue",
    title: "React Native",
  },
  {
    skillName: TechDomainSkills.Android,
    icon: imageUrl.logoAndroid,
    color: "android-green",
    title: "Android",
  },
  {
    skillName: TechDomainSkills.Drupal,
    icon: imageUrl.logoDrupal,
    color: "drupal-blue",
    title: "Drupal",
  },
  {
    skillName: TechDomainSkills.DevOps,
    icon: imageUrl.devOps,
    color: "devops-green",
    title: "DevOps",
  },
  {
    skillName: TechDomainSkills.AzureDevOps,
    icon: imageUrl.azureDevops,
    color: "azure-devops-green",
    title: "Azure Devops",
  },
  {
    skillName: TechDomainSkills.Java,
    icon: imageUrl.java,
    color: "java-blue",
    title: "Java",
  },
  {
    skillName: TechDomainSkills.Magento,
    icon: imageUrl.logoMagento,
    color: "magento-orange",
    title: "Magento",
  },
  {
    skillName: TechDomainSkills.DataScience,
    icon: imageUrl.logoDataScience,
    color: "data-science-red",
    title: "Data Engineer",
  },
  {
    skillName: TechDomainSkills.PHP,
    icon: imageUrl.logoPHP,
    color: "php-blue",
    title: "PHP",
  },
  {
    skillName: TechDomainSkills.Oracle,
    icon: imageUrl.logoOracle,
    color: "oracle-red",
    title: "Oracle",
  },
  {
    skillName: TechDomainSkills.GCP,
    icon: imageUrl.logoGCP,
    color: "gcp-blue",
    title: "GCP",
  },
  {
    skillName: TechDomainSkills.DESIGNER,
    icon: imageUrl.logoNonTech,
    color: "non-tech-color",
    title: nonTechTalentCategorySkills[TechDomainSkills.DESIGNER],
  },
  {
    skillName: TechDomainSkills.PERFORMANCE_MARKETING_SPECIALIST,
    icon: imageUrl.logoNonTech,
    color: "non-tech-color",
    title: nonTechTalentCategorySkills[TechDomainSkills.PERFORMANCE_MARKETING_SPECIALIST],
  },
  {
    skillName: TechDomainSkills.SDR,
    icon: imageUrl.logoNonTech,
    color: "non-tech-color",
    title: nonTechTalentCategorySkills[TechDomainSkills.SDR],
  },
  {
    skillName: TechDomainSkills.TECHNICAL_RECRUITER,
    icon: imageUrl.logoNonTech,
    color: "non-tech-color",
    title: nonTechTalentCategorySkills[TechDomainSkills.TECHNICAL_RECRUITER],
  },
];

export const defaultTestBlock: ITestArray = {
  icon: imageUrl.logoDefault,
  color: "default",
  skillName: "",
  title: "",
};

export enum TestsType {
  DomainTest = "Domain Test",
  HRInterview = "Video Screening",
  ProblemSolving = "Problem Solving",
  FinalInterview = "Final Interview",
  Network = "Network",
}

export type TestsClearedType = {
  [TestsType.DomainTest]: boolean | null;
  [TestsType.HRInterview]: CanNullOrUndefined<boolean>;
  [TestsType.ProblemSolving]: CanNullOrUndefined<boolean>;
  [TestsType.FinalInterview]: CanNullOrUndefined<boolean>;
  [TestsType.Network]: CanNullOrUndefined<boolean>;
};

export const PendingDomainTestTooltip = "Result will come in 10 to 15 mins";

export interface PendingDomainTestType {
  testId: string;
  url: string;
  tag: string;
  candidateId: string;
}

export interface HackerRankInviteResponse {
  candidateId: string | undefined;
  url: string;
}

export const skillTechHeading = "Pass a tech stack 📈";
export const skillNonTechHeading = "Pass a skill test";

export const skillTechSubHeading =
  "Unlock your Live Coding Challenge by passing the tests for any tech stack below.";
export const skillNonTechSubHeading =
  "Unlock the next hiring stage by passing the MCQ-based test for your skill set.";
