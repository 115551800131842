import { FC, Fragment } from "react";
import classNames from "classnames";
import { ITestBlock } from "state/codeTests";
import { HackerRank } from "components";
import { DomainPassIcon, DomainPendingIcon, DomainFailIcon } from "assets/icons";
import { PendingDomainTestType, PendingDomainTestTooltip, Sidebar, TestStatus } from "utils";
import * as Styled from "./styles";

type Props = {
  testBlock: ITestBlock;
  status: TestStatus;
  pendingDomainTestData: PendingDomainTestType | undefined;
};
export const TestBlockSingleList: FC<Props> = ({ testBlock, status, pendingDomainTestData }) => {
  const { title, color, icon, id: testId } = testBlock;

  const getBoxText = (): JSX.Element => {
    if (status !== TestStatus.READY) {
      return <Styled.SubText>{status}</Styled.SubText>;
    }
    return <Fragment></Fragment>;
  };
  const getStatusIcon = (): JSX.Element => {
    switch (status) {
      case TestStatus.PASSED:
        return <DomainPassIcon />;
      case TestStatus.PENDING:
        return <DomainPendingIcon />;
      case TestStatus.FAILED:
        return <DomainFailIcon />;
      default:
        return <Fragment />;
    }
  };

  return (
    <HackerRank
      clickableId={`${testId}_id`}
      test={testBlock}
      type={Sidebar.DomainTest}
      pendingDomainTest={pendingDomainTestData}
    >
      <Styled.TestBox
        data-testid={"single_test_block"}
        className={classNames({
          [`${color}`]: status === TestStatus.PASSED || status === TestStatus.READY,
          fail: status === TestStatus.FAILED || status === TestStatus.PENDING,
          test__passed: status !== TestStatus.READY,
          toolText: status === TestStatus.PENDING,
        })}
      >
        <Styled.Tooltip
          className={classNames({
            tooltip: status === TestStatus.PENDING,
          })}
        >
          {PendingDomainTestTooltip}
        </Styled.Tooltip>
        <Styled.StatusIconBox>{getStatusIcon()}</Styled.StatusIconBox>

        <Styled.TestImgBox>
          <img
            className={classNames({
              grayed: status === TestStatus.FAILED || status === TestStatus.PENDING,
            })}
            src={icon}
            alt="logo"
          />
        </Styled.TestImgBox>
        <Styled.TestBoxDetails>
          <Styled.TestBoxText>{title}</Styled.TestBoxText>
          {getBoxText()}
        </Styled.TestBoxDetails>
        <Styled.TestOverlay>
          <Styled.TestBtn id={`${testId}_id`}>Start test</Styled.TestBtn>
        </Styled.TestOverlay>
      </Styled.TestBox>
    </HackerRank>
  );
};

export default TestBlockSingleList;
