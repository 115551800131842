import { FC, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Color } from "@remotebase/constants";
import { ActionBtn } from "../../../buttons";
import { ProfileEditInput, ProfileTextArea, SelectMonthYear } from "../../../formInput";
import {
  editEmploymentHistoryTitle,
  TalentEditData,
  IEmploymentHistory,
  addEmploymentHistoryTitle,
  companyPlaceholder,
  checkBoxLabel,
  titlePlaceholder,
  summaryPlaceholder,
  summaryLabel,
  companyLabel,
  startDateLabel,
  endDateLabel,
  titleLabel,
  ReturnEmploymentValidationType,
  checkboxInputText,
  formInputText,
  mandatoryNotification,
  EmploymentFormEnum,
  addBtnText,
  cancelBtnText,
  updateBtnText,
  employmentFormValidation,
  educationBtnWidth,
  submissionProfileErrorMessage,
  submissionErrorTitle,
  submissionProfileSuccessMessage,
  submissionSuccessTitle,
} from "../../../utils";
import * as S from "../styles";

export const EditEmploymentHistory: FC<TalentEditData> = ({
  data,
  close,
  setTalentData,
  talentUpdateProps,
  editIndex,
}) => {
  const {
    isLoading,
    profileData,
    updateProfile,
    refetchProfile,
    showError: showMessage,
  } = talentUpdateProps || {};
  const employmentHistory = data?.profile?.employmentHistory || [];

  const extractedHistory = employmentHistory.map((currHistory) => ({
    company: currHistory?.company,
    summary: currHistory?.summary,
    title: currHistory?.title,
    startDate: currHistory?.startDate,
    endDate: currHistory?.endDate,
    currentJob: currHistory?.currentJob,
  }));

  const selectedEmployment = useMemo(() => {
    if (!_.isNil(editIndex)) {
      return extractedHistory[editIndex];
    }
    return null;
  }, [extractedHistory, editIndex]);

  const defaultFormData = {
    company: selectedEmployment?.company || "",
    summary: selectedEmployment?.summary || "",
    title: selectedEmployment?.title || "",
    startDate: selectedEmployment?.startDate || "",
    endDate: selectedEmployment?.endDate || null,
    currentJob: selectedEmployment?.currentJob || false,
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<IEmploymentHistory>(defaultFormData);
  const [formError, setFormError] = useState<ReturnEmploymentValidationType>({});

  const updateFormData = (key: string, value: string | boolean | null): void => {
    setFormError({});
    if (key === EmploymentFormEnum.currentJob) {
      const newFormData = { ...formData, currentJob: !!value };
      if (value) newFormData[EmploymentFormEnum.endDate] = null;
      setFormData(newFormData);
    } else setFormData({ ...formData, [key]: value });
  };

  const handleSave = (): void => {
    const errorObject = employmentFormValidation(formData);
    if (Object.values(errorObject).some((it) => !!it)) {
      setFormError(errorObject);
      return;
    }
    const currHistory = [...extractedHistory];
    setIsSubmitted(true);
    if (!_.isNil(editIndex)) {
      currHistory[editIndex] = {
        ...currHistory[editIndex],
        ...formData,
      };
    } else {
      currHistory.push(formData);
    }
    if (updateProfile) {
      updateProfile({
        variables: {
          input: {
            id: data?.profile?.id,
            expectedVersion: data?.profile?.version,
            employmentHistory: currHistory,
          },
        },
        onCompleted: () => {
          showMessage?.({
            title: submissionSuccessTitle,
            message: submissionProfileSuccessMessage,
          });
        },
        onError: () => {
          showMessage?.({ title: submissionErrorTitle, message: submissionProfileErrorMessage });
        },
      });
    }
  };
  useEffect(() => {
    if (profileData && isSubmitted) {
      if (setTalentData && refetchProfile) {
        setIsSubmitted(false);
        if (data) setTalentData({ ...data, profile: profileData });
        refetchProfile();
      }
      setFormData(defaultFormData);
      if (close) close();
    }
  }, [profileData, isSubmitted]);

  return (
    <S.EditorWrapper>
      <S.EditSectionHeading>
        {!_.isNil(editIndex) ? editEmploymentHistoryTitle : addEmploymentHistoryTitle}
      </S.EditSectionHeading>
      <S.FormBody>
        <S.FormGroup>
          <ProfileEditInput
            type={formInputText}
            label={companyLabel}
            placeholder={companyPlaceholder}
            value={formData[EmploymentFormEnum.company]}
            errorMsg={formError[EmploymentFormEnum.company]}
            onTextChange={(val): void => updateFormData(EmploymentFormEnum.company, val)}
          />
        </S.FormGroup>
        <S.FormGroup>
          <SelectMonthYear
            label={startDateLabel}
            valueLabel={EmploymentFormEnum.startDate}
            date={formData[EmploymentFormEnum.startDate]}
            errorMsg={formError[EmploymentFormEnum.startDate]}
            handleChange={updateFormData}
          />
          <S.CheckBoxWrapper>
            <S.CheckBox
              type={checkboxInputText}
              defaultChecked={!!formData[EmploymentFormEnum.currentJob]}
              onChange={(): void =>
                updateFormData(EmploymentFormEnum.currentJob, !formData.currentJob)
              }
            />
            <S.SelectLabel>{checkBoxLabel}</S.SelectLabel>
          </S.CheckBoxWrapper>
        </S.FormGroup>
        <S.FormGroup>
          <SelectMonthYear
            label={endDateLabel}
            valueLabel={EmploymentFormEnum.endDate}
            date={formData[EmploymentFormEnum.endDate]}
            handleChange={updateFormData}
            isDisabled={!!formData[EmploymentFormEnum.currentJob]}
            minYear={new Date(formData[EmploymentFormEnum.startDate]).getFullYear()}
          />
        </S.FormGroup>
        <S.FormGroup>
          <ProfileEditInput
            type={formInputText}
            label={titleLabel}
            placeholder={titlePlaceholder}
            value={formData[EmploymentFormEnum.title]}
            errorMsg={formError[EmploymentFormEnum.title]}
            onTextChange={(val): void => updateFormData(EmploymentFormEnum.title, val)}
          />
        </S.FormGroup>
        <S.FormGroup>
          <ProfileTextArea
            label={summaryLabel}
            placeholder={summaryPlaceholder}
            value={`${formData[EmploymentFormEnum.summary]}`}
            onTextChange={(val): void => updateFormData(EmploymentFormEnum.summary, val)}
          />
        </S.FormGroup>
        <S.FootNote>{mandatoryNotification}</S.FootNote>
      </S.FormBody>

      <S.BtnGroup>
        <ActionBtn
          btnText={cancelBtnText}
          clickHandler={close}
          width={educationBtnWidth}
          color={Color.LIGHT_BLUE}
          backgroundColor={Color.TRANSPARENT}
        />
        <ActionBtn
          btnText={!_.isNil(editIndex) ? updateBtnText : addBtnText}
          isLoading={isLoading}
          clickHandler={handleSave}
          width={educationBtnWidth}
          color={Color.WHITE}
          backgroundColor={Color.LIGHT_BLUE}
          disabled={isLoading}
        />
      </S.BtnGroup>
    </S.EditorWrapper>
  );
};

export default EditEmploymentHistory;
