import styled from "styled-components";
import { StyledProps } from "utils";

type ProblemSolvingWrapperProps = {
  isGrey: boolean;
};
export const ProblemSolvingWrapper = styled.div<ProblemSolvingWrapperProps>`
  border: 1px solid #e4e4e4;
  padding: 20px;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  max-width: 840px;
  margin: 0 auto;
  background-color: #fcfcfc;
  margin-top: calc(1rem * 1.5);
  color: ${(props): StyledProps => (props.isGrey ? "#5e5e5e" : "#11142d")};
`;

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
`;

export const ProblemSolUl = styled.ul`
  padding-left: 28px;
  margin-bottom: 18px;
`;

export const ProblemSolLi = styled.li`
  list-style-type: decimal;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;

export const ProblemSolPara = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;
