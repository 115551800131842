import { Table } from "react-bootstrap";
import styled, { css } from "styled-components";
import { StyledProps } from "utils";

// eslint-disable-next-line import/prefer-default-export
export const RefTable = styled(Table)<{ $isPaginated: boolean }>`
  min-width: 950px;
  margin-bottom: 0;

  thead tr th {
    font-size: 18px;
    color: #34495e;
    font-weight: 500;
    padding: 16px 6px 16px 0;
    border-top: 0;
    word-break: break-word;
    border-bottom: 1px solid #e2e2e2;
    font-family: "Poppins", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    &:nth-child(1) {
      width: 28%;
    }
    &:nth-child(2) {
      width: 16%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 10%;
    }
  }

  tbody tr td {
    font-size: 16px;
    color: #34495e;
    font-weight: normal;
    padding: 16px 6px 16px 0;
    border-top: 0;
    word-break: break-word;
    border-bottom: 1px solid #e2e2e2;
    font-family: "Poppins", sans-serif;
    &:nth-child(1) {
      width: 28%;
    }
    &:nth-child(2) {
      width: 16%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 10%;
    }
  }
  ${(props): StyledProps =>
    props.$isPaginated
      ? css`
          tbody tr:nth-last-child(-n + 2) td {
            border-bottom: 0;
          }
        `
      : css`
          tbody tr:last-child td {
            border-bottom: 0;
          }
        `}
`;
