import React, { useState } from "react";
import classNames from "classnames";
import { LandingLine } from "assets/icons";
import {
  fetchUtmParams,
  imageUrl,
  landingNavItems,
  redirectToExperience,
  redirectToHireDeveloper,
  shouldShowMaintenence,
  UnAuthRoutes,
} from "utils";
import { usePixelTrack } from "hooks";
import { FBPixelCustomEvents } from "@remotebase/constants";
import * as S from "./styles";

export const LandingHeader: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { performAction } = usePixelTrack();
  const toggleMenu = (): void => {
    setMobileMenu(true);
  };

  const redirectToRoute = (): string => {
    if (shouldShowMaintenence) return UnAuthRoutes.Maintenance;
    return `${UnAuthRoutes.Register}?${fetchUtmParams()}`;
  };

  const closeMenu = (): void => {
    setMobileMenu(false);
  };

  return (
    <S.HeaderWrapper
      id="page-top"
      className="aos-item"
      data-aos="header-background-on-scroll"
      data-aos-anchor="#aboutUs"
      data-aos-anchor-placement="top-top"
    >
      <S.Container>
        <S.HeaderLogoBox target="_blank" to={{ pathname: redirectToExperience }}>
          <S.HeaderLogoImg
            src={imageUrl.newRBLogo}
            alt="logo"
            className={classNames({ hide: mobileMenu })}
          />
        </S.HeaderLogoBox>
        <S.HeaderWrap className={classNames({ visible: mobileMenu })}>
          <S.HeaderCross onClick={closeMenu}>
            <i className="icon-cross" />
          </S.HeaderCross>
          <S.HeaderNav>
            {landingNavItems?.map((item, index) => {
              return (
                <S.HeaderItem key={`${item.text}-${index}`} onClick={closeMenu} href={item.href}>
                  {item.text}
                </S.HeaderItem>
              );
            })}
          </S.HeaderNav>

          <S.HeaderLineBox>
            <LandingLine />
          </S.HeaderLineBox>
        </S.HeaderWrap>
        <S.HeaderBtnCanvas>
          <S.HeaderBtn target="_blank" to={{ pathname: redirectToHireDeveloper }}>
            Hire Developers
          </S.HeaderBtn>
          <S.HeaderBtn
            to={redirectToRoute()}
            onClick={(): void => performAction(FBPixelCustomEvents.DEVELOPER_SIGNUP)}
          >
            Apply as a Developer
          </S.HeaderBtn>
        </S.HeaderBtnCanvas>
        <S.HeaderBurger onClick={toggleMenu} />
      </S.Container>
      <S.HeaderBg />
    </S.HeaderWrapper>
  );
};

export default LandingHeader;
