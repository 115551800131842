import styled from "styled-components";
import { StyledProps } from "utils";

export const TabWrapper = styled.div`
  border: 1px solid #e4e4e4;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const TabHeading = styled.h5<{
  isActive: boolean;
}>`
  margin-bottom: 0;
  color: #3c3c3c;
  font-size: 1rem;
  padding-top: 2rem;
  cursor: pointer;
  font-weight: ${(props): StyledProps => (props.isActive ? "500" : "300")};
  padding-bottom: ${(props): StyledProps => (props.isActive ? "calc(1.5rem - 2px)" : "1.5rem")};
  border-bottom: ${(props): StyledProps => (props.isActive ? "2px solid #3615bd" : "none")};
`;

export const HorizontalDivider = styled.hr`
  margin-bottom: 0;
  margin-top: 0;
`;
