import { FC } from "react";
import { SidebarProps, sidebarCaptionTestId } from "utils";
import { withSidebar } from "state/sidebar";
import { withProfile } from "state/profileSteps";
import SidebarMenu from "./SidebarMenu";
import * as Styled from "./styles";

const SidebarList: FC<SidebarProps> = () => {
  return (
    <Styled.SidebarGroup>
      <Styled.Caption data-testid={sidebarCaptionTestId}>
        Job <span>Application</span>
      </Styled.Caption>
      <SidebarMenu />
    </Styled.SidebarGroup>
  );
};

export default withSidebar(withProfile(SidebarList));
