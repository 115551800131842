import { ApplicationStatus, FaqList, MeetTeam, PerksList, WorkFigureList } from "components";
import React, { useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import AOS from "aos";
import { autoScrollOnTop } from "utils";
import { useApplyJobWorkable } from "hooks/workable/useApplyJobWorkable";
import { ProfileContext } from "state/profileSteps";
import { ErrorProps } from "@remotebase/constants";
import withError from "state/error/withErrorHoc";
import * as Styled from "./styles";

const Dashboard: React.FC<ErrorProps> = ({ showError }) => {
  const { applyThroughWorkableApi, data } = useApplyJobWorkable();
  const {
    profileState: { data: user },
  } = useContext(ProfileContext);
  useEffect(() => {
    autoScrollOnTop();
    AOS.init();
    if (localStorage.getItem("atsJobID")) {
      applyThroughWorkableApi({
        variables: {
          input: {
            email: user?.email,
            firstname: user?.fullName.split(" ")[0],
            lastname: user?.fullName.split(" ")[1],
            resumeURL: user?.profile?.resumeLink,
            phone: user?.phone,
            jobcode: localStorage.getItem("atsJobID"),
          },
        },
      });
      console.log(`${localStorage.getItem("jobTitle")} job application started`);
    }
  }, []);

  useEffect(() => {
    console.log("Application submission status:", data);
    if (data) {
      showError({
        title: data.applyJobWorkable?.message || "Error",
        message:
          data.applyJobWorkable?.code === 200
            ? `${localStorage.getItem("jobTitle")} job application submitted successfully`
            : `${localStorage.getItem("jobTitle")} job application could not be sumbitted`,
      });
      localStorage.removeItem("atsJobID");
      localStorage.removeItem("jobTitle");
    }
  }, [data]);

  return (
    <Styled.DashboardWrapper>
      <Styled.DashboardInner>
        <ApplicationStatus />
        <Styled.WorkDiv id="aboutUs">
          <Styled.WorkCanvas>
            <Styled.WorkCircles />

            <Styled.WorkTextWrapper>
              <Styled.Tagline>Join the top 1% of the country’s leading talent pool.</Styled.Tagline>
              <Styled.H2 className="aos-item">Realise your future with us!</Styled.H2>
              <Styled.WorkText>
                Becoming part of Remotebase extends further than just a job. We, at the company, aim
                to make your time with us oriented towards your professional success just as much as
                your personal progress. We provide opportunities for you to create innovative
                solutions for real-life startups that help them accelerate their growth while
                enabling yours
              </Styled.WorkText>
            </Styled.WorkTextWrapper>

            <Styled.PerksDiv>
              <Styled.PerksText>Why join Remotebase?</Styled.PerksText>
              <PerksList />
            </Styled.PerksDiv>

            <Styled.WorkImgBox>
              <Styled.WorkImg
                className="aos-item"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-scale="2"
                data-orientation="down"
                src="/images/work-cube.png"
                alt=""
              />
            </Styled.WorkImgBox>
            <WorkFigureList />
          </Styled.WorkCanvas>
        </Styled.WorkDiv>
        {/* Commenting it temporarily
         * Code commented by masoodtalha
         */}
        <div id="meet__team">
          <Styled.HeadingMain>Meet our Talents 👋</Styled.HeadingMain>
          <Styled.HeadingSmall>Click on our talent to watch their story.</Styled.HeadingSmall>
          <MeetTeam />
        </div>

        <div id="faq__section">
          <Styled.HeadingMain>FAQ section 👋</Styled.HeadingMain>
          <Accordion className="mt-5 pt-2">
            <FaqList />
          </Accordion>
        </div>
      </Styled.DashboardInner>
    </Styled.DashboardWrapper>
  );
};

export default withError(Dashboard);
