import { upperFirst } from "lodash";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getMonthsDropdown, getYearsDropdown } from "../helpers";
import { monthPlaceholder, MonthYearFields, SelectOption, yearPlaceholder } from "../utils";
import { SelectInput } from "./SelectInput";
import * as S from "./styles";

interface ISelectMonthYear {
  label?: string;
  valueLabel: string;
  date: null | string;
  handleChange: (key: string, value: string) => void;
  showCurrent?: boolean;
  errorMsg?: string;
  isDisabled?: boolean;
  minYear?: number;
}

export const SelectMonthYear: FC<ISelectMonthYear> = ({
  valueLabel,
  date,
  handleChange,
  label,
  errorMsg,
  isDisabled,
}) => {
  const [years, setYears] = useState<SelectOption[]>([]);
  const [months, setMonths] = useState<SelectOption[]>([]);

  const {
    setValue,
    watch: watchDate,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const [selectedMonth, selectedYear] = watchDate([MonthYearFields.month, MonthYearFields.year]);

  useEffect(() => {
    if (!selectedYear || (!selectedMonth && selectedMonth !== 0)) return;

    const formattedDate = moment({
      year: selectedYear,
      month: selectedMonth,
    });

    handleChange(valueLabel, formattedDate.format("YYYY-MM-DD"));
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    setYears(getYearsDropdown());
    setMonths(getMonthsDropdown());
  }, []);

  useEffect(() => {
    const formattedDate = moment(date);
    const month = formattedDate.month();
    const year = formattedDate.year();
    const currentYear = Number(selectedYear);
    const currentMonth = Number(selectedMonth);

    if (currentYear !== year || currentMonth !== month)
      reset({
        [MonthYearFields.year]: year,
        [MonthYearFields.month]: month,
      });
  }, [date]);

  return (
    <S.Container>
      <S.SelectLabel>{label}</S.SelectLabel>
      <S.MonthYearWrapper isDisabled={isDisabled}>
        <SelectInput
          placeholder={monthPlaceholder}
          isDisabled={isDisabled}
          selected={months.find((it) => it.value === String(selectedMonth))}
          options={months}
          handleSingleSelect={(option): void => setValue(MonthYearFields.month, option.value)}
          removeMargin
        />
        <SelectInput
          placeholder={yearPlaceholder}
          isDisabled={isDisabled}
          selected={years.find((it) => it.value === String(selectedYear))}
          options={years}
          handleSingleSelect={(option): void => setValue(MonthYearFields.year, option.value)}
          removeMargin
        />
      </S.MonthYearWrapper>
      <S.EditFormError>{upperFirst(errorMsg)}</S.EditFormError>
    </S.Container>
  );
};

export default SelectMonthYear;
