import styled from "styled-components";

export const ChatPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
  padding: 0px;
  @media only screen and (min-width: 768px) {
    height: 100vh;
    overflow: hidden;
    min-height: 100%;
  }
  @media only screen and (min-width: 1440px) {
    padding: 13px 20px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: auto;
      height: 130px;
      background-color: rgb(54 21 189);
      border: none;
      z-index: 1;
    }
  }
  &::before {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
  }
`;
export default ChatPageWrapper;
