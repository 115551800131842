import { FC } from "react";
import { InterviewsDisplay } from "components";
import * as S from "./styled";

export const EngineerBookings: FC = () => {
  return (
    <S.BookingsWrapper>
      <S.BookingsContainer>
        <S.BookingsHeading>Manage Bookings</S.BookingsHeading>
        <S.BookingsParagraph>Here is where you manage your bookings.</S.BookingsParagraph>
        <S.BookingsInner>
          <InterviewsDisplay />
        </S.BookingsInner>
      </S.BookingsContainer>
    </S.BookingsWrapper>
  );
};

export default EngineerBookings;
