import { FC, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "assets/icons";
import { EmploymentEntry } from "@remotebase/amplify-constants/API";
import { useForm } from "react-hook-form";
import {
  EmploymentFormEnum,
  extractMonthAndYear,
  EmploymentFormModal,
} from "@remotebase/components";
import * as Styled from "../../styles";

interface Props {
  data: Array<EmploymentEntry>;
  updateEntries: (data: Array<EmploymentEntry>) => void;
}

const EmploymentHistorySection: FC<Props> = ({ data, updateEntries }) => {
  const [showModal, setShowModal] = useState(false);
  const formHook = useForm({
    mode: "onChange",
  });

  const { reset } = formHook;

  const initEdit = (index: number, employment: EmploymentEntry): void => {
    const { __typename: typeName, ...employmentData } = employment;

    reset({
      ...employmentData,
      [EmploymentFormEnum.isEdit]: true,
      [EmploymentFormEnum.selectedIndex]: index,
    });

    setShowModal(true);
  };

  const initCreate = (): void => {
    reset({
      [EmploymentFormEnum.isEdit]: false,
    });

    setShowModal(true);
  };

  const initDeleteEntry = (id: number): void => {
    updateEntries([...data.slice(0, id), ...data.slice(id + 1)]);
  };

  const handleFormSubmission = (savedData: EmploymentEntry, index = -1): void => {
    if (!index && index !== 0) return;
    if (index === -1) updateEntries([...data, savedData]);
    else updateEntries([...data.slice(0, index), savedData, ...data.slice(index + 1)]);
  };

  return (
    <>
      <Styled.ExpComponent>
        <Styled.SectionHead>
          <Styled.SectionTitle>Experience</Styled.SectionTitle>
          <Styled.SectionBtn onClick={(): void => initCreate()}>
            <PlusIcon />
            Add
          </Styled.SectionBtn>
        </Styled.SectionHead>
        <Styled.ExpGrid>
          {data.map((history, index) => (
            <Styled.ExpCard key={`history-${index}`}>
              <Styled.ExpContent>
                <Styled.ExpTitleCanvas>
                  <Styled.ExpRole>{history.title}</Styled.ExpRole>
                  <Styled.ExpRoleType>{`. ${history.company}`}</Styled.ExpRoleType>
                </Styled.ExpTitleCanvas>
                <Styled.ExpDate>
                  {`${extractMonthAndYear(history.startDate)} -
                  ${extractMonthAndYear(history.endDate)}`}
                </Styled.ExpDate>
                <Styled.ExpPara>{history.summary}</Styled.ExpPara>
              </Styled.ExpContent>
              <Styled.EducationOptCanvas>
                <Styled.EducationOpt onClick={(): void => initEdit(index, history)}>
                  <PencilIcon />
                </Styled.EducationOpt>
                <Styled.EducationOpt onClick={(): void => initDeleteEntry(index)}>
                  <TrashIcon />
                </Styled.EducationOpt>
              </Styled.EducationOptCanvas>
            </Styled.ExpCard>
          ))}
        </Styled.ExpGrid>
      </Styled.ExpComponent>
      <EmploymentFormModal
        show={showModal}
        formHook={formHook}
        close={(): void => setShowModal(false)}
        handleFormSubmission={handleFormSubmission}
      />
    </>
  );
};

export default EmploymentHistorySection;
