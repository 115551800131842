import { Link } from "react-router-dom";
import styled from "styled-components";

export const SkillsWrapper = styled.div`
  padding: 70px 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1108px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
`;

export const SkillsTitle = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
`;

export const SkillsInfo = styled.p`
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  line-height: 180%;
  max-width: 620px;
  margin: 0 auto 50px;
  text-align: center;
`;

export const DevCarousel = styled.div`
  width: 100%;
  position: relative;

  & .dev__carousel {
    padding: 20px 0;

    & .devCarousel__ul {
      & .devCarousel__li {
        height: 330px;
        &:first-child {
          @media screen and (min-width: 640px) {
            margin-left: -15px;
          }
        }
        &:last-child {
          @media screen and (min-width: 640px) {
            margin-right: -15px;
          }
        }
      }
    }
  }
`;

export const SkillsContent = styled.div`
  width: 100%;
`;

export const SkillsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const SkillsItem = styled.div`
  position: relative;

  &:nth-child(1) {
    & a {
      & > div {
        background-color: #7acbff;
      }
    }
  }
  &:nth-child(2) {
    & a {
      & > div {
        background-color: #8cc84b;
      }
    }
  }
  &:nth-child(3) {
    & a {
      & > div {
        background-color: #376e9d;
      }
    }
  }
  &:nth-child(4) {
    & a {
      & > div {
        background-color: #cc0000;
      }
    }
  }
`;

export const SignupLink = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 20px;
  transition: all 0.25s;
  text-decoration: none;

  @media only screen and (min-width: 576px) {
    padding: 30px;
  }

  &:hover,
  &:focus {
    background-color: #fff;
    text-decoration: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
`;

export const SkillsPreview = styled.div`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 15px;
`;

export const SkillsPhoto = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const SkillsVacancy = styled.h3`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
`;

export const SkillsText = styled.p`
  font-size: 14px;
  color: #323232;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 34px;
`;

export const SkillsLink = styled.a`
  font-size: 14px;
  color: #3615bd;
  font-weight: 400;
  line-height: 170%;
  display: inline-flex;
  margin-bottom: 6px;
`;

export const SkillsBtnCanvas = styled.div`
  padding-top: 50px;
  text-align: center;

  a {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 120%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 190px;
    height: 60px;
    border-radius: 40px;
    background-color: #3615bd;
    border: none;
    font-family: "Poppins", sans-serif;
    transition: all 0.25s;

    &:hover {
      background-color: #1c0381;
      border: none;
      box-shadow: none;
      text-decoration: none;
      color: #fff;
    }
  }
`;

export const CarouselBtnCanvas = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
`;

export const CarouselPrevBtnPosition = styled.div`
  position: absolute;
  top: 338px;
  left: 430px;
`;

export const CarouselNextBtnPosition = styled.div`
  position: absolute;
  top: 338px;
  right: 434px;
`;

export const CarouselBtnPrev = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c65fe;
  border: 1px solid #3c65fe;
  position: static;

  @media screen and (min-width: 576px) {
    width: 40px;
    height: 40px;
  }

  & svg {
    width: 14px;
    height: 14px;

    @media screen and (min-width: 576px) {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: #fff;
    }
  }

  &:disabled {
    background-color: transparent;

    & svg {
      & path {
        fill: #3c65fe;
      }
    }
  }
`;

export const CarouselBtnNext = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c65fe;
  border: 1px solid #3c65fe;
  position: static;

  @media screen and (min-width: 576px) {
    width: 40px;
    height: 40px;
  }

  & svg {
    width: 14px;
    height: 14px;

    @media screen and (min-width: 576px) {
      width: 18px;
      height: 18px;
    }

    & path {
      fill: #fff;
    }
  }

  &:disabled {
    background-color: transparent;

    & svg {
      & path {
        fill: #3c65fe;
      }
    }
  }
`;
