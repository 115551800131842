import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 40px;
  font-weight: 600;
  color: #11142d;
  font-family: "Poppins", sans-serif;
  margin-bottom: calc(0.5 * 1rem);
  padding-bottom: calc(0.25 * 1rem);
`;

export const TestPara = styled.p`
  font-size: 18px;
  color: #808191;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  max-width: 550px;
`;

export const TestGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-gap: 30px;
  padding-top: 34px;

  @media (max-width: 1199px) {
    grid-gap: 24px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`;
